import { Permission } from '../../models/parse-model/permission';

export class Contact {

    contactName: string;
    companyName: string;
    title: string;
    relation: string;
    contactSource: string;
    phoneNumber: number;
    email: string;
    website: string;
    countryId: number;
    stateId: number;
    companyPhoneNumber?: string;
    companyAddress?: string;
    companyEmail?: string;
    industry?: string;
    rcno?: string;
    primaryTitleId?: number;
    referral?: number;
    isReferedbyClient?: boolean;

    public id?: number;

    public firstName: string;
    public lastName: string;

    public jobDescription: string;

    public instantMessenger: string;

    public contactStatusId: number;

    public firmLabelId: number;


    public firmId: number;

    public contactSourceId: number;

    public titleId: number;
    contactLabelId: number;

}

