import { RenameDocumentModel } from './../../models/view-model/document-rename';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, OnChanges } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-rename-item',
  templateUrl: './rename-item.component.html',
  styleUrls: ['./rename-item.component.scss']
})
export class RenameItemComponent implements OnInit, OnChanges {
  renameModalForm: FormGroup;
  newName = new RenameDocumentModel();
  @Input() btnSpinner$: Observable<boolean> = of(false);
  @Input() oldName: string;
  @Output() emitNewName = new EventEmitter();
  btnSpinner: boolean;
  @ViewChild('close_modal_editFirm') close_modal_editFirm: ElementRef;

  constructor(private fb: FormBuilder) {
    this.btnSpinner$.subscribe(result => {
      this.btnSpinner = result;
    });

    this.renameModalForm = fb.group({
      documentNewName: ['', Validators.required],
    });
  }
  ngOnChanges(): void {
    this.renameModalForm.patchValue({
      documentNewName: this.oldName
    });
  }

  get documentNewName() {
    return this.renameModalForm.get('documentNewName');
  }

  ngOnInit() {

  }

  renameDocument() {


    this.close_modal_editFirm.nativeElement.click();
    this.newName.name = this.renameModalForm.value.documentNewName;

    this.emitNewName.emit(this.newName.name);


  }

}
