import { Component, OnInit } from "@angular/core";
import { LicensingService } from "src/app/services/licensing.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PaystackOptions, PlanDetails } from "../helpers/paystack-options";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { CompletePayment } from "../helpers/complete-payment";
import { Notifier } from "src/app/shared/base-class/notifier";
import * as fromStore from "../../store/reducers/calendar-lander.reducer";
import { UserDetail } from "src/app/models/view-model/user-detail";
import * as LoginActions from "../../auth/state/login.actions";
import { select, Store } from "@ngrx/store";
import * as fromUserHeaderStore from "../../store/reducers/user-header.reducer";
import { isNullOrUndefined } from "util";
import { AppConfigService } from "src/app/shared/helpers/app-config.service";

interface MyWindow extends Window { PaystackPop: any; };
declare var window: MyWindow;

@Component({
  selector: "app-money-transfer-option",
  templateUrl: "./money-transfer-option.component.html",
  styleUrls: ["./money-transfer-option.component.scss"],
})
export class MoneyTransferOptionComponent extends Notifier implements OnInit {
  // banks: any [] =[];
  banks: any[] = this.config.getConfig().banks;
  isLicensed: Boolean = false;
  btnSpinner: boolean;
  bankTranseferForm: FormGroup;
  selectedTransactionType: number;
  private paystackOptions: PaystackOptions;
  showSmallSpinner: boolean;
  paymentDetails = new PlanDetails();
  userData = new UserDetail();

  constructor(
    private license: LicensingService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private rootStore: Store<fromStore.State>,
    public service: LicensingService,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>,
    private config: AppConfigService
  ) {
    super(headerStore);
    this.rootStore.dispatch(new LoginActions.LoadLocalUserDetail());
    this.rootStore.pipe(select("auth")).subscribe((data) => {
      this.userData = data.userProfile;
    });

    this.bankTranseferForm = this.fb.group({
      bankId: ["0"],
      reference: ["", Validators.required],
    });
  }

  ngOnInit() {
    // this.btnSpinner = false;
    let param = this.activatedRoute.snapshot.queryParams["plan"];
    param = decodeURIComponent(param);
    this.paymentDetails = JSON.parse(param);
    this.selectedTransactionType = 1;
  }

  get bankId() {
    return this.bankTranseferForm.get("bankId");
  }

  get reference() {
    return this.bankTranseferForm.get("reference");
  }

  selectType(event) {
    this.selectedTransactionType = +event.target.value;
  }

  async pay() {
    this.showSmallSpinner = true;
    await this.loadScript();
    this.setUp();
    // if (this.isPaying) { return; };
    const payment = window.PaystackPop.setup(this.paystackOptions);
    payment.openIframe();
    // this.isPaying = true;
  }

  setUp() {
    const dateTimeSent = (+new Date()).toString();
    this.paystackOptions = {
      // key: "pk_live_eefcb9a565ffe71ef28d3a2ce05e6edb4bbb7500",
      key: 'pk_test_a720b607052806bbc2d3f05adcf3970684aa4e56',
      email: this.userData.email,
      amount: this.paymentDetails.amount * 100,
      ref: "" + Math.floor(Math.random() * 1000000000 + 1),
      currency: "NGN",
      channels: ["card"],
      quantity: "",
      callback: (res) => {
        // Function that runs when payment is successful. This should ideally be a script that
        // uses the verify endpoint on the Paystack API to check the status of the transaction.
        this.completePayment(res, dateTimeSent);
      },
      onClose: () => {
        this.sendErrorMessage("Transaction closed without completion");
        this.showSmallSpinner = false;
      },
    };
  }

  loadScript(): Promise<void> {
    return new Promise((resolve) => {
      if (window.PaystackPop && typeof window.PaystackPop.setup === "function") {
        resolve();
        return;
      }
      const script = window.document.createElement("script");
      window.document.head.appendChild(script);
      const onLoadFunc = () => {
        script.removeEventListener("load", onLoadFunc);
        resolve();
      };
      script.addEventListener("load", onLoadFunc);
      script.setAttribute("src", "https://js.paystack.co/v1/inline.js");
    });
  }

  completePayment(res?: any, dateTimeSent?: string) {
    const completePayment = new CompletePayment();

    // this.btnSpinner = true;
    if (this.selectedTransactionType === 1) {
      this.sendErrorMessage("Select a payment type");
      return;
    };

    if (this.selectedTransactionType === 2) {
      if (isNullOrUndefined(this.bankId.value) || !(+this.bankId.value > 0)) {
        this.sendErrorMessage("please select a bank");
        return;
      };
      completePayment.licensePaymentId = this.paymentDetails.licensePayementId;
      completePayment.reference = this.reference.value;
      completePayment.transactionStatus = true;
      completePayment.isBankDeposit = true;
      completePayment.crmPlanId = this.paymentDetails.crmPlanId;
      completePayment.bankId = +this.bankId.value;
      completePayment.couponCode = this.paymentDetails.couponCode;
      // this.bankTranseferForm.reset()
    };

    if (this.selectedTransactionType === 3) {
      completePayment.licensePaymentId = this.paymentDetails.licensePayementId;
      completePayment.reference = this.reference.value;
      completePayment.transactionStatus = true;
      completePayment.isCash = true;
      completePayment.crmPlanId = this.paymentDetails.crmPlanId;
      // completePayment.bankId = +this.bankId.value;
      completePayment.couponCode = this.paymentDetails.couponCode;
    };

    if (this.selectedTransactionType === 4) {
      if (!isNullOrUndefined(res)) {
        if (res.status === "success") {
          completePayment.paymentgatewayCharges = 0;
          completePayment.licensePaymentId = this.paymentDetails.licensePayementId;
          completePayment.reference = res.transaction;
          completePayment.timeTransactionSentToGateway = dateTimeSent;
          completePayment.timeTransactionValidatedByGateway = (+new Date()).toString();
          completePayment.transactionStatus = true;
          completePayment.isPaystack = true;
          completePayment.crmPlanId = this.paymentDetails.crmPlanId;
          // completePayment.bankId = +this.bankId.value;
          completePayment.couponCode = this.paymentDetails.couponCode;
        } else {
          this.sendErrorMessage("Transaction failed");
          this.showSmallSpinner = false;
          return;
        };
      };
    };

    if (this.selectedTransactionType === 5) {
      if (isNullOrUndefined(this.bankId.value) || !(+this.bankId.value > 0)) {
        this.sendErrorMessage("please select a bank");
        return;
      };
      completePayment.licensePaymentId = this.paymentDetails.licensePayementId;
      completePayment.reference = this.reference.value;
      completePayment.transactionStatus = true;
      completePayment.isInternetTransfer = true;
      completePayment.crmPlanId = this.paymentDetails.crmPlanId;
      completePayment.bankId = +this.bankId.value;
      completePayment.couponCode = this.paymentDetails.couponCode;
    };

    if (this.selectedTransactionType === 6) {
      if (isNullOrUndefined(this.bankId.value) || !(+this.bankId.value > 0)) {
        this.sendErrorMessage("please select a bank");
        return;
      };
      completePayment.licensePaymentId = this.paymentDetails.licensePayementId;
      completePayment.reference = this.reference.value;
      completePayment.transactionStatus = true;
      completePayment.isCheque = true;
      completePayment.crmPlanId = this.paymentDetails.crmPlanId;
      completePayment.bankId = +this.bankId.value;
      completePayment.couponCode = this.paymentDetails.couponCode;
    };

    this.service.completePayment(completePayment).toPromise()
      .then((res) => {
        if (this.selectedTransactionType === 4) {
          this.router.navigate(["/payment-success"], {
            queryParams: { card: true },
          });
        } else {
          this.router.navigate(["/payment-success"]);
        }
      })
      .catch((err) => {
        this.sendErrorMessage("Transaction failed due to " + err.message);
        this.btnSpinner = false;
        this.showSmallSpinner = false;
      });
  }
}
