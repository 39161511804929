import { Component, OnInit } from "@angular/core";
import { Notifier } from "../base-class/notifier";
import * as fromUserHeaderStore from "../../store/reducers/user-header.reducer";
import { Store, select } from "@ngrx/store";
import * as headertActions from "../../store/actions/user-header.actions";

@Component({
  selector: "app-global-alert",
  templateUrl: "./global-alert.component.html",
  styleUrls: ["./global-alert.component.scss"],
})
export class GlobalAlertComponent extends Notifier implements OnInit {
  // notificationMessages: string[] = [
  //     'you edited succesfully', 'you created a brief succesfully'
  //   ];
  constructor(protected headerStore: Store<fromUserHeaderStore.HeaderState>) {
    super(headerStore);
  }

  ngOnInit() {
  }

  // removeMessage() {
  //   //console.log(this.notificationMessages);
  //   this.notificationMessages.pop();
  // }
}
