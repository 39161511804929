import { IUser } from "../interfaces/user";

export class UserProfileViewModelLite implements IUser {
  profileImage?: string;
  profileImageFileBinary?: string;
  profileImageFileType?: string;
  firstName: string;
  lastName: string;
  firmRoleId?: number;
  firmRoleName?: string;
  userId?: number;
  id?: number;
}
