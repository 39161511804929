import { Action, createFeatureSelector, createSelector } from "@ngrx/store";
// import { ActionTypes } from '../actions/home.actions';
import * as authentication from "./login.actions";

import { TokenParams } from "../../models/generic-model.ts/token-params";
import { LoginActionsUnion, LoginActionTypes } from "./login.actions";
import { IsLogin } from "./login.actions";
import { UserDetail } from "../../models/view-model/user-detail";
import { PermissionViewModel } from "../../models/view-model/permission";
import { FileUploadModel } from "src/app/models/generic-model.ts/file-upload";
import { FirmDetailViewModel } from "src/app/models/view-model/firm-detail";

// for modules that are lazy loaded you import from root

// import * as fromRoot from '../index';

// export interface State extends fromRoot.State {
//   auth: AuthState;
// }

export interface AuthState {
  loggedIn: boolean;
  errorMsg: any;
  user: object;
  isLogging: boolean;
  authToken: TokenParams;
  userProfile: UserDetail;
  newProfilePicture: FileUploadModel;
  currentProfilePicture: string;
  accountPermissions: PermissionViewModel[];
  email: string;
  role: string;
  returnUrl: string;
  currentUserId: number;
  errorInUpload: string;

  firmDetail: FirmDetailViewModel;
  error: string;
  isUpdated: boolean;
  isUpdating: boolean;
  isUpdate: boolean;

  isUpdatedLogo: boolean;
  isUpdatingLogo: boolean;
  isUpdateLogo: boolean;
  logoUpdateError: string;

  newLogo: FileUploadModel;
  newSignature: FileUploadModel;
  currentFirmSignature: string;
  currentFirmLogo: string;
  isUpdatedSignature: boolean;
  isUpdatingSignature: boolean;
  isUpdateSignature: boolean;
  signatureUpdateError: string;
  isUserFirmSubscribedToOKM: boolean,
  isLoggedOut: boolean;
  isLoggingOut: boolean;
}

export const initialState: AuthState = {
  loggedIn: false,
  errorMsg: null,
  user: null,
  isLogging: false,
  isLoggingOut: false,
  role: "",
  authToken: new TokenParams(),
  userProfile: new UserDetail(),
  accountPermissions: [],
  email: "",
  returnUrl: "",
  currentProfilePicture: "",
  newProfilePicture: new FileUploadModel(),
  currentUserId: 0,
  errorInUpload: "",
  firmDetail: new FirmDetailViewModel(),
  error: null,
  isUpdated: false,
  isUpdating: false,
  isUpdate: false,
  isUpdatedLogo: false,
  isUpdatingLogo: false,
  isUpdateLogo: false,

  logoUpdateError: "",
  newLogo: new FileUploadModel(),
  currentFirmLogo: "",

  isUpdatedSignature: false,
  isUpdatingSignature: false,
  isUpdateSignature: false,

  signatureUpdateError: "",
  newSignature: new FileUploadModel(),
  currentFirmSignature: "",
  isUserFirmSubscribedToOKM: false,
  isLoggedOut: false,
};
//
const authState = createFeatureSelector<AuthState>("auth");

export function loginReducer(
  state: AuthState = initialState,
  action: LoginActionsUnion
): AuthState {
  switch (action.type) {
    case LoginActionTypes.LOGIN:
      return {
        ...state,
        user: action.payload,
        isLogging: true,
        errorMsg: null,
      };

    case LoginActionTypes.LOGIN_SUCCESS: {
      // console.log(action);
      return {
        ...state,
        loggedIn: true,
        isUserFirmSubscribedToOKM: action.payload.isUserFirmSubscribedToOKM,
        authToken: action.payload.authToken,
        userProfile: action.payload.userProfile,
        accountPermissions: action.payload.accountPermissions,
        email: action.payload.userProfile.email,
        role: action.payload.userProfile.role,
        errorMsg: null,
        isLoggedOut: false,
      };
    }

    case LoginActionTypes.LOGIN_FAIL:
      // console.log(action);
      return {
        ...state,
        errorMsg: action.payload,
      };

    case LoginActionTypes.LOGOUT:
      return {
        ...state,
        isLogging: false,
        isLoggingOut: true,
        errorMsg: null,
      };

    case LoginActionTypes.LOGOUT_SUCCESS: {
      return {
        ...state,
        loggedIn: false,
        errorMsg: null,
        user: null,
        isLogging: false,
        role: "",
        authToken: new TokenParams(),
        userProfile: new UserDetail(),
        accountPermissions: [],
        email: "",

        isLoggedOut: true,
      };
    }
    case LoginActionTypes.LOGOUT_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
      };

    case LoginActionTypes.LOAD_LOCAL_USER_DETAIL:
      return {
        ...state,
      };

    case LoginActionTypes.LOAD_LOCAL_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
        role: action.payload.role,
        email: action.payload.email,
        errorMsg: null,
      };

    case LoginActionTypes.LOAD_LOCAL_USER_DETAIL_FAIL:
      return {
        ...state,
      };

    case LoginActionTypes.LOAD_PROFILE_PICTURE:
      return {
        ...state,
        currentUserId: action.payload,
      };

    case LoginActionTypes.LOAD_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        currentProfilePicture: action.payload,
        errorMsg: null,
      };

    case LoginActionTypes.LOAD_PROFILE_PICTURE_FAIL:
      return {
        ...state,
      };

    case LoginActionTypes.UPDATE_PROFILE_PICTURE:
      return {
        ...state,
        newProfilePicture: action.payload,
      };

    case LoginActionTypes.UPDATE_PROFILE_PICTURE_SUCCESS: {
      return {
        ...state,
        currentProfilePicture: <string>state.newProfilePicture.fileBinary,
        errorMsg: null,
      };
    }

    case LoginActionTypes.LOAD_PROFILE_PICTURE_FAIL:
      return {
        ...state,
        errorInUpload: action.payload,
      };

    case LoginActionTypes.LOAD_ONLINE_USER_DETAIL:
      return {
        ...state,
      };

    case LoginActionTypes.LOAD_ONLINE_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
      };

    case LoginActionTypes.LOAD_ONLINE_USER_DETAIL_FAIL:
      return {
        ...state,
      };

    case LoginActionTypes.IS_LOGIN:
      return {
        ...state,
        isLogging: true,
      };
    case LoginActionTypes.LOGIN_DONE:
      return {
        ...state,
        isLogging: false,
      };

    // load firm detail
    case LoginActionTypes.LOAD:
      return {
        ...state,
        error: null,
        isUpdated: false,
        isUpdate: false,
        isUpdating: false,
      };

    case LoginActionTypes.LOAD_SUCCESS:
      return {
        ...state,
        firmDetail: action.payload,
        error: null,
        isUpdated: false,
        isUpdate: false,
        isUpdating: false,
      };

    case LoginActionTypes.LOAD_FAIL:
      return {
        ...state,
        error: action.payload,
        isUpdated: false,
        isUpdate: false,
        isUpdating: false,
      };

    case LoginActionTypes.UPDATE_FIRM:
      return {
        ...state,
        isUpdate: true,
        isUpdating: true,
        isUpdated: false,
      };

    case LoginActionTypes.UPDATE_FIRM_SUCCESS:
      return {
        ...state,
        // firmDetail: action.payload,
        error: null,
        isUpdated: true,
        isUpdate: true,
      };

    case LoginActionTypes.UPDATE_FIRM_FAIL:
      return {
        ...state,
        error: "update Failed",
        isUpdated: false,
        isUpdate: true,
        isUpdating: false,
      };

    case LoginActionTypes.LOAD_FIRM_LOGO:
      return {
        ...state,
        logoUpdateError: "",
        isUpdateLogo: false,
        isUpdatedLogo: false,
        isUpdatingLogo: false,
      };

    case LoginActionTypes.LOAD_FIRM_LOGO_SUCCESS:
      return {
        ...state,
        currentFirmLogo: action.payload,
        logoUpdateError: "",
        isUpdateLogo: false,
        isUpdatedLogo: false,
        isUpdatingLogo: false,
      };

    case LoginActionTypes.LOAD_FIRM_LOGO_FAIL:
      return {
        ...state,
        logoUpdateError: "",
        isUpdateLogo: false,
        isUpdatedLogo: false,
        isUpdatingLogo: false,
      };

    case LoginActionTypes.LOAD_FIRM_SIGNATURE:
      return {
        ...state,
        signatureUpdateError: "",
        isUpdateLogo: false,
        isUpdatedLogo: false,
        isUpdatingLogo: false,
      };

    case LoginActionTypes.LOAD_FIRM_SIGNATURE_SUCCESS:
      return {
        ...state,
        currentFirmSignature: action.payload,
        signatureUpdateError: "",
        isUpdateSignature: false,
        isUpdatedSignature: false,
        isUpdatingSignature: false,
      };

    case LoginActionTypes.LOAD_FIRM_SIGNATURE_FAIL:
      return {
        ...state,
        signatureUpdateError: "",
        isUpdateSignature: false,
        isUpdatedSignature: false,
        isUpdatingSignature: false,
      };

    case LoginActionTypes.UPDATE_FIRM_LOGO:
      return {
        ...state,
        newLogo: action.payload.fileModel,
        logoUpdateError: "",
        isUpdateLogo: true,
        isUpdatedLogo: false,
        isUpdatingLogo: true,
      };

    case LoginActionTypes.UPDATE_FIRM_LOGO_SUCCESS:
      return {
        ...state,
        currentFirmLogo: <string>state.newLogo.fileBinary,
        logoUpdateError: "",
        isUpdateLogo: true,
        isUpdatedLogo: true,
        isUpdatingLogo: false,
      };

    case LoginActionTypes.LOAD_FIRM_LOGO_FAIL:
      return {
        ...state,
        logoUpdateError: action.payload,
        isUpdateLogo: true,
        isUpdatedLogo: false,
        isUpdatingLogo: false,
      };

    case LoginActionTypes.UPDATE_FIRM_SIGNATURE:
      return {
        ...state,
        newSignature: action.payload.fileModel,
        signatureUpdateError: "",
        isUpdateSignature: true,
        isUpdatedSignature: false,
        isUpdatingSignature: true,
      };

    case LoginActionTypes.UPDATE_FIRM_SIGNATURE_SUCCESS:
      return {
        ...state,
        currentFirmSignature: <string>state.newSignature.fileBinary,
        signatureUpdateError: "",
        isUpdateSignature: true,
        isUpdatedSignature: true,
        isUpdatingSignature: false,
      };

    case LoginActionTypes.UPDATE_FIRM_SIGNATURE_FAIL:
      return {
        ...state,
        signatureUpdateError: action.payload,
        isUpdateSignature: true,
        isUpdatedSignature: false,
        isUpdatingSignature: false,
      };

    case LoginActionTypes.LOGIN:
      state = initialState;
      return {
        ...state,
        isLoggedOut: true,
      };

    default:
      return state;
  }
}

export const getLoginState = (state: AuthState) => state.loggedIn;
export const getLoginStatus = (state: AuthState) => state.errorMsg;
export const getIsLoginState = (state: AuthState) => state.isLogging;
