import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Notifier } from '../base-class/notifier';
import * as fromStore from "../../auth/state/index";
import * as fromUserHeaderStore from "../../store/reducers/user-header.reducer";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatterService } from 'src/app/services/matter.service';
import { MatterList } from 'src/app/models/view-model/matter-list';
import { ApplicationViewModel } from 'src/app/matter-application/models/application-list';
import { UserDetail } from 'src/app/models/view-model/user-detail';
import { MatterApplicationService } from 'src/app/matter-application/services/matter-application.service';
import { EndorsementService } from 'src/app/matter-endorsement/services/endorsement.service';
import { ProceedingViewModel } from 'src/app/matter-endorsement/models/endorsement';
import { isNullOrUndefined } from "util";

@Component({
  selector: 'app-matter-selection-box',
  templateUrl: './matter-selection-box.component.html',
  styleUrls: ['./matter-selection-box.component.scss']
})
export class MatterSelectionBoxComponent extends Notifier implements OnInit, OnChanges, OnDestroy {
  user = new UserDetail();
  titleString: string;
  searchValue = new FormControl("");
  matterList: MatterList[] = [];
  subList: ApplicationViewModel[] | ProceedingViewModel[] = [];
  loading = false;
  topLevel: HTMLElement;
  lastLevel: HTMLElement;
  selectedMatterId: number;
  selectedResult = 'Please select...';

  @Input() searchBoxTagOption: number;
  @Output() postSelectedMatter = new EventEmitter<number>();

  constructor(
    private matterService: MatterService,
    private applicationService: MatterApplicationService,
    private endorsementService: EndorsementService,
    private store: Store<fromStore.State>,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>
  ) {
    super(headerStore);

    this.store.pipe(select(fromStore.getUserDetail)).subscribe((data) => {
      this.user = data;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populateMatterList();
  }

  ngOnInit() {
    this.titleString = this.searchBoxTagOption === 2 ? 'endorsement' : 'application';

    this.searchValue.valueChanges.pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(() => { this.populateMatterList(); });
  }

  populateMatterList(): void {
    this.matterService.getMatterList(this.user.firmId, this.searchValue.value)
      .subscribe((data) => { this.matterList = data.body; });

      // if (this.searchBoxTagOption === 2) {
      //   this.titleString = "endorsement";
      //   if (this.selectedMatterId) {
      //     this.addSubscription(
      //       this.matterService.getMatterEndorsement(this.selectedMatterId, false, this.search_value.value)
      //         .subscribe((data) => { this.matterList = data.body; console.log("getMatterEndorsement"); console.log(data) })
      //     );
      //   } else {
      //     this.addSubscription(
      //       this.matterService.getAllProceeding(this.search_value.value)
      //         .subscribe((data) => { this.matterList = data.body; console.log("getAllProceeding"); console.log(data) })
      //     );
      //   }
      // }
  }

  populateSubList(matterId: number): void {
    this.loading = true;
    this.selectedMatterId = matterId;

    if (this.searchBoxTagOption === 2) {
      this.addSubscription(
        this.endorsementService.loadEndorsementList(matterId)
          .subscribe((data) => {
            this.subList = data;
            this.loading = false;
          })
      );
    }

    if (this.searchBoxTagOption === 3) {
      if (this.selectedMatterId) {
        this.addSubscription(
          this.applicationService.getMatterApplication(matterId)
            .subscribe((data) => {
              this.subList = data.body;
              this.loading = false;
            })
        );
      }
    }
  }

  selectResult(id: number, name: string, summary: string = ''): void {
    if (!isNullOrUndefined(name)) {
      this.selectedResult = name;
    } else {
      this.selectedResult = summary;
    }
    this.postSelectedMatter.emit(id);
  }

  toggleCaret(event: Event): void {
    event.stopPropagation();

    if ((<HTMLSpanElement>event.target).classList.contains("topLevel")) {
      if (this.topLevel) { this.topLevel.classList.remove("caret-down"); }

      (<HTMLSpanElement>event.target).classList.add("caret-down");
      this.topLevel = <HTMLElement>event.target;
    }
  }

  toggleOpenedCaret(matterId: number): void {
    this.populateSubList(matterId);
  }

  ngOnDestroy() {
    this.clearSubscription();
  }

}
