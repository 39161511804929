import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FileUploadModel } from "src/app/models/generic-model.ts/file-upload";
import { IAttachFile } from "../interfaces/attach-file";

@Component({
  selector: "app-view-detail-attach-file",
  templateUrl: "./view-detail-attach-file.component.html",
  styleUrls: ["./view-detail-attach-file.component.scss"],
})
export class ViewDetailAttachFileComponent implements OnInit {
  errorText: string;
  showError: boolean;
  fileProgressArray: number[];
  currentViewDetailDocuments: FileUploadModel[];
  totalFileSize: number;

  @Output() uploaderObject_Secondary = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.fileProgressArray = [];
    this.currentViewDetailDocuments = [];
    this.totalFileSize = 0;
  }

  //#region File attachment
  /**
   * when file is changed
   * @param event;
   */
  onViewDetailFileChange(event) {
    this.currentViewDetailDocuments = [];
    this.totalFileSize = 0;
    this.set_upReader(event.target.files, 0);
  }

  set_upReader(files, i) {
    // check if it has file
    if (files && files[i]) {
      const file = files[i];
      const fileName = file.name;
      const fileSize = file.size;
      const fileType = file.type;

      if (file.size + this.totalFileSize > 25597152) {
        this.errorText = `File attached ${file.name} with file size greater than 10MB is not allowed`;
        this.uploaderObject_Secondary.emit({
          errorMsg: this.errorText,
          errorText: this.errorText,
        });
      }
      else {
        if (
          fileType === "image/png" ||
          fileType === "application/pdf" ||
          fileType ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
          fileType === "text/plain" ||
          fileType === "application/x-msdownload" ||
          fileType === "image/jpeg" ||
          fileType === "text/csv" ||
          fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          // if (file.size > 2097152) {
          //   (this.errorText =
          //     "File attached " +
          //     file.name +
          //     "  with file size greater than 10MB is not allowed"),
          //     "Error! ";
          //   const errorNotifier = "file size greater than 10MB is not allowed";
          //   this.uploaderObject_Secondary.emit({
          //     errorMsg: errorNotifier,
          //     errorText: errorNotifier,
          //   });
          // } else {
          const fileType = file.type;
          const previewReader = new FileReader();

          //
          previewReader.onprogress = (e) => {
            this.fileProgressArray.push((e.loaded / e.total) * 100);
          };

          previewReader.readAsDataURL(file);
          previewReader.onload = (e: any) => {
            this.readerLoaded(e, files, i, fileName, fileSize, fileType);
          };
          ///
          // }
        } else {
          const errorMessage = "File format/type not supported";
          this.uploaderObject_Secondary.emit({
            errorMsg: errorMessage,
            errorText: errorMessage,
          });
        }
      }
    }
  }

  readerLoaded(e, files, i, fileName, fileSize, fileType) {
    // called once readAsDataURL is completed
    //console.log(e);
    // this is correct , ignore errors
    const FILE = new FileReader();

    const taskUpload = new FileUploadModel();

    taskUpload.fileBinary = (<string>e.target.result).split(",")[1];

    taskUpload.fileExtension = fileType;
    taskUpload.fileType = fileType;
    taskUpload.fileName = fileName;
    taskUpload.fileSize = fileSize;

    this.totalFileSize += taskUpload.fileSize;
    this.currentViewDetailDocuments.push(taskUpload);
    // if all files are loaded
    if (i === files.length - 1) {
      this.uploaderObject_Secondary.emit({
        showError: this.showError,
        errorText: this.errorText,
        docs: this.currentViewDetailDocuments,
      });
    }

    // call service

    // If there's a file left to load
    if (i < files.length - 1) {
      // Load the next file
      this.set_upReader(files, i + 1);
    }
  }

  //#endregion
}
