import { IDraft } from '../interfaces/lp-draft';

export class DraftEditModel implements IDraft {
  id: number;
  title: string;
  description: string;
  contents: string;
  matterId: number;
  directoryTypeId: number;
  directoryId: number;
  categoryId: number;


}
