import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FileViewModel } from "src/app/models/view-model/file";
import { DraftService } from 'src/app/services/draft.service';
import { FileUploadService } from "src/app/services/file-upload.service";

@Component({
  selector: 'app-file-list-custom',
  templateUrl: './file-list-custom.component.html',
  styleUrls: ['./file-list-custom.component.scss']
})
export class FileListCustomComponent implements OnInit, OnChanges {
  @Input() files: FileViewModel[] = [];
  @Input() customDownload = false;
  @Output() deleteFile = new EventEmitter<number>();
  @Output() downloadEvent = new EventEmitter<number>();
  @ViewChild("downloadBtn") downloadBtn: ElementRef;

  existingDocument: FileViewModel[] = [];
  downloadUrl: string = "";

  constructor(
    private _fileUploadService: FileUploadService,
    private externalStorageService: DraftService
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    this.existingDocument = this.files;
  }

  download(document: FileViewModel): void {
    if (document.driveFileId) {
      this.externalStorageService.downloadFile(document.driveFileId).subscribe((result) => {
        if (result.serverFileName) {
          window.open(result.serverFileName, "_blank");
        } else {
          alert('Error downloading file');
        }
      });
    } else if (this.customDownload) {
      this.downloadEvent.emit(document.id);
    } else {
      this._fileUploadService
        .downloadNonAsync(document.id, document.itemTag)
        .toPromise()
        .then((result) => {
          this.downloadUrl = result.serverFileName;

          if (this.downloadUrl) {
            window.open(this.downloadUrl, "_blank");
          } else alert("error downloading file");
        });
    }
  }

  closeFileItem(index: number): void {
    this.deleteFile.emit(index);
  }

  formatFileName(fileName: string): string {
    const splitFileName = fileName.split('.');

    if (splitFileName[0].length > 13) {
      const newFileName = splitFileName[0].slice(0, 20);
      return `${newFileName}...${splitFileName[1]}`
    }

    return fileName;
  }

}
