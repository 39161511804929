import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Notifier } from '../base-class/notifier';
import * as fromStore from "../../auth/state/index";
import * as fromUserHeaderStore from "../../store/reducers/user-header.reducer";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatterService } from 'src/app/services/matter.service';
import { ApplicationViewModel } from 'src/app/matter-application/models/application-list';
import { UserDetail } from 'src/app/models/view-model/user-detail';
import { MatterApplicationService } from 'src/app/matter-application/services/matter-application.service';
import { EndorsementService } from 'src/app/matter-endorsement/services/endorsement.service';
import { ProceedingViewModel } from 'src/app/matter-endorsement/models/endorsement';
import { isNullOrUndefined } from "util";

@Component({
  selector: 'app-matter-resource-selection-box',
  templateUrl: './matter-resource-selection-box.component.html',
  styleUrls: ['./matter-resource-selection-box.component.scss']
})
export class MatterResourceSelectionBoxComponent extends Notifier implements OnInit, OnChanges, OnDestroy {

  user = new UserDetail();
  titleString: string;
  searchValue = new FormControl("");
  subList: ApplicationViewModel[] | ProceedingViewModel[] = [];
  selectedResult = '';
  spinner: boolean;

  @Input() selectedMatterId: number;
  @Input() searchBoxTagOption: number;
  @Output() postSelectedMatter = new EventEmitter<number>();

  constructor(
    // private matterService: MatterService,
    private applicationService: MatterApplicationService,
    private endorsementService: EndorsementService,
    private store: Store<fromStore.State>,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>
  ) {
    super(headerStore);

    this.store.pipe(select(fromStore.getUserDetail)).subscribe((data) => {
      this.user = data;
    });
  }

  ngOnInit() {
    this.titleString = this.searchBoxTagOption === 2 ? 'endorsement' : 'application';

    this.selectedResult = `Select an ${this.titleString} from this dropdown`;

    this.searchValue.valueChanges.pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value) => { this.populateResourceList(); });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populateResourceList();
  }

  // filterResourceList(value: string) {
  //   const matchReg = new RegExp(`\\b${value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\b`, 'gi');

  //   if (this.searchBoxTagOption === 3) {
  //     this.subList = this.subList.filter(item => {
  //       if (item instanceof ApplicationViewModel) {
  //         return matchReg.test(item.name)
  //       }
  //     }) as ApplicationViewModel[];
  //     console.log(this.subList)
  //   }

  //   if (this.searchBoxTagOption === 2) {
  //     this.subList = this.subList.filter(item => {
  //       if (item instanceof ProceedingViewModel) {
  //         return matchReg.test(item.summary)
  //       }
  //     }) as ProceedingViewModel[];
  //     console.log(this.subList);
  //   }

  //   // const stuff = this.subList.filter(item => {
  //   //     if (item instanceof ApplicationViewModel) {
  //   //       return matchReg.test(item.name)
  //   //     }
  //   //     if (item instanceof ProceedingViewModel) {
  //   //       return matchReg.test(item.summary)
  //   //     }
  //   // })
  // }

  populateResourceList(): void {
    this.spinner = true;

    if (this.searchBoxTagOption === 2) {
      this.addSubscription(
        this.endorsementService.loadEndorsementList(this.selectedMatterId, this.searchValue.value)
          .subscribe((data) => {
            this.subList = data;
            this.spinner = false;
            // console.log(this.subList);
          })
      );
    }

    if (this.searchBoxTagOption === 3) {
      if (this.selectedMatterId) {
        this.addSubscription(
          this.applicationService.getMatterApplication(this.selectedMatterId, this.searchValue.value)
            .subscribe((data) => {
              this.subList = data.body;
              this.spinner = false;
              // console.log(this.subList);
            })
        );
      }
    }

  }

  // populateSubList(matterId: number): void {
  //   this.spinner = true;
  //   this.selectedMatterId = matterId;

  //   if (this.searchBoxTagOption === 2) {
  //     this.addSubscription(
  //       this.endorsementService.loadEndorsementList(matterId)
  //         .subscribe((data) => {
  //           this.subList = data;
  //           this.spinner = false;
  //         })
  //     );
  //   }

  //   if (this.searchBoxTagOption === 3) {
  //     if (this.selectedMatterId) {
  //       this.addSubscription(
  //         this.applicationService.getMatterApplication(matterId)
  //           .subscribe((data) => {
  //             this.subList = data.body;
  //             this.spinner = false;
  //           })
  //       );
  //     }
  //   }
  // }

  selectResult(id: number, name: string, summary: string = ''): void {
    if (!isNullOrUndefined(name)) {
      this.selectedResult = name;
    } else {
      this.selectedResult = summary;
    }
    this.postSelectedMatter.emit(id);
  }

  ngOnDestroy() {
    this.clearSubscription();
  }

}
