import { Component, OnInit } from "@angular/core";
import { CurrentUserService } from "src/app/services/factories/current-user.service";
import { UserDetail } from "src/app/models/view-model/user-detail";
import { LicensingService } from "src/app/services/licensing.service";
import { isNullOrUndefined } from "util";
import { PlanGroup } from "src/app/models/view-model/license-endpoint.model";

@Component({
  selector: "app-pricing",
  templateUrl: "./pricing.component.html",
  styleUrls: ["./pricing.component.scss"],
})
export class PricingComponent implements OnInit {
  valuePerMonth: number;
  currentUser = new UserDetail();
  public getAllLicense: PlanGroup[] = [];
  public showLoader: boolean = false;
  constructor(
    private currentUserService: CurrentUserService,
    public service: LicensingService
  ) {
    this.currentUserService.getuserProfile().subscribe(
      (res) => {
        this.currentUser = res;
      },
      (err) => {
        //console.log(err);
        this.currentUser = null;
      }
    );
  }

  ngOnInit() {
    this.getEndPointLicensing();
  }

  public getEndPointLicensing() {
    this.showLoader = true;
    this.service.getLicensingList().subscribe((data) => {
      if (!isNullOrUndefined(data)) {
        // console.log(data);
        this.getAllLicense = data;
        this.valuePerMonth = this.getAllLicense[0].bucketList[0].amount / this.getAllLicense[0].bucketList[0].maximumUserCap;
        this.showLoader = false;
        // this.showNoResult;
      } else {
        // say something
      }
    }),
      (error) => {
        this.showLoader = false;
        // tslint:disable-next-line: no-unused-expression
        // this.showNoResult;
      };
  }

  goToLink(url) {
    window.open(url, "_blank");
  }
}
