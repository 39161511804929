import { IUser } from "../interfaces/user";

export class UserLite implements IUser {
    public id: number;
    public userId: number;
    public firstName: string;
    public lastName: string;
    public otherName: string;
    public profileImage?: string;
    public profileImageFileBinary?: string;
    public profileImageFileType?: string;
    public firmRoleName?: string;
    public firmRoleId?: number;
}