import { Action, createFeatureSelector, createSelector } from "@ngrx/store";
// import { ActionTypes } from '../actions/home.actions';
import * as authentication from "../../auth/state/login.actions";

import { TokenParams } from "../../models/generic-model.ts/token-params";
import { LoginActionsUnion, LoginActionTypes } from "../../auth/state/login.actions";
import { IsLogin } from "../../auth/state/login.actions";
import { UserDetail } from "../../models/view-model/user-detail";
import { PermissionViewModel } from "../../models/view-model/permission";
import {
  UserHeaderActionsUnion,
  UserHeaderActionTypes,
} from "../actions/user-header.actions";
import { StatusViewModel } from "src/app/models/view-model/connection-view-model";

// for modules that are lazy loaded you import from root

// import * as fromRoot from '../index';

// export interface State extends fromRoot.State {
//   auth: AuthState;
// }

// InternetAvailable = new ConnectionViewModel();

export interface HeaderState {
  isShowTimer: boolean;
  errorMsg: string;
  currentMatter: number;
  notificationMessageList: string[];
  isInternetAvailable: StatusViewModel;
  errorNotifier: StatusViewModel;
  notificationCount: number;
}

export const initialState: HeaderState = {
  isShowTimer: false,
  errorMsg: "",
  currentMatter: 0,
  notificationMessageList: [],
  isInternetAvailable: { status: false, text: "" },
  errorNotifier: { status: false, text: "" },
  notificationCount: 0,
};

const getUserHeaderFeatureState =
  createFeatureSelector<HeaderState>("user-header");

export const getNotificationCount = createSelector(
  getUserHeaderFeatureState,
  (state) => state.notificationCount
);

export const getTimerState = createSelector(
  getUserHeaderFeatureState,
  (state) => state.isShowTimer
);

export const getCurrentMatter = createSelector(
  getUserHeaderFeatureState,
  (state) => state.currentMatter
);

export const getNotificationMessages = createSelector(
  getUserHeaderFeatureState,
  (state) => state.notificationMessageList
);

export const getConnectionState = createSelector(
  getUserHeaderFeatureState,
  (state) => state.isInternetAvailable
);

export const getErrorState = createSelector(
  getUserHeaderFeatureState,
  (state) => state.errorNotifier
);

export function UserHeaderReducer(
  state: HeaderState = initialState,
  action: UserHeaderActionsUnion
): HeaderState {
  switch (action.type) {
    case UserHeaderActionTypes.SHOW_TIMER:
      return {
        ...state,
        errorMsg: "",
      };
    case UserHeaderActionTypes.SHOW_TIMER_SUCCESS: {
      //console.log(state);
      return {
        ...state,
        isShowTimer: true,
        errorMsg: "",
      };
    }
    case UserHeaderActionTypes.SHOW_TIMER_FAIL:
      return {
        ...state,
        isShowTimer: state.isShowTimer,
        errorMsg: "An error Occurred, could not show timer",
      };

    case UserHeaderActionTypes.HIDE_TIMER:
      return {
        ...state,
        errorMsg: "",
      };
    case UserHeaderActionTypes.HIDE_TIMER_SUCCESS: {
      //console.log(state);
      return {
        ...state,
        isShowTimer: false,
        errorMsg: "",
      };
    }
    case UserHeaderActionTypes.HIDE_TIMER_FAIL:
      return {
        ...state,
        isShowTimer: state.isShowTimer,
        errorMsg: "An error Occurred, could not hide timer",
      };

    case UserHeaderActionTypes.SELECT_CURRENT_MATTER:
      return {
        ...state,
        isShowTimer: state.isShowTimer,
        errorMsg: "",
        currentMatter: action.payload,
      };
    case UserHeaderActionTypes.DESELECT_CURRENT_MATTER:
      return {
        ...state,
        isShowTimer: state.isShowTimer,
        errorMsg: "",
        currentMatter: 0,
      };

    case UserHeaderActionTypes.PUSH_NOTIFICATION: {
      state.notificationMessageList.push(action.payload);
      return {
        ...state,
      };
    }

    case UserHeaderActionTypes.SEND_ERROR: {
      state.errorNotifier.status = true;
      state.errorNotifier.text = action.payload;
      return {
        ...state,
      };
    }

    case UserHeaderActionTypes.SET_INTERNET_AVAILABLE: {
      state.isInternetAvailable.status = true;
      state.isInternetAvailable.text = action.payload;
      return {
        ...state,
      };
    }

    case UserHeaderActionTypes.SET_NOTIFICATION_COUNT: {
      state.notificationCount = action.payload;
      return {
        ...state,
      };
    }

    case UserHeaderActionTypes.CLEAR_FROM_NOTIFICATION_COUNT: {
      state.notificationCount = state.notificationCount - action.payload;
      return {
        ...state,
      };
    }

    case UserHeaderActionTypes.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notificationMessageList: [],
        notificationCount: 0,
      };

    default:
      return state;
  }
}

export const getShowTimerState = (state: HeaderState) => state.isShowTimer;
export const getErrorStatus = (state: HeaderState) => state.errorMsg;
