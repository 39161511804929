import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, } from "@angular/core";
import { AppConfigService } from "../../shared/helpers/app-config.service";
import { FormBuilder, Validators, FormGroup, FormArray } from "@angular/forms";
import { NewEndorsement, ProceedingReminder, tagId, } from "../../matter-endorsement/models/new-endorsement";
import { CalendarViewModel } from "src/app/models/view-model/calendar-event";
import * as fromStore from "../../store/reducers/calendar-lander.reducer";
import { Store, select } from "@ngrx/store";
import * as CalendarActions from "../../store/actions/calendar-lander.actions";
import * as fromMatters from "../../store/reducers/matters.reducers";
import { UserDetail } from "src/app/models/view-model/user-detail";
import * as LoginActions from "../../auth/state/login.actions";
import { isNullOrUndefined } from "util";
import { EndorsementService } from "src/app/matter-endorsement/services/endorsement.service";
import { of } from "rxjs";
import { CommonUserModel } from "src/app/models/generic-model.ts/user-share";
import { ApplicationReminder } from "src/app/models/parse-model/add-application";

@Component({
  selector: "app-new-endorsement-modal",
  templateUrl: "./new-endorsement-modal.component.html",
  styleUrls: ["./new-endorsement-modal.component.scss"],
})
export class NewEndorsementModalComponent implements OnInit {

  tagIdArr: tagId[] = this.config.getConfig().priorities;
  newEndorsement: FormGroup;
  currentMatterId: number;
  teamMembers = [];
  teamMembers$ = of([]);
  btnSpinner: boolean;
  successText: string;
  errorText: string;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  showUsersModal = false;
  firmId: number;
  userId: number;
  userData = new UserDetail();
  showResourceNotSelectedError: boolean;
  courtAppearnceToggle = false;
  courtAppearnceReturn = false;
  indefiniteAdjournment = false;
  public reminders: any = {};

  @Input() disableSelectOnEndorsementSelect: boolean;
  @Output() getSavedEndorsement = new EventEmitter();
  @ViewChild("messageBox") messageBox: ElementRef;
  @ViewChild("addCourtToggle") addCourtToggle: ElementRef;
  @ViewChild("closeEndorsementModal") closeEndorsementModal: ElementRef;

  constructor(
    private calendarStore: Store<fromStore.CalendarState>,
    private fb: FormBuilder,
    private rootStore: Store<fromStore.State>,
    private config: AppConfigService,
    private service: EndorsementService,
    private store: Store<fromMatters.State>
  ) {
    this.rootStore.dispatch(new LoginActions.LoadLocalUserDetail());

    this.rootStore.pipe(select("auth")).subscribe((data) => {
      this.userData = data.userProfile;
      this.firmId = this.userData.firmId;
    });

    this.newEndorsement = fb.group({
      hearingDate: ["", Validators.required],
      summary: ["", Validators.required],
      nextHearingDate: this.fb.array([this.createNextHearing()]),
      nextAction: ["", Validators.required],
      nextStage: ["", Validators.required],
      matterId: [{ value: "" }, [Validators.min(1)]],
      note: [""],
      tagId: [""],
      reminders: this.fb.array([]),
    });

    this.store.pipe(select(fromMatters.getCurrentMatter))
      .subscribe((matter) => {
        if (!isNullOrUndefined(matter)) {
          this.currentMatterId = matter.id;
          this.matterId.patchValue(this.currentMatterId);
        }
      });
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngDoCheck() {
    // console.log(this.courtAppearnceToggle)
    if (this.courtAppearnceToggle == true) {
      // console.log('toggleTrue')
      if (this.indefiniteAdjournment) {
        // console.log('infinite')
        if (this.nextStage.valid && this.tagId.valid && this.nextAction.valid) {
          this.courtAppearnceReturn = true;
        } else {
          this.courtAppearnceReturn = false;
        }
      } else {
        // console.log('not infinite && toggle false ')
        if (
          this.nextStage.valid &&
          this.tagId.valid &&
          this.nextAction.valid &&
          this.nextHearingDate.valid
        ) {
          // console.log('valid')
          this.courtAppearnceReturn = true;
        } else {
          // console.log('invalid')
          this.courtAppearnceReturn = false;
        }
      }
    }

    if (this.courtAppearnceToggle === false) {
      if (this.hearingDate.valid && this.summary.valid) {
        this.courtAppearnceReturn = true;
      } else {
        this.courtAppearnceReturn = false;
      }
    }
  }

  ngOnInit() {
    this.showResourceNotSelectedError = false;
    this.disableSelectOnEndorsementSelect = true;
  }

  get hearingDate() { return this.newEndorsement.get("hearingDate"); }
  get nextHearingDate(): FormArray { return <FormArray>this.newEndorsement.get("nextHearingDate"); }
  get nextAction() { return this.newEndorsement.get("nextAction"); }
  get nextStage() { return this.newEndorsement.get("nextStage"); }
  get summary() { return this.newEndorsement.get("summary"); }
  get matterId() { return this.newEndorsement.get("matterId"); }
  get tagId() { return this.newEndorsement.get("tagId"); }
  get note() { return this.newEndorsement.get("note"); }

  createNextHearing(): FormGroup {
    return this.fb.group({
      date: [{ value: "" }, Validators.required],
      reminders: this.fb.array([]),
    });
  }

  // removeReminder(index): void {
  //   this.reminders.removeAt(index);
  // }

  // public addReminders(
  //   defaultValue = { value: 1, interval: "Days before due" }
  // ): void {
  //   this.reminders = this.newEndorsement.get("reminders") as FormArray;
  //   this.reminders.push(this.createReminder(defaultValue));
  // }

  public getReminders(index: number): FormArray {
    return this.nextHearingDate.at(index).get("reminders") as FormArray;
  }

  public createReminder(defaultValue): FormGroup {
    return this.fb.group({
      value: [defaultValue.value, Validators.required],
      interval: [defaultValue.interval, Validators.required],
    });
  }

  addNextHearingDate(): void {
    this.nextHearingDate.push(this.createNextHearing());
  }

  removenextHearingDate(index): void {
    this.nextHearingDate.removeAt(index);
  }

  public addReminder(index: number, defaultValue = { value: 1, interval: "Days before due" }): void {
    this.getReminders(index).push(this.createReminder(defaultValue));
  }

  public removeReminder(controlIndex: number, reminderIndex: number): void {
    this.getReminders(controlIndex).removeAt(reminderIndex);
  }

  courtAppearanceChange(event: any): void {
    this.courtAppearnceToggle = event.target.checked;
  }

  // closeSuccess(): void {
  //   this.showSuccessMessage = false;
  // }

  closeError(): void {
    this.showErrorMessage = false;
  }

  setIndefiniteDate(): void {
    this.indefiniteAdjournment = !this.indefiniteAdjournment;
    if (this.indefiniteAdjournment) {
      this.newEndorsement.controls["nextHearingDate"].disable();
      this.newEndorsement.controls["nextHearingDate"].clearValidators();
    } else {
      this.newEndorsement.controls["nextHearingDate"].enable();
      this.newEndorsement.controls["nextHearingDate"].setValidators(
        Validators.required
      );
    }
  }

  getSelectedOption(event): void {
    if (!isNullOrUndefined(this.currentMatterId)) {
      this.matterId.patchValue(this.currentMatterId);
    }

    this.currentMatterId = event;
  }

  removeMember(member: CommonUserModel): void {
    this.teamMembers = this.teamMembers.filter((user) => user.id != member.id);
    this.teamMembers$ = of(this.teamMembers);
  }

  addNewEndorsement(newEndorse: NewEndorsement): void {
    if (!this.courtAppearnceToggle || this.indefiniteAdjournment) {
      newEndorse.nextHearingDate = [];
    }

    if (isNullOrUndefined(this.currentMatterId)) {
      this.showResourceNotSelectedError = true;
      return;
    } else if (this.currentMatterId < 1) {
      this.showResourceNotSelectedError = true;
      return;
    }

    this.btnSpinner = true;

    newEndorse.matterId = Number(this.matterId.value);
    newEndorse.isIndefinate = this.indefiniteAdjournment;
    newEndorse.nextHearingDate = this.transformNextHearing(newEndorse);

    newEndorse.hearingDate = "" + new Date(newEndorse.hearingDate).getTime();

    if (!newEndorse.tagId) {
      newEndorse.tagId = 3;
    } else {
      newEndorse.tagId = Number(newEndorse.tagId);
    }

    const teamMembersSelected = [];
    if (this.teamMembers.length) {
      this.teamMembers.forEach((member) => teamMembersSelected.push(member.id));
    }

    newEndorse.users = teamMembersSelected;

    this.service.addNewEndorsement(newEndorse).subscribe(
      (data) => {
        this.successText = `endorsement was created successfully`;

        // foreach date pass event to calendar

        newEndorse.nextHearingDate.forEach((element) => {
          const SaveEndorsement = new CalendarViewModel();
          SaveEndorsement.title = "Court Appearance";
          SaveEndorsement.isEndorsement = true;
          //  SaveEndorsement.matterId = this.matterIdNo;
          // SaveEndorsement.resourceId = data;

          SaveEndorsement.date = element as any as string;

          this.calendarStore.dispatch(
            new CalendarActions.AddCalendarEvent(SaveEndorsement)
          );
        });
        // end of attaching dates to calendar

        this.btnSpinner = false;
        this.newEndorsement.reset();

        this.closeEndorsementModal.nativeElement.click();
        this.addCourtToggle.nativeElement.click();

        this.getSavedEndorsement.emit(newEndorse);
      },
      (error) => {
        //console.log(error);
        this.showErrorMessage = true;
        this.messageBox.nativeElement.focus();
        this.errorText = `creating endorsement failed`;
        this.btnSpinner = false;
      }
    );
  }

  openUsersModal(): void {
    this.showUsersModal = true;
  }

  closeUsersModal(event: boolean): void {
    this.showUsersModal = false;
  }

  getUsersChosen(event): void {
    this.teamMembers = event.selectedUsers;
    this.teamMembers$ = of(this.teamMembers);
  }

  public transformNextHearing(newEndorse: any): any[] {
    const newData = newEndorse.nextHearingDate.map((endorsement) => {
      // console.log(endorsement);
      return {
        date: "" + new Date(endorsement.date).getTime(),
        reminders: endorsement.reminders.map((reminder: any) => {
          const interval = reminder.interval;
          let dueDateTimeForReminder = new Date(endorsement.date);
          const value = reminder.value;

          if (interval.toLowerCase().includes("minute")) {
            dueDateTimeForReminder.setMinutes(
              dueDateTimeForReminder.getMinutes() - value
            );
          }
          if (interval.toLowerCase().includes("hour")) {
            dueDateTimeForReminder.setHours(
              dueDateTimeForReminder.getHours() - value
            );
          }
          if (interval.toLowerCase().includes("day")) {
            dueDateTimeForReminder.setDate(
              dueDateTimeForReminder.getDate() - value
            );
          }
          if (interval.toLowerCase().includes("week")) {
            dueDateTimeForReminder.setDate(
              dueDateTimeForReminder.getDate() - value * 7
            );
          }
          if (interval.toLowerCase().includes("month")) {
            dueDateTimeForReminder.setMonth(
              dueDateTimeForReminder.getMonth() - value
            );
          }
          if (interval.toLowerCase().includes("year")) {
            dueDateTimeForReminder.setFullYear(
              dueDateTimeForReminder.getFullYear() - value
            );
          }
          return {
            reminderTime: +new Date(dueDateTimeForReminder),
            message: interval + "#" + value,
          };
        }),
      };
    });

    return newData;
  }
}
