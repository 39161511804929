export class EditContactModel {
  id: number;
  firstName: string;
  lastName: string;
  jobDescription: string;
  companyName: string;
  phoneNumber: string;
  email: string;
  website: string;
  instantMessenger: string;
  contactStatusId: number;
  firmId: number;
  contactSource: string;
  titleId: number;
  firmLabelId: number;
  contactLabelId: number;
  companyPhoneNumber?: string;
  companyEmail?: string;
  companyAddress?: string;
  industry?: string;
  rcno?: string;
  primaryTitleId?: number;
  referral?: number;
  isReferedbyClient: boolean;
}
