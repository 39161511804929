import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CalendarService } from '../services/calendar.service';
import { CalendarViewModel } from '../models/view-model/calendar-event';
@Injectable()

export class CalendarResolverService implements Resolve<CalendarViewModel[]> {
  upcomingCalendarEvents: CalendarViewModel[] = [];
  constructor( private calendarService: CalendarService) {}


  getCurrentMonth(): number {
    let currentMonth: number;
    // the get month method picks january as 0 and febuary as 1 (0-11 (Jan- dec))
    currentMonth = new Date().getMonth() + 1;
    const date = new Date();

    return currentMonth;
  }



  getCurrentYear(): number {
    let currentMonth: number;
    currentMonth = new Date().getFullYear();

    return currentMonth;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CalendarViewModel []> {

    const month = this.getCurrentMonth();
    const year = this.getCurrentYear();

    return  this.calendarService
        .loadUserCalendar(month, year)
  }
}
