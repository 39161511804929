import { Action } from "@ngrx/store";
import { TokenParams } from "../../models/generic-model.ts/token-params";
import { UserDetail } from "../../models/view-model/user-detail";
import { PermissionViewModel } from "../../models/view-model/permission";
import { UserLoginDetail } from "../../models/parse-model/login";

// [AUTH] Auth module
export enum LoginActionTypes {
  LOGIN = "[AUTH] LOGIN",
  LOGIN_SUCCESS = "[AUTH] LOGIN_SUCCESS",
  LOGIN_FAIL = "[AUTH] LOGIN_FAIL",
  IS_LOGIN = "[AUTH] IS_LOGIN",
  LOGIN_DONE = "[AUTH] LOGIN_DONE",
  LOAD_LOCAL_USER_DETAIL = "[AUTH] LOAD_LOCAL_USER_DETAIL",
  LOAD_LOCAL_USER_DETAIL_SUCCESS = "[AUTH] LOAD_LOCAL_USER_DETAIL_SUCCESS",
  LOAD_LOCAL_USER_DETAIL_FAIL = "[AUTH] LOAD_LOCAL_USER_DETAIL_FAIL",

  LOAD_LOCAL_USER_TOKEN = "[AUTH] LOAD_LOCAL_USER_TOKEN",
  LOAD_LOCAL_USER_TOKEN_SUCCESS = "[AUTH] LOAD_LOCAL_USER_TOKEN_SUCCESS",
  LOAD_LOCAL_USER_TOKEN_FAIL = "[AUTH] LOAD_LOCAL_USER_TOKEN_FAIL",

  LOAD_ONLINE_USER_DETAIL = "[AUTH] LOAD_ONLINE_USER_DETAIL",
  LOAD_ONLINE_USER_DETAIL_SUCCESS = "[AUTH] LOAD_ONLINE_USER_DETAIL_SUCCESS",
  LOAD_ONLINE_USER_DETAIL_FAIL = "[AUTH] LOAD_ONLINE_USER_DETAIL_FAIL"
}

export class Login implements Action {
  readonly type = LoginActionTypes.LOGIN;
  constructor(public payload: UserLoginDetail) {}
}

export class LoginSuccess implements Action {
  readonly type = LoginActionTypes.LOGIN_SUCCESS;
  constructor(
    public payload: {
      authToken: TokenParams;
      userProfile: UserDetail;
      accountPermissions: PermissionViewModel[];
    }
  ) {}
}

export class LoginFail implements Action {
  readonly type = LoginActionTypes.LOGIN_FAIL;
  constructor(public payload: string) {}
}

export class IsLogin implements Action {
  readonly type = LoginActionTypes.IS_LOGIN;
}

export class LoginDone implements Action {
  readonly type = LoginActionTypes.LOGIN_DONE;
}

export class LoadLocalUserDetail implements Action {
  readonly type = LoginActionTypes.LOAD_LOCAL_USER_DETAIL;
}

export class LoadLocalUserDetailSuccess implements Action {
  readonly type = LoginActionTypes.LOAD_LOCAL_USER_DETAIL_SUCCESS;
  constructor(public payload: UserDetail) {}
}

export class LoadLocalUserDetailFail implements Action {
  readonly type = LoginActionTypes.LOAD_LOCAL_USER_DETAIL_FAIL;
  constructor(public payload: string) {}
}

export class LoadOnlineUserDetail implements Action {
  readonly type = LoginActionTypes.LOAD_ONLINE_USER_DETAIL;
  constructor(public payload: { firmId: number; userId: number }) {}
}

export class LoadOnlineUserDetailSuccess implements Action {
  readonly type = LoginActionTypes.LOAD_ONLINE_USER_DETAIL_SUCCESS;
  constructor(public payload: UserDetail) {}
}

export class LoadOnlineUserDetailFail implements Action {
  readonly type = LoginActionTypes.LOAD_ONLINE_USER_DETAIL_FAIL;
  constructor(public payload: string) {}
}

export type LoginActionsUnion =
  | Login
  | LoginSuccess
  | LoginFail
  | LoadLocalUserDetail
  | LoadLocalUserDetailSuccess
  | LoadLocalUserDetailFail
  | IsLogin
  | LoginDone
  | LoadOnlineUserDetail
  | LoadOnlineUserDetailSuccess
  | LoadOnlineUserDetailFail;
