import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CurrentUserService } from "../../../services/factories/current-user.service";

import * as LoginActions from "../../state/login.actions";
import { ErrorStateMatcher } from "@angular/material/core";
import { ActivatedRoute } from "@angular/router";

import * as fromStore from "../../state/index";
import { isNullOrUndefined } from "util";
import { Store, select } from "@ngrx/store";
import * as fromAuth from "../../state/login.reducer";
import { UserDetail } from "src/app/models/view-model/user-detail";

@Component({
  selector: "app-force-logout",
  templateUrl: "./force-logout.component.html",
  styleUrls: ["./force-logout.component.scss"],
})
export class ForceLogoutComponent implements OnInit {
  authState: any;
  email: string;

  userData = new UserDetail();

  constructor(
    private router: Router,
    private currentUserService: CurrentUserService,
    private store: Store<fromStore.State>,
    private activatedRoute: ActivatedRoute
  ) {
    //  isLoggingOut errorMsg
  }

  ngOnInit() {
    if (!isNullOrUndefined(this.activatedRoute.snapshot.params["email"])) {
      this.email = this.activatedRoute.snapshot.params["email"];
      this.store.dispatch(new LoginActions.Logout(this.email));
    } else {
      this.email = this.userData.email;
      this.router.navigate(["/login"]);
    }
  }
}
