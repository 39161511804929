import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-okm-subscription-modal',
  templateUrl: './okm-subscription-modal.component.html',
  styleUrls: ['./okm-subscription-modal.component.scss']
})
export class OkmSubscriptionModalComponent implements OnInit {

  @ViewChild('closeConfirmModal') closeConfirmModal: ElementRef;

  constructor() {
  }

  ngOnInit() {
  }

  closeModal() {
    this.closeConfirmModal.nativeElement.click();
  }

}
