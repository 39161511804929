import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { GenericListViewModel } from "src/app/models/generic-model.ts/generic-list";
import { DocumentList } from "src/app/models/view-model/document-list";
import { AppConfigService } from "src/app/shared/helpers/app-config.service";
import { Contract, ContractMilestone } from "../models/contract";
import { ContractOverview } from "../models/contract-overview";
import { ContractParseModel, ContractParty } from "../models/contract-parse";

@Injectable({
  providedIn: "root",
})
export class ContractService {
  contractDataSubject = new BehaviorSubject<ContractParseModel>(null);
  contractDetailSubject = new Subject<any>();

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) {}

  getContracts(
    searchQuery = "",
    page: number,
    pageSize = 0,
    usePageSize = false
  ): Observable<HttpResponse<Contract[]>> {
    const endpoint = `${this.config.getConfig().serverEndpoint}Contract/firm`;
    const url = `${endpoint}?SearchQuery=${searchQuery}&Page=${page}&PageSize=${pageSize}&usePageSize=${usePageSize}&Sort=desc`;

    return this.httpClient.get<Contract[]>(url, { observe: "response" });
  }

  getContractById(contractId: number): Observable<Contract> {
    const endpoint = `${
      this.config.getConfig().serverEndpoint
    }Contract/${contractId}`;

    return this.httpClient.get<Contract>(endpoint);
  }

  getContractByClientId(
    clientId: number,
    searchQuery: string,
    pageIndex: number,
    pageSize: number
  ): Observable<HttpResponse<Contract[]>> {
    const usePageSize = true;
    const url = `${
      this.config.getConfig().serverEndpoint
    }Contract/${clientId}/Client?searchQuery=${searchQuery}&page=${pageIndex}&pageSize=${pageSize}&usePageSize=${usePageSize}`;

    return this.httpClient.get<Contract[]>(url, { observe: "response" });
  }

  getStats(): Observable<ContractOverview> {
    const endpoint = `${
      this.config.getConfig().serverEndpoint
    }Contract/Overview`;

    return this.httpClient.get<ContractOverview>(endpoint);
  }

  getContractTypes(): Observable<GenericListViewModel[]> {
    const endpoint = `${
      this.config.getConfig().serverEndpoint
    }Contract/Type/firm`;

    return this.httpClient.get<GenericListViewModel[]>(endpoint);
  }

  addNewContractType(newType): Observable<GenericListViewModel[]> {
    const endpoint = `${this.config.getConfig().serverEndpoint}Contract/Type`;

    return this.httpClient.post<any>(endpoint, newType);
  }

  addNewParty(newParty: ContractParty): Observable<GenericListViewModel[]> {
    const endpoint = `${this.config.getConfig().serverEndpoint}Contract/Party`;

    return this.httpClient.post<GenericListViewModel[]>(endpoint, newParty);
  }

  getAllParties(): Observable<GenericListViewModel[]> {
    const endpoint = `${
      this.config.getConfig().serverEndpoint
    }Contract/Parties`;

    return this.httpClient.get<GenericListViewModel[]>(endpoint);
  }

  createContract(contract: ContractParseModel): Observable<number> {
    const endpoint = `${this.config.getConfig().serverEndpoint}Contract`;

    return this.httpClient.post<number>(endpoint, contract);
  }

  editContract(contract: ContractParseModel): Observable<Contract> {
    const endpoint = `${this.config.getConfig().serverEndpoint}Contract`;

    return this.httpClient.put<Contract>(endpoint, contract);
  }

  editMileStone(contract: ContractParseModel): Observable<Contract> {
    const endpoint = `${
      this.config.getConfig().serverEndpoint
    }Contract/Milestone`;

    return this.httpClient.put<Contract>(endpoint, contract);
  }

  deleteContract(contractId: number): Observable<any> {
    const endpoint = `${
      this.config.getConfig().serverEndpoint
    }Contract/${contractId}`;

    return this.httpClient.delete<any>(endpoint);
  }

  getNewContractData(): Observable<ContractParseModel> {
    return this.contractDataSubject.asObservable();
  }

  deleteContractFile(fileId: number): Observable<boolean> {
    const endpoint = `${
      this.config.getConfig().serverEndpoint
    }Contract/File/${fileId}`;

    return this.httpClient.delete<boolean>(endpoint);
  }

  getContractFile(fileId: number): Observable<DocumentList> {
    const endpoint = `${
      this.config.getConfig().serverEndpoint
    }Contract/File/download/${fileId}`;

    return this.httpClient.get<DocumentList>(endpoint);
  }

  addMilestoneToContract(milestone): Observable<boolean> {
    const endpoint = `${
      this.config.getConfig().serverEndpoint
    }Contract/Milestone`;

    return this.httpClient.post<boolean>(endpoint, milestone);
  }

  getMilestones(
    contractId: number,
    page: number,
    pageSize = 10,
    usePageSize = true
  ): Observable<HttpResponse<ContractMilestone[]>> {
    const endpoint = `${
      this.config.getConfig().serverEndpoint
    }Contract/Milestone/${contractId}`;
    const url = `${endpoint}?Page=${page}&PageSize=${pageSize}&usePageSize=${usePageSize}`;

    return this.httpClient.get<ContractMilestone[]>(url, {
      observe: "response",
    });
  }

  sendContractDataToEdit(data: any) {
    this.contractDetailSubject.next(data);
  }

  getAllContractData(): Observable<any> {
    return this.contractDetailSubject.asObservable();
  }

  editMilestone(milestone: ContractMilestone): Observable<any> {
    const endpoint = `${
      this.config.getConfig().serverEndpoint
    }Contract/Milestone`;

    return this.httpClient.put<any>(endpoint, milestone);
  }
}
