import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PaymentParseModel } from "src/app/models/parse-model/add-payment";
import { PaymentListViewModel } from "src/app/models/view-model/payment-list";
import { IPayment } from "src/app/models/interfaces/payment";
import { isNullOrUndefined } from "util";
import { InvoiceService } from "src/app/services/invoice.service";
import { ActivatedRoute } from "@angular/router";
import { CurrencyPipe } from "@angular/common";

@Component({
  selector: "app-new-payment",
  templateUrl: "./new-payment.component.html",
  styleUrls: ["./new-payment.component.scss"],
})
export class NewPaymentComponent implements OnInit {
  newPaymentForm: FormGroup;
  btnSpinner: boolean;
  invoiceId: number;
  previousAmount: number;
  amountError: boolean;
  @Input() toBalance: number;
  @Output() recordPayment = new EventEmitter();
  @ViewChild("closeModal") closeModal: ElementRef;

  constructor(
    private fb: FormBuilder,
    private invoiceService: InvoiceService,
    private activatedRoute: ActivatedRoute,
    private currencyPipe: CurrencyPipe
  ) {
    this.previousAmount = null;
    this.amountError = false;

    this.newPaymentForm = this.fb.group({
      date: ["", [Validators.required]],
      amount: ["", [Validators.required]],
      message: [""],
    });
  }

  get date() {
    return this.newPaymentForm.get("date");
  }

  get amount() {
    return this.newPaymentForm.get("amount");
  }

  get message() {
    return this.newPaymentForm.get("message");
  }

  ngOnInit() {
    this.btnSpinner = false;
    this.invoiceId = this.activatedRoute.snapshot.params["id"];
  }

  transformAmount(element: HTMLInputElement) {
    if (this.amount.value) {
      this.previousAmount = this.amount.value;
      this.newPaymentForm.patchValue({
        amount: this.currencyPipe.transform(this.amount.value).substring(1),
      });
    }
  }

  setAmount() {
    this.amountError = false;
    this.newPaymentForm.patchValue({ amount: this.previousAmount });
  }

  savePayment() {
    this.btnSpinner = true;
    let payment = new PaymentParseModel();
    payment.amount = this.previousAmount;
    payment.datePaid = (+new Date(this.date.value)).toString();
    payment.note = this.message.value;
    if (!isNullOrUndefined(this.invoiceId)) {
      payment.clientInvoiceId = this.invoiceId;
    }

    if (payment.amount > this.toBalance) {
      this.amountError = true;
      this.btnSpinner = false;
      return;
    }

    this.invoiceService
      .savePayment(payment)
      .toPromise()
      .then((res) => {
        let pay = new PaymentListViewModel();
        // convert to interface
        const _pay: IPayment = payment;

        // convert to class that implements the interface
        pay = <PaymentListViewModel>_pay;
        pay.id = res;

        this.recordPayment.emit(pay);
        this.closeModal.nativeElement.click();
        this.newPaymentForm.reset();
      })
      .catch((err) => {
        //console.log(err);
      })
      .finally(() => {
        this.btnSpinner = false;
      });
  }
}
