import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from 'src/app/services/factories/current-user.service';

@Component({
  selector: 'app-anonymous-header',
  templateUrl: './anonymous-header.component.html',
  styleUrls: ['./anonymous-header.component.scss']
})
export class AnonymousHeaderComponent implements OnInit {

  constructor(
    private currentUserService: CurrentUserService
  ) { }

  ngOnInit() {
  }


  isLoggedIn() {
   if(this.currentUserService.isLoggedIn()) {
    return true;
   } else {
     return false;
   }
  }

}
