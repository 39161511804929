// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  timi_url: "https://timi-staging.lawpavilion.com/",
  googleDriveAuthenticationUrl:
    "https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&response_type=code&client_id=462881947442-hgq16djs7oag5qqoqck35ils17nl2skt.apps.googleusercontent.com&redirect_uri=https://casemanager-staging.lawpavilion.com/manage-user/external-storage&scope=https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/userinfo.email&prompt=consent",
  googleAuthenticationUrl:
    "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?redirect_uri=https://casemanager-qa.lawpavilion.com/manage-user/external-storage?gService=calendar&prompt=consent&response_type=code&client_id=452462815349-g7m141aretqq5d3unf0b45hpfs1disbo.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/drive.file%20https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/calendar%20https://www.googleapis.com/auth/calendar.acls%20https://www.googleapis.com/auth/calendar.acls.readonly%20https://www.googleapis.com/auth/calendar.calendarlist%20https://www.googleapis.com/auth/calendar.calendars%20https://www.googleapis.com/auth/calendar.calendars.readonly%20https://www.googleapis.com/auth/calendar.events&flowName=GeneralOAuthFlow&access_type=offline",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
