import { Component, OnInit, Input } from "@angular/core";
import { UserTask } from "../../models/view-model/user-tasks";
import * as fromTask from "../../store/reducers/task.reducer";
import * as TaskActions from "../../store/actions/task.action";
import { Store, select } from "@ngrx/store";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-matter-due-task",
  templateUrl: "./matter-due-task.component.html",
  styleUrls: ["./matter-due-task.component.scss"],
})
export class MatterDueTaskComponent implements OnInit {
  @Input() tasks: UserTask[];
  _tasks: UserTask[] = [];
  currentTask: any;
  showCurrentTask: boolean;
  constructor(private taskStore: Store<fromTask.State>) {}

  ngOnInit() {}

  ngOnChanges(changes) {
    if (!isNullOrUndefined(this.tasks)) {
      this._tasks = this.tasks;

      this._tasks = this._tasks.sort((c) => +c.dueDate);
    }
  }

  setCurrentTask(task) {
    this.currentTask = task;
    //console.log(task);
  }
}
