import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService } from '../shared/helpers/app-config.service';
import { Endpoints } from '../models/shared-model/endpoint';
import { Observable } from 'rxjs';

import { UserDetail } from '../models/view-model/user-detail';
import * as fromStore from '../auth/state/index';
import { Store, select } from '@ngrx/store';
import { CommonUserModel } from '../models/generic-model.ts/user-share';
import { UserShareParseModel } from '../models/parse-model/user-share';
import { FolderParseModel } from '../models/parse-model/add-file';
import { FileEditModel } from '../models/edit-model/edit-file.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  Endpoints = new Endpoints(this.config);
  userData = new UserDetail();


  constructor(private httpClient: HttpClient,
    private config: AppConfigService,
    private store: Store<fromStore.State>) {

    // geth the userId from the store (used NGRX)
    this.store.pipe(select('auth')).subscribe(data => {
      /// save token and user detail to local storage
      this.userData = data.userProfile;

    });

  }

  /**
   *
   * @param pageSize
   * @param page
   * @param usePageSize
   */

  public getApplicationDocuments(pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const fileType = 1;
    const isAdmin = this.userData.isAdmin;
    const endpoint = this.config.getConfig().serverEndpoint + `file/firm`;
    const url = `${endpoint}?searchQuery=${searchQuery}&fileType=${fileType}&isAdmin=${isAdmin}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  public getFirmDocuments(pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const fileType = 2;
    const isAdmin = this.userData.isAdmin;
    const endpoint = this.config.getConfig().serverEndpoint + `file/firm`;
    const url = `${endpoint}?searchQuery=${searchQuery}&fileType=${fileType}&isAdmin=${isAdmin}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  public getJudgementDocuments(pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const fileType = 3;
    const isAdmin = this.userData.isAdmin;
    const endpoint = this.config.getConfig().serverEndpoint + `file/firm`;
    const url = `${endpoint}?searchQuery=${searchQuery}&fileType=${fileType}&isAdmin=${isAdmin}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  public getMatterDocuments(pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const fileType = 4;
    const isAdmin = this.userData.isAdmin;
    const endpoint = this.config.getConfig().serverEndpoint + `file/firm`;
    const url = `${endpoint}?searchQuery=${searchQuery}&fileType=${fileType}&isAdmin=${isAdmin}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  public getProceedingsDocuments(pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const fileType = 5;
    const isAdmin = this.userData.isAdmin;
    const endpoint = this.config.getConfig().serverEndpoint + `file/firm`;
    const url = `${endpoint}?searchQuery=${searchQuery}&fileType=${fileType}&isAdmin=${isAdmin}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  public getTaskDocuments(pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const fileType = 6;
    const isAdmin = this.userData.isAdmin;
    const endpoint = this.config.getConfig().serverEndpoint + `file/firm`;
    const url = `${endpoint}?searchQuery=${searchQuery}&fileType=${fileType}&isAdmin=${isAdmin}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;

    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  /**
   *
   * @param pageSize
   * @param page
   * @param usePageSize
   */

  public getDraftDocument(pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Document/Draft/Firm`;
    const url = `${endpoint}?searchQuery=${searchQuery}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  // FILE FRIM DIRECTORY
  /**
   *
   * @param pageSize
   * @param page
   * @param usePageSize
   */
  public getApplicationDirectory(pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const fileType = 1;
    const endpoint = this.config.getConfig().serverEndpoint + `file/firmDirectory/fileType/${fileType}`;
    const url = `${endpoint}?searchQuery=${searchQuery}&fileType=${fileType}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  getAllDocument(pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `file/GetAllFirmFiles`;
    const url = `${endpoint}?searchQuery=${searchQuery}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  getAllDirectories(pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `FileDirectory/firmdirectory`;
    const url = `${endpoint}?searchQuery=${searchQuery}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  getAllFirmDrafts(pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Document/Draft/Firm`;
    const url = `${endpoint}?searchQuery=${searchQuery}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  // For Getting Briefs Document Directories
  getMatterDirectories(matterid: number, pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `FileDirectory/${matterid}/firmmatterdirectory`;
    const url = `${endpoint}?searchQuery=${searchQuery}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  // For Getting Briefs Document Files
  getMatterFiles(matterId: number, pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `file/GetFirmFilesByMatter/${matterId}`;
    const url = `${endpoint}?searchQuery=${searchQuery}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  getMatterDraft(matterId: number, pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Document/Draft/Matter/${matterId}`;
    const url = `${endpoint}?searchQuery=${searchQuery}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  getCategoryDraft(categoryId: number, pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Document/Draft/Category/${categoryId}`;
    const url = `${endpoint}?searchQuery=${searchQuery}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  // For Getting Category Document Files
  getCategoryFiles(categoryId: number, pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `file/GetFirmFilesByCategory/${categoryId}`;
    const url = `${endpoint}?searchQuery=${searchQuery}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  // For Getting Category Document Files
  getCategoryDirectories(categoryid: number, pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `FileDirectory/${categoryid}/firmcategorydirectory`;
    const url = `${endpoint}?searchQuery=${searchQuery}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  // For Getting Application Document
  getApplicationFiles(applicationId: number, pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `file/GetFilesByApplication/${applicationId}`;
    const url = `${endpoint}?searchQuery=${searchQuery}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  getCategories(): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `FileCategory`;
    return this.httpClient.get<any>(endpoint);
  }

  getFileBinary(id): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `file/getfilebinary/${id}`;
    return this.httpClient.get<any>(endpoint);
  }

  UpdateCategories(categoryPayload): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `FileCategory`;
    return this.httpClient.put<any>(endpoint, categoryPayload);
  }

  deleteCategories(categoryId): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `FileCategory/DeleteCategory/${categoryId}`;
    return this.httpClient.delete<any>(endpoint);
  }

  public getFirmDirectory(pageSize: number, page?: number, searchQuery = '', usePageSize = true, fileType = 2): Observable<any> {
    // const fileType = 2;
    const endpoint = this.config.getConfig().serverEndpoint + `file/firmDirectory/fileType/${fileType}`;
    const url = `${endpoint}?searchQuery=${searchQuery}&fileType=${fileType}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  public getJudgementDirectory(pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const fileType = 3;
    const endpoint = this.config.getConfig().serverEndpoint + `file/firmDirectory/fileType/${fileType}`;
    const url = `${endpoint}?searchQuery=${searchQuery}&fileType=${fileType}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  public getMatterDirectrory(pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const fileType = 4;
    const endpoint = this.config.getConfig().serverEndpoint + `file/firmDirectory/fileType/${fileType}`;
    const url = `${endpoint}?searchQuery=${searchQuery}&fileType=${fileType}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  public getProceedingsDirectory(pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const fileType = 5;
    const endpoint = this.config.getConfig().serverEndpoint + `file/firmDirectory/fileType/${fileType}`;
    const url = `${endpoint}?searchQuery=${searchQuery}&fileType=${fileType}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;

    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  public getTaskDirectory(pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const fileType = 6;
    const endpoint = this.config.getConfig().serverEndpoint + `file/firmDirectory/fileType/${fileType}`;
    const url = `${endpoint}?searchQuery=${searchQuery}&fileType=${fileType}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  public goToDirectory(directoryId: number, fileType: number,
    searchQuery = '', sort = '', order = 'asc', page = 0, pageSize = 10, usePageSize = true): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `file/Directory/${directoryId}/fileType/${fileType}`;
    const api = `${endpoint}?searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${page + 1}&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(api, { observe: 'response' });
  }

  getDirectoryFiles(directoryId: number,
    searchQuery = '', sort = '', order = 'asc', page = 0, pageSize = 10, usePageSize = true): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `file/GetFilesByDirectory/${directoryId}`;
    const api = `${endpoint}?searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${page + 1}&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(api, { observe: 'response' });
  }

  getDraftFiles(directoryId: number,
    searchQuery = '', sort = '', order = 'asc', page = 0, pageSize = 10, usePageSize = true): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Document/Draft/Directory/${directoryId}`;
    const api = `${endpoint}?searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${page + 1}&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(api, { observe: 'response' });
  }

  getContactFiles(contactId: number, pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `file/GetFilesByContact/${contactId}`;
    const url = `${endpoint}?searchQuery=${searchQuery}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }


  public deleteDocument(id: number): Observable<boolean> {
    const url = this.config.getConfig().serverEndpoint + `file/${id}`;
    return this.httpClient.delete<boolean>(url);
  }

  public deleteDirectoryDocument(id: number, fileType: number): Observable<boolean> {
    const url = this.config.getConfig().serverEndpoint + `file/Directory/${id}/filetype/${fileType}`;
    return this.httpClient.delete<boolean>(url);
  }


  public getDocumentUsers(docId, docTypeId): Observable<CommonUserModel[]> {
    const endpoint = this.config.getConfig().serverEndpoint + `file/singlefileuser/${docTypeId}/${docId}`;
    return this.httpClient.get<CommonUserModel[]>(endpoint);
  }


  addDocumentUser(data: UserShareParseModel): Observable<number> {
    const endpoint = this.config.getConfig().serverEndpoint + `file/fileuser`;
    const newData = [data];
    return this.httpClient.post<number>(endpoint, newData);
  }


  addMatterFolder(folder:FolderParseModel ): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint+ `FileDirectory`;
    return this.httpClient.post<any>(endpoint, folder );
  }


  deleteDocumentUser(docId: number, userId: number, docTypeId: number): Observable<boolean> {
    const endpoint = this.config.getConfig().serverEndpoint + `file/${docId}/fileuser/${userId}/filetype/${docTypeId}`;
    return this.httpClient.delete<boolean>(endpoint);
  }

  updateDocumentUser(data: UserShareParseModel): Observable<boolean> {
    const endpoint = this.config.getConfig().serverEndpoint + `file/fileuser`;
    return this.httpClient.put<boolean>(endpoint, data);
  }

  deleteFolder(id: number): Observable<boolean> {
    const url = this.config.getConfig().serverEndpoint + `FileDirectory/DeleteDirectory/${id}`;
    return this.httpClient.delete<boolean>(url);
  }

  deleteDraft(draftId: number): Observable<boolean> {
    const url = this.config.getConfig().serverEndpoint + `Document/Draft/${draftId}`;
    return this.httpClient.delete<boolean>(url);
  }

  addFileToMatter(data: FileEditModel): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint+ `file/matter`;
    return this.httpClient.post<any>(endpoint, data );
  }

  UnattachFileFromMatter(matterId: number, fileId: number): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint+ `file/${matterId}/${fileId}`;
    return this.httpClient.delete<any>(endpoint);
  }

  getFileBinaryUsingFileUniqueName(fileUniqueName: string) {
    const endpoint = this.config.getConfig().serverEndpoint+ `file/base-64`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append("fileUniqueName", fileUniqueName.toString());

    return this.httpClient.get<any>(endpoint, { params: queryParams });
  }

}
