export interface ISearchData {
  id: Number;
  resource_type: resourceType;
  title: String;
  subtitle: String;
  year: Number;
  issue: String;
  body: String;
  suit_number: String;
  from_page: Number;
  to_page: Number;
  case_title: String;
  full_case_title: String;
  citation: String;
  court: String;
  date_modified: string;
  body_highlight: string;
  optimized_for: string;
  from_para: string;
  to_para: string;
  page: string;
  pageno: string;
  legal_head: string;
  practice_area: string;
  rule_id: number;
  short_title?: string;
  law_title?: string;
  publication_id?: string;
  judges?: string;
  keywords?: string;
}
export enum resourceType {
  lfn = "lfn",
  roc = "roc",
  supreme = "analysis_sc",
  appeal = "analysis_ca",
  forms = "forms",
  tj = "textbooks_journals",
  slSections = "sl_sections",
  slSchedules = "sl_schedules",
}
export enum SearchResourceType {
  cases = "cases",
  legislation = "legislation",
  forms = "forms",
  books = "textbook_journals",
}
