import { Action } from '@ngrx/store';
import { MailGenericGetRequestModel } from '../../models/generic-model.ts/mail-request';
import { MailListViewModel, MailFolderCount } from '../../models/view-model/email-list';
import { EmailLoginParseModel } from '../../models/parse-model/email-login';
import { FileModel } from './../../models/generic-model.ts/file-model';
import {
  EmailAccountParseModel,
  EmailAccountModel
} from '../../models/parse-model/add-account';
import { AccountResponseModel } from '../../models/generic-model.ts/account-response';

export enum EmailActionTypes {
  CHANGE_ACTIVE_EMAIL_ACCOUNT = '[EMAIL] CHANGE_ACTIVE_EMAIL_ACCOUNT',
  UPDATE_EMAIL_ACCOUNT_DETAILS = '[EMAIL] UPDATE_EMAIL_ACCOUNT_DETAILS',
  SHOW_ALL_EMAIL_ACCOUNTS = '[EMAIL] SHOW_ALL_EMAIL_ACCOUNTS',
  HIDE_EMAIL_ACCOUNT_MENU = '[EMAIL] HIDE_EMAIL_ACCOUNT_MENU',
  // load all inbox
  LOAD_ALL_EMAIL_INBOX = '[EMAIL] LOAD_ALL_EMAIL_INBOX',
  LOAD_ALL_EMAIL_INBOX_SUCCESS = '[EMAIL] LOAD_ALL_EMAIL_INBOX_SUCCESS',
  LOAD_ALL_EMAIL_INBOX_FAIL = '[EMAIL] LOAD_ALL_EMAIL_INBOX_FAIL',

  // load all user account
  LOAD_ALL_USER_EMAIL_ACCOUNT = '[EMAIL] LOAD_ALL_USER_EMAIL_ACCOUNT',
  LOAD_ALL_USER_EMAIL_ACCOUNT_SUCCESS = '[EMAIL] LOAD_ALL_USER_EMAIL_ACCOUNT_SUCCESS',
  LOAD_ALL_USER_EMAIL_ACCOUNT_FAIL = '[EMAIL] LOAD_ALL_USER_EMAIL_ACCOUNT_FAIL',

  // Single email
  LOAD_SINGLE_EMAIL_INBOX = '[EMAIL] LOAD_SINGLE_EMAIL_INBOX',
  LOAD_SINGLE_EMAIL_INBOX_SUCCESS = '[EMAIL] LOAD_SINGLE_EMAIL_INBOX_SUCCESS',
  LOAD_SINGLE_EMAIL_INBOX_FAIL = '[EMAIL] LOAD_SINGLE_EMAIL_INBOX_FAIL',


  // Email Folder Count
  LOAD_EMAIL_FOLDER_COUNT = '[EMAIL] LOAD_EMAIL_FOLDER_COUNT',
  LOAD_EMAIL_FOLDER_COUNT_SUCCESS = '[EMail] LOAD_EMAIL_FOLDER_COUNT_SUCCESS',
  LOAD_EMAIL_FOLDER_COUNT_FAIL = '[EMAIL] LOAD_EMAIL_FOLDER_COUNT_FAIL',
  // draft
  LOAD_DRAFT_EMAIL = '[EMAIL] LOAD_DRAFT_EMAIL',
  LOAD_DRAFT_EMAIL_SUCCESS = '[EMAIL] LOAD_DRAFT_EMAIL_SUCCESS',
  LOAD_DRAFT_EMAIL_FAIL = '[EMAIL] LOAD_DRAFT_EMAIL_FAIL',

  // SENT
  LOAD_SENT_EMAIL = '[EMAIL] LOAD_SENT_EMAIL',
  LOAD_SENT_EMAIL_SUCCESS = '[EMAIL] LOAD_SENT_EMAIL_SUCCESS',
  LOAD_SENT_EMAIL_FAIL = '[EMAIL] LOAD_SENT_EMAIL_FAIL',

  // READ MAIL
  LOAD_EMAIL = '[EMAIL] LOAD_EMAIL',
  LOAD_EMAIL_FILES = '[EMAIL] LOAD_EMAIL_FILES',
  LOAD_EMAIL__FILES_SUCCESS = '[EMAIL] LOAD_EMAIL__FILES_SUCCESS',
  LOAD_EMAIL_FILES_FAIL = '[EMAIL] LOAD_EMAIL_FILES_FAIL',

  // REGISTER_MICROSOFT
  REGISTER_MICROSOFT_EMAIL = '[EMAIL] REGISTER_MICROSOFT_EMAIL',
  REGISTER_MICROSOFT_EMAIL_SUCCESS = '[EMAIL] REGISTER_MICROSOFT_EMAIL_SUCCESS',
  REGISTER_MICROSOFT_EMAIL_FAIL = '[EMAIL] REGISTER_MICROSOFT_EMAIL_FAIL',

  // REGISTER_GMAIL
  REGISTER_GMAIL_EMAIL = '[EMAIL] REGISTER_GMAIL_EMAIL',
  REGISTER_GMAIL_EMAIL_SUCCESS = '[EMAIL] REGISTER_GMAIL_EMAIL_SUCCESS',
  REGISTER_GMAIL_EMAIL_FAIL = '[EMAIL] REGISTER_GMAIL_EMAIL_FAIL',

  // REGISTER_GMAIL
  REGISTER_OTHER_EMAIL = '[EMAIL] REGISTER_OTHER_EMAIL',
  REGISTER_OTHER_EMAIL_SUCCESS = '[EMAIL] REGISTER_OTHER_EMAIL_SUCCESS',
  REGISTER_OTHER_EMAIL_FAIL = '[EMAIL] REGISTER_OTHER_EMAIL_FAIL',

  // LOGIN_MICROSOFT
  LOGIN_MICROSOFT_EMAIL = '[EMAIL] LOGIN_MICROSOFT_EMAIL',
  LOGIN_MICROSOFT_EMAIL_SUCCESS = '[EMAIL] LOGIN_MICROSOFT_EMAIL_SUCCESS',
  LOGIN_MICROSOFT_EMAIL_FAIL = '[EMAIL] LOGIN_MICROSOFT_EMAIL_FAIL',

  // LOGIN_GMAIL
  LOGIN_GMAIL_EMAIL = '[EMAIL] LOGIN_GMAIL_EMAIL',
  LOGIN_GMAIL_EMAIL_SUCCESS = '[EMAIL] LOGIN_GMAIL_EMAIL_SUCCESS',
  LOGIN_GMAIL_EMAIL_FAIL = '[EMAIL] LOGIN_GMAIL_EMAIL_FAIL',

  // LOGIN_GMAIL
  LOGIN_OTHER_EMAIL = '[EMAIL] LOGIN_OTHER_EMAIL',
  LOGIN_OTHER_EMAIL_SUCCESS = '[EMAIL] LOGIN_OTHER_EMAIL_SUCCESS',
  LOGIN_OTHER_EMAIL_FAIL = '[EMAIL] LOGIN_OTHER_EMAIL_FAIL',

  // Send mail
  SEND_EMAIL = '[EMAIL] SEND_EMAIL',
  SEND_EMAIL_EMAIL_SUCCESS = '[EMAIL] SEND_EMAIL_EMAIL_SUCCESS',
  SEND_EMAIL_EMAIL_FAIL = '[EMAIL] SEND_EMAIL_EMAIL_FAIL'
}

export class ChangeActiveEmailAccount implements Action {
  readonly type = EmailActionTypes.CHANGE_ACTIVE_EMAIL_ACCOUNT;
  constructor(public payload: EmailAccountModel) { }
}

export class UpdateEmailAccountDetailUnreadCount implements Action {
  readonly type = EmailActionTypes.UPDATE_EMAIL_ACCOUNT_DETAILS;
  constructor(public payload: { email: string; unreadCount: number }) { }
}

export class ShowAllEmail implements Action {
  readonly type = EmailActionTypes.SHOW_ALL_EMAIL_ACCOUNTS;
}

export class HideEmailAccountMenu implements Action {
  readonly type = EmailActionTypes.HIDE_EMAIL_ACCOUNT_MENU;
}

// LOAD EMAIL ACCOUNT
export class LoadUserEmailAccount implements Action {
  readonly type = EmailActionTypes.LOAD_ALL_USER_EMAIL_ACCOUNT;
}

export class LoadUserEmailAccountSuccess implements Action {
  readonly type = EmailActionTypes.LOAD_ALL_USER_EMAIL_ACCOUNT_SUCCESS;
  constructor(public payload: EmailAccountModel[]) { }
}

export class LoadUserEmailAccountFail implements Action {
  readonly type = EmailActionTypes.LOAD_ALL_USER_EMAIL_ACCOUNT_FAIL;
  constructor(public payload: string) { }
}
// load Email Folder Count
export class LoadEmailFolderCount implements Action {
  readonly type = EmailActionTypes.LOAD_EMAIL_FOLDER_COUNT;
  constructor(public payload: number) {}
}
export class LoadEmailFolderCountSuccess implements Action {
  readonly type = EmailActionTypes.LOAD_EMAIL_FOLDER_COUNT_SUCCESS;
  constructor(public payload: MailFolderCount) {}
}
export class LoadEmailFolderCountFail implements Action {
  readonly type = EmailActionTypes.LOAD_EMAIL_FOLDER_COUNT_FAIL;
  constructor(public payload: string) { }
}

// load all email inbox

export class LoadAllEmailInbox implements Action {
  readonly type = EmailActionTypes.LOAD_ALL_EMAIL_INBOX;
  constructor(public payload: MailGenericGetRequestModel) { }
}

export class LoadAllEmailInboxSuccess implements Action {
  readonly type = EmailActionTypes.LOAD_ALL_EMAIL_INBOX_SUCCESS;
  constructor(public payload: MailListViewModel[]) { }
}

export class LoadAllEmailInboxFail implements Action {
  readonly type = EmailActionTypes.LOAD_ALL_EMAIL_INBOX_FAIL;
  constructor(public payload: string) { }
}

// single email

export class LoadEmailInbox implements Action {
  readonly type = EmailActionTypes.LOAD_SINGLE_EMAIL_INBOX;
  constructor(public payload: MailGenericGetRequestModel) { }
}

export class LoadEmailInboxSuccess implements Action {
  readonly type = EmailActionTypes.LOAD_SINGLE_EMAIL_INBOX_SUCCESS;
  constructor(public payload: MailListViewModel[]) { }
}

export class LoadEmailInboxFail implements Action {
  readonly type = EmailActionTypes.LOAD_SINGLE_EMAIL_INBOX_FAIL;
  constructor(public payload: string) { }
}

// draft

export class LoadDraftEmail implements Action {
  readonly type = EmailActionTypes.LOAD_DRAFT_EMAIL;
  constructor(public payload: MailGenericGetRequestModel) { }
}

export class LoadDraftEmailSuccess implements Action {
  readonly type = EmailActionTypes.LOAD_DRAFT_EMAIL_SUCCESS;
  constructor(public payload: MailListViewModel[]) { }
}

export class LoadDraftEmailFail implements Action {
  readonly type = EmailActionTypes.LOAD_DRAFT_EMAIL_FAIL;
  constructor(public payload: string) { }
}

// sent

export class LoadSentEmail implements Action {
  readonly type = EmailActionTypes.LOAD_SENT_EMAIL;
  constructor(public payload: MailGenericGetRequestModel) { }
}

export class LoadSentEmailSuccess implements Action {
  readonly type = EmailActionTypes.LOAD_SENT_EMAIL_SUCCESS;
  constructor(public payload: MailListViewModel[]) { }
}

export class LoadSentEmailFail implements Action {
  readonly type = EmailActionTypes.LOAD_SENT_EMAIL_FAIL;
  constructor(public payload: string) { }
}

// read email

export class LoadEmail implements Action {
  readonly type = EmailActionTypes.LOAD_EMAIL;
  constructor(public payload: string) { }
}

export class LoadEmailFiles implements Action {
  readonly type = EmailActionTypes.LOAD_EMAIL_FILES;
  constructor(public payload: MailGenericGetRequestModel) { }
}

export class LoadEmailFilesSuccess implements Action {
  readonly type = EmailActionTypes.LOAD_EMAIL__FILES_SUCCESS;
  constructor(public payload: FileModel[]) { }
}

export class LoadEmailFilesFail implements Action {
  readonly type = EmailActionTypes.LOAD_EMAIL_FILES_FAIL;
  constructor(public payload: string) { }
}

// register microsoft account

export class RegisterMicrosoftEmail implements Action {
  readonly type = EmailActionTypes.REGISTER_MICROSOFT_EMAIL;
  constructor(public payload: EmailAccountModel) { }
}

export class RegisterMicrosoftEmailSuccess implements Action {
  readonly type = EmailActionTypes.REGISTER_MICROSOFT_EMAIL_SUCCESS;
  constructor(public payload: AccountResponseModel) { }
}

export class RegisterMicrosoftEmailFail implements Action {
  readonly type = EmailActionTypes.REGISTER_MICROSOFT_EMAIL_FAIL;
  constructor(public payload: any) { }
}

// GMAIL

export class RegisterGmailEmail implements Action {
  readonly type = EmailActionTypes.REGISTER_GMAIL_EMAIL;
  constructor(public payload: EmailAccountModel) { }
}

export class RegisterGmailEmailSuccess implements Action {
  readonly type = EmailActionTypes.REGISTER_GMAIL_EMAIL_SUCCESS;
  constructor(public payload: AccountResponseModel) { }
}

export class RegisterGmailEmailFail implements Action {
  readonly type = EmailActionTypes.REGISTER_GMAIL_EMAIL_FAIL;
  constructor(public payload: any) { }
}

// register other email

export class RegisterOtherEmail implements Action {
  readonly type = EmailActionTypes.REGISTER_OTHER_EMAIL;
  constructor(public payload: EmailAccountModel) { }
}

export class RegisterOtherEmailSuccess implements Action {
  readonly type = EmailActionTypes.REGISTER_OTHER_EMAIL_SUCCESS;
  constructor(public payload: AccountResponseModel) { }
}

export class RegisterOtherEmailFail implements Action {
  readonly type = EmailActionTypes.REGISTER_OTHER_EMAIL_FAIL;
  constructor(public payload: any) { }
}

// login microsoft account

export class LoginMicrosoftEmail implements Action {
  readonly type = EmailActionTypes.LOGIN_MICROSOFT_EMAIL;
  constructor(public payload: EmailLoginParseModel) { }
}

export class LoginMicrosoftEmailSuccess implements Action {
  readonly type = EmailActionTypes.LOGIN_MICROSOFT_EMAIL_SUCCESS;
  constructor(public payload: AccountResponseModel) { }
}

export class LoginMicrosoftEmailFail implements Action {
  readonly type = EmailActionTypes.LOGIN_MICROSOFT_EMAIL_FAIL;
  constructor(public payload: string) { }
}

// login GMAIL

export class LoginGmailEmail implements Action {
  readonly type = EmailActionTypes.LOGIN_GMAIL_EMAIL;
  constructor(public payload: EmailLoginParseModel) { }
}

export class LoginGmailEmailSuccess implements Action {
  readonly type = EmailActionTypes.LOGIN_GMAIL_EMAIL_SUCCESS;
  constructor(public payload: AccountResponseModel) { }
}

export class LoginGmailEmailFail implements Action {
  readonly type = EmailActionTypes.LOGIN_GMAIL_EMAIL_FAIL;
  constructor(public payload: string) { }
}

// login other email

export class LoginOtherEmail implements Action {
  readonly type = EmailActionTypes.LOGIN_OTHER_EMAIL;
  constructor(public payload: EmailLoginParseModel) { }
}

export class LoginOtherEmailSuccess implements Action {
  readonly type = EmailActionTypes.LOGIN_OTHER_EMAIL_SUCCESS;
  constructor(public payload: AccountResponseModel) { }
}

export class LoginOtherEmailFail implements Action {
  readonly type = EmailActionTypes.LOGIN_OTHER_EMAIL_FAIL;
  constructor(public payload: string) { }
}

export type EmailClientActionsUnion =
  | ChangeActiveEmailAccount
  | ShowAllEmail
  | HideEmailAccountMenu
  | LoadAllEmailInbox
  | LoadAllEmailInboxSuccess
  | LoadAllEmailInboxFail
  | LoadEmailInbox
  | LoadEmailInboxSuccess
  | LoadEmailInboxFail
  | LoadDraftEmail
  | LoadDraftEmailSuccess
  | LoadDraftEmailFail
  | LoadSentEmail
  | LoadSentEmailSuccess
  | LoadSentEmailFail
  | LoadEmail
  | LoadEmailFiles
  | LoadEmailFilesSuccess
  | LoadEmailFilesFail
  | RegisterMicrosoftEmail
  | RegisterMicrosoftEmailSuccess
  | RegisterMicrosoftEmailFail
  | RegisterGmailEmail
  | RegisterGmailEmailSuccess
  | RegisterGmailEmailFail
  | RegisterOtherEmail
  | RegisterOtherEmailSuccess
  | RegisterOtherEmailFail
  | LoginMicrosoftEmail
  | LoginMicrosoftEmailSuccess
  | LoginMicrosoftEmailFail
  | LoginGmailEmail
  | LoginGmailEmailSuccess
  | LoginGmailEmailFail
  | LoginOtherEmail
  | LoginOtherEmailSuccess
  | LoginOtherEmailFail
  | LoadUserEmailAccount
  | LoadUserEmailAccountSuccess
  | LoadUserEmailAccountFail
  | UpdateEmailAccountDetailUnreadCount
  | LoadEmailFolderCount
  | LoadEmailFolderCountSuccess
  | LoadEmailFolderCountFail;
