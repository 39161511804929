export class PlanGroup {
  days: number;
  groupName: string;
  minimumAmount: number;
  maximumuAmount: number;
  bucketList:  LicenseEndPointModel [];
}

export class LicenseEndPointModel  {
  id: number;
  name: string;
  discountedPercentagePerUser: number;
  minimumUserCap: number;
  maximumUserCap: number;
  dataCap: number;
  isFreeSubscriptionType: boolean;
  valueDays: number;
  amount: number;
  crmPlanId?: number;
}

export class AppliedCouponResponse  {
  data: AppliedCouponData;
  errors?: any;
  message: string;
  status: number;
}


export class AppliedCouponData  {
  discounted_cost: number;
  expires: string;
  is_amount: boolean;
  is_percentage: boolean;
  name: string;
  type: string;
  value: number;
}

export interface MonthLicenseCost {
  id: number;
  name: string;
}

export interface PeriodDiscount {
  id: number;
  period: string;
  discountPercentage: number;
  monthCount: number;
  periodCost: number;
}

export interface ILicensePayment {
  licenseTypeId: number;
  licenseName: string;
  numberOfusers: number;
  licenseuserCharge: number;
  numberOfDays: number;
  currency: string;
}
