import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
  OnChanges,
  ElementRef,
} from "@angular/core";
import { DocumentService } from "src/app/services/document.service";
import { Notifier } from "../base-class/notifier";
import { Store } from "@ngrx/store";
import * as fromUserHeaderStore from "../../store/reducers/user-header.reducer";
// Briefs
import { GenericListViewModel } from "src/app/models/generic-model.ts/generic-list";
import { FilterGenericModel } from "src/app/models/generic-model.ts/filter-model";
import { MatterService } from "src/app/services/matter.service";
import { FormControl, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { DraftService } from "src/app/services/draft.service";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-add-file-to-folder",
  templateUrl: "./add-file-to-folder.component.html",
  styleUrls: ["./add-file-to-folder.component.scss"],
})
export class AddFileToFolderComponent
  extends Notifier
  implements OnInit, OnChanges, OnDestroy
{
  // pageSize = 8;
  // pageSizeIndex: number;
  // usePageSize: boolean;

  pagination = {
    currentPage: 0,
    nextPageLink: "",
    pageSize: 10,
    previousPageLink: "",
    totalCount: 0,
    totalPages: 0,
  };

  ShowSearchBar = false;
  @Input() showSearchBar: boolean;
  @Output() emitFileTypeId = new EventEmitter<any>();

  outPutModel = {
    matterId: 0,
    directoryId: 0,
    categoryId: 0,
    driveType: 0,
    parentId: 0,
  };

  @Input() collapseAllTreeNode = false;

  seachQuery = "";

  selectedDropDownList: any;

  documentCategory = 0;
  showDocumentFolders = false;
  documentFolderList = [];

  categoryList = {
    loading: true,
    categories: [],
    categoriesFolder: [],
  };

  googleDriveList = {
    loading: true,
    googleDrive: [],
    googleDriveList: [],
  };
  litigationList = {
    loading: true,
    matterList: [],
    matterListData: [],
  };
  nonLitigationList = {
    loading: true,
    matterList: [],
    matterListData: [],
  };
  alternateDisputeList = {
    loading: true,
    matterList: [],
    matterListData: [],
  };

  matterCategoryId: number;
  matterCategory = new GenericListViewModel();
  matterFilter = new FilterGenericModel();
  firmId: number;

  searchTargetDropDown = [
    {
      name: "All",
      id: 0,
    },
    {
      name: "Litigation",
      id: 1,
    },
    {
      name: "Non-Litigation",
      id: 1,
    },
    {
      name: "Alternate Dispute Resolution",
      id: 1,
    },
    {
      name: "Categories",
      id: 2,
    },
    // Temporarily hide this
    // {
    //   name: "Google_Drive",
    //   id: 2,
    // },
  ];

  searchFormGroup: FormGroup;
  searchValue: FormControl;

  selectedTargetDropDown = this.searchTargetDropDown[0].name;
  currentSelectedElement: HTMLElement;
  topLevel: HTMLElement;
  midLevel: HTMLElement;
  lastLevel: HTMLElement;

  hasChild = (_: number, node: any) =>
    !!node.children && node.children.length > 0;

  constructor(
    protected headerStore: Store<fromUserHeaderStore.HeaderState>,
    private documentService: DocumentService,
    private matterService: MatterService,
    private externalStorageService: DraftService
  ) {
    super(headerStore);
  }
  ngOnChanges(): void {
    this.ShowSearchBar = this.showSearchBar;
  }

  selectSearchTarget(dropDownValue) {
    this.selectedTargetDropDown = dropDownValue.name;
    // if (this.seachQuery) {
    this.categorySelected(dropDownValue.name);
    // }
  }

  categorySelected(category) {
    switch (category) {
      case "Categories":
        this.getCategories();
        break;
      case "Litigation":
        this.matterCategory.id = 1;
        this.matterCategory.name = "Litigation";
        this.litigationList.loading = true;
        this.getBriefs();
        break;
      case "Non-Litigation":
        this.matterCategory.id = 2;
        this.matterCategory.name = "Non-Litigation";
        this.nonLitigationList.loading = true;
        this.getBriefs();
        break;
      case "Alternate Dispute Resolution":
        this.matterCategory.id = 3;
        this.matterCategory.name = "Alternate Dispute Resolution";
        this.alternateDisputeList.loading = true;
        this.getBriefs();
        break;
    }
  }

  getAll(category) {
    switch (category) {
      case "Categories":
        this.getCategories();
        break;
      case "Google_Drive":
        this.getGoogleDriveFolder();
        break;
      case "Litigation":
        this.litigationList.loading = true;
        this.getAllLitigation();
        break;
      case "Non-Litigation":
        this.nonLitigationList.loading = true;
        this.getAllNonLitigation();
        break;
      case "Alternate Dispute Resolution":
        this.alternateDisputeList.loading = true;
        this.getAllAdrLitigation();
        break;
    }
  }

  getAllLitigation() {
    this.matterService.getAllLitigation().subscribe(
      (data) => {
        data.body.sort(this.sortAlphabetically);
        this.litigationList.matterList = data.body;
      },
      (err) => {
        this.litigationList.loading = false;
      }
    );
  }

  getAllNonLitigation() {
    this.matterService.getAllNonLitigation().subscribe(
      (data) => {
        data.sort(this.sortAlphabetically);
        this.nonLitigationList.matterList = data;
      },
      (err) => {
        this.nonLitigationList.loading = false;
      }
    );
  }

  getAllAdrLitigation() {
    this.matterService.getAllAdrLitigation().subscribe(
      (data) => {
        data.sort(this.sortAlphabetically);
        this.alternateDisputeList.matterList = data;
      },
      (err) => {
        this.alternateDisputeList.loading = false;
      }
    );
  }

  getBriefs() {
    this.matterCategoryId = this.matterCategory.id;

    this.addSubscription(
      this.matterService
        .getMatterListByCatgory(
          this.matterFilter.orderByName,
          this.matterFilter.orderByDate,
          this.matterFilter.ongoing,
          this.matterFilter.archived,
          this.matterFilter.completed,
          this.matterFilter.categoryType,
          this.matterFilter.categorySubType,
          this.matterFilter.clientId,
          this.seachQuery,
          "",
          this.firmId,
          this.matterCategoryId,
          "asc",
          this.pagination.currentPage,
          this.pagination.pageSize,
          false
        )
        .subscribe(
          (data) => {
            if (this.matterCategory.name === "Litigation") {
              this.litigationList.matterList = data.body;
            } else if (this.matterCategory.name === "Non-Litigation") {
              this.nonLitigationList.matterList = data.body;
            } else if (
              this.matterCategory.name === "Alternate Dispute Resolution"
            ) {
              this.alternateDisputeList.matterList = data.body;
            }
            this.pagination = JSON.parse(data.headers.get("X-Pagination"));
          },
          () => {
            this.litigationList.loading = false;
            this.nonLitigationList.loading = false;
            this.alternateDisputeList.loading = false;
          }
        )
    );
  }

  getCategories() {
    this.addSubscription(
      this.documentService.getCategories().subscribe(
        (res) => {
          this.categoryList.categories = res;
        },
        () => {
          this.categoryList.loading = false;
          this.showLoader = false;
        }
      )
    );
  }

  ngOnInit() {
    this.searchValue = new FormControl();
    this.searchFormGroup = new FormGroup({ searchValue: this.searchValue });

    this.searchValue.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((data) => {
        this.seachQuery = data;
        this.categorySelected(this.selectedTargetDropDown);
      });
  }

  getFirmMatterDirectory(matterId: number) {
    return this.matterService.getFirmMatterDirectory(matterId);
  }

  getCategoriesFolder(category) {
    this.matterService.getCategoriesFolder(category.id).subscribe(
      (res) => {
        this.categoryList.categoriesFolder = res;
        this.categoryList.loading = false;
      },
      (err) => {
        this.categoryList.loading = false;
      }
    );
  }

  getGoogleDriveFolder() {
    this.externalStorageService
      .getDriveFolders(
        1,
        this.pagination.pageSize,
        this.pagination.currentPage,
        this.seachQuery,
        true
      )
      .subscribe(
        (res) => {
          this.googleDriveList.googleDrive = res.body;
          this.googleDriveList.loading = false;
        },
        (err) => {
          //console.log(err);
          this.googleDriveList.loading = false;
        }
      );
  }

  setMatterDirectoryId(event, matterFolder) {
    if (this.currentSelectedElement) {
      this.currentSelectedElement.classList.remove("clicked");
    }
    event.target.classList.add("clicked");
    this.currentSelectedElement = event.target;

    this.outPutModel.directoryId = matterFolder.id;
    this.outPutModel.categoryId = 0;
    this.emitOutPut();
  }

  setCategoryDirectoryId(event, category) {
    if (this.currentSelectedElement) {
      this.currentSelectedElement.classList.remove("clicked");
    }
    event.target.classList.add("clicked");
    this.currentSelectedElement = event.target;

    this.outPutModel.directoryId = category.id;
    this.outPutModel.matterId = 0;
    this.outPutModel.driveType = 0;
    this.emitOutPut();
  }

  setMatterId(matter) {
    this.outPutModel.matterId = matter.id;
    this.outPutModel.directoryId = 0;
    this.outPutModel.categoryId = 0;
    this.outPutModel.driveType = 0;
    this.emitOutPut();
  }

  setCategoryId(category) {
    this.outPutModel.categoryId = category.id;
    this.outPutModel.matterId = 0;
    this.outPutModel.directoryId = 0;
    this.outPutModel.driveType = 0;
    this.emitOutPut();
  }

  setGoogleDrive(driveType: number) {
    this.outPutModel.categoryId = 0;
    this.outPutModel.matterId = 0;
    this.outPutModel.directoryId = 0;
    this.outPutModel.driveType = driveType;
    this.emitOutPut();
  }

  setGoogleDriveFile(drive) {
    this.outPutModel.categoryId = 0;
    this.outPutModel.matterId = 0;
    this.outPutModel.directoryId = 0;
    this.outPutModel.parentId = drive.id;
    this.emitOutPut();
  }

  emitOutPut() {
    this.emitFileTypeId.emit(this.outPutModel);
  }

  // ******************

  toggleCaret(event, treeCategory = "") {
    if (event.target.classList.contains("no-child-directories")) {
      if (this.currentSelectedElement) {
        this.currentSelectedElement.classList.remove("clicked");
      }
      event.target.classList.add("clicked");
      this.currentSelectedElement = event.target;
    } else if (event.target.classList.contains("topLevel")) {
      if (this.topLevel) {
        this.topLevel.nextElementSibling.classList.remove("active");
        this.topLevel.classList.remove("caret-down");
      }
      event.target.nextElementSibling.classList.add("active");
      event.target.classList.add("caret-down");
      this.topLevel = event.target;
    } else if (
      !event.target.classList.contains("topLevel") &&
      !event.target.classList.contains("no-child-directories") &&
      !event.target.classList.contains("last_drop_down")
    ) {
      if (this.midLevel) {
        this.midLevel.nextElementSibling.classList.remove("active");
        this.midLevel.classList.remove("caret-down");
      }
      event.target.nextElementSibling.classList.add("active");
      event.target.classList.add("caret-down");
      this.midLevel = event.target;
    } else if (event.target.classList.contains("last_drop_down")) {
      if (this.lastLevel) {
        this.lastLevel.nextElementSibling.classList.remove("active");
        this.lastLevel.classList.remove("caret-down");
      }
      event.target.nextElementSibling.classList.add("active");
      event.target.classList.add("caret-down");
      this.lastLevel = event.target;
    }

    if (
      treeCategory &&
      treeCategory === "Google_Drive" &&
      !this.showSearchBar
    ) {
      return;
    } else {
      this.getAll(treeCategory);
    }
  }

  getMatterDirectories(matter) {
    this.getFirmMatterDirectory(matter.id).subscribe(
      (res) => {
        if (matter.matterCategory === "Litigation") {
          this.litigationList.matterListData = res.body;
        } else if (matter.matterCategory === "Non-Litigation") {
          this.nonLitigationList.matterListData = res.body;
        } else if (matter.matterCategory === "ADR") {
          this.alternateDisputeList.matterListData = res.body;
        }

        if (res.body.length === 0) {
          this.litigationList.loading = false;
          this.nonLitigationList.loading = false;
          this.alternateDisputeList.loading = false;
        }
      },
      (err) => {
        this.litigationList.loading = false;
        this.nonLitigationList.loading = false;
        this.alternateDisputeList.loading = false;
      }
    );
  }

  toggleOpenedCaret(event, matter = null, category = null) {
    if (category !== null && !isNullOrUndefined(category.format)) {
      this.setGoogleDriveFile(category);
    } else {
      if (matter) {
        this.setMatterId(matter);
        this.getMatterDirectories(matter);
      }

      if (category) {
        this.setCategoryId(category);
        this.getCategoriesFolder(category);
      }

      const ref = event.target.parentNode.parentNode.children;

      if (ref.length > 0) {
        for (let i = 0; i < ref.length; i++) {
          const locateData = ref[i].childNodes[1];
          const isActive = ref[i].childNodes[1].classList.contains("active");

          if (locateData !== event.target.nextElementSibling && isActive) {
            ref[i].childNodes[1].classList.toggle("active");
            ref[i].childNodes[0].classList.toggle("caret-down");
          }
        }
      }
    }
  }

  private sortAlphabetically(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  ngOnDestroy(): void {
    this.clearSubscription();
  }
}
