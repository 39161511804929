import { AppConfigService } from '../../shared/helpers/app-config.service';

export class Endpoints {
  /**
   *
   */
  constructor(private config: AppConfigService) {
  }

  inviteEndpoint = 'firm/invite/user';
  registerEndpoint = 'firm';
  // remoteEndPoint  = this.config.getConfig().serverEndpoint;
  //  remoteEndPoint  = 'https://lpcasemanager.azurewebsites.net/api/v1/';
  // rootEndPoint = 'http://localhost:20532/api/v1/';
  // http://lawpavilioncasemanager.uksouth.cloudapp.azure.com/casemanager_api/api/v1/
  loginEndPoint = 'account/login';
  confirmEmailEndPoint = 'account/confirm-email';
  resendEmailConfirmationEndPoint = 'account/confirm-email';
  forgotPasswordEndPoint = 'account/forgot-password';
  addfirmUser = 'firm/user';
  getUserProfile = 'firm/{firmId:int}/user/{userId:int}';
  updateUserProfile = 'firm/user';
  getfirmDetails = 'firm';
  updateFirmDetails = 'firm';
  disableUser = 'firm/disable/user';
  enableUser = 'firm/enable/user';
  deleteUser = 'firm/enable/user';
  newRole = 'firm/role';
  updateRole = 'firm/permission/role';
  changePasswordEndpoint = 'account/change-password';
  addFirmLabelEndpoint = 'contact/firm/label';
  contactEndpoint = 'contact'; // add contact
  attachFirmLabel = 'contact/attach/firm/label';
  updateContactStatus = 'contact/status';
  validateEmail = 'account/validate-email/';
  matterEndPoint = 'matter';
  changeMatterCourtEndPoint = 'matter/change-court/court';
  matterContactEndPoint = 'matter/contact';
  getContactMatter = 'contact/{{contactId}}/matter';
  firmRoles = '';
  noteEndPoint = 'matter/note';
  timeSheetEndPoint = 'expense/TimeSheets';
  newUserGroup = 'firm/firmgroup';
  newFirmAccount = 'firm/Bank-Account';
  // getTimeSheetEndPoint = `expense/${matterId}/Timesheets`;
  // getInvitedUsers = `firm/invite/${firmId}/user`;
  // getFirmUsers = 'firm/{firmId}/user';
  // Reset = '[Scoreboard Page] Score Reset';

  // NBAMailRegisterLink = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=edb15f30-56a6-4c50-b20c-a743368a3daa
  // tslint:disable-next-line: max-line-length
  // &redirect_uri=http://localhost:4200/mailbox/confirm-mailbox&response_type=code&scope=Files.ReadWrite.All+Mail.Read+Mail.ReadWrite+Mail.Send+Mail.Send.Shared+MailboxSettings.ReadWrite+openid+offline_access+User.Read`;;
  // tslint:disable-next-line: max-line-length
  // "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=edb15f30-56a6-4c50-b20c-a743368a3daa&redirect_uri=https://casemanagerapp.azurewebsites.net/mail/outlook&response_type=code&scope=Files.ReadWrite.All+Mail.Read+Mail.ReadWrite+Mail.Send+Mail.Send.Shared+MailboxSettings.ReadWrite+openid+offline_access+User.Read";

  // tslint:disable-next-line: max-line-length
  // NBAMailRegisterLink = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=0e596ec2-6bc0-49ae-9db3-20d0c52961bd&response_type=code&redirect_uri=${window.location.origin}/mailbox/inbox&response_mode=query&scope=Files.ReadWrite.All+Mail.Read+Mail.ReadWrite+Mail.Send+Mail.Send.Shared+MailboxSettings.ReadWrite+openid+offline_access+User.Read&state=12345`;
  NBAMailRegisterLink = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=655fef99-2b63-4207-af4e-112cd2506566&response_type=code&redirect_uri=${window.location.origin}/mailbox/NaN/confirm-mailbox&response_mode=query&scope=Files.ReadWrite.All+Mail.Read+Mail.ReadWrite+Mail.Send+Mail.Send.Shared+MailboxSettings.ReadWrite+openid+offline_access+User.Read&state=12345`;
  // tslint:disable-next-line:max-line-length
  // GMAILMailLink = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://mail.google.com&access_type=offline&redirect_uri=${window.location.origin}/mailbox/confirm-mailbox&response_type=code&client_id=462881947442-tjhsmg3nhlj3p1inrug8uoc51ajd0rgc.apps.googleusercontent.com`;

  // tslint:disable-next-line: max-line-length
  GMAILMailLink = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=349828703431-9utboncvhnmj8carvima9u13vch9iprk.apps.googleusercontent.com&access_type=offline&scope=https://mail.google.com/ https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/userinfo.email&redirect_uri=${window.location.origin}/mailbox/NaN/confirm-mailbox?configId=1`;

}
