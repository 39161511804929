import {
  Component,
  OnInit,
  OnChanges,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
} from "@angular/core";

import { Store, select } from "@ngrx/store";
import { State } from "../../store/reducers/firm-roles.reducer";
import * as FirmRoleActions from "../../store/actions/firm-roles.action";
import * as fromFirmRoles from "../../store/reducers/firm-roles.reducer";
import { Observable, of } from "rxjs";
import { FirmRole } from "../../models/view-model/role-list";
import { UserManagementService } from "../../services/user-management.service";
import { isNullOrUndefined } from "util";
import { UserDetail } from "src/app/models/view-model/user-detail";
import { FormControl } from "@angular/forms";
import { FirmRoleService } from "../../services/firm-role.service";
import { UserRoleModel } from "src/app/models/edit-model/user-role-model";
import { Notifier } from "../base-class/notifier";
import * as fromUserHeaderStore from "../../store/reducers/user-header.reducer";

@Component({
  selector: "app-change-role-modal",
  templateUrl: "./change-role-modal.component.html",
  styleUrls: ["./change-role-modal.component.scss"],
})
export class ChangeRoleModalComponent
  extends Notifier
  implements OnInit, OnChanges
{
  rolePermission: any[] = [];
  currentRole: FirmRole;
  roleControl = new FormControl("");
  @Input() selectedRoleId: number;
  @Input() refreshList: number;

  @Output() emitRoleSelected = new EventEmitter();
  @ViewChild("closeChooseRoleModal") closeChooseRoleModal: ElementRef;

  firmRoles: FirmRole[] = [];

  roleId: number;
  @Input() btnSpinner$: Observable<boolean> = of(false);

  user = new UserDetail();
  sharedData: any;
  roleSelected: number;

  constructor(
    public roleStore: Store<fromFirmRoles.FirmRoleState>,
    private store: Store<State>,
    private firmRoleService: FirmRoleService,
    private service: UserManagementService,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>
  ) {
    super(headerStore);
    this.store.pipe(select("auth")).subscribe((data) => {
      /// save token and user detail to local storage
      this.user = data.userProfile;
      //console.log(this.user)
    });

    this.store.pipe(select(fromFirmRoles.getFirmRoles)).subscribe((data) => {
      if (!isNullOrUndefined(data)) {
        this.firmRoles = data;
        //console.log(this.firmRoles);
      }
    });
    this.firmRoleService.shareDataSubject.subscribe((receiveddata) => {
      if (!isNullOrUndefined(receiveddata)) {
        this.sharedData = receiveddata;
      }
    });
  }

  ngOnInit() {
    this.roleStore.dispatch(new FirmRoleActions.LoadRoles(this.user.firmId));

    // this.roleStore.pipe(select(fromFirmRoles.getFirmRoles)).subscribe(data => {
    //   this.firmRoles = data;
    //   //console.log(this.firmRoles);
    // });
  }

  ngOnChanges(changes) {
    // if the Id selected to change role is changed

    if (!isNullOrUndefined(this.selectedRoleId)) {
      const found = this.firmRoles.find((c) => c.id === this.selectedRoleId);

      //console.log(found);

      if (!isNullOrUndefined(found)) {
        this.currentRole = found;
        this.roleControl.patchValue(this.currentRole.id);

        this.rolePermission = this.currentRole.firmRolePermissions;
      }
    }

    if (!isNullOrUndefined(this.refreshList)) {
      this.service.getRolesList(this.user.firmId).subscribe((data) => {
        this.firmRoles = data;
      });
    }

    // watch btn spinner if the old value is true and the new value is false, then close the modal
  }

  OnRoleSelected(selectedRoleId: number) {
    //console.log(selectedRoleId);

    //console.log(this.firmRoles);

    if (!isNullOrUndefined(selectedRoleId)) {
      const found = this.firmRoles.find((c) => c.id == selectedRoleId);
      this.roleSelected = found.id;
      //console.log('this.roleSelected', this.roleSelected);

      if (!isNullOrUndefined(found)) {
        this.currentRole = found;
        this.rolePermission = this.currentRole.firmRolePermissions;
      }
    }

    // emit selected
  }

  saveRoleChange(data) {
    //console.log(data);
    const userRole = new UserRoleModel();
    userRole.firmRoleId = this.roleSelected;
    userRole.userId = this.sharedData.id;
    this.service
      .updateUserRole(userRole)
      .toPromise()
      .then(() => {
        this.firmRoleService.refreshUserRoles(true);
        this.pushMessage("UserRole successfully changed");
      })
      .catch((err) => {
        this.sendErrorMessage(err.error);
        //console.log(err);
      })
      .finally(() => {
        this.emitRoleSelected.emit(this.currentRole);
        this.closeChooseRoleModal.nativeElement.click();
        this.btnSpinner$ = of(false);
      });

    // button should be passed with of
  }
}
