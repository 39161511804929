export class CaseListViewModel {
  current_page: number;
  data: CaseTitleViewModel[];
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
  status: number;
  message: number;
}

export class CaseTitleViewModel {
  suit_number: string;
  title: string;
  citation: string;
  first_party: string;
  second_party: string;
  court: string;
  practice_area: string;
}
