import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
// import { firmRegistrationService } from './firm-registration.service';
import { AuthService } from "../../../services/auth.service";
import { PasswordValidation } from "../../../shared/helpers/password-matcher";
import { Register } from "../../../models/parse-model/register";
import { Router } from "@angular/router";
import { AppConfigService } from "src/app/shared/helpers/app-config.service";
import { GenericService } from "src/app/services/generic.service";
import { StateViewModel } from "src/app/models/view-model/state.model";

@Component({
  selector: "app-firm-registration",
  templateUrl: "./firm-registration.component.html",
  styleUrls: ["./firm-registration.component.scss"],
})
export class FirmRegistrationComponent implements OnInit {
  invalidEmail: boolean;
  error: string;
  firmReg: FormGroup;
  allFieldNotField = false;
  // while page is loading first, before interaction, dont show notification
  onLoadHide: boolean;
  showError: boolean;
  btnSpinner: boolean;
  emailCheckSpinner: boolean;
  firmUserTitle: any[] = [];
  public errorMessage = "";
  firmUserGender = [
    {
      id: 1,
      name: "Male",
    },
    {
      id: 2,
      name: "Female",
    },
  ];
  // An id for a particular country to be used to populate state of that country
  countryId = 1;
  states: StateViewModel[] = [];

  @ViewChild("messageBox") messageBox: ElementRef;

  constructor(
    fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private config: AppConfigService,
    private genericService: GenericService
  ) {
    this.firmReg = fb.group(
      {
        email: ["", [Validators.required, Validators.email]],
        userName: [""],
        password: ["", [Validators.required]],
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        firmName: ["", Validators.required],
        address: ["", Validators.required],
        city: ["", Validators.required],
        stateId: ["", Validators.required],
        gender: ["", Validators.required],
        titleId: ["", Validators.required],
        phoneNumber: ["", Validators.required],
        confirmPassword: ["", Validators.required],
      },
      {
        validator: PasswordValidation.MatchPassword, // your validation method
      }
    );
  }

  ngOnInit() {
    this.invalidEmail = false;
    this.onLoadHide = true;
    this.btnSpinner = false;
    this.emailCheckSpinner = false;

    this.firmUserTitle = this.config.getConfig().firmUserTitle;
    this.genericService.getStates(this.countryId).subscribe((res) => {
      res.forEach((data) => {
        this.states.push(data);
      });
    });
  }

  // display form data
  regFirm(data) {
    if (this.firmReg.invalid) {
      this.allFieldNotField = true;
      return;
    } else {
      this.allFieldNotField = false;
      this.btnSpinner = true;
      data.value.userName = data.value.email;
      let user: Register;
      user = data.value as Register;
      this.authService
        .register(user)
        .toPromise()
        .then((response) => {
          this.errorMessage = "";
          this.router.navigate(["/confirm-email", user.email]);
        })
        .catch((error) => {
          console.log(error);
          this.btnSpinner = false;
          this.showError = true;
          this.errorMessage = error;
        });
    }
  }

  validateEmail() {
    this.emailCheckSpinner = true;
    this.authService
      .validateEmail(this.email.value)
      .toPromise()
      .then(
        (data) => {
          if (data) {
            this.invalidEmail = false;
            this.emailCheckSpinner = false;
          } else {
            this.invalidEmail = true;
            this.messageBox.nativeElement.focus();
            // this.btnSpinner = false;
          }
        } // success path
      )
      .catch((error) => {
        this.error = error; // error path
        this.emailCheckSpinner = false;
        this.invalidEmail = true;
        this.messageBox.nativeElement.focus();
      });
  }

  // gain access to each form field for validation
  get firstName() {
    return this.firmReg.get("firstName");
  }
  get lastName() {
    return this.firmReg.get("lastName");
  }
  get email() {
    return this.firmReg.get("email");
  }
  get firmName() {
    return this.firmReg.get("firmName");
  }
  get firmPhoneNo() {
    return this.firmReg.get("firmPhoneNo");
  }
  get password() {
    return this.firmReg.get("password");
  }
  get address() {
    return this.firmReg.get("address");
  }
  get city() {
    return this.firmReg.get("city");
  }
  get stateId() {
    return this.firmReg.get("stateId");
  }
  get gender() {
    return this.firmReg.get("gender");
  }
  get titleId() {
    return this.firmReg.get("titleId");
  }
  get phoneNumber() {
    return this.firmReg.get("phoneNumber");
  }

  get confirmPassword() {
    return this.firmReg.get("confirmPassword");
  }
}
