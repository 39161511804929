import { FileUploadModel } from "src/app/models/generic-model.ts/file-upload";
import { Observable } from "rxjs";
import { EditFirmDetailsModel } from "./../models/edit-model/edit-firm.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfigService } from "../shared/helpers/app-config.service";
import { Endpoints } from "../models/shared-model/endpoint";
import { UserLite } from "../models/view-model/user-lite";
import { UserDetail } from "../models/view-model/user-detail";
import * as LoginActions from "../auth/state/login.actions";
import * as fromStore from "../auth/state/index";
import { Store, select } from "@ngrx/store";

@Injectable({
  providedIn: "root",
})
export class FirmService {
  Endpoints = new Endpoints(this.config);
  userData = new UserDetail();

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService,
    private store: Store<fromStore.State>
  ) {
    this.store.dispatch(new LoginActions.LoadLocalUserDetail());

    // geth the userId from the store (used NGRX)
    this.store.pipe(select("auth")).subscribe((data) => {
      /// save token and user detail to local storage
      this.userData = data.userProfile;
    });
  }
  public getFirmDetails(firmId: number): Observable<EditFirmDetailsModel> {
    //console.log(firmId);
    const url = this.config.getConfig().serverEndpoint + `firm/${firmId}`;
    return this.httpClient.get<EditFirmDetailsModel>(url);
  }

  public updateFirmDetails(firmDetails: EditFirmDetailsModel): Observable<any> {
    const api = this.config.getConfig().serverEndpoint + `firm`;
    firmDetails.id = this.userData.firmId;
    //console.log(api);
    return this.httpClient.put<any>(api, firmDetails);
  }

  public updateLogo(
    firmId: number,
    fileModel: FileUploadModel
  ): Observable<boolean> {
    //console.log(fileModel);
    const url = this.config.getConfig().serverEndpoint + `firm/logo/${firmId}`;

    const param: FileUploadModel = { ...fileModel };

    param.fileBinary = (<string>param.fileBinary).split(",")[1];

    return this.httpClient.patch<boolean>(url, param);
  }

  public updateSignature(
    firmId: number,
    fileModel: FileUploadModel
  ): Observable<boolean> {
    //console.log(fileModel);
    const url = this.config.getConfig().serverEndpoint + `firm/signature/${firmId}`;

    const param: FileUploadModel = { ...fileModel };

    param.fileBinary = (<string>param.fileBinary).split(",")[1];

    return this.httpClient.patch<boolean>(url, param);
  }

  public loadFirmLogo(): Observable<string> {
    const url = this.config.getConfig().serverEndpoint + `firm/firmLogo`;
    return this.httpClient.get<string>(url);
  }

  public loadFirmSignature(): Observable<string> {
    const url = this.config.getConfig().serverEndpoint + `firm/firmSignature`;
    return this.httpClient.get<string>(url);
  }

  public getAllFirmUsers(
    firmId: number,
    searchQuery = ""
  ): Observable<UserLite[]> {
    const endpoint =
      this.config.getConfig().serverEndpoint + `firm/${firmId}/user`;
    const url = `${endpoint}?searchQuery=${searchQuery}`;
    return this.httpClient.get<UserLite[]>(url);
  }
  public editClientDetails() {}
}
