import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from "@angular/core";
import { AppConfigService } from "../../shared/helpers/app-config.service";
import { FormBuilder, Validators, FormGroup, FormArray } from "@angular/forms";
import {
  NewEndorsement,
  tagId,
} from "../../matter-endorsement/models/new-endorsement";
import * as fromStore from "../../store/reducers/calendar-lander.reducer";
import { Store, select } from "@ngrx/store";

import { UserDetail } from "src/app/models/view-model/user-detail";
import * as LoginActions from "../../auth/state/login.actions";
import { isNullOrUndefined } from "util";
import * as fromMatters from "../../store/reducers/matters.reducers";
import { Endorsement } from "src/app/matter-endorsement/models/endorsement";
import { EndorsementService } from "src/app/matter-endorsement/services/endorsement.service";
import { of } from "rxjs";
import { CommonUserModel } from "src/app/models/generic-model.ts/user-share";

@Component({
  selector: "app-edit-endorsement-modal",
  templateUrl: "./edit-endorsement-modal.component.html",
  styleUrls: ["./edit-endorsement-modal.component.scss"],
})
export class EditEndorsementModalComponent
  implements OnInit, OnChanges, OnDestroy {
  tagIdArr: tagId[] = this.config.getConfig().priorities;
  editEndorsement: FormGroup;
  currentMatterId: number;
  btnSpinner: boolean;
  successText: string;
  errorText: string;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  showUsersModal = false;
  teamMembers = [];
  teamMembers$ = of([]);
  datesTracked: string[] = [];

  @Input() endorsementId: number;
  @Input() endorsementDetails: Endorsement;
  @Input() disableSelectOnEndorsementSelect: boolean;

  @Output() getSavedEndorsement = new EventEmitter();
  @Output() updateEndorsement = new EventEmitter();

  @ViewChild("messageBox") messageBox: ElementRef;
  @ViewChild("closeEndorsementModal") closeEndorsementModal: ElementRef;

  firmId: number;
  userId: number;
  userData = new UserDetail();
  showResourceNotSelectedError: boolean;
  indefiniteAdjournment = false;
  theMatterId: number;

  constructor(
    private fb: FormBuilder,
    private rootStore: Store<fromStore.State>,
    private config: AppConfigService,
    private service: EndorsementService,
    private store: Store<fromMatters.State>
  ) {
    this.rootStore.dispatch(new LoginActions.LoadLocalUserDetail());

    this.rootStore.pipe(select("auth")).subscribe((data) => {
      this.userData = data.userProfile;
      this.firmId = this.userData.firmId;
    });

    this.editEndorsement = fb.group({
      hearingDate: ["", Validators.required],
      proceedingId: [""],
      nextHearingDate: this.fb.array([], Validators.required),
      nextAction: [""],
      nextStage: ["", Validators.required],
      summary: ["", Validators.required],
      matterId: [
        {
          value: "",
          // ,disabled: true
        },
        [Validators.min(1)],
      ],
      note: [""],
      tagId: [""],
    });

    this.store
      .pipe(select(fromMatters.getCurrentMatter))
      .subscribe((matter) => {
        if (!isNullOrUndefined(matter)) {
          this.currentMatterId = matter.id;

          if (!isNullOrUndefined(this.currentMatterId)) {
            this.matterId.patchValue(this.currentMatterId);
          }
        }
      });
  }

  get hearingDate() {
    return this.editEndorsement.get("hearingDate");
  }

  get nextHearingDate(): FormArray {
    return <FormArray>this.editEndorsement.get("nextHearingDate");
  }

  get nextAction() {
    return this.editEndorsement.get("nextAction");
  }

  get nextStage() {
    return this.editEndorsement.get("nextStage");
  }

  get summary() {
    return this.editEndorsement.get("summary");
  }

  get matterId() {
    return this.editEndorsement.get("matterId");
  }

  get tagId() {
    return this.editEndorsement.get("tagId");
  }

  get note() {
    return this.editEndorsement.get("note");
  }

  ngOnInit() {
    this.showResourceNotSelectedError = false;
    this.disableSelectOnEndorsementSelect = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.endorsementId > 0 && this.endorsementDetails) {
      this.editEndorsement.patchValue({
        hearingDate: new Date(+this.endorsementDetails.hearingDate)
          .toISOString()
          .substring(0, 10),
        nextAction: this.endorsementDetails.nextAction,
        nextStage: this.endorsementDetails.nextStage,
        summary: this.endorsementDetails.summary,
        tagId: this.endorsementDetails.tagId,
        proceedingId: this.endorsementDetails.proceedingId,
      });

      this.teamMembers = this.endorsementDetails.users;
      this.teamMembers$ = of(this.endorsementDetails.users);

      this.indefiniteAdjournment = this.endorsementDetails.isIndefinate;

      if (this.indefiniteAdjournment) {
        this.editEndorsement.controls["nextHearingDate"].disable();
        this.editEndorsement.controls["nextHearingDate"].clearValidators();
      }

      if (this.endorsementDetails.nextHearingDate) {
        this.endorsementDetails.nextHearingDate.forEach((date) => {
          const existingDate = new Date(+date).toISOString().substring(0, 16);
          this.addNextHearingDate(existingDate);
        });
      }
    }
  }

  addNextHearingDate(date?: string, force = false): void {
    if (!isNullOrUndefined(date) && !this.datesTracked.includes(date)) {
      this.nextHearingDate.push(this.createNextHearing(date));
    } else {
      if (force) {
        this.nextHearingDate.push(this.createNextHearing());
      }
    }
  }

  createNextHearing(date?: string): FormGroup {
    if (isNullOrUndefined(date)) {
      return this.fb.group({
        date: [{ value: "" }, Validators.required],
        // Validators.pattern('(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g')
      });
    } else {
      this.datesTracked.push(date);
      return this.fb.group({
        date: [date, Validators.required],
        // Validators.pattern('(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g')
      });
    }
  }

  removenextHearingDate(index: number): void {
    this.nextHearingDate.removeAt(index);
  }

  closeSuccess(): void {
    this.showSuccessMessage = false;
  }

  closeError(): void {
    this.showErrorMessage = false;
  }

  getSelectedOption(event) {
    if (!isNullOrUndefined(this.currentMatterId)) {
      if (this.currentMatterId > 0) {
        this.matterId.patchValue(this.currentMatterId);
      }
    }

    if (!isNullOrUndefined(event)) {
      if (event > 0) {
        this.currentMatterId = event;
      }
    }
  }

  convertToInt(value: string): number {
    return parseInt(value);
  }

  setIndefiniteDate(): void {
    this.indefiniteAdjournment = !this.indefiniteAdjournment;
    if (this.indefiniteAdjournment) {
      this.editEndorsement.controls["nextHearingDate"].disable();
      this.editEndorsement.controls["nextHearingDate"].clearValidators();
    } else {
      this.editEndorsement.controls["nextHearingDate"].enable();
      this.editEndorsement.controls["nextHearingDate"].setValidators(
        Validators.required
      );
    }
  }

  removeMember(member: CommonUserModel): void {
    this.teamMembers = this.teamMembers.filter((user) => user.id != member.id);
    this.teamMembers$ = of(this.teamMembers);
  }

  saveUpdate(newEndorse: NewEndorsement) {
    this.btnSpinner = true;
    const hearingDateModel = [];
    if (this.indefiniteAdjournment) {
      newEndorse.nextHearingDate = [];
    }

    if (newEndorse.nextHearingDate.length) {
      newEndorse.nextHearingDate.forEach((date) => {
        hearingDateModel.push({ "date": (+new Date(date.date)).toString() });
      });
    }

    newEndorse.isIndefinate = this.indefiniteAdjournment;

    const teamMembersSelected = [];
    if (this.teamMembers.length) {
      this.teamMembers.forEach((member) => teamMembersSelected.push(member.id));
    }

    const endorsementModel = {
      proceedingId: this.endorsementDetails.proceedingId,
      hearingDate: (+new Date(newEndorse.hearingDate)).toString(),
      nextHearingDate: hearingDateModel,
      nextAction: newEndorse.nextAction,
      nextStage: newEndorse.nextStage,
      summary: newEndorse.summary,
      matterId: this.endorsementDetails.matterId,
      tagId: newEndorse.tagId,
      isIndefinate: newEndorse.isIndefinate,
      users: teamMembersSelected,
    };

    this.service.editEndorsement(endorsementModel).subscribe(
      (data) => {
        this.successText = `endorsement was created successfully`;

        this.btnSpinner = false;

        this.closeEndorsementModal.nativeElement.click();
        this.editEndorsement.reset();
        this.updateEndorsement.emit(data);

        while (this.nextHearingDate.length != 0) {
          this.nextHearingDate.removeAt(0);
        }
      },
      (error) => {
        this.showErrorMessage = true;
        this.messageBox.nativeElement.focus();
        this.errorText = `creating endorsement failed`;
        this.btnSpinner = false;
      }
    );
  }

  openUsersModal(): void {
    this.showUsersModal = true;
  }

  closeUsersModal(event: boolean): void {
    this.showUsersModal = false;
  }

  getUsersChosen(event): void {
    this.teamMembers = event.selectedUsers;
    this.teamMembers$ = of(this.teamMembers);
  }

  ngOnDestroy() {
    this.nextHearingDate.reset();
  }
}
