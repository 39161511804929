import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges } from "@angular/core";
import { Store, select } from "@ngrx/store";

import * as fromStore from "../../auth/state/index";
import * as LoginActions from "../../auth/state/login.actions";
import { MatterService } from "src/app/services/matter.service";
import { UserDetail } from "src/app/models/view-model/user-detail";
import { MatterList, GenericViewModel } from "src/app/models/view-model/matter-list";
import { TagOptionGenericModel } from "src/app/models/generic-model.ts/tag-option";
import { isNullOrUndefined } from "util";
import { FormControl } from "@angular/forms";
import { DraftEditModel } from "src/app/models/edit-model/edit-draft";
import { LpDocService } from "src/app/services/lp-doc.service";
import { FileEditModel } from "src/app/models/edit-model/edit-file.model";
import { DocumentService } from "src/app/services/document.service";

@Component({
  selector: "app-attach-entity",
  templateUrl: "./attach-entity.component.html",
  styleUrls: ["./attach-entity.component.scss"],
})
export class AttachEntityComponent implements OnInit, OnChanges {
  user = new UserDetail();
  itemList: GenericViewModel[] = [];
  titleString: string;

  @Input() selectedItemId: number;
  @Input() searchBoxTagOption: number;
  @Input() disableChangeOfItem: boolean;
  @Input() selectedItemName: string;
  @Input() showMe: boolean;
  @Input() serviceEntityId: number; // 1 for draft means it will call draft service
  @Input() resourceAttachedToEntityId: number; // for draft, DraftId
  @Output() postSelectedItem = new EventEmitter();

  @ViewChild("closeModal") closeModal: ElementRef;
  btnSpinner: boolean;

  matterId: number;
  tagId = new FormControl(0);

  constructor(
    private matterService: MatterService,
    private store: Store<fromStore.State>,
    private lpDocService: LpDocService,
    private documentService: DocumentService
  ) {
    this.store.dispatch(new LoginActions.LoadLocalUserDetail());

    this.store.pipe(select(fromStore.getUserDetail)).subscribe((data) => {
      //console.log(data);
      this.user = data;
    });
  }

  ngOnChanges(simpleChanges): void {
    if (!isNullOrUndefined(this.resourceAttachedToEntityId)) {
      this.resourceAttachedToEntityId = this.resourceAttachedToEntityId;
    }
    // this.serviceEntityId = this.serviceEntityId;
  }

  ngOnInit() {
    this.showMe = false;
    this.btnSpinner = false;
    this.selectedItemId = 0;
    this.matterId = 0;
    // this.serviceEntityId = 1;

    this.titleString = "Application/Endorsement...";

    if (this.selectedItemId === 0) {
      this.selectedItemId = 0;
      this.selectedItemName = "select " + this.titleString;
    }

    if (isNullOrUndefined(this.selectedItemId)) {
      this.selectedItemId = 0;
      this.selectedItemName = "select " + this.titleString;
    }

    this.postSelectedItem.emit(this.selectedItemId.toString());
  }

  getSelectedMatter(value) {
    //console.log(value);
    this.matterId = value;

    // after matter is selected load item the user wants to find. either endorsement or application etc

    // begin if else
    if (this.tagId.value === 3) {
      this.titleString = "application";

      this.matterService.getMatterList(this.user.firmId).subscribe((data) => {
        this.itemList = data.body;

        if (
          !isNullOrUndefined(
            this.itemList.find((c) => c.id === this.selectedItemId)
          )
        ) {
          this.selectedItemName = this.itemList.find(
            (c) => c.id === this.selectedItemId
          ).name;
        }
      });
    } else if (this.searchBoxTagOption === 2) {
      this.titleString = "endorsement";

      this.matterService.getMatterList(this.user.firmId).subscribe((data) => {
        this.itemList = data.body;

        if (
          !isNullOrUndefined(
            this.itemList.find((c) => c.id === this.selectedItemId)
          )
        ) {
          this.selectedItemName = this.itemList.find(
            (c) => c.id === this.selectedItemId
          ).name;
        }
      });
    }

    /// end of if else
  }

  selectItem(name: string, id: number) {
    //console.log(name);
    //console.log(id);
    this.selectedItemName = name;
    this.selectedItemId = id;
  }

  saveAttahment() {
    // console.log(this.serviceEntityId);

    if (this.serviceEntityId == 1) {
      if (this.matterId > 0) {
        this.btnSpinner = true;

        // call service to save

        const draft = new DraftEditModel();

        draft.id = this.resourceAttachedToEntityId;
        draft.matterId = this.matterId;
        //console.log(draft);

        this.lpDocService
          .attachDraftToEntity(draft)
          .toPromise()
          .then((res) => {
            this.postSelectedItem.emit(draft.id);
            this.btnSpinner = false;
            this.closeModal.nativeElement.click();
          })
          .catch((err) => {
            //console.log(draft.id);

            this.postSelectedItem.emit(-1);
            this.btnSpinner = false;
            this.closeModal.nativeElement.click();
          });
      }
    }
    if (this.serviceEntityId === 2) {
      if (this.matterId > 0) {
        this.btnSpinner = true;
        const file = new FileEditModel();

        file.fileId = this.resourceAttachedToEntityId;
        file.matterId = this.matterId;
        // console.log(file);
        this.documentService.addFileToMatter(file)
          .toPromise()
          .then((res) => {
            this.postSelectedItem.emit(file.fileId);
            this.btnSpinner = false;
            this.closeModal.nativeElement.click();
          })
          .catch((err) => {
            this.postSelectedItem.emit(-1);
            this.btnSpinner = false;
            this.closeModal.nativeElement.click();
          });
      }
    }
    //  else if(true){

    // }
  }

  searchItem(value, matterId) {
    this.matterService.getMatterList(matterId, value).subscribe((data) => {
      this.itemList = data.body;
    });
  }
}
