import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AddDocumentFolderModel } from '../../models/view-model/document-folder';
import { DocumentService } from '../../services/document.service';



@Component({
  selector: 'app-new-folder',
  templateUrl: './new-folder.component.html',
  styleUrls: ['./new-folder.component.scss']
})
export class NewFolderComponent implements OnInit, OnChanges {

  newFolderName = new AddDocumentFolderModel();
  @Input() publicBtnSpinner;
  @Input() addFolderToFileType: boolean;
  btnSpinner: boolean;
  @ViewChild('close_modal_addFolder') close_modal_addFolder: ElementRef;
  @Output() createFolder = new EventEmitter();
  @Output() emitFolderTypeId = new EventEmitter();
  documentCategory = 0;
  collapseAllTreeNode = false;

  folderGroup: FormGroup;

  get_folder_upload_destination: any;


  constructor(private fb: FormBuilder,
    private docService: DocumentService) {
    this.folderGroup = this.fb.group({
      folderName: ['', Validators.required],
    });

  }

  get folderName() {
    return this.folderGroup.get('folderName');
  }

  cancel() {
    this.folderGroup.reset();
  }

  ngOnChanges(changes) {
    if (this.publicBtnSpinner && this.publicBtnSpinner !== 'failed') {
      this.btnSpinner = true;
      this.close_modal_addFolder.nativeElement.click();
      this.folderName.reset();
      this.btnSpinner = false;
      this.collapseAllTreeNode = true;
    } else {
      this.btnSpinner = false;
    }
  }

  addFolder() {
    this.btnSpinner = true;

    this.newFolderName.folderName = this.folderGroup.value.folderName;
    if (this.addFolderToFileType === true) {
      this.createFolder.emit({ folderName: this.folderName.value, ...this.get_folder_upload_destination });
    } else {
      this.createFolder.emit(this.folderName.value);
    }
    this.close_modal_addFolder.nativeElement.click();
  }

  ngOnInit() {
    // this.addFolderToFileType = false;
  }

  setDocumentCategory(categoryNumber: number) {
    this.documentCategory = categoryNumber;
    this.emitFolderTypeId.emit(categoryNumber);
  }

  fileTypeEmitter(fileTarget) {
    this.get_folder_upload_destination = fileTarget;
  }

}
