import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router,
  CanLoad,
  Route
} from "@angular/router";
import { CurrentUserService } from "../../services/factories/current-user.service";

@Injectable({ providedIn: "root" })
export class LicenseGuard implements CanActivate {
  constructor(
    private currentUserService: CurrentUserService,
    private router: Router
  ) { }

  // canLoad(route: Route): boolean {
  //   let url = `/${route.path}`;
  //   return this.checkLicense(url);
  // }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    return this.checkLicense(url);
  }

  checkLicense(url: string): boolean {
    if (this.currentUserService.isLicensed()) {
      return true;
    }

    if (this.currentUserService.currentUser.role === "In-House Administrator") {
      this.router.navigate(["/analytics"]);
      return true;
    }

    this.currentUserService.addRedirectUrl(url);

    this.router.navigate(["/pricing"]);
    return false;
  }
}
