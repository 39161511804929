import { Action } from "@ngrx/store";
import { TokenParams } from "../../models/generic-model.ts/token-params";
import { UserDetail } from "../../models/view-model/user-detail";
import { PermissionViewModel } from "../../models/view-model/permission";
import { UserLoginDetail } from "../../models/parse-model/login";
import { FileUploadModel } from "src/app/models/generic-model.ts/file-upload";
import { EditFirmDetailsModel } from "src/app/models/edit-model/edit-firm.model";
import { FirmDetailViewModel } from "src/app/models/view-model/firm-detail";

// [AUTH] Auth module
export enum LoginActionTypes {
  LOGIN = "[AUTH] LOGIN",
  LOGIN_SUCCESS = "[AUTH] LOGIN_SUCCESS",
  LOGIN_FAIL = "[AUTH] LOGIN_FAIL",
  IS_LOGIN = "[AUTH] IS_LOGIN",
  LOGIN_DONE = "[AUTH] LOGIN_DONE",
  LOAD_LOCAL_USER_DETAIL = "[AUTH] LOAD_LOCAL_USER_DETAIL",
  LOAD_LOCAL_USER_DETAIL_SUCCESS = "[AUTH] LOAD_LOCAL_USER_DETAIL_SUCCESS",
  LOAD_LOCAL_USER_DETAIL_FAIL = "[AUTH] LOAD_LOCAL_USER_DETAIL_FAIL",

  LOAD_LOCAL_USER_TOKEN = "[AUTH] LOAD_LOCAL_USER_TOKEN",
  LOAD_LOCAL_USER_TOKEN_SUCCESS = "[AUTH] LOAD_LOCAL_USER_TOKEN_SUCCESS",
  LOAD_LOCAL_USER_TOKEN_FAIL = "[AUTH] LOAD_LOCAL_USER_TOKEN_FAIL",

  LOAD_ONLINE_USER_DETAIL = "[AUTH] LOAD_ONLINE_USER_DETAIL",
  LOAD_ONLINE_USER_DETAIL_SUCCESS = "[AUTH] LOAD_ONLINE_USER_DETAIL_SUCCESS",
  LOAD_ONLINE_USER_DETAIL_FAIL = "[AUTH] LOAD_ONLINE_USER_DETAIL_FAIL",

  LOAD_PROFILE_PICTURE = "[AUTH] LOAD_PROFILE_PICTURE",
  LOAD_PROFILE_PICTURE_SUCCESS = "[AUTH] LOAD_PROFILE_PICTURE_SUCCESS",
  LOAD_PROFILE_PICTURE_FAIL = "[AUTH] LOAD_PROFILE_PICTURE_FAIL",

  UPDATE_PROFILE_PICTURE = "[AUTH] UPDATE_PROFILE_PICTURE",
  UPDATE_PROFILE_PICTURE_SUCCESS = "[AUTH] UPDATE_PROFILE_PICTURE_SUCCESS",
  UPDATE_PROFILE_PICTURE_FAIL = "[AUTH] UPDATE_PROFILE_PICTURE_FAIL",

  LOAD = "[FIRMDETAIL] LOAD",
  LOAD_SUCCESS = "[FIRMDETAIL] LOAD_SUCCESS",
  LOAD_FAIL = "[FIRMDETAIL] LOAD_FAIL",
  UPDATE_FIRM = "[FIRMDETAIL] UPDATE_FIRM",
  UPDATE_FIRM_SUCCESS = "[FIRMDETAIL] UPDATE_FIRM_SUCCESS",
  UPDATE_FIRM_FAIL = "[FIRMDETAIL] UPDATE_FIRM_FAIL",

  LOAD_FIRM_LOGO = "[FIRMDETAIL] LOAD_FIRM_LOGO",
  LOAD_FIRM_LOGO_SUCCESS = "[FIRMDETAIL] LOAD_FIRM_LOGO_SUCCESS",
  LOAD_FIRM_LOGO_FAIL = "[FIRMDETAIL] LOAD_FIRM_LOGO_FAIL",

  LOAD_FIRM_SIGNATURE = "[FIRMDETAIL] LOAD_FIRM_SIGNATURE",
  LOAD_FIRM_SIGNATURE_SUCCESS = "[FIRMDETAIL] LOAD_FIRM_SIGNATURE_SUCCESS",
  LOAD_FIRM_SIGNATURE_FAIL = "[FIRMDETAIL] LOAD_FIRM_SIGNATURE_FAIL",

  UPDATE_FIRM_LOGO = "[FIRMDETAIL] UPDATE_FIRM_LOGO",
  UPDATE_FIRM_LOGO_SUCCESS = "[FIRMDETAIL] UPDATE_FIRM_LOGO_SUCCESS",
  UPDATE_FIRM_LOGO_FAIL = "[FIRMDETAIL] UPDATE_FIRM_LOGO_FAIL",

  UPDATE_FIRM_SIGNATURE = "[FIRMDETAIL] UPDATE_FIRM_SIGNATURE",
  UPDATE_FIRM_SIGNATURE_SUCCESS = "[FIRMDETAIL] UPDATE_FIRM_SIGNATURE_SUCCESS",
  UPDATE_FIRM_SIGNATURE_FAIL = "[FIRMDETAIL] UPDATE_FIRM_SIGNATURE_FAIL",

  LOGOUT = "[AUTH] LOGOUT",
  LOGOUT_SUCCESS = "[AUTH] LOGOUT_SUCCESS",
  LOGOUT_FAIL = "[AUTH] LOGOUT_FAIL",
}

export class Login implements Action {
  readonly type = LoginActionTypes.LOGIN;
  constructor(public payload: UserLoginDetail) {}
}

export class LoginSuccess implements Action {
  readonly type = LoginActionTypes.LOGIN_SUCCESS;
  constructor(
    public payload: {
      isUserFirmSubscribedToOKM: boolean;
      authToken: TokenParams;
      userProfile: UserDetail;
      accountPermissions: PermissionViewModel[];
    }
  ) {}
}

export class LoginFail implements Action {
  readonly type = LoginActionTypes.LOGIN_FAIL;
  constructor(public payload: string) {}
}

export class Logout implements Action {
  readonly type = LoginActionTypes.LOGOUT;
  constructor(public payload: string) {}
}

export class LogoutSuccess implements Action {
  readonly type = LoginActionTypes.LOGOUT_SUCCESS;
}

export class LogoutFail implements Action {
  readonly type = LoginActionTypes.LOGOUT_FAIL;
  constructor(public payload: any) {}
}

export class IsLogin implements Action {
  readonly type = LoginActionTypes.IS_LOGIN;
}

export class LoginDone implements Action {
  readonly type = LoginActionTypes.LOGIN_DONE;
}

export class LoadLocalUserDetail implements Action {
  readonly type = LoginActionTypes.LOAD_LOCAL_USER_DETAIL;
}

export class LoadLocalUserDetailSuccess implements Action {
  readonly type = LoginActionTypes.LOAD_LOCAL_USER_DETAIL_SUCCESS;
  constructor(public payload: UserDetail) {}
}

export class LoadLocalUserDetailFail implements Action {
  readonly type = LoginActionTypes.LOAD_LOCAL_USER_DETAIL_FAIL;
  constructor(public payload: string) {}
}

export class LoadOnlineUserDetail implements Action {
  readonly type = LoginActionTypes.LOAD_ONLINE_USER_DETAIL;
  constructor(public payload: { firmId: number; userId: number }) {}
}

export class LoadOnlineUserDetailSuccess implements Action {
  readonly type = LoginActionTypes.LOAD_ONLINE_USER_DETAIL_SUCCESS;
  constructor(public payload: UserDetail) {}
}

export class LoadOnlineUserDetailFail implements Action {
  readonly type = LoginActionTypes.LOAD_ONLINE_USER_DETAIL_FAIL;
  constructor(public payload: string) {}
}

export class LoadProfilePicture implements Action {
  readonly type = LoginActionTypes.LOAD_PROFILE_PICTURE;
  constructor(public payload: number) {}
}

export class LoadProfilePictureSuccess implements Action {
  readonly type = LoginActionTypes.LOAD_PROFILE_PICTURE_SUCCESS;
  constructor(public payload: string) {}
}

export class LoadProfilePictureFail implements Action {
  readonly type = LoginActionTypes.LOAD_PROFILE_PICTURE_FAIL;
  constructor(public payload: string) {}
}

export class UpdateProfilePicture implements Action {
  readonly type = LoginActionTypes.UPDATE_PROFILE_PICTURE;
  constructor(public payload: FileUploadModel) {}
}

export class UpdateProfilePictureSuccess implements Action {
  readonly type = LoginActionTypes.UPDATE_PROFILE_PICTURE_SUCCESS;
  constructor(public payload: boolean) {}
}

export class UpdateProfilePictureFail implements Action {
  readonly type = LoginActionTypes.UPDATE_PROFILE_PICTURE_FAIL;
  constructor(public payload: string) {}
}

export class Load implements Action {
  readonly type = LoginActionTypes.LOAD;
  constructor(public payload: number) {}
}

export class LoadSuccess implements Action {
  readonly type = LoginActionTypes.LOAD_SUCCESS;
  constructor(public payload: FirmDetailViewModel) {}
}

export class LoadFail implements Action {
  readonly type = LoginActionTypes.LOAD_FAIL;
  constructor(public payload: string) {}
}

export class Update implements Action {
  readonly type = LoginActionTypes.UPDATE_FIRM;
  constructor(public payload: EditFirmDetailsModel) {}
}

export class UpdateSuccess implements Action {
  readonly type = LoginActionTypes.UPDATE_FIRM_SUCCESS;
  constructor(public payload: EditFirmDetailsModel) {}
}

export class UpdateFail implements Action {
  readonly type = LoginActionTypes.UPDATE_FIRM_FAIL;
  constructor(public payload: string) {}
}

export class LoadFirmLogo implements Action {
  readonly type = LoginActionTypes.LOAD_FIRM_LOGO;
}

export class LoadFirmLogoSuccess implements Action {
  readonly type = LoginActionTypes.LOAD_FIRM_LOGO_SUCCESS;
  constructor(public payload: string) {}
}

export class LoadFirmLogoFail implements Action {
  readonly type = LoginActionTypes.LOAD_FIRM_LOGO_FAIL;
  constructor(public payload: string) {}
}


export class LoadFirmSignature implements Action {
  readonly type = LoginActionTypes.LOAD_FIRM_SIGNATURE;
}

export class LoadFirmSignatureSuccess implements Action {
  readonly type = LoginActionTypes.LOAD_FIRM_SIGNATURE_SUCCESS;
  constructor(public payload: string) {}
}

export class LoadFirmSignatureFail implements Action {
  readonly type = LoginActionTypes.LOAD_FIRM_SIGNATURE_FAIL;
  constructor(public payload: string) {}
}

export class UpdateFirmLogo implements Action {
  readonly type = LoginActionTypes.UPDATE_FIRM_LOGO;
  constructor(public payload: { firmId: number; fileModel: FileUploadModel }) {}
}

export class UpdateFirmLogoSuccess implements Action {
  readonly type = LoginActionTypes.UPDATE_FIRM_LOGO_SUCCESS;
  constructor(public payload: boolean) {}
}

export class UpdateFirmLogoFail implements Action {
  readonly type = LoginActionTypes.UPDATE_FIRM_LOGO_FAIL;
  constructor(public payload: string) {}
}

export class UpdateFirmSignature implements Action {
  readonly type = LoginActionTypes.UPDATE_FIRM_SIGNATURE;
  constructor(public payload: { firmId: number; fileModel: FileUploadModel }) {}
}

export class UpdateFirmSignatureSuccess implements Action {
  readonly type = LoginActionTypes.UPDATE_FIRM_SIGNATURE_SUCCESS;
  constructor(public payload: boolean) {}
}

export class UpdateFirmSignatureFail implements Action {
  readonly type = LoginActionTypes.UPDATE_FIRM_SIGNATURE_FAIL;
  constructor(public payload: string) {}
}

export type LoginActionsUnion =
  | Login
  | LoginSuccess
  | LoginFail
  | LoadLocalUserDetail
  | LoadLocalUserDetailSuccess
  | LoadLocalUserDetailFail
  | IsLogin
  | LoginDone
  | LoadOnlineUserDetail
  | LoadOnlineUserDetailSuccess
  | LoadOnlineUserDetailFail
  | LoadProfilePicture
  | LoadProfilePictureSuccess
  | LoadProfilePictureFail
  | UpdateProfilePicture
  | UpdateProfilePictureSuccess
  | UpdateProfilePictureFail
  | Load
  | LoadSuccess
  | LoadFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | LoadFirmLogo
  | LoadFirmLogoSuccess
  | LoadFirmLogoFail
  | LoadFirmSignature
  | LoadFirmSignatureSuccess
  | LoadFirmSignatureFail
  | UpdateFirmLogo
  | UpdateFirmLogoSuccess
  | UpdateFirmLogoFail
  | UpdateFirmSignature
  | UpdateFirmSignatureSuccess
  | UpdateFirmSignatureFail
  | Logout
  | LogoutSuccess
  | LogoutFail;
