import { FileUploadModel } from 'src/app/models/generic-model.ts/file-upload';
import { FileViewModel } from 'src/app/models/view-model/document-list';

export class ModelFactory {



    Create(input: FileUploadModel): FileViewModel {
        const doc = new FileViewModel();
        doc.itemName = input.fileName;
        doc.fileBinary = <string>input.fileBinary;
        doc.format = input.fileExtension;
        doc.fileSize = input.fileSize.toString();

        return doc;


      }

}

