import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FileUploadModel } from 'src/app/models/generic-model.ts/file-upload';
import { IAttachFile } from '../interfaces/attach-file';


@Component({
  selector: 'app-attach-file',
  templateUrl: './attach-file.component.html',
  styleUrls: ['./attach-file.component.scss']
})
export class AttachFileComponent implements OnInit, IAttachFile {
  errorText: string;
  showError: boolean;
  fileProgressArray: number[];
  currentDocument: FileUploadModel[];
  totalFileSize: number;

  @Output() uploaderObject = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.fileProgressArray = [];
    this.currentDocument = [];
    this.totalFileSize = 0;
  }


  closeFileItem(index) {
    this.fileProgressArray.splice(index, 1);
    this.currentDocument.splice(index, 1);
  }


  //#region File attachment
  /**
 * when file is changed
 * @param event;
 */
  onFileChange(event) {
    this.set_upReader(event.target.files, 0);
  }

  set_upReader(files, i) {
    // check if it has file
    if (files && files[i]) {
      const file = files[i];
      const fileName = file.name;
      const fileSize = file.size;
      const fileType = file.type;
      if (((file.size + this.totalFileSize) > (25597152))) {
        this.errorText =
          'File attached ' +
          file.name +
          '  with file size greater than 10MB is not allowed',
          // tslint:disable-next-line: no-unused-expression
          'Error! ';
      } else {
        const fileType = file.type;
        const previewReader = new FileReader();
        previewReader.onprogress = (e) => {
          this.fileProgressArray.push(e.loaded / e.total * 100);
        };

        previewReader.readAsDataURL(file);
        previewReader.onload = (e: any) => {

          this.readerLoaded(e, files, i, fileName, fileSize, fileType);
        };
      }
    }
  }


  readerLoaded(e, files, i, fileName, fileSize, fileType) {

    // called once readAsDataURL is completed
    // this is correct , ignore errors
    const FILE = new FileReader();

    const taskUpload = new FileUploadModel();

    taskUpload.fileBinary = (<string>e.target.result).split(
      ','
    )[1];


    taskUpload.fileExtension = fileType;
    taskUpload.fileType = fileType;
    taskUpload.fileName = fileName;
    taskUpload.fileSize = fileSize;
    if (taskUpload.fileExtension ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      taskUpload.fileExtension = "docx"
    }

    this.totalFileSize += taskUpload.fileSize;
    this.currentDocument.push(taskUpload);
    // call service


    // If there's a file left to load
    if (i < files.length - 1) {
      // Load the next file
      return this.set_upReader(files, i + 1);
    }

    this.uploaderObject.emit({ showError: this.showError, errorText: this.errorText, currentDocument: this.currentDocument });
  }

  //#endregion


}
