import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { CalendarViewModel } from "../../models/view-model/calendar-event";

@Component({
  selector: "app-calendar-modal",
  templateUrl: "./calendar-modal.component.html",
  styleUrls: ["./calendar-modal.component.scss"],
})
export class CalendarModalComponent implements OnInit {
  @Input() clickedTasks: CalendarViewModel[] = [];
  @Output() closeTaskDetailModal = new EventEmitter();
  constructor() {}

  ngOnInit() {
    //console.log(this.clickedTasks);
  }

  close_modal() {
    this.closeTaskDetailModal.emit("close");
  }
}
