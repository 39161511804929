import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LayoutComponent } from "./layout/layout.component";
import { HomepageComponent } from "./homepage/homepage.component";
import { FaqsComponent } from "./faqs/faqs.component";
import { PricingComponent } from "./pricing/pricing.component";
import { HomeGuard } from "../shared/guards/home-guard.service";
import { MoneyTransferOptionComponent } from "./money-transfer-option/money-transfer-option.component";
import { TermsComponent } from "./terms/terms.component";
import { PaymentSuccesfulComponent } from "./payment-succesful/payment-succesful.component";
import { PolicyComponent } from "./policy/policy.component";

const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "",
        component: HomepageComponent,
        pathMatch: "full",
        canActivate: [HomeGuard],
      },
      { path: "help", component: FaqsComponent },
      {
        path: "pricing",
        component: PricingComponent,
        //  canActivate: [AuthAdminGuard]
      },
      { path: "pay", component: MoneyTransferOptionComponent },
      { path: "terms", component: TermsComponent },
      { path: "userterms", component: TermsComponent },
      { path: "payment-success", component: PaymentSuccesfulComponent },
      { path: "policies", component: PolicyComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
