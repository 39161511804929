import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { AppConfigService } from "../../shared/helpers/app-config.service";
import { FormBuilder, Validators, FormGroup, FormArray } from "@angular/forms";
import { tagId } from "../../matter-endorsement/models/new-endorsement";
import { MatterService } from "../../services/matter.service";
import * as fromStore from "../../store/reducers/calendar-lander.reducer";
import { Store, select } from "@ngrx/store";

import { UserDetail } from "src/app/models/view-model/user-detail";
import * as LoginActions from "../../auth/state/login.actions";
import { isNullOrUndefined } from "util";
import { Notifier } from "../base-class/notifier";
import * as fromUserHeaderStore from "../../store/reducers/user-header.reducer";
import { endOfDay } from "date-fns";
import { GoogleCalendarService } from "src/app/services/google-calendar.service";
import { forkJoin, iif } from "rxjs";
import { CalendarReminderViewModel } from "src/app/models/view-model/calendar-event";

@Component({
  selector: "app-court-appearance",
  templateUrl: "./court-appearance.component.html",
  styleUrls: ["./court-appearance.component.scss"],
})
export class CourtAppearanceComponent extends Notifier implements OnInit {
  searchBoxTagOption: number = 1;
  tagIdArr: tagId[] = this.config.getConfig().priorities;
  newAppearance: FormGroup;
  matterIdNo: number;
  matterName: number;
  @Input() disableSelectOnEndorsementSelect: boolean;
  btnSpinner: boolean;
  @Output() getSavedAppearance = new EventEmitter();
  @Output() newGCalTaskEmit = new EventEmitter<any>();
  @Input() gCalId = "";
  @ViewChild("closeEndorsementModal") closeEndorsementModal: ElementRef;
  firmId: number;
  userId: number;
  userData = new UserDetail();
  showResourceNotSelectedError: boolean;

  constructor(
    private fb: FormBuilder,
    private rootStore: Store<fromStore.State>,
    private config: AppConfigService,
    private service: MatterService,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>,
    private gCalendarService: GoogleCalendarService
  ) {
    super(headerStore);

    this.rootStore.dispatch(new LoginActions.LoadLocalUserDetail());

    this.rootStore.pipe(select("auth")).subscribe((data) => {
      /// save token and user detail to local storage
      this.userData = data.userProfile;
      this.firmId = this.userData.firmId;
    });

    this.newAppearance = fb.group({
      date: this.fb.array([], Validators.required),
      action: ["", Validators.required],
      briefStage: ["", Validators.required],
      //   matterId: [{value:""
      //   // ,disabled: true
      // }, [Validators.min(1)]],
      note: [""],
      tagId: [""],
    });
  }

  get date(): FormArray {
    return <FormArray>this.newAppearance.get("date");
  }
  get action() {
    return this.newAppearance.get("action");
  }
  get briefStage() {
    return this.newAppearance.get("briefStage");
  }

  get tagId() {
    return this.newAppearance.get("tagId");
  }
  get note() {
    return this.newAppearance.get("note");
  }

  ngOnInit() {
    this.newAppearance.valueChanges.subscribe((data) => { });

    this.showResourceNotSelectedError = false;

    this.addNextHearingDate();
  }

  addNextHearingDate(): void {
    this.date.push(this.createNextHearing());
  }

  createNextHearing(): FormGroup {
    return this.fb.group({
      date: [{ value: "" }, Validators.required],
      // Validators.pattern('(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g')
    });
  }

  removenextHearingDate(index: number): void {
    this.date.removeAt(index);
  }

  getSelectedOption(event: number, type) {
    if (!isNullOrUndefined(event)) {
      if (type == 'id') return this.matterIdNo = +event;
      return this.matterName = event;
    }
  }

  addNewAppearance(newCourtAppearance) {
    if (isNullOrUndefined(this.matterIdNo)) {
      this.showResourceNotSelectedError = true;
      return;
    } else if (this.matterIdNo < 1) {
      this.showResourceNotSelectedError = true;
      return;
    }
    this.btnSpinner = true;
    newCourtAppearance.matterId = this.matterIdNo;

    let dateArray = [];
    newCourtAppearance.date.forEach((element) => {
      element = "" + new Date(element.date).getTime();
      dateArray.push(element);
    });

    newCourtAppearance.date = dateArray;

    if (newCourtAppearance.tagId === "") {
      newCourtAppearance.tagId = 3;
    } else {
      newCourtAppearance.tagId = Number(newCourtAppearance.tagId);
    }
    if (this.gCalId) {
      newCourtAppearance.gCalId = this.gCalId;
      const saveToCase = this.service.addNewGCalCourtAppearance(newCourtAppearance)
      const endPointCall = [saveToCase]
      newCourtAppearance.date.map((event) => {
        
        const payload: CalendarReminderViewModel = {
          date: event,
          title: this.matterName.toString(),
          googleCalId: this.gCalId
        }
        endPointCall.push(this.gCalendarService.creategCalReminder(payload))
        return event
      })
  
  
      iif(
        () => !!this.gCalId,
        forkJoin(endPointCall),
        saveToCase
      ).subscribe(
        (data) => {
          this.pushMessage("endorsement was created successfully");
  
          this.searchBoxTagOption = 0;
          this.newAppearance.reset();
          this.btnSpinner = false;
          this.closeEndorsementModal.nativeElement.click();
          this.getSavedAppearance.emit(newCourtAppearance);
        },
        (error) => {
          this.sendErrorMessage("creating endorsement failed");
          this.btnSpinner = false;
        }
      );
    
    }
    else {
      const saveToCase = this.service.addNewCourtAppearance(newCourtAppearance)
      const endPointCall = [saveToCase]
      newCourtAppearance.date.map((event) => {
        let eventDate = new Date(+event)
        const payload = {
          start: {
            dateTime: eventDate
          },
          end: {
            dateTime: endOfDay(eventDate)
          },
          summary: this.matterName,
          description: newCourtAppearance.briefStage,
          guestsCanModify: false,
          guestsCanInviteOthers: true,
          guestsCanSeeOtherGuests: true,
          reminders: {
            useDefault: true,
          },
        }
  
        endPointCall.push(this.gCalendarService.createEvent(payload, this.gCalId))
        return event
      })
  
  
      iif(
        () => !!this.gCalId,
        forkJoin(endPointCall),
        saveToCase
      ).subscribe(
        (data) => {
          this.pushMessage("endorsement was created successfully");
  
          this.searchBoxTagOption = 0;
          this.newAppearance.reset();
          this.btnSpinner = false;
          this.closeEndorsementModal.nativeElement.click();
          this.getSavedAppearance.emit(newCourtAppearance);
        },
        (error) => {
          this.sendErrorMessage("creating endorsement failed");
          this.btnSpinner = false;
        }
      );
    }

    

    
  }
}
