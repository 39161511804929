import { TagOptionGenericModel } from "../generic-model.ts/tag-option";
import { Reminder } from "./matter-tasks";
import { UserDetailLiteModel } from "./user-detail-lite";

export class UserTask {
  id?: number;
  name: string;
  dueDate: any;
  dueDateInNumber?: number;
  resourceId: number;
  resourceName: string;
  resourceType: string;
  isCompleted: boolean;
  tag: string;
  taskUsersCount: number;
  taskUsers: UserDetailLiteModel[];
  description: string;
  tagId?: number;
  rating?: number;
  reminders?: Reminder[];
  isReoccurringTask?: boolean = false;
  reoccurringInterval?: number;
  startDate?: string;
  tagOption?: TagOptionGenericModel;
}

export const userTaskListSample: UserTask[] = [];
