import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StoreModule } from "@ngrx/store";
import { CoreModule } from "./core/core.module";
import { AuthModule } from "./auth/auth.module";
import { ReadMailResolverService } from "./resolver/read-mail-resolver.service";
import { AuthService } from "./services/auth.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { EffectsModule } from "@ngrx/effects";
import { AuthInterceptor } from "./interceptors/auth-interceptor";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { AppConfigService } from "./shared/helpers/app-config.service";
import { NgxWebstorageModule } from "ngx-webstorage";
import { FirmRoleService } from "./services/firm-role.service";
import { ExportAsModule } from "ngx-export-as";
import { SharedModule } from "./shared/shared.module";
// import { MainModule } from './main/main.module';
import { HomeModule } from "./home/home.module";
import { NgxDraggableDomModule } from "ngx-draggable-dom";
import { CalendarResolverService } from "./resolver/calendar-resolver.service";
import { UtilService } from "./core/util.service";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    NgxWebstorageModule.forRoot(),
    CoreModule,
    SharedModule,
    NgbModule,
    HomeModule,
    HttpClientModule,
    NgxDraggableDomModule,
    // UserDashboardModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      name: "Case Manager App DevTools",
      maxAge: 25,
      logOnly: environment.production,
    }),
    AuthModule,
    ExportAsModule,
    AppRoutingModule,
  ],
  providers: [
    AuthService,
    CalendarResolverService,
    ReadMailResolverService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    UtilService,
    FirmRoleService,
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule { }
