import { HttpClient, HttpEventType } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Endpoints } from "../models/shared-model/endpoint";
import { AppConfigService } from "../shared/helpers/app-config.service";

@Injectable({
  providedIn: "root",
})
export class FileUploadService {
  Endpoints = new Endpoints(this.config);

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) {}

  /**
   * upload file to the api asynchronously
   * @param data FormData appended with file and caption
   * @param entityId Application = 1,
   // Firm = 2,
   // Judgement = 3,
   // Matter = 4,
   // Proceeding = 5,
   // Task = 6
   */
  public upload(
    data,
    entityId,
    resourceId,
    resourceDirectoryId
  ): Observable<any> {
    //console.log(resourceId);
    //console.log(resourceDirectoryId);
    const url =
      this.config.getConfig().serverEndpoint +
      `file/${entityId}/${resourceId}/${resourceDirectoryId}`; // todo  change endpoint + i.e. this.Endpoints.contactEndpoint;
    return this.httpClient
      .post<any>(url, data, {
        reportProgress: true,
        observe: "events",
      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: "progress", message: progress };

            case HttpEventType.Response:
              return event.body;
            default:
              return `Unhandled event: ${event.type}`;
          }
        })
      );
  }

  public uploadNonAsync(data: any[]): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `file`;
    return this.httpClient.post<any>(url, data);
  }

  public addFile(data: any[]): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `file/Add`;
    return this.httpClient.post<any>(url, data);
  }

  public uploadLargeFile(data: any): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `file/upload-large-file`;
    return this.httpClient.post<any>(url, data);
  }

  public addLargeFile(data: any[]): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `file/add-large`;
    return this.httpClient.post<any>(url, data);
  }

  public downloadNonAsync(id, fileType): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `file/download/${id}`;

    return this.httpClient.get<any>(url);
  }

  public deleteNonAsync(id, fileType): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      `file/${id}/filetype/${fileType}`;

    return this.httpClient.delete<any>(url);
  }

  public deleteFile(id): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `file/${id}`;

    return this.httpClient.delete<any>(url);
  }
}
