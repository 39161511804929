import { Component, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import * as fromStore from "../../auth/state/index";
import { UserDetail } from "../../models/view-model/user-detail";
import * as LoginActions from "../../auth/state/login.actions";
import { Observable, of } from "rxjs";
import * as fromEmailClientStore from "../../store/reducers/email-client.reducer";
import * as fromTaskStore from "../../store/reducers/calendar-lander.reducer";
import * as fromTask from "../../store/reducers/task.reducer";
import * as emailClientActions from "../../store/actions/email-client.actions";
import { MatterService } from "../../services/matter.service";
import { GenericListViewModel } from "../../models/generic-model.ts/generic-list";
import { isNullOrUndefined } from "util";
import { FirmDetailViewModel } from "../../models/view-model/firm-detail";
import { PermissionViewModel } from "src/app/models/view-model/permission";
import { EmailClientService } from "src/app/services/email-client.service";
import { EmailAccountModel } from "src/app/models/parse-model/add-account";
import { CommonUserModel } from "src/app/models/generic-model.ts/user-share";
import { CurrentUserService } from "src/app/services/factories/current-user.service";
import { PermissionService } from "src/app/shared/services/permission.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-primary-sidebar",
  templateUrl: "./primary-sidebar.component.html",
  styleUrls: ["./primary-sidebar.component.scss"],
})
export class PrimarySidebarComponent implements OnInit {
  showChildren: boolean;
  showReportChildren: boolean;
  showCalendarChildren: boolean;
  user = new UserDetail();
  userEmail: string;
  userRole: string;
  userProfilePicture = new Observable<string>();
  firstName: Observable<string>;
  lastName: Observable<string>;
  matterCategories: GenericListViewModel[] = [];
  reportCategories: GenericListViewModel[] = [];
  calendarCategories: GenericListViewModel[] = [];
  theFirm = new FirmDetailViewModel();
  isSuccess: boolean;
  canViewInvoice = false;
  canViewReport: boolean = false;
  canViewClients: boolean = false;
  configId: number;
  emailList: EmailAccountModel[] = [];
  hasLogInEmail: boolean = false;

  firmLogo = new Observable<string>();
  // Task
  newtaskUserList: CommonUserModel[] = [];
  userData = new UserDetail();
  firmId: any;
  userId: any;
  showCurrentTask: boolean;
  stillLoadingMail: boolean = true;
  taskUsersList$: Observable<CommonUserModel[]> = of([]);
  taskDetailUserList$: Observable<CommonUserModel[]> = of([]); // observable version, used with view task to update task users
  constructor(
    private store: Store<fromStore.State>,
    private router: Router,
    private emailClientStore: Store<fromEmailClientStore.State>,
    private matterService: MatterService,
    private emailService: EmailClientService,
    private rootStore: Store<fromTaskStore.State>,
    private taskStore: Store<fromTask.State>,
    public currentUser: CurrentUserService,
    private permissionService: PermissionService
  ) {
    this.store.select((fromStore.getUserDetail)).subscribe((data) => {
      this.user = data;
      // console.log(data);
      this.firstName = of(this.user.firstName);
      this.lastName = of(this.user.lastName);
      if (
        !isNullOrUndefined(this.user.accountPermissions) &&
        this.user.accountPermissions.length > 0
      ) {
        const invoiceView: PermissionViewModel =
          this.user.accountPermissions.find((c) => c.permissionEntityId === 1);
        if (!isNullOrUndefined(invoiceView)) {
          this.canViewInvoice = invoiceView.permissionAction.canView;
        }
      }
    });

    this.store.pipe(select(fromStore.getCurrentProfileImage))
      .subscribe((pic) => {
        if (!isNullOrUndefined(pic)) {
          this.userProfilePicture = of(pic);
        }
      });

    this.store.pipe(select(fromStore.getFirmDetail)).subscribe((data) => {
      // check if firm detail ngrx state dat is not null

      this.theFirm = data;
      // check if the firm country is not null
    });

    this.store.pipe(select(fromStore.getUserRole)).subscribe((data) => {
      this.userRole = data;
    });

    this.store.pipe(select(fromStore.getUserEmail)).subscribe((data) => {
      this.userEmail = data;
    });

    this.store.pipe(select(fromStore.getFirmLogo)).subscribe((data) => {
      // check if firm detail ngrx state dat is not null
      if (!isNullOrUndefined(data)) {
        this.firmLogo = of(data);
      }

      // check if the firm country is not null
    });

    // Task Implementation
    this.rootStore.dispatch(new LoginActions.LoadLocalUserDetail());

    this.rootStore.pipe(select("auth")).subscribe((data) => {
      /// save token and user detail to local storage
      this.userData = data.userProfile;
      this.firmId = this.userData.firmId;
      this.userId = this.userData.userId;
    });
    this.taskStore.pipe(select(fromTask.viewTask)).subscribe((data) => {
      this.showCurrentTask = data;
    });

    this.canViewReport = this.permissionService.checkPermission("Report", "canView");
    this.canViewClients = this.permissionService.checkPermission("Client", "canView");
  }

  ngOnInit() {
    this.store.dispatch(new LoginActions.LoadLocalUserDetail());

    this.showChildren = false;
    this.showReportChildren = false;
    this.showCalendarChildren = false;

    this.getMatterCategories();
    this.getReportCategories();
    this.getcalendarCategories();

    if (!isNullOrUndefined(this.user.userId)) {
      this.store.dispatch(
        new LoginActions.LoadProfilePicture(this.user.userId)
      );
    }

    // load firm detail
    this.store.dispatch(new LoginActions.Load(this.user.firmId));

    this.store.dispatch(new LoginActions.LoadFirmLogo());
    this.store.dispatch(new LoginActions.LoadFirmSignature());

    this.getEmailAccountsCount();
  }

  getMatterCategories() {
    this.matterService.getMatterCategory().subscribe((data) => {
      this.matterCategories = data;
    });
  }

  getReportCategories(): void {
    this.reportCategories = [
      { id: 1, name: "Client Management", description: "/firm/client-report" },
      {
        id: 2,
        name: "Productivity Management",
        description: "/firm/productivity",
      },
      // { id: 3, name: "Financial Management", description: "/firm/financials" },
      { id: 4, name: "Practice Management", description: "/firm/practice" },
    ];
  }

  getcalendarCategories(): void {
    this.calendarCategories = [
      { id: 1, name: "Calendar", description: "/firm/calendar" },
      { id: 2, name: "Causelist", description: "/firm/causelist" },
    ];
  }

  getEmailAccountsCount() {
    this.emailService.getUserEmailAccounts().subscribe((response) => {
      if (!isNullOrUndefined(response)) {
        this.emailList = response;
        // console.log(this.emailList)
        this.emailList.forEach((email) => {
          if (email.loggedIn) {
            this.hasLogInEmail = true;
            this.configId = email.configId;
            this.stillLoadingMail = false;
            return
          }
        })
        this.stillLoadingMail = false;

      }
    });
  }
  setStatus(e) {
    this.isSuccess = e;
    if (this.isSuccess) {
      //  this.router
    }
  }

  updateTaskUsersList(usersList) {
    this.taskUsersList$ = of(usersList);
  }

  clearRightClicks() {
    this.emailClientStore.dispatch(
      new emailClientActions.HideEmailAccountMenu()
    );
  }

  getUsersChosen(result: CommonUserModel[]) {
    // this passes the user chosen to the appdrawer for showing task detail
    // if the drawer is not shown, you dont want the data to be subscribed to by it.

    if (this.showCurrentTask) {
      const tempUser = result;
      this.taskDetailUserList$ = of(tempUser);
    } else {
      this.newtaskUserList = result;
    }
  }
  goToMailBox(configId) {
    this.router.navigate([`/mailbox/${configId}/inbox`], { queryParams: { emailId: configId } });
  }
}
