import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Appstate } from '../../models/shared-model/appstate';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  topFirms$: Observable<any>;
  constructor() {
    // this.topFirms$ = this.store.select(state => state.topFirms);
  }

  ngOnInit() { }

  // getTopFirms() {
  //   this.store.dispatch(new homeAction.IncrementHome());
  // }


  goToLink(url: string) {
    window.open(url, '_blank');
  }
}
