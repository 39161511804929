export interface FeedbackRating {
  id: number;
  caption: string;
  colorClass: string;
}

export class ClientFeedback {
  addedBy: number;
  clientId: number;
  contactName?: string;
  counselName?: string;
  dateAdded?: string;
  dueDate?: string;
  feedback: string;
  feedbackType?: string;
  feedbackTypeId: number;
  fullfillmentDate?: string;
  id: number;
  nextAction?: string;
  nextHearingDate?: string;
  nextStage?: string;
  parentId?: number;
  parentResourceName?: string;
  rating: number;
  resourceId?: number;
  resourceName?: string;
  files?: any[];
}

export class FeedbackParseModel {
  counselId: number;
  matterId: number;
  rating: number;
  clientId: number;
  feedback: string;
}

export class FeedbackDataModel {
  matter: string;
  endorsement: string;
  contract: string;
  milestone: string;
  feedback: string;
  ratingName: string;
  ratingId: number;
}
