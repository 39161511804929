import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';

// since it's lazy loaded
import * as fromUserHeaderStore from '../../store/reducers/user-header.reducer';
import { Store, select } from '@ngrx/store';
import * as UserHeaderActions from '../../store/actions/user-header.actions';
import { UserDetail } from '../../models/view-model/user-detail';
import * as LoginActions from '../../auth/state/login.actions';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { UserProfileViewModelLite } from 'src/app/models/view-model/UserProfileViewModelLite';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationViewModel } from 'src/app/models/shared-model/notification';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss']
})
export class UserHeaderComponent implements OnInit {
  timerActive: boolean;
  userData = new UserDetail();
  unreadNotificationCount: number;

  // searchForm: FormGroup;
  firmId: number;
  userId: number;
  showTimi: boolean;

  activitiesDrawerShown: boolean;
  activityList: NotificationViewModel[] = [];

  @ViewChild('searchText') searchText: ElementRef;
  private _searchTerm: string;

  get searchTerm(): string {
    return this._searchTerm;
  }
  set searchTerm(value: string) {
    this._searchTerm = value;
  }

  constructor(
    private store: Store<fromUserHeaderStore.HeaderState>,
    private _notyService: NotificationService,
    private router: Router,
    private ngZone: NgZone,
  ) {

    this.store.dispatch(new LoginActions.LoadLocalUserDetail());
    this.store.pipe(select('auth')).subscribe(data => {
      /// save token and user detail to local storage
      this.userData = data.userProfile;
    });

    this.store
      .pipe(select(fromUserHeaderStore.getTimerState))
      .subscribe(data => {
        this.timerActive = data;
      });

    this._notyService.getActivities().subscribe(res => {
      this.activityList = res.body;
    });

    this.store.pipe(select(fromUserHeaderStore.getNotificationCount)).subscribe(res => {
      this.unreadNotificationCount = res;
    });

    this.router.events.subscribe((event: RouterEvent) => {
      this.toggleTimi(event);
    });

  }

  private toggleTimi(event: RouterEvent): void {
    if (event instanceof NavigationEnd) {
      if (event.url === "/firm/okm/ask") {
        this.showTimi = true;
      } else {
        this.showTimi = false;
      }
    }
  }


  showTimer() {
    if (!this.timerActive) {
      this.store.dispatch(new UserHeaderActions.ShowTimer());
    } else {
      this.store.dispatch(new UserHeaderActions.HideTimer());
    }
  }

  showActivitiesDrawer() {
    this.activitiesDrawerShown = true;
  }


  closeActivitiesDrawer() {
    this.activitiesDrawerShown = false;
  }


  clearSearchField() {
    this.searchText.nativeElement.value = '';
  }

  search() {

    // do search logic
    this.router.navigate([`/firm/search`], {
      queryParams: { 'q': this.searchTerm }
    });


  }

  hideTimer() { }

  ngOnInit() {

    this.activitiesDrawerShown = false;


  }
}
