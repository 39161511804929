import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Endpoints } from '../models/shared-model/endpoint';
import { AppConfigService } from '../shared/helpers/app-config.service';
import { DriveRenameModel } from '../models/view-model/document-list';
import { CommonUserModel } from '../models/generic-model.ts/user-share';
import { DrivePermittedUser } from '../documents/models/drive-reusable.model';

@Injectable({
  providedIn: 'root'
})
export class DraftService {

  Endpoints = new Endpoints(this.config);

  constructor(private httpClient: HttpClient, private config: AppConfigService,) { }

  deleteDriveAccount(id: any): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `drive/firm/account`;
    return this.httpClient.delete<any>(endpoint, id);
  }

  authenticateGoogleEmail(): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Email/url`;
    return this.httpClient.get<any>(endpoint);
  }

  authenticatePlugins(): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `auth/url`;
    return this.httpClient.get<any>(endpoint);
  }

  addDriveAccount(payload: any): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `drive/firm/account`;
    return this.httpClient.post<any>(endpoint, payload);
  }

  getGoogleDriveAddAccountURL(): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `drive/firm/url`;
    return this.httpClient.get<any>(endpoint);
  }

  getGoogleDriveAccounts(): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `drive/firm/accounts`;
    return this.httpClient.get<any>(endpoint);
  }

  getGoogleDriveFolders(searchQuery: string = ""): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `drive/firm/files?searchQuery=${searchQuery}`;
    return this.httpClient.get<any>(endpoint);
  }

  getGoogleDriveHistory(pageSize?: number, page?: number, searchQuery = '', usePageSize = true, FromDate = '', ToDate = '', ActionIds = []): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `drive/firm/history`;
    var url =
      `${endpoint}?Search=${searchQuery}&usePageSize=${usePageSize}&FromDate=${FromDate}&ToDate=${ToDate}&`;
    if (pageSize) {
      url += `pageSize=${pageSize}&`
    }
    if (page) {
      url += `page=${page + 1}&`
    }
    if (ActionIds && ActionIds.length > 0) {
      url += `ActionIds=${ActionIds}&`
    }
    return this.httpClient.get<any>(url);
  }

  deleteGoogleDriveFile(fileId: string): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `drive/firm/files/${fileId}`;
    return this.httpClient.delete<any>(endpoint);
  }

  deleteGoogleDriveFolder(fileId: string): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `drive/firm/folders/${fileId}`;
    return this.httpClient.delete<any>(endpoint);
  }


  public updateDriveUserPermission(fileId: string, permissionId: string, permissionType: number): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Drive/1/files/${fileId}/permissions/${permissionId}`;
    const payload = {
      "permissionType": permissionType
    }
    return this.httpClient.patch<any>(endpoint, payload);
  }

  public googleDriveShare(fileId: string, permissionId: string, permissionType: number): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Drive/1/files/${fileId}/permissions/${permissionId}`;
    const payload = {
      "permissionType": permissionType
    }
    return this.httpClient.patch<any>(endpoint, payload);
  }


  public getDrivePermissedUsers(fileId: string): Observable<DrivePermittedUser[]> {
    const endpoint = this.config.getConfig().serverEndpoint + `Drive/1/files/${fileId}/permissions`;

    return this.httpClient.get<DrivePermittedUser[]>(endpoint);
  }

  public getDriveUsersList(): Observable<any[]> {
    const endpoint = this.config.getConfig().serverEndpoint + `Drive/Accounts/firm/1`;

    return this.httpClient.get<any[]>(endpoint);
  }


  getGoogleDriveFolderFiles(folderId: any = "root", searchQuery: string = ""): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `drive/firm/files?searchQuery=${searchQuery}&parent=${folderId}`;
    return this.httpClient.get<any>(endpoint);
  }

  addDriveFolder(payload: any): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Drive/NewFolder`;
    return this.httpClient.post<any>(endpoint, payload);
  }

  addNewDriveFolder(payload: any): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `drive/firm/folders`;
    return this.httpClient.post<any>(endpoint, payload);
  }

  renameNewDriveFolder(folderId: string, payload: any): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `drive/firm/folders/${folderId}`;
    return this.httpClient.put<any>(endpoint, payload);
  }

  addNewDriveFile(payload: any): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `drive/firm/files`;
    return this.httpClient.post<any>(endpoint, payload);
  }

  addDriveFile(payload: any): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Drive/UploadFile`;
    return this.httpClient.post<any>(endpoint, payload);
  }

  DeleteFile(driveType: number, fileId: number): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Drive/DeleteFile/${driveType}/${fileId}`;
    return this.httpClient.delete<any>(endpoint);
  }

  DeleteFolder(driveType: number, fileId: number): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Drive/DeleteDriveFolder/${driveType}/${fileId}`;
    return this.httpClient.delete<any>(endpoint);
  }

  getAccounts(): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Drive/Accounts/1`;
    return this.httpClient.get<any>(endpoint);
  }

  downloadFile(fileId: string): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Drive/googlefiledownload/${fileId}`;
    return this.httpClient.get<any>(endpoint);
  }

  getDriveFiles(driveType: number, pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Drive/DriveFiles/${driveType}`;
    const url =
      `${endpoint}?searchQuery=${searchQuery}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  getDriveFolders(driveType: number, pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Drive/DriveFolders/${driveType}`;
    const url =
      `${endpoint}?searchQuery=${searchQuery}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  getSharePointFolders(): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `Sharepoint/FolderList`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  getSharePointFiles(): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `Sharepoint/FileList`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  renameSharePointFolder(id: string, newName: string): Observable<string> {
    const url = this.config.getConfig().serverEndpoint + `Sharepoint/RenameFolder/${id}`;
    return this.httpClient.post<string>(url, newName);
  }

  renameSharePointFile(id: string, newName: string): Observable<string> {
    const url = this.config.getConfig().serverEndpoint + `Sharepoint/RenameFile/${id}`;
    return this.httpClient.post<string>(url, newName);
  }

  deleteSharePointFile(fileId: string, driveType: number): Observable<boolean> {
    const url = this.config.getConfig().serverEndpoint + `Sharepoint/File/${fileId}`;
    return this.httpClient.delete<boolean>(url);
  }

  deleteSharePointFolder(fileId: string, driveType: number): Observable<boolean> {
    const url = this.config.getConfig().serverEndpoint + `Sharepoint/Folder/${fileId}`;
    return this.httpClient.delete<boolean>(url);
  }

  get_Drive_Files_In_Folders(driveType: number, folderId: string, pageSize?: number, page?: number, searchQuery = '', usePageSize = true): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Drive/DriveFilesInFolder/${driveType}/${folderId}`;
    const url =
      `${endpoint}?searchQuery=${searchQuery}&pageSize=${pageSize}&page=${page + 1}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  renameDriveFolder(driveFolder: DriveRenameModel): Observable<string> {
    const url = this.config.getConfig().serverEndpoint + `Drive/RenameFolder`;
    return this.httpClient.put<string>(url, driveFolder);
  }

  renameDriveFile(driveFile: DriveRenameModel): Observable<string> {
    const url = this.config.getConfig().serverEndpoint + `Drive/RenameFile`;
    return this.httpClient.put<string>(url, driveFile);
  }

  deleteDriveFile(fileId: string, driveType: number): Observable<boolean> {
    const url = this.config.getConfig().serverEndpoint + `Drive/DeleteFile/${driveType}/${fileId}`;
    return this.httpClient.delete<boolean>(url);
  }

  deleteDriveFolder(folderId: string, driveType: number): Observable<boolean> {
    const url = this.config.getConfig().serverEndpoint + `Drive/DeleteDriveFolder/${driveType}/${folderId}`;
    return this.httpClient.delete<boolean>(url);
  }

}
