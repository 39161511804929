import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Endpoints } from '../models/shared-model/endpoint';
import { NotificationViewModel } from '../models/shared-model/notification';
import { AppConfigService } from '../shared/helpers/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  Endpoints = new Endpoints(this.config);

  constructor(private httpClient: HttpClient,
    private config: AppConfigService) {
  }

  getNotifications(searchQuery = '', sort = '', order = 'asc', page = 0, pageSize = 0, usePageSize = false): Observable<any> {
    const api = this.config.getConfig().serverEndpoint + 'Notification';

    const url = `${api}?searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${page + 1}&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }

  getNewNotifications(searchQuery = '', sort = '', order = 'asc', page = 0, pageSize = 0, usePageSize = false): Observable<any> {
    const api = this.config.getConfig().serverEndpoint + 'new-notification';

    const url = `${api}?searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${page + 1}&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }



  getActivities(searchQuery = '', sort = '', order = 'asc', page = 0, pageSize = 10, usePageSize = true): Observable<any> {
    const api = this.config.getConfig().serverEndpoint + 'Notification/Activities';

    const url = `${api}?searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${page + 1}&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: 'response' });
  }
}
