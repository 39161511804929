import { Action } from "@ngrx/store";

export enum DocumentActionTypes {
  SET_RELOAD_PAGE = "[DOCUMENT] SET_RELOAD_PAGE",
  SET_DOCUMENT_REFRESH = "[DOCUMENT] SET_DOCUMNET_REFRESH",
  SET_GOOGLE_DRIVE_REFRESH = "[DOCUMENT] SET_GOOGLE_DRIVE_REFRESH",
  SET_FOLDER_DOCUMENT_REFRESH_ = "[FOLDER] DOCUMENT_SET_RELOAD_PAGE"
}

export class SetReloadPage implements Action {
  readonly type = DocumentActionTypes.SET_RELOAD_PAGE;
  constructor(public payLoad: any) { }
}

export class SetDocumentRefresh implements Action {
  readonly type = DocumentActionTypes.SET_DOCUMENT_REFRESH;
  constructor(public payLoad: any) { }
}

export class SetGoogleDriveRefresh implements Action {
  readonly type = DocumentActionTypes.SET_GOOGLE_DRIVE_REFRESH;
  constructor(public payLoad: any) { }
}
export class SetFolderDocumentRefresh implements Action {
  readonly type = DocumentActionTypes.SET_FOLDER_DOCUMENT_REFRESH_;
  constructor(public payLoad: any) { }
}

export type DocumentActions = SetReloadPage | SetDocumentRefresh | SetGoogleDriveRefresh| SetFolderDocumentRefresh;
