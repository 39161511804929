import { Observable, throwError, Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { tap, catchError } from "rxjs/operators";
import { FirmRole } from "../models/view-model/role-list";
import { Endpoints } from "../models/shared-model/endpoint";
import { AppConfigService } from "../shared/helpers/app-config.service";

@Injectable({
  providedIn: "root",
})
export class FirmRoleService {
  Endpoints = new Endpoints(this.config);
  shareDataSubject = new Subject<any>();
  refresh = new Subject<any>();

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) {}

  public getFirmRoles(): Observable<FirmRole[]> {
    const url = this.Endpoints.firmRoles;
    return this.httpClient.get<FirmRole[]>(url).pipe(
      tap((data) => {
        // console.log(JSON.stringify(data))
      }),
      catchError(this.handleError)
    );
  }

  private handleError(err) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
    }
    // console.error(err);
    return throwError(errorMessage);
  }

  userRoleGetter(somedata) {
    this.shareDataSubject.next(somedata);
  }
  refreshUserRoles(somedata) {
    this.refresh.next(somedata);
  }
}
