import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy, DoCheck } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PermissionEditModel, PermissionControlModel, } from 'src/app/models/edit-model/permission-edit-model';
import { FormGroup, FormControl, FormBuilder, FormArray, } from '@angular/forms';
import { GenericService } from 'src/app/services/generic.service';
import { GenericListViewModel } from 'src/app/models/generic-model.ts/generic-list';
import { PermissionActionParseModel } from 'src/app/models/view-model/permission-action';
import { isNullOrUndefined } from 'util';
import { Notifier } from 'src/app/shared/base-class/notifier';
import { Store } from '@ngrx/store';

import * as fromUserHeaderStore from '../../store/reducers/user-header.reducer';

@Component({
  selector: 'app-permission-control',
  templateUrl: './permission-control.component.html',
  styleUrls: ['./permission-control.component.scss'],
})
export class PermissionControlComponent extends Notifier implements OnInit, OnChanges, OnDestroy {
  @Input() permissions$: Observable<PermissionEditModel[]> = of([]);
  @Input() existingPermissions: PermissionControlModel[] = [];
  @Input() btnSpinner$: Observable<boolean> = of(false);
  @Input() isPermissionControlLocked: boolean;
  @Input() hidePermissionList = false;
  @Input() roleId: number;
  @Output() savedPermission = new EventEmitter();

  permissionForm: FormGroup;
  platformMatterPermissions: GenericListViewModel[] = [];
  btnSpinner = true;

  constructor(
    private fb: FormBuilder,
    private genericService: GenericService,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>
  ) {
    super(headerStore);
    this.permissionForm = this.fb.group({
      permissions: new FormArray([]),
    });
  }

  ngOnChanges(changes) {
    this.combineData();
  }

  ngOnInit() {
    this.loadMatterPermissions();
  }

  get permissions(): FormArray {
    return <FormArray>this.permissionForm.get('permissions');
  }

  combineData() {
    if (!isNullOrUndefined(this.existingPermissions)) {
      if (this.existingPermissions.length > 0) {
        if (this.platformMatterPermissions.length > 0) {
          this.platformMatterPermissions.forEach((p) => {
            const cacheOfPermissionAction: number[] = [];
            this.existingPermissions.forEach((ep) => {
              if (ep.permissionEntityId === p.id) {
                cacheOfPermissionAction.push(ep.actionId);
              }
            });

            // patch
            const tempIndex = this.platformMatterPermissions.indexOf(p);
            this.patchPermissionSelector(cacheOfPermissionAction, tempIndex);
          });
        }
      }
    }
  }

  disableAdminAndMatterManager() {
    if(this.roleId === 4725 || this.roleId === 4726) {
      this.permissions.controls.forEach(control => {
        control.disable();
      }
      );
    }
  }

  /*** Fetch all permissions that are available on the application. */
  loadMatterPermissions() {
    this.addSubscription(this.genericService.getMatterPermissions().subscribe((res) => {
      this.platformMatterPermissions = [...res];
      this.platformMatterPermissions.forEach((p) => {
        this.permissions.push(this.attachPermissionSelector());
      });
      this.combineData();
      this.disableAdminAndMatterManager();
      this.btnSpinner = false;
    },
      err => {
        this.btnSpinner = false;
      }
    )
    );
  }

  patchPermissionSelector(permissionActions: number[], index: number) {
    this.permissions.controls[index].patchValue({
      fullAccess: false,
      canView: false,
      canCreate: false,
      canEdit: false,
      canDelete: false,
    });

    const isFullPermission = permissionActions.find((p) => p === 4);

    if (!isNullOrUndefined(isFullPermission)) {
      this.permissions.controls[index].patchValue({
        fullAccess: true,
        canView: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      });
    } else {
      // view
      if (permissionActions.includes(1)) {
        this.permissions.controls[index].patchValue({
          canView: true,
        });
      }

      // create
      if (permissionActions.includes(2)) {
        this.permissions.controls[index].patchValue({
          canCreate: true,
        });
      }

      // edit
      if (permissionActions.includes(3)) {
        this.permissions.controls[index].patchValue({
          canEdit: true,
        });
      }
    }
  }

  attachPermissionSelector(): FormGroup {
    return new FormGroup({
      fullAccess: new FormControl(
        { value: false, disabled: false }
      ),
      canView: new FormControl(
        { value: false, disabled: false }
      ),
      canCreate: new FormControl(
        { value: false, disabled: false }
      ),
      canEdit: new FormControl(
        { value: false, disabled: false }
      ),
      canDelete: new FormControl(
        { value: false, disabled: false }
      ),
    });
  }

  /*** can delete and full accessequals canCreate + canView + canEdit + canDelete */
  fullAccess(permission: FormGroup, isChecked: boolean) {
    permission.patchValue({
      fullAccess: isChecked,
      canView: isChecked,
      canCreate: isChecked,
      canEdit: isChecked,
      canDelete: isChecked,
    });
  }

  /** can create equals canCreate + canView */
  canCreate(permission: FormGroup, isChecked: boolean) {
    permission.patchValue({
      fullAccess: false,
      canView: isChecked,
      canCreate: isChecked,
      canEdit: false,
      canDelete: false,
    });
  }

  /*** can Edit equals canCreate + canView + canEdit */
  canEdit(permission: FormGroup, isChecked: boolean) {
    permission.patchValue({
      fullAccess: false,
      canView: isChecked,
      canCreate: isChecked,
      canEdit: isChecked,
      canDelete: false,
    });
  }

  canView(permission: FormGroup, isChecked: boolean) {
    permission.patchValue({
      fullAccess: false,
      canView: isChecked,
      canCreate: false,
      canEdit: false,
      canDelete: false,
    });
  }

  checkAccess(permission: FormGroup) {
    const access = permission.value;
    const arr = [];
    const allAccess = [];
    // convert object to array
    for (const [key, value] of Object.entries(access)) {
      if (value === true) {
        arr.push(value);
      }
      allAccess.push(value);
    }

    if (arr.length < 1) {
      return 'No Access';
    } else if (arr.length < allAccess.length) {
      return 'Limited Access';
    } else if (arr.length === allAccess.length) {
      return 'Full Access';
    }
  }

  savePermission() {
    this.btnSpinner$ = of(true);
    const permissionArray: PermissionEditModel[] = [];
    const permissions: any[] = this.permissions.value;

    for (let j = 0; j < permissions.length; j++) {
      const action: PermissionActionParseModel = permissions[j];

      const permission = new PermissionEditModel();
      permission.permissionEntityId = this.platformMatterPermissions[j].id;
      permission.permissionsActionId = [];
      if (action.canView) {
        permission.permissionsActionId.push(1);
      }
      if (action.canCreate) {
        permission.permissionsActionId.push(2);
      }
      if (action.canEdit) {
        permission.permissionsActionId.push(3);
      }
      if (action.canDelete) {
        permission.permissionsActionId.push(4);
      }

      permissionArray.push(permission);
    }

    this.permissions$ = of(permissionArray);
    this.savedPermission.emit(permissionArray);
  }

  ngOnDestroy(): void {
    this.clearSubscription();
    this.btnSpinner$ = of(false);
    this.existingPermissions = null;
    this.isPermissionControlLocked = null;
    this.btnSpinner = false;
  }

}
