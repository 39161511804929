import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordValidation } from 'src/app/shared/helpers/password-matcher';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  token: string;
  fb = new FormBuilder();
  resetPasswordForm: FormGroup;
  isError: boolean;
  isSuccess: boolean;
  btnSpinner: boolean;
  email: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {

    this.resetPasswordForm = this.fb.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', Validators.required],
    }, {
        validator: PasswordValidation.MatchPassword // your validation method
      });

  }

  ngOnInit() {
    this.btnSpinner = false;
    this.isSuccess = false;
    this.isError = false;

    this.token = this.activatedRoute.snapshot.queryParams['token'];
    this.email = this.activatedRoute.snapshot.queryParams['email'];

  }


  get password() {
    return this.resetPasswordForm.get('password');
  }

  get confirmPassword() {
    return this.resetPasswordForm.get('confirmPassword');
  }


  resetPassword() {
    if (this.resetPasswordForm.invalid) {
      return;
    } else {

      this.btnSpinner = true;
      this.authService.resetPassword(this.password.value, this.confirmPassword.value,
        this.token, this.email).toPromise().
        then(
          response => {
            this.router.navigate(['/login']);
          }
        )
        .catch(error => {
          this.btnSpinner = false;
          this.isError = true;
        });
    }

  }

}
