import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  NavigationExtras,
  CanLoad,
  Route,
} from "@angular/router";

import { Store, select } from "@ngrx/store";
import * as fromStore from "../../auth/state/index";
import { UserDetail } from "../../models/view-model/user-detail";
import * as LoginActions from "../../auth/state/login.actions";
import { Observable, of } from "rxjs";
import { CurrentUserService } from "../../services/factories/current-user.service";
import { PermissionService } from "../services/permission.service";

@Injectable()
// , CanActivateChild
// ,CanLoad
// tslint:disable-next-line:one-line
export class AuthAdminGuard implements CanActivate {
  user = new UserDetail();
  userEmail: string;
  userRole: string;
  firstName: Observable<string>;
  lastName: Observable<string>;

  constructor(
    private router: Router,
    private store: Store<fromStore.State>,
    private currentUserService: CurrentUserService,
    private permissionService: PermissionService,
  ) {
    this.store.dispatch(new LoginActions.LoadLocalUserDetail());

    this.store.pipe(select(fromStore.getUserDetail)).subscribe((data) => {
      //console.log(data);
      this.user = data;
    });
    this.store.pipe(select(fromStore.getUserRole)).subscribe((data) => {
      this.userRole = data;
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    //console.log(url);
    return (this.checkIsAdmin(url) || this.permissionService.checkPermission( "Client", "canWrite" ));
  }

  // checkLogin(url: string): boolean {
  //   if (this.authService.isLoggedIn) { return true; }

  //   // Store the attempted URL for redirecting
  //   this.authService.redirectUrl = url;

  //   // Navigate to the login page with extras
  //   this.router.navigate(['/login']);
  //   return false;
  // }

  // canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  //   return this.canActivate(route, state);
  // }

  // canActivateChild(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): boolean {
  //   return this.canActivate(route, state);
  // }

  // canLoad(route: Route): boolean {
  //   let url = `/${route.path}`;

  //   return this.checkLogin(url);
  // }

  checkIsAdmin(url: string): boolean {
    //console.log(this.user);
    //console.log(this.userRole);
    // tslint:disable-next-line:triple-equals
    if (this.user.isAdmin) {
      //console.log("logged in as admin");
      return true;
    };

    if (this.permissionService.checkPermission( "Client", "canWrite" )) {
      return true;
    }
    // Navigate to the login page with extras
    alert("User has no permission to edit contact informations");
    return false;
  }
}
