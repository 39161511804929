import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { UserDetail } from "src/app/models/view-model/user-detail";
import { AppConfigService } from "src/app/shared/helpers/app-config.service";
import * as fromStore from "../../auth/state/index";
import { ApplicationViewModel } from "../models/application-list";

@Injectable({
  providedIn: "root",
})
export class MatterApplicationService {
  userData = new UserDetail();

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService,
    private store: Store<fromStore.State>
  ) {
    this.store.pipe(select("auth")).subscribe((data) => {
      this.userData = data.userProfile;
    });
  }

  getMatterApplication(
    matterId?: number,
    searchQuery = "",
    isAdmin?: boolean,
    filter = "",
    sortDirection = "",
    pageIndex = 0,
    pageSize = 0
  ): Observable<HttpResponse<ApplicationViewModel[]>> {
    const endpoint = `${
      this.config.getConfig().serverEndpoint
    }matter/${matterId}/application`;
    const usePageSize = true;
    // tslint:disable-next-line: max-line-length
    const url = `${endpoint}?searchQuery=${searchQuery}&isAdmin=${
      this.userData.isAdmin
    }&sort=${sortDirection}&order=${filter}&page=${
      pageIndex + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<ApplicationViewModel[]>(url, {
      observe: "response",
    });
  }

  deleteMatterApplication(applicationId: number): Observable<boolean> {
    const api = `${
      this.config.getConfig().serverEndpoint
    }matter/application/${applicationId}`;
    return this.httpClient.delete<boolean>(api);
  }

  public addNewApplicationType(payload: any): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `matter/application-type`;
    return this.httpClient.post(url, payload);
  }

  public getApplicationTypes(): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `matter/application-type/firm`;
    return this.httpClient.get(url);
  }

  public addNewApplicationStatus(payload: any): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `matter/application-status`;
    return this.httpClient.post(url, payload);
  }

  public getApplicationStatuses(): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `matter/application-status/firm`;
    return this.httpClient.get(url);
  }
}
