import { UserTask } from "./../../models/view-model/user-tasks";
import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import * as taskActions from "../actions/task.action";
import { TaskService } from "../../services/task.service";
@Injectable()
export class TaskEffects {
  constructor(private actions$: Actions, private service: TaskService) {}
  @Effect()
  loadTasks$ = this.actions$.pipe(
    ofType<taskActions.LoadTasks>(taskActions.TasksActionTypes.LoadTasks),
    mergeMap((action) =>
      this.service
        .getFirmTask(
          action.payload.searchQuery,
          action.payload.filter,
          action.payload.sortDirection,
          action.payload.pageIndex,
          action.payload.pageSize
        )
        .pipe(
          map((tasks) => new taskActions.LoadTasksSuccess(tasks)),
          catchError((err) => of(new taskActions.LoadTasksFail(err)))
        )
    )
  );
  @Effect()
  loadMatterTasks$ = this.actions$.pipe(
    ofType<taskActions.LoadMatterTasks>(
      taskActions.TasksActionTypes.LoadMatterTasks
    ),
    mergeMap((action) =>
      this.service
        .loadMatterUsertasks(
          action.payload.matterId,
          action.payload.searchQuery,
          action.payload.filter,
          action.payload.sortDirection,
          action.payload.pageIndex,
          action.payload.pageSize
        )
        .pipe(
          map((tasks) => new taskActions.LoadMatterTasksSuccess(tasks)),
          catchError((err) => of(new taskActions.LoadMatterTasksFail(err)))
        )
    )
  );

  @Effect()
  CheckTask$ = this.actions$.pipe(
    ofType(taskActions.TasksActionTypes.CheckTask),
    map((action: taskActions.CheckTask) => action.payload),
    mergeMap((arg: UserTask) =>
      this.service.checkTask(arg).pipe(
        // change type here once model is provided
        map((done: boolean) => new taskActions.CheckTaskSuccess(done)),
        catchError((err) => of(new taskActions.CheckTaskFail(err)))
      )
    )
  );

  @Effect()
  CheckMatterTask$ = this.actions$.pipe(
    ofType(taskActions.TasksActionTypes.CheckMatterTask),
    map((action: taskActions.CheckMatterTask) => action.payload),
    mergeMap((arg: UserTask) =>
      this.service.checkTask(arg).pipe(
        // change type here once model is provided
        map((done: boolean) => new taskActions.CheckMatterTaskSuccess(done)),
        catchError((err) => of(new taskActions.CheckMatterTaskFail(err)))
      )
    )
  );

  @Effect()
  AddTask$ = this.actions$.pipe(
    ofType(taskActions.TasksActionTypes.AddTask),
    map((action: taskActions.AddTask) => action.payload),
    mergeMap((arg: any) =>
      this.service.addNewTask(arg).pipe(
        // change type here once model is provided
        map((id: number) => new taskActions.AddTaskSuccess(id)),
        catchError((err) => of(new taskActions.AddTaskFail(err)))
      )
    )
  );

  @Effect()
  UpdateTask$ = this.actions$.pipe(
    ofType(taskActions.TasksActionTypes.UpdateTask),
    map((action: taskActions.UpdateTask) => action.payload),
    mergeMap((arg: any) =>
      this.service.updateTask(arg).pipe(
        // change type here once model is provided
        map((res) => new taskActions.UpdateTaskSuccess(res)),
        catchError((err) => of(new taskActions.UpdateTaskFail(err)))
      )
    )
  );
}
