import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { Notifier } from 'src/app/shared/base-class/notifier';
import { Store } from '@ngrx/store';
import * as fromUserHeaderStore from '../../../store/reducers/user-header.reducer';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends Notifier implements OnInit {

  forgotPasswordForm: FormGroup;
  isError: boolean;
  btnSpinner: boolean;
  responseData: any;
  isSuccess: boolean;

  constructor(private fb: FormBuilder, protected headerStore: Store<fromUserHeaderStore.HeaderState>,
    private router: Router, private passwordService: AuthService) {
      super(headerStore);
    this.forgotPasswordForm = this.fb.group({
      email: ['', Validators.required]
    });
  }

  get email() {
    return this.forgotPasswordForm.get('email');
  }

  ngOnInit() {
    this.isError = false;
    this.btnSpinner = false;
  }

  forgotPassword() {
    this.btnSpinner = true;
    const email: string = this.forgotPasswordForm.value.email;
    // console.log(email);
    this.passwordService.forgotPassword(email)
      .subscribe(
        data => {
          // console.log(data);
          this.responseData = data;
          // console.log(this.responseData);
          this.pushMessage('Done!.. We have sent an Email to you! It will inform you of the next instruction');
          // this.isError = false;
          // this.isSuccess = true;
         this.btnSpinner = false;
        },
        error => {
          // console.log('failed !');
          this.sendErrorMessage('We cannot find any account associated to the email address you provided.');
          // this.isError = true;
          this.btnSpinner = false;
        },
        () => {
          // console.log('got here');
          // show error message
          this.btnSpinner = false;
        }
      );
  }

}
