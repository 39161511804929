import { Injectable } from "@angular/core";

import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { MailInboxViewModel } from "../models/view-model/email-list";
import { EmailClientService } from "../services/email-client.service";

@Injectable()
export class ReadMailResolverService implements Resolve<MailInboxViewModel> {
  constructor(
    private emailService: EmailClientService,
    private router: Router
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    //console.log(route.params);
    return this.emailService
      .getInboxDetail(route.params.mailId, route.params.configId, route.params.mailBoxType)
      .pipe(
        map((data) => {
          const mail = data;
          if (!mail) {
            // or show error could not get email
            this.router.navigate(["/not-found"]);
          }
          return mail;
        }),
        catchError((error) => {
          const message = "An error occurred, please try again";
          //console.log('read email error', error);
          return of(error);
        })
      );
  }
}
