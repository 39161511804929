import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from 'events';

@Component({
  selector: 'app-attach-matter',
  templateUrl: './attach-matter.component.html',
  styleUrls: ['./attach-matter.component.scss']
})
export class AttachMatterComponent implements OnInit {
  @Output() selectedMatter = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }


  save(matterId) {

    this.selectedMatter.emit(matterId);
  }

}
