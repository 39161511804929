import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatterApplicationService } from "../../services/matter-application.service";

@Component({
  selector: "app-application-type",
  templateUrl: "./application-type.component.html",
  styleUrls: ["./application-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationTypeComponent implements OnInit {
  applicationTypeForm: FormGroup;
  btnSpinner = false;
  @ViewChild("closeModal") closeModal: ElementRef;
  @Output() emitOutPut = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private matterApplicationService: MatterApplicationService
  ) {
    this.applicationTypeForm = this.fb.group({
      name: ["", Validators.required],
      description: [""],
    });
  }

  get getApplicationTypeName() {
    return this.applicationTypeForm.get("name");
  }

  get getApplicationTypeDescription() {
    return this.applicationTypeForm.get("description");
  }

  ngOnInit() {}

  saveApplicationType() {
    this.btnSpinner = true;
    const payload = this.applicationTypeForm.value;
    this.matterApplicationService.addNewApplicationType(payload).subscribe(
      (res) => {
        this.applicationTypeForm.reset();
        this.btnSpinner = false;
        this.emitOutPut.emit({
          isSuccessful: true,
          message: `${payload.name} was successfully added to application type`,
        });

        this.closeModal.nativeElement.click();
      },
      (err) => {
        this.btnSpinner = false;
        this.emitOutPut.emit({ isSuccessful: false, message: err.error });
        this.closeModal.nativeElement.click();
      }
    );
  }
}
