import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Output, EventEmitter, Inject, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { FileViewerModel } from 'src/app/documents/models/okmresults.model';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FileViewerComponent implements OnInit, OnDestroy {

  private sub: Subscription = new Subscription();
  public isRequestingBinary: boolean = false;
  public maximizeClass: string = 'maximize';
  public sanitizedUrl!: any;
  public hasSanitized: boolean = false;
  @Output() isMaximizedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isMaximized: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<FileViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FileViewerModel,
    private _sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document,
    private documentService: DocumentService,
    private _cd: ChangeDetectorRef
  ) {}


  ngOnInit(): void {
    this.checkFileTypeAndDisplay();
  }

  public close(): void {
    this.data = null;
    this.sanitizedUrl = '';
    this.dialogRef.close();
  }

  public checkFileTypeAndDisplay(): void {
    this.isRequestingBinary = true;
    if (this.data != null && this.data != undefined) {
      if (this.data.binaryPointer === "id") {
        this.getFileBinaryUsingId(this.data.id);
      } else {
        this.getFileBinaryUsingFileUniqueName(this.data.fileUniqueName);
      }
    }
  }

  public toggleMaximize(): void {
    this.isMaximized
      ? this.document.body.classList.remove(this.maximizeClass)
      : this.document.body.classList.add(this.maximizeClass);
    this.isMaximized = !this.isMaximized;
    this.isMaximizedEvent.emit(this.isMaximized);
  }

  public getFileBinaryUsingFileUniqueName(url: string): void {
    this.documentService.getFileBinaryUsingFileUniqueName(url).subscribe({
      next: (res) => {
        this.data.fileBinary = res;
        this.prependType();
      },
      error: (error) => (this.isRequestingBinary = false),
    });
  }

  public getFileBinaryUsingId(id: number): void {
    this.documentService.getFileBinary(id).subscribe({
      next: (res) => {
        this.data.fileBinary = res.serverFileName;
        this.prependType();
      },
      error: (error) => (this.isRequestingBinary = false),
    });
  }

  public prependType(): void {
    let dataPrefix = '';

    if (this.data.fileExt.toLowerCase() === 'application/pdf') {
      this.data.fileExt = 'pdf';
    }

    if (this.data.fileExt.toLowerCase() === 'pdf') {
      dataPrefix = 'data:application/PDF;base64';
    } else {
      dataPrefix = 'data:image/png;base64';
    }

    this.sanitizedUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
      `${dataPrefix},${this.data.fileBinary}`
    );
    this._cd.detectChanges();
    this.isRequestingBinary = false;
    this.hasSanitized = true;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
