import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.scss']
})
export class ConfirmAccountComponent implements OnInit {
  registeredEmail: string;
  isError: boolean;
  resend: boolean;
  btnSpinner: boolean;

  @ViewChild("errorBox") errorBox: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.isError = false;
    this.btnSpinner = false;
    this.resend = false;

    this.registeredEmail = this.route.snapshot.paramMap.get('email');
  }




  resendConfirmation() {
    this.btnSpinner = true;

    this.authService.resendConfirmation(this.registeredEmail).toPromise().then(res => {

      this.btnSpinner = false;
      this.resend = true;
      this.isError = false;

    }).catch(err => {
      this.btnSpinner = false;
      this.isError = true;
      this.resend = false;
      this.errorBox.nativeElement.focus();
    });
  }

}
