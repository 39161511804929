import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, FormControl, FormArray } from "@angular/forms";
import { FileUploadStructure } from "../../models/shared-model/file-upload";
import { FileUploadModel } from "src/app/models/generic-model.ts/file-upload";
import { isNullOrUndefined } from "util";
import { Store } from "@ngrx/store";
import * as fromUserHeaderStore from "../../store/reducers/user-header.reducer";
import { Notifier } from "../base-class/notifier";

import * as fromStore from "../../auth/state/index";
import * as documentActions from "../../store/actions/document.action";
import { DraftService } from "src/app/services/draft.service";
import { FileUploadService } from "src/app/services/file-upload.service";
/** Changes made */

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
})
export class FileUploadComponent
  extends Notifier
  implements OnInit, OnChanges, OnDestroy {
  uploadResponse = { status: "", message: "", filePath: "" };
  error: string;
  btnSpinner: boolean;
  uploadForm: FormGroup;
  files: FileUploadStructure[] = [];
  fileNames: string[] = [];
  @ViewChild("fileUploadClose") fileUploadClose: ElementRef;
  @Output() uploadResponseEmitter = new EventEmitter<any>();

  @Input() entityId: number;
  @Input() resourceId: number;
  @Input() resourceDirectoryId: number;
  @Input() addFileToFolder = false;
  @Input() driveFolderModel: any;

  @Output() uploaderObject = new EventEmitter<any>();

  collapseAllTreeNode = false;

  errorText: string;
  showError: boolean;
  currentDocument: FileUploadModel[];
  fileProgressArray: number[];
  totalFileSize: number;
  resourceDirectory: number;
  resourceDirectoryType: number;
  fileIntoFolder: any = {};
  fileTypeIdGetter: number;

  constructor(
    private _fileUploadService: FileUploadService,
    private fb: FormBuilder,
    private store: Store<fromStore.State>,
    private externalStorageService: DraftService,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>
  ) {
    super(headerStore);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.driveFolderModel) {
      this.fileIntoFolder.parentId = this.driveFolderModel.parentId;
      this.fileIntoFolder.driveType = this.driveFolderModel.driveType;
    }
  }
  ngOnDestroy(): void {
    this.addFileToFolder = false;
  }

  ngOnInit() {
    this.btnSpinner = false;
    this.totalFileSize = 0;
    this.fileProgressArray = [];
    this.currentDocument = [];
    this.errorText = "";

    this.uploadForm = this.fb.group({
      attachments: new FormArray([]),
    });
  }

  closeFileItem(index) {
    this.fileProgressArray.splice(index, 1);
    this.currentDocument.splice(index, 1);
    this.attachments.controls.splice(index, 1);
  }

  get attachments(): FormArray {
    return <FormArray>this.uploadForm.get("attachments");
  }

  attachAnUploadSelector(): FormGroup {
    return new FormGroup({
      fileCaption: new FormControl(""),
    });
  }

  //#region File attachment
  /**
   * when file is changed
   * @param event;
   */
  onFileChange(event) {
    this.set_upReader(event.target.files, 0);
    event.target.value = "";
  }

  set_upReader(files, i) {
    // check if it has file
    for (let i = 0; i < files.length; i++) {
      if (files && files[i]) {
        const file = files[i];
        const fileName = file.name;
        const fileSize = file.size;
        const fileType: string = file.type;
        if (file.size + this.totalFileSize > 25597152) {
          (this.errorText =
            "Files attached and" +
            file.name +
            "'s size are greater than 25MB is not allowed"),
            "Error! ";
        } else if (this.currentDocument.length > 4) {
          (this.errorText =
            "File attached " +
            file.name +
            " and others sum size greater than 25MB and is not allowed"),
            "Error! ";
        } else if (
          fileType.toString().toLowerCase().includes("image") ||
          fileType.toString().toLowerCase().includes("document") ||
          fileType.toString().toLowerCase().includes("pdf") ||
          fileType.toString().toLowerCase().includes("excel") ||
          fileType.toString().toLowerCase().includes("sheet") ||
          fileType.toString().toLowerCase().includes("msword") ||
          fileType.toString().toLowerCase().includes("")
        ) {
          const fileType = file.type;
          const previewReader = new FileReader();

          this.attachments.push(this.attachAnUploadSelector());
          //
          previewReader.onprogress = (e) => {
            this.fileProgressArray[i] = (e.loaded / e.total) * 100;
          };

          previewReader.readAsDataURL(file);
          previewReader.onload = (e: any) => {
            this.readerLoaded(e, files, i, fileName, fileSize, fileType);
          };
        } else {
          (this.errorText =
            "File attached " + file.name + "'s type is not allowed"),
            "Error! ";
          ///
        }
      }
    }
  }

  readerLoaded(e, files, i, fileName, fileSize, fileType) {
    // called once readAsDataURL is completed
    // this is correct , ignore errors
    const FILE = new FileReader();

    const upload = new FileUploadModel();

    upload.fileBinary = (<string>e.target.result).split(",")[1];

    if (fileType.toString().toLowerCase().includes("msword")) {
      upload.fileExtension = "doc";
    }

    upload.fileExtension = fileType;
    upload.fileType = fileType;
    upload.fileName = fileName;
    upload.fileSize = fileSize;
    if (
      upload.fileExtension ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      upload.fileExtension = "docx";
    }
    this.totalFileSize += upload.fileSize;
    this.currentDocument.push(upload);
    // call service

    // If there's a file left to load
    if (i < files.length - 1) {
      // Load the next file
      this.set_upReader(files, i + 1);
    }
  }

  //#endregion

  onSubmit() {
    const files: any[] = [];
    this.btnSpinner = true;
    // move this line from been global to onSubmit method
    // to be within the scope of loops
    // let formData: any = {};

    if (this.fileIntoFolder && this.fileIntoFolder.driveType > 0) {
      this.currentDocument.forEach((item) => {
        let formData: any = {};
        formData.driveType = this.fileIntoFolder.driveType;

        if (this.fileIntoFolder.parentId !== 0) {
          formData.fileModel = {
            ...item,
            parentId: this.fileIntoFolder.parentId,
          };
        } else {
          formData.fileModel = item;
        }
        files.push(formData);
      });

      this.externalStorageService.addDriveFile(files).subscribe(
        (data) => {
          this.uploadResponse = data;
          this.uploadResponseEmitter.emit("googleDriveFile");
          this.fileProgressArray = [];
          this.currentDocument = [];
          this.uploadForm.reset();
          this.attachments.controls = [];
          this.totalFileSize = 0;

          this.pushMessage(`File(s) Uploaded into google drive Successful`);

          this.fileUploadClose.nativeElement.click();
          this.btnSpinner = false;
          this.collapseAllTreeNode = true;

          this.store.dispatch(
            new documentActions.SetGoogleDriveRefresh("added")
          );

          /** Changes made here */
          // this.store.dispatch(new documentActions.SetReloadPage(true));
          /**End s here */
        },
        (err) => {
          this.sendErrorMessage("File Upload failed, please try again...");
          this.errorText = err.message;
          this.fileUploadClose.nativeElement.click();
          this.btnSpinner = false;
        }
      );
    } else {
      this.currentDocument.forEach((item) => {
        let formData: any = {};
        formData.fileModel = item;

        if (!isNullOrUndefined(this.fileIntoFolder)) {
          formData.matterId = this.fileIntoFolder.matterId;
          formData.directoryId = this.fileIntoFolder.directoryId;
          formData.categoryId = this.fileIntoFolder.categoryId;
        }

        if (!isNullOrUndefined(this.resourceDirectoryId)) {
          formData.directoryId = this.resourceDirectoryId;
        }

        if (!isNullOrUndefined(this.resourceId)) {
          formData.matterId = this.resourceId;
        }

        files.push(formData);
      });

      this._fileUploadService.addFile(files).subscribe(
        (data) => {
          this.uploadResponse = data;
          this.uploadResponseEmitter.emit(data);
          this.fileProgressArray = [];
          this.currentDocument = [];
          this.uploadForm.reset();
          this.attachments.controls = [];
          this.totalFileSize = 0;

          this.pushMessage(`File(s) Uploaded Successful`);

          /** Changes made here */
          this.store.dispatch(new documentActions.SetReloadPage(true));
          /**End s here */

          this.fileUploadClose.nativeElement.click();
          this.btnSpinner = false;
          this.collapseAllTreeNode = true;
        },
        (err) => {
          this.sendErrorMessage("File Upload failed, please try again...");
          this.errorText = err.message;
          this.fileUploadClose.nativeElement.click();
          this.btnSpinner = false;
        }
      );
    }
  }

  fileTypeEmitter(fileTarget) {
    this.fileIntoFolder = fileTarget;
  }
}
