import { Component, Input, OnInit } from '@angular/core';
import { AppConfigService } from '../../shared/helpers/app-config.service';
import { isNullOrUndefined } from 'util';
@Component({
  selector: 'app-generate-default-picture-non-observable',
  templateUrl: './generate-default-picture-non-observable.component.html',
  styleUrls: ['./generate-default-picture-non-observable.component.scss']
})
export class GenerateDefaultPictureNonObservableComponent implements OnInit {
  color: string;
  alphabet = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
  ];

  @Input() firstName: string;
  @Input() lastName: string;
  @Input() theLetter: string;

  constructor(private config: AppConfigService) { }

  ngOnInit() {
    if (
      !isNullOrUndefined(this.firstName) &&
      !isNullOrUndefined(this.lastName)
    ) {
      this.theLetter =
        this.firstName[0].toUpperCase() + '' + this.lastName[0].toUpperCase();
      const index = this.alphabet.indexOf(
        this.theLetter[this.theLetter.length - 1]
      ); // find the alphabet index
      const bgColors = this.config.getConfig().colors; // get all the colors from config
      const colorIndex = index % bgColors.length; // pick the color
      this.color = bgColors[colorIndex]; // set the color
    }
  }
}
