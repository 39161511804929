import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anonymous-footer',
  templateUrl: './anonymous-footer.component.html',
  styleUrls: ['./anonymous-footer.component.scss']
})
export class AnonymousFooterComponent implements OnInit {
currentYear: any;
  constructor() { }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

}
