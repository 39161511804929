import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { CurrentUserService } from "../../services/factories/current-user.service";

@Injectable({ providedIn: "root" })
export class HomeGuard implements CanActivate {
  constructor(
    private currentUserService: CurrentUserService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    if (!this.currentUserService.isLoggedIn()) {
      return true;
    }

    this.router.navigate(["/firm"]);
    return false
  }
}
