import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./components/login/login.component";
import { FirmRegistrationComponent } from "./components/firm-registration/firm-registration.component";
import { NewInvitedUserComponent } from "./components/new-invited-user/new-invited-user.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { EmailConfirmationComponent } from "./components/email-confirmation/email-confirmation.component";
import { ConfirmAccountComponent } from "./components/confirm-account/confirm-account.component";
import { RegistrationSuccessComponent } from "./components/registration-success/registration-success.component";
import { HomeGuard } from "../shared/guards/home-guard.service";
import { SetPasswordComponent } from "./components/set-password/set-password.component";
import { ForceLogoutComponent } from "./components/force-logout/force-logout.component";

const routes: Routes = [
  { path: "login", component: LoginComponent, canActivate: [HomeGuard] },
  { path: "set-password", component: SetPasswordComponent, canActivate: [HomeGuard] },
  { path: "logout", component: LogoutComponent },
  { path: "logout/:email", component: ForceLogoutComponent },
  { path: "confirm-email/:email", component: ConfirmAccountComponent },
  { path: "email-confirmed", component: EmailConfirmationComponent },
  { path: "login/:email", component: LoginComponent, canActivate: [HomeGuard] },
  { path: "register", component: FirmRegistrationComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "join-successfull", component: RegistrationSuccessComponent },
  { path: "join", component: NewInvitedUserComponent },
  // {path: 'confirm-account/:token', component: ConfirmAccountComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
