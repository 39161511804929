import { ComingSoonComponent } from "./../../shared/dialogs/coming-soon/coming-soon.component";
import { Component, OnInit, Input } from "@angular/core";
import { CaseTitleViewModel } from "src/app/models/view-model/cases-list";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import * as fromlpdoc from "../../store/reducers/lp-doc.reducer";

import * as LPDocActions from "../../store/actions/lp-doc.actions";
import { Notifier } from "src/app/shared/base-class/notifier";

import * as fromUserHeaderStore from "../../store/reducers/user-header.reducer";
import { CKEditor5 } from "@ckeditor/ckeditor5-angular";
import * as DecoupledEditorwithComment from "../helpers/build/ckeditor";
import { Location } from "@angular/common";
import { LpDocService } from "src/app/services/lp-doc.service";
import {
  AIDoc,
  AIDocResponse,
  initialAIDoc,
} from "src/app/models/view-model/ai-doc.model";
import { UtilService } from "src/app/core/util.service";
import { isNullOrUndefined } from "util";
import { AuthState } from "src/app/auth/state/login.reducer";

@Component({
  selector: "app-ai-document-review",
  templateUrl: "./ai-document-review.component.html",
  styleUrls: ["./ai-document-review.component.scss"],
})
export class AiDocumentReviewComponent extends Notifier implements OnInit {
  @Input() isFile: boolean = false;
  @Input() previewFileDataUri: any;
  public loadingJudgment: boolean = false;
  public Editor = DecoupledEditorwithComment;
  public editor?: CKEditor5.Editor;
  public selectedScreen: number = SelectedScreen.ONE;
  public selectedScreenEnum = SelectedScreen;
  public aiDoc: AIDoc = initialAIDoc;
  public caseReport: any;
  public searchCase: boolean = false;
  public selectedCaseTitle: string;
  src: string;
  query: string;
  public cantFindJudgement: boolean = false;
  public collapseSummary: boolean = false;
  public collapseJudges: boolean = false;
  public _court: string;
  private firmId: number;

  @Input() searchValue: string;

  countObservable: number[] = [];

  public okmText: string = "";
  public showLoader: boolean = false;
  showNoResult: boolean;
  page: number;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>,
    private lpDocStore: Store<fromlpdoc.State>,
    private _lppcDoc: LpDocService,
    private _location: Location,
    private _util: UtilService,
    private rootStore: Store<AuthState>
  ) {
    super(headerStore);

    this.rootStore.pipe(select("auth")).subscribe((data) => {
      this.firmId = data.userProfile.firmId;
    });
    this.activatedRoute.queryParamMap.subscribe((data: any) => {
      if (data.params.okmText) {
        this.okmText = JSON.parse(data.params["okmText"]);
      } else {
        this.okmText = `
         `;
      }
    });
    // this.lpDocStore
    //   .pipe(select(fromlpdoc.getSearchResult))
    //   .subscribe((data) => {
    //     if (!isNullOrUndefined(data)) {
    //       this.countObservable = [];

    //       this.searchResult = data;

    //       if (!isNullOrUndefined(this.searchResult.data)) {
    //         for (let i = 1; i <= this.searchResult.last_page; i++) {
    //           this.countObservable.push(i);
    //         }
    //       } else {
    //         this.searchResult.data = [];
    //       }
    //     }
    //   });

    // this.lpDocStore.pipe(select(fromlpdoc.getSearchError)).subscribe((data) => {
    //   if (!isNullOrUndefined(data)) {
    //     // push error message
    //     this.sendErrorMessage("An unknown error occured, check your license");
    //   }
    // });
  }

  ngOnInit() {
    this.showNoResult = false;
    // const textarea = document.getElementById("okm");
    // textarea.addEventListener("input", function (e) {
    //   this.style.height = "auto";
    //   this.style.height = this.scrollHeight + "px";
    // });
    if (this.isFile) {
      this.analyzewithAI();
    }
  }

  searchCases(case_title: string) {
    console.log(case_title)
    this.selectedCaseTitle = case_title;
    console.log(this.selectedCaseTitle)
    this.searchCase = true;
    // const searchQuery = case_title;

    // // this.searchResult = new CaseListViewModel();
    // // this.searchResult.data = [];

    // this.lpDocStore.dispatch(
    //   new LPDocActions.LoadCasesSearch({ searchQuery, page: this.page })
    // );
  }

  public aiDocumentReview(): void {
    //  this.firmId
    const operation = "aiDocReview";
    this.showLoader = true;
    const formData = new FormData();
    formData.append("content", this.okmText);
    formData.append("organization", this.firmId.toString());
    this._lppcDoc[operation](formData).subscribe({
      next: (res: AIDocResponse) => {
        console.log(res);
        this.showLoader = false;
        this.aiDoc = res.data;
        this.setScreen(this.selectedScreenEnum.TWO);
      },
      error: (_) => {
        console.log(_);
        this.showLoader = false;
      },
    });
  }

  public analyzewithAI(): void {
    this.showLoader = true;
    const formData = new FormData();
    formData.append(
      "file",
      this._util.blobCreationFromURL(this.previewFileDataUri)
    );
    this._lppcDoc.aiUploadReview(formData).subscribe({
      next: (res: AIDocResponse) => {
        //console.log(res);

        this.showLoader = false;
        this.setScreen(this.selectedScreenEnum.TWO);
      },
      error: (_) => {
        console.log(_);
        this.showLoader = false;
        this.setScreen(this.selectedScreenEnum.TWO);
      },
    });
  }
  public setScreen(screen: number) {
    this.selectedScreen = screen;
  }
  public clearText(): void {
    this.okmText = "";
  }
  public updateRouteQuery(): void {
    const url = this.router
      .createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: { okmText: JSON.stringify(this.okmText) },
      })
      .toString();

    this._location.go(url);
  }

  public selectCase(selectedCase: CaseTitleViewModel) {
    this.lpDocStore.dispatch(new LPDocActions.SetCurrentCase(selectedCase));
    this.router.navigate(
      [
        "report/" +
        encodeURIComponent(selectedCase.suit_number) +
        "/" +
        selectedCase.court +
        "/" +
        selectedCase.practice_area,
      ],
      { relativeTo: this.activatedRoute }
    );
  }
  public goToJudgment(suitNo: any, court: string): void {
    this.loadingJudgment = true;
    this.setScreen(this.selectedScreenEnum.THREE);
    let c = court.toLowerCase().includes("supreme") ? "supreme" : "appeal";
    this._court = c;
    console.log(c);
    if (!isNullOrUndefined(suitNo)) {
      this._lppcDoc.loadFullJudgment(suitNo, c).subscribe((data) => {
        console.log(data);
        this.caseReport = data.data;
        this.loadingJudgment = false;
      }),
        (error) => {
          this.setScreen(this.selectedScreenEnum.TWO);
          this.loadingJudgment = false;
          this.cantFindJudgement = true;
        };
    }
  }

  principleSelected(doc: string) {
    this.lpDocStore.dispatch(new LPDocActions.clipText(doc));
  }
  public goToCase(): void { }
  public goToLaw(): void { }
}

export enum SelectedScreen {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  COMING = 4,
}
