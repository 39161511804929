import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { HomepageComponent } from './homepage/homepage.component';
import { HomeRoutingModule } from './home-routing.module';
import { FaqsComponent } from './faqs/faqs.component';
import { LayoutComponent } from './layout/layout.component';
import { RouterModule } from '@angular/router';
import { PricingComponent } from './pricing/pricing.component';
import { SharedModule } from '../shared/shared.module';
import { MoneyTransferOptionComponent } from './money-transfer-option/money-transfer-option.component';
import { PaymentSuccesfulComponent } from './payment-succesful/payment-succesful.component';
import { TermsComponent } from './terms/terms.component';
import { UserTermsComponent } from './user-terms/user-terms.component';
import { PolicyComponent } from './policy/policy.component';

@NgModule({
  declarations: [HomepageComponent, FaqsComponent, LayoutComponent, PricingComponent, MoneyTransferOptionComponent, PaymentSuccesfulComponent, TermsComponent, UserTermsComponent, PolicyComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    HomeRoutingModule
  ],
  exports: [HomepageComponent, FaqsComponent, LayoutComponent, PricingComponent]
})
export class HomeModule { }
