import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AddMatter } from "../models/parse-model/add-matter";
import { CourtMatterParseModel } from "../models/parse-model/matter-court";
import { Endpoints } from "../models/shared-model/endpoint";
import {
  documentListSample,
  folderSampleList,
} from "../models/view-model/document-list";
import { ExpenseSample } from "../models/view-model/matter-expenses";
import {
  contactListLite,
  CourtListModel,
  EditMatterCourt,
  EditMatterJudgeDetail,
} from "../models/view-model/matter-list";
import { TasksSample } from "../models/view-model/matter-tasks";
import { MatterTimeSheetModel } from "../models/view-model/time-sheet";
import { ApplicationViewModel } from "../matter-application/models/application-list";
import { AppConfigService } from "../shared/helpers/app-config.service";
import { GenericListViewModel } from "../models/generic-model.ts/generic-list";
import { ActivatedRoute } from "@angular/router";
import { isNullOrUndefined } from "util";
import { FilterGenericModel } from "../models/generic-model.ts/filter-model";
import { GenericListCountViewModel } from "../models/generic-model.ts/list-count";
import { ContactViewModel } from "../contact/models/contact-list";
import { ProceedingViewModel } from "src/app/matter-endorsement/models/endorsement";

import { ApplicationParseModel } from "../models/parse-model/add-application";
import { JudgemntParsemModel } from "../models/parse-model/new-judgement";
import {
  MatterTeamMember,
  MatterTimeTask,
} from "../models/view-model/team-list";
import { UserDetail } from "../models/view-model/user-detail";

import * as fromStore from "../auth/state/index";
import { Store, select } from "@ngrx/store";

@Injectable({
  providedIn: "root",
})
export class MatterService {
  matterCategory = new GenericListViewModel();

  Endpoints = new Endpoints(this.config);

  filter = new FilterGenericModel();

  userData = new UserDetail();

  constructor(
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
    private config: AppConfigService,
    private store: Store<fromStore.State>
  ) {
    this.filter = new FilterGenericModel();

    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.matterCategory.id = +params.get("categoryId");
      this.matterCategory.name = params.get("categoryName");

      if (!isNullOrUndefined(params.get("ongoing"))) {
        // tslint:disable-next-line:triple-equals
        this.filter.ongoing =
          params.get("ongoing").toLowerCase() === "true" ? true : false;
      }

      if (!isNullOrUndefined(params.get("archived"))) {
        // tslint:disable-next-line:triple-equals
        this.filter.archived =
          params.get("archived").toLowerCase() === "true" ? true : false;
      }

      if (!isNullOrUndefined(params.get("completed"))) {
        // tslint:disable-next-line:triple-equals
        this.filter.completed =
          params.get("completed").toLowerCase() === "true" ? true : false;
      }

      if (!isNullOrUndefined(params.get("categoryType"))) {
        // tslint:disable-next-line:triple-equals
        this.filter.categoryType = JSON.parse(
          "[" + params.getAll("categoryType") + "]"
        );

        // this.filter.categoryType.forEach(c => {
        //   // tslint:disable-next-line:triple-equals
        //   this.practiceAreas.find(p => p.id == c).checked = true;
        // });
      }
      if (!isNullOrUndefined(params.get("categorySubType"))) {
        this.filter.categorySubType = JSON.parse(
          "[" + params.getAll("categorySubType") + "]"
        );
      }

      if (!isNullOrUndefined(params.get("clientId"))) {
        // tslint:disable-next-line:triple-equals
        this.filter.clientId = Number.parseInt(params.get("clientId"), 10);
      }
    });

    // geth the userId from the store (used NGRX)
    this.store.pipe(select("auth")).subscribe((data) => {
      /// save token and user detail to local storage
      this.userData = data.userProfile;
    });
  }

  /************
   *  MATTER
   ************/
  public getMatterList(
    firmId?: number,
    searchQuery = "",
    sort = "",
    CategoryId = 0,
    order = "asc",
    page?: number,
    pageSize = 0,
    usePageSize = false
  ): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `matter/${firmId}/firmlite`;
    const url = `${endpoint}?searchQuery=${searchQuery}&CategoryId=${CategoryId}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: "response" });
  }

  getMatterEndorsement(
    matterId?: number,
    isAdmin?: boolean,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex = 0,
    pageSize = 0
  ): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + "matter/" + matterId + "/proceeding";
    const usePageSize = true;
    // tslint:disable-next-line: max-line-length
    const url = `${endpoint}?searchQuery=${searchQuery}&isAdmin=${
      this.userData.isAdmin
    }&sort=${sortDirection}&order=${filter}&page=${
      pageIndex + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: "response" });
  }

  getMatterApplicationById(applicationId: number): Observable<ApplicationViewModel> {
    const api = this.config.getConfig().serverEndpoint + "matter/application/" + applicationId;
    return this.httpClient.get<ApplicationViewModel>(api);
  }

  getApplicationDocuments(applicationId: number): Observable<any> {
    const endpoint = `${this.config.getConfig().serverEndpoint}file/GetFilesByApplication/${applicationId}`;
    return this.httpClient.get<any>(endpoint, { observe: "response" });
  }

  updateMatterApplication(model: ApplicationParseModel): Observable<any> {
    const api = this.config.getConfig().serverEndpoint + "matter/application";
    return this.httpClient.put<any>(api, model);
  }

  public getMatterListNoSearch(firmId: number): Observable<any> {
    const url = `${this.config.getConfig().serverEndpoint}matter/${firmId}/firm`;
    return this.httpClient.get<any>(url);
  }

  getMatterendorsementByPrcceedingId(proceedingId?: number): Observable<ProceedingViewModel> {
    const api = this.config.getConfig().serverEndpoint + "matter/proceeding/" + proceedingId;
    return this.httpClient.get<ProceedingViewModel>(api);
  }

  addApplicationFiles(model) {
    const url = this.config.getConfig().serverEndpoint + "task/taskfiles";
    return this.httpClient.post<any>(url, model);
  }

  public sendEndorsementForReview(model: Object): Observable<any> {
    const api = this.config.getConfig().serverEndpoint + "ClientReport/SendForReview";
    return this.httpClient.put(api, model);
  }

  public approveEndorsement(model: Object): Observable<any> {
    const api = this.config.getConfig().serverEndpoint + "ClientReport/Approve";
    return this.httpClient.put(api, model);
  }

  // matter filter calls
  public getMatterPractiseArearAndMatterCount(categoryId: number) {
    const url = `${this.config.getConfig().serverEndpoint}matter/${categoryId}/categorysubtypemattercount`;
    return this.httpClient.get<GenericListCountViewModel[]>(url);
  }

  public getMatterSubCategoryAndMatterCount(categoryId: number) {
    const url = `${this.config.getConfig().serverEndpoint}matter/${categoryId}/categorytypemattercount`;
    return this.httpClient.get<GenericListCountViewModel[]>(url);
  }

  public getMatterCategoryClient(categoryId: number) {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${categoryId}/GetMatterCategoryClient`;
    return this.httpClient.get<ContactViewModel[]>(url);
  }

  public getMatterListByCatgory(
    orderByName?: number,
    orderByDate?: number,
    ongoing?: boolean,
    archived?: boolean,
    completed?: boolean,
    categoryType = [0],
    categorySubType = [0],
    clientId = 0,
    searchQuery = "",
    sort = "",
    firmId = 0,
    matterCategoryId?: number,
    order = "asc",
    page?: number,
    pageSize = 0,
    usePageSize?: boolean
  ): Observable<any> {
    if (usePageSize !== false) {
      usePageSize = true;
    }
    let categoryTypeQueryString: string;
    let categorySubTypeQueryString: string;
    let clientQueryNumber: string;

    let ongoingQueryString: string;
    let archivedQueryString: string;
    let completedQueryString: string;
    let orderByNameQueryString: string;
    let orderByDateQueryString: string;

    categoryTypeQueryString = "";
    categorySubTypeQueryString = "";
    clientQueryNumber = "";
    orderByNameQueryString = "";
    orderByDateQueryString = "";

    if (!isNullOrUndefined(orderByName)) {
      orderByNameQueryString = `OrderByName=${orderByName}&`;
    }
    if (!isNullOrUndefined(orderByDate)) {
      orderByDateQueryString = `OrderByDate=${orderByDate}&`;
    }
    if (isNullOrUndefined(ongoing)) {
      ongoingQueryString = `ongoing=${false}&`;
    } else {
      ongoingQueryString = `ongoing=${ongoing}`;
    }
    if (isNullOrUndefined(completed)) {
      completedQueryString = `completed=${false}&`;
    } else {
      completedQueryString = `&completed=${completed}`;
    }
    if (isNullOrUndefined(archived)) {
      archivedQueryString = `archived=${false}&`;
    } else {
      archivedQueryString = `&archived=${archived}`;
    }
    if (
      archived ||
      completed ||
      ongoing ||
      categorySubType[0] > 0 ||
      categoryType[0] > 0 ||
      clientId > 0
    ) {
      if (isNullOrUndefined(ongoing)) {
        ongoingQueryString = `ongoing=${false}&`;
      } else {
        ongoingQueryString = `ongoing=${ongoing}&`;
      }

      if (isNullOrUndefined(archived)) {
        archivedQueryString = `archived=${false}&`;
      } else {
        archivedQueryString = `archived=${archived}&`;
      }

      if (isNullOrUndefined(completed)) {
        completedQueryString = `completed=${false}&`;
      } else {
        completedQueryString = `completed=${completed}&`;
      }
      if (isNullOrUndefined(categorySubType === null)) {
        categorySubTypeQueryString = `&`;
        // categoryTypeQueryString = `&CategoryType=${[0]}`;
      }

      if (isNullOrUndefined(categoryType === null)) {
        // categorySubTypeQueryString = `&CategorySubType=${[0]}`;
        categoryTypeQueryString = `&`;
      }

      if (!isNullOrUndefined(clientId == null)) {
        clientQueryNumber = `&clientId=${clientId}`;
      } else {
        clientQueryNumber = ``;
      }
    }
    if (categorySubType.length > 1 || categorySubType[0] > 0) {
      categorySubType.forEach((c) => {
        categorySubTypeQueryString =
          categorySubTypeQueryString + `&CategorySubType=${c}`;
      });
    } else {
      // if the query string is default or one query param
      categorySubTypeQueryString = ``;
    }

    if (categoryType.length > 1 || categoryType[0] > 0) {
      categoryType.forEach((c) => {
        categoryTypeQueryString =
          categoryTypeQueryString + `&CategoryType=${c}`;
      });
    } else {
      // if the query string is default or one query param
      categoryTypeQueryString = ``;
    }

    if (!isNullOrUndefined(clientId == null) && clientId !== 0) {
      clientQueryNumber = `&clientId=${clientId}`;
    } else {
      clientQueryNumber = ``;
    }

    let url = ``;
    const allUrl =
      this.config.getConfig().serverEndpoint +
      `matter/${matterCategoryId}/category/All`;
    const ongoingUrl =
      this.config.getConfig().serverEndpoint +
      `matter/${matterCategoryId}/category/Ongoing`;
    const archievedUrl =
      this.config.getConfig().serverEndpoint +
      `matter/${matterCategoryId}/category/Archeived`;
    const completedUrl =
      this.config.getConfig().serverEndpoint +
      `matter/${matterCategoryId}/category/completed`;
    if (!isNullOrUndefined(ongoing) && ongoing) {
      url = ongoingUrl;
    } else if (!isNullOrUndefined(completed) && completed) {
      url = completedUrl;
    } else if (!isNullOrUndefined(archived) && archived) {
      url = archievedUrl;
    } else {
      url = allUrl;
    }

    // tslint:disable-next-line:max-line-length
    const api = `${url}?${ongoingQueryString}${orderByNameQueryString}${orderByDateQueryString}${archivedQueryString}${completedQueryString}${clientQueryNumber}&searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}${categoryTypeQueryString}${categorySubTypeQueryString}`;
    return this.httpClient.get<any>(api, { observe: "response" });
  }

  getCategoriesFolder(categoryid: number): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `FileDirectory/${categoryid}/firmcategorydirectory`;
    return this.httpClient.get(url);
  }

  // getAllLitigation(): Observable<any> {
  //   const url = this.config.getConfig().serverEndpoint + `matter/LitigationLite`;
  //   return this.httpClient.get(url);
  // }

  public getAllLitigation(searchQuery = "", page?: number, pageSize = 0, usePageSize = false): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `matter/LitigationLite`;
    const url = `${endpoint}?SearchQuery=${searchQuery}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: "response" });
  }

  getAllNonLitigation(): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `matter/nonlitigationLite`;
    return this.httpClient.get(url);
  }

  getAllAdrLitigation(): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `matter/ADRLite`;
    return this.httpClient.get(url);
  }

  // change type of observable returned
  public getCurrentMatter(matterId: number): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `matter/${matterId}`;
    return this.httpClient.get(url);
  }

  public getCurrentMatterJudges(matterId: number): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `matter/${matterId}/judge`;
    return this.httpClient.get(url);
  }

  public getAllProceeding(searchQuery = "", page?: number, pageSize = 0, usePageSize = false): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `matter/proceeding`;
    const url = `${endpoint}?SearchQuery=${searchQuery}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: "response" });
  }

  public getAllApplication(searchQuery = "", page?: number, pageSize = 0, usePageSize = false): Observable<any> {
    const endpoint = `${this.config.getConfig().serverEndpoint}matter/application`;
    const url = `${endpoint}?SearchQuery=${searchQuery}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: "response" });
  }

  public addMatter(data: AddMatter) {
    const url = this.config.getConfig().serverEndpoint + this.Endpoints.matterEndPoint;
    return this.httpClient.post<any>(url, data);
  }

  public getMatterCategory(): Observable<GenericListViewModel[]> {
    const url = this.config.getConfig().serverEndpoint + "matter_category";
    const matterCategory: GenericListViewModel[] = [
      {
        id: 1,
        name: "Litigation",
      },
      {
        id: 2,
        name: "Non-Litigation",
      },
      {
        id: 3,
        name: "Alternate Dispute Resolution",
      },
    ];
    return of(matterCategory);
  }

  /**
   * Get Matter Contacts
   * @param matterId The Id of the matter
   */
  public getContacts(matterId: number): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `matter/${matterId}/contact`;
    return this.httpClient.get(url);
  }

  public getFirmMatterDirectory(matterId: number): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      `FileDirectory/${matterId}/firmmatterdirectory`;
    return this.httpClient.get(url, { observe: "response" });
  }

  /************
   *  Judges
   ************/

  public deleteCourtJudge(courtMatterJudgeId: number) {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${courtMatterJudgeId}/judge`;
    return this.httpClient.delete<any>(url);
  }

  public getFiles(matterId: number) {
    const url =
      this.config.getConfig().serverEndpoint + `matter/${matterId}/file`;
    // return this.httpClient.get(url).pipe(
    //   catchError(this.handleError)
    //   );
    return of(documentListSample).pipe(catchError(this.handleError));
  }
  public getFolders(matterId: number) {
    const url =
      this.config.getConfig().serverEndpoint + `matter/${matterId}/directory`;
    // return this.httpClient.get(url).pipe(
    //   catchError(this.handleError)
    //   );
    return of(folderSampleList).pipe(catchError(this.handleError));
  }
  public getAllFiles(matterId: number) {
    const url =
      this.config.getConfig().serverEndpoint + `matter/${matterId}/file`;
    // return this.httpClient.get(url).pipe(
    //   catchError(this.handleError)
    //   );
    return of(documentListSample).pipe(catchError(this.handleError));
  }
  public getAllFolders(matterId: number) {
    const url =
      this.config.getConfig().serverEndpoint + `matter/${matterId}/directory`;

    return of(folderSampleList).pipe(catchError(this.handleError));
  }

  getCourtList(matterId): Observable<CourtListModel[]> {
    const api = this.config.getConfig().serverEndpoint + `CourtType`;
    return this.httpClient.get<CourtListModel[]>(api);
  }

  getJudgeMatterDetails(judgeId): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint + `matter/court/${judgeId}/judge`;
    return this.httpClient.get<any>(api);
  }

  updateJudgeMatterDetails(
    judgeDetails: EditMatterJudgeDetail
  ): Observable<any> {
    //console.log(judgeDetails)
    const api = this.config.getConfig().serverEndpoint + `matter/judge`;
    return this.httpClient.put<any>(api, judgeDetails);
  }

  updateMatterCourt(courtDetails: EditMatterCourt): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint + `matter/change-court/court`;
    return this.httpClient.patch<any>(api, courtDetails);
  }

  addJudgement(model: JudgemntParsemModel): Observable<any> {
    const api = this.config.getConfig().serverEndpoint + "matter/judgement";
    return this.httpClient.post<any>(api, model);
  }
  public getMatterDocument(matterId: number): Observable<Document[]> {
    const api =
      this.config.getConfig().serverEndpoint +
      "matter/ " +
      matterId +
      "/document";
    return this.httpClient.get<Document[]>(api);
  }

  /********
   *  NOTE
   ********/

  /***************
   *  COURT
   ***************/

  public changeCourt(data: CourtMatterParseModel) {
    const url =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.changeMatterCourtEndPoint;
    return this.httpClient.patch<any>(url, data);
  }

  public deleteCurrentCourt(courtMatterId: number) {
    const url =
      this.config.getConfig().serverEndpoint + `matter/${courtMatterId}/court`;
    return this.httpClient.delete<any>(url);
  }

  /***************
   *  APPLICATIONS
   ***************/

  public loadApplicationList(matterId): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `matter/${matterId}/application`;
    return this.httpClient.get(url);
    // return of (applicationListSample);
  }

  public addNewApplication(object) {
    const url = this.config.getConfig().serverEndpoint + "matter/application";
    return this.httpClient.post<any>(url, object);
  }

  public editApplication(data): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `/matter/application`;
    return this.httpClient.put(url, data);
    // return of (data);
  }

  /***************
   *  JUDGEMENTS
   ***************/
  public loadJudgementList(matterId): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `matter/${matterId}/judgement`;
    return this.httpClient.get(url);
  }

  public addNewJudgement(object) {
    const url = this.config.getConfig().serverEndpoint + "matter/judgement";
    return this.httpClient.post<any>(url, object);
  }

  public editJudgement(data): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `/matter/judgement`;
    return this.httpClient.put(url, data);
    // return of (data);
  }
  getJudgementByJudgementId(judgementId: number): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint +
      "matter/judgement/" +
      judgementId;
    return this.httpClient.get<any>(api);
  }

  getJudgementDocuments(
    judgementId: number,
    pageSize?: number,
    page?: number,
    searchQuery = "",
    usePageSize = false
  ): Observable<any> {
    const fileType = 3;
    const isAdmin = this.userData.isAdmin;

    const endpoint = this.config.getConfig().serverEndpoint + `file`;
    const url = `${endpoint}?searchQuery=${searchQuery}&fileType=${fileType}&id=${judgementId}&isAdmin=${isAdmin}&usePageSize=${usePageSize}`;

    return this.httpClient.get<any>(url, { observe: "response" });
  }

  getNewJudgementDocuments(judgementId: number): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint +
      `file/GetFilesByJudgement/${judgementId}`;
    //console.log(api);
    return this.httpClient.get<any>(api);
  }

  /***************
   *  ENDORSEMENT
   ***************/
  public addNewCourtAppearance(object) {
    const url = this.config.getConfig().serverEndpoint + "matter/Appearance";
    return this.httpClient.post<any>(url, object);
  }

  public addNewGCalCourtAppearance(object) {
    const url =
      this.config.getConfig().serverEndpoint +
      "matter/Appearance/SyncGoogleCal";
    return this.httpClient.post<any>(url, object);
  }

  public getEndorsementDetails(proceedingId: number): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/proceeding/${proceedingId}`;
    return this.httpClient.get<any>(url);
  }

  /********
   *  TEAM
   ********/

  public getTeam(teamId: number): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `matter/${teamId}/teammembers`;
    return this.httpClient.get<any>(url);
  }

  public getTeamList(teamId: number): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${teamId}/teammemberlist`;
    return this.httpClient.get<any>(url);
  }

  public deleteTeamMember(teamMemberId: number): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${teamMemberId}/member/tem`;
    return this.httpClient.delete<any>(url).pipe(catchError(this.handleError));
    // return of (true);
  }

  public deleteClient(matterId, client): Observable<any> {
    const contactId = client.contactId;
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${matterId}/contact/${contactId}`;
    return this.httpClient.delete<any>(url).pipe(catchError(this.handleError));
    // return of (true);
  }

  public deleteEndorsementDate(proceedingdateId): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/proceedingdate/${proceedingdateId}`;
    return this.httpClient.delete<any>(url).pipe(catchError(this.handleError));
    // return of (true);
  }

  public deleteParty(matterId, party): Observable<any> {
    const contactId = party.contactId;
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${matterId}/contact/${contactId}`;
    return this.httpClient.delete<any>(url).pipe(catchError(this.handleError));
    // return of (true);
  }

  public appearanceDate(proceedingId, date): Observable<any> {
    const _date = date;
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${proceedingId}/appearanceDate/${_date}`;
    return this.httpClient.delete<any>(url).pipe(catchError(this.handleError));
    // return of (true);
  }

  public addNewTeamMember(teamMemberFull): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/member/team";
    const obj: any[] = [];
    teamMemberFull.forEach((m) => {
      obj.push({
        userId: m.userId,
        firmRoleId: m.firmRoleId,
        teamId: m.teamId,
      });
    });
    return this.httpClient.post(url, obj).pipe(catchError(this.handleError));
    // return of (12)
  }
  /********
   *  PARTY
   ********/

  public addNewParty(data: contactListLite): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.matterContactEndPoint;
    return this.httpClient.post(url, data);
    // return of (null).pipe(
    //   catchError(this.handleError)
    // );
  }

  attachDocumentForMatters(model): Observable<any> {
    const api = this.config.getConfig().serverEndpoint + "file";
    return this.httpClient.post<any>(api, model);
  }

  createNewCourt(obj) {
    const url = this.config.getConfig().serverEndpoint + "matter/court";
    return this.httpClient.post<any>(url, obj);
  }

  createNewJudge(obj) {
    const url = this.config.getConfig().serverEndpoint + "matter/judge";
    return this.httpClient.post<any>(url, obj);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }

  // Tasks

  public addNewTask(payload: any) {
    const url = this.config.getConfig().serverEndpoint + "task/matter";
    return this.httpClient.post(url, payload);
  }

  public updateTask(payload: any) {
    const url = this.config.getConfig().serverEndpoint + "task/matter";
    return this.httpClient.put(url, payload);
  }

  public getMatterTasks(id: number): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "task/matter/" + id;
    // return this.httpClient.get(url);
    return of(TasksSample);
  }

  public markTaskAsCompleted(id: number): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "task/matter/" + id;
    // return this.httpClient.get(url);
    return of(TasksSample[0]);
  }

  public deleteTask(id: number): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "task/matter/" + id;
    // return this.httpClient.get(url);
    return of({});
  }

  // expenses

  public addNewExpense(payload: any) {
    const url = this.config.getConfig().serverEndpoint + "expense/matter";
    return this.httpClient.post(url, payload);
  }

  public updateExpense(payload: any) {
    const url = this.config.getConfig().serverEndpoint + "expense/matter";
    return this.httpClient.put(url, payload);
  }

  public getExpense(expenseId: number) {
    const url = this.config.getConfig().serverEndpoint + "expense/" + expenseId;
    // return this.httpClient.get(url);
    return of(ExpenseSample[0]);
  }

  public getMatterTimeTasks() {
    const url =
      this.config.getConfig().serverEndpoint + "firm/FirmTimeTaskType";
    return this.httpClient.get<any>(url);
  }

  public addMatterTimeTask(payload: MatterTimeTask) {
    const url =
      this.config.getConfig().serverEndpoint + "firm/FirmTimeTaskType";
    return this.httpClient.post(url, payload);
  }

  public getMatterExpenses(
    matterId: number,
    searchQuery = "",
    sort = "",
    order = "asc",
    page: number = 1,
    pageSize = 1,
    usePageSize = true
  ): Observable<any> {
    let url =
      this.config.getConfig().serverEndpoint +
      "expense/" +
      matterId +
      "/Expenses";
    url = `${url}?searchQuery=${searchQuery}&isAdmin=${
      this.userData.isAdmin
    }&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get(url);
    // return of(ExpenseSample);
  }

  public deleteExpense(id: number): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "expense/" + id;
    return this.httpClient.delete(url);
    // return of({});
  }

  public deleteExpenseFile(id: number): Observable<boolean> {
    const url = `${
      this.config.getConfig().serverEndpoint
    }expense/ExpenseFile/${id}`;
    return this.httpClient.delete<boolean>(url);
  }

  // time sheet

  saveTimeEntry(payload: MatterTimeSheetModel) {
    const url =
      this.config.getConfig().serverEndpoint + this.Endpoints.timeSheetEndPoint;
    return this.httpClient.post(url, payload);
  }

  public getMatterTimes(
    matterId: number,
    searchQuery = "",
    sort = "",
    order = "asc",
    page: number = 1,
    pageSize = 10,
    usePageSize = true
  ): Observable<any> {
    let url =
      this.config.getConfig().serverEndpoint + `expense/${matterId}/Timesheets`;
    url = `${url}?searchQuery=${searchQuery}&sort=${sort}&isAdmin=${
      this.userData.isAdmin
    }&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get(url, { observe: "response" });
    // return of(TimeSheetSample);
  }

  public deleteTime(id: number): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "time/" + id;
    return this.httpClient.delete(url);
    // return of({});
  }

  /************
   *  DOCUMENTS
   ************/
  /**
   *
   * @param matterId
   * @param searchQuery
   * @param sort
   * @param order
   * @param page
   * @param pageSize
   * @param usePageSize
   */
  public getMatterDocumentFiles(
    matterId: number,
    searchQuery = "",
    sort = "",
    order = "asc",
    page = 0,
    pageSize = 10,
    usePageSize = true
  ): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `file/${matterId}/${this.userData.isAdmin}/matterfilesfolder`;
    // tslint:disable-next-line: max-line-length
    const api = `${endpoint}?searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(api, { observe: "response" });
  }

  getMatterDraftDocument(
    matterId: number,
    searchQuery = "",
    sort = "",
    order = "asc",
    page = 0,
    pageSize = 10,
    usePageSize = true
  ): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `Document/Draft/Matter/${matterId}`;
    const api = `${endpoint}?searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(api, { observe: "response" });
  }

  // tslint:disable-next-line: max-line-length
  public getMatterDocumentFolders(
    directoryId: number,
    searchQuery = "",
    sort = "",
    order = "asc",
    page = 0,
    pageSize = 10,
    usePageSize = true
  ): Observable<any> {
    const fileType = 4;
    // const endpoint = this.config.getConfig().serverEndpoint + `file/Directory/${directoryId}/fileType/${fileType}`;
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `file/GetFilesByDirectory/${directoryId}`;
    // tslint:disable-next-line: max-line-length
    const api = `${endpoint}?&matterId=${directoryId}&fileType=${fileType}searchQuery=${searchQuery}&isAdmin=${
      this.userData.isAdmin
    }&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(api, { observe: "response" });
  }

  /**
   *
   * @param matterFileId for Deleting (files)
   */
  public deletetMatterDocumentFiles(id: number): Observable<any> {
    // const api = this.config.getConfig().serverEndpoint + `file/${id}/filetype/${fileType}`;
    const api = this.config.getConfig().serverEndpoint + `file/${id}`;
    return this.httpClient.delete<any>(api);
  }

  /**
   *
   * @param matterDirectoryId for Deleting Folder(directories)
   */
  public deleteMatterDocumentFolders(id: number): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint +
      `FileDirectory/DeleteDirectory/${id}`;
    return this.httpClient.delete<any>(api);
  }

  /************
   *  DOCUMENTS
   ************/

  /************
   * NOTES
   ************/

  /**
   *
   * @param values Create New Note
   */
  public createNote(values: any): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/note";
    return this.httpClient.post<any>(url, values);
  }

  /**
   *
   * @param values Edit Note
   */
  public EditNote(values: any): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/note";
    return this.httpClient.put<any>(url, values);
  }

  /**
   * Get list of Notes
   * @param matterId
   * @param searchQuery
   * @param sort
   * @param order
   * @param page
   * @param pageSize
   * @param usePageSize
   */
  // tslint:disable-next-line: max-line-length
  public getNoteList(
    matterId: number,
    searchQuery = "",
    sort = "",
    order = "asc",
    page = 0,
    pageSize = 6,
    usePageSize = true
  ): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint + `matter/${matterId}/note`;
    const api = `${endpoint}?searchQuery=${searchQuery}&sort=${sort}&isAdmin=${
      this.userData.isAdmin
    }&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(api, { observe: "response" });
  }

  /**
   * Delete Note
   * @param matterNoteId
   */
  public deleteNote(matterNoteId: number): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint + `matter/${matterNoteId}/note`;

    return this.httpClient.delete<any>(api);
  }
  getNoteById(noteId: number): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint +
      "matter/" +
      noteId +
      "/notedetail";
    return this.httpClient.get<any>(api);
  }

  /************
   * NOTES
   ************/

  public createExpense(values: any): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "expense/matter";
    return this.httpClient.post<any>(url, values);
  }

  // expense/{matterId}/Timesheets expense/TimeSheets

  /************
   * TIME SHEETS
   ************/
  public getTimeSheets(
    matterId: number,
    searchQuery = "",
    sort = "",
    order = "asc",
    page = 0,
    pageSize = 10,
    usePageSize = true
  ): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint + `expense/${matterId}/Timesheets`;
    const api = `${endpoint}?searchQuery=${searchQuery}&isAdmin=${
      this.userData.isAdmin
    }&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(api, { observe: "response" });
  }

  deleteTimeSheet(timeSheetId: number): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint +
      `expense/${timeSheetId}/TimeSheets`;

    return this.httpClient.delete<any>(api);
  }
  /************
   * TIME SHEETS
   ************/

  /************
   * JUDGEMENT
   ************/
  public getJudgementList(
    matterId: number,
    searchQuery = "",
    sort = "",
    order = "asc",
    page = 0,
    pageSize = 10,
    usePageSize = true
  ): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint + `matter/${matterId}/judgement`;
    // tslint:disable-next-line: max-line-length
    const api = `${endpoint}?searchQuery=${searchQuery}&isAdmin=${
      this.userData.isAdmin
    }&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(api, { observe: "response" });
  }

  public deleteJudgement(judgementId: number) {
    const api =
      this.config.getConfig().serverEndpoint +
      `matter/judgement/${judgementId}`;

    return this.httpClient.delete<any>(api);
  }
  /************
   * JUDGEMENT matter/{judgementId}
   ************/
  public briefGetMatterDetails(id: any): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `matter/${id}`;
    return this.httpClient.get<any>(url, id);
  }
  public briefEditDetails(values: any): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `matter`;
    return this.httpClient.put<any>(url, values);
  }

  // tslint:disable-next-line: max-line-length
  public getExpensesList(
    matterId: number,
    searchQuery = "",
    sort = "",
    order = "",
    page = 0,
    pageSize = 10,
    usePageSize = true
  ): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint + `expense/${matterId}/Expenses`;
    // tslint:disable-next-line: max-line-length
    const api = `${endpoint}?searchQuery=${searchQuery}&isAdmin=${
      this.userData.isAdmin
    }&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;

    return this.httpClient.get<any>(api, { observe: "response" });
  }
  public deleteExpenses(expenseId: number) {
    const api = this.config.getConfig().serverEndpoint + `expense/${expenseId}`;

    return this.httpClient.delete<any>(api);
  }

  public completeBriefDetails(id: any): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `matter/complete/${id}`;
    return this.httpClient.patch<any>(url, id);
  }

  public unCompleteBriefDetails(id: any): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `matter/Uncomplete/${id}`;
    return this.httpClient.patch<any>(url, id);
  }

  public newCategorySubType(values: any): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + "matter/categorysubtype";
    return this.httpClient.post<any>(url, values);
  }

  public deleteBrief(matterId: any): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `matter/${matterId}`;
    return this.httpClient.delete<any>(url, matterId);
  }

  public editExpense(values: any): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `expense/matter`;
    return this.httpClient.put<any>(url, values);
  }

  public updateTeamMember(userId, roleId, teamId: number) {
    const url = this.config.getConfig().serverEndpoint + "matter/member/team";
    return this.httpClient.put(url, {
      firmUserId: userId,
      firmRoleId: roleId,
      teamId: teamId,
    });
  }

  public updateTeamMembers(payload) {
    const url = this.config.getConfig().serverEndpoint + "matter/member/team";
    return this.httpClient.post(url, payload);
  }

  public deleteTeamMemeber(payload: MatterTeamMember, teamId: number) {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/team/${teamId}/member/${payload.id}`;
    return this.httpClient.delete(url);
  }

  public deleteMatter(matterId: number): Observable<any> {
    const api = this.config.getConfig().serverEndpoint + `matter/${matterId}`;
    return this.httpClient.delete<any>(api);
  }

  public newClientReport(report: any) {
    const url = this.config.getConfig().serverEndpoint + `ClientReport`;
    return this.httpClient.post(url, report);
  }
  // categorytype

  addNewClientRole(payload: any): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `Matter-Contact-Status`;
    return this.httpClient.post(url, payload);
  }

  public addSubCategory(values: any): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `matter/categorytype`;
    return this.httpClient.post(url, values);
  }

  public addPractise(values: any): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `matter/categorysubtype`;
    return this.httpClient.post(url, values);
  }

  public archiveStatus(matterID: any): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `matter/Archeive/${matterID}`;
    return this.httpClient.patch(url, matterID);
  }
  unArchivedStatus(matterId: number): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `matter/UnArcheive/${matterId}`;
    return this.httpClient.patch(url, matterId);
  }
}
