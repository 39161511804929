import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-payment-succesful",
  templateUrl: "./payment-succesful.component.html",
  styleUrls: ["./payment-succesful.component.scss"],
})
export class PaymentSuccesfulComponent implements OnInit {
  isCard: boolean;
  countdown: number;
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    if (!isNullOrUndefined(this.activatedRoute.snapshot.queryParams["card"])) {
      this.isCard = this.activatedRoute.snapshot.queryParams["card"];
    } else {
      this.isCard = false;
    }

    this.countdown = 120;

    this.startCountdown(this.countdown);
  }

  startCountdown(seconds) {
    var counter = seconds;

    var interval = setInterval(() => {
      //console.log(counter);
      counter--;
      this.countdown = counter;

      if (counter < 0) {
        // The code here will run when
        // the timer has reached zero.

        clearInterval(interval);
        //console.log("Ding!");
        this.router.navigate(["/logout"]);
      }
    }, 1000);
  }

  goToLink(url) {
    window.open(url, "_blank");
  }
}
