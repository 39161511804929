import { Reminder } from "../view-model/matter-tasks";
import { TagOptionGenericModel } from "../generic-model.ts/tag-option";
import { FileUploadModel } from "../generic-model.ts/file-upload";

// a model used for creatingTask
export class TaskParseModel {
  id?: number;
  name?: any;
  startDate?: any;
  dueDate?: any;
  isReoccurringTask?: boolean = false;
  resourceId?: number;
  tagId?: number;
  description?: string;
  otherResource?: string;
  reminders?: Reminder[];
  reoccurringInterval: number;
  // tagOption: TagOptionGenericModel;
  files: FileUploadModel[] = [];
  userIds: number[] = [];
  userEmails: { email: string }[] = [];
  googleCalId?: string;
  featureId: number;
}

export class EmailTaskParseModel extends TaskParseModel {
  messageId: string[];
  configId: number;
}

export class EmaialTaskModel {
  dueDate: string;
  googleCalEventId: any;
  id: number;
  isOverDued: boolean;
  isReviewer: boolean;
  iscompleted: boolean
  matterId: number;
  name: string;
  rating: any;
  resourceId: number;
  resourceName: string;
  resourceType: string;
  tag: any;
  taskFileCount: number;
  taskType: string;
  teamMemberCount: number;
  teamMembers: any
}

export class AddEmailToExistingTask {
  taskId: number;
  messageId: string[];
  configId: number;
}

export class GetEmailExistingTasksPayload {
  messageIds: any[]
}
