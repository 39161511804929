import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, SimpleChanges, } from "@angular/core";
import { UserDetail } from "../../models/view-model/user-detail";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { MatterConfigService } from "../../services/matter-config.service";
import { MatterService } from "src/app/services/matter.service";
import { GenericListViewModel } from "src/app/models/generic-model.ts/generic-list";

@Component({
  selector: "app-new-type",
  templateUrl: "./new-type.component.html",
  styleUrls: ["./new-type.component.scss"],
})
export class NewTypeComponent implements OnInit {
  errorMessage: string;
  public showSuccessMessage: boolean;
  public showErrorMessage: boolean;
  public btnSpinner: boolean;
  newCategoryTypeForm: FormGroup;

  @Input() categoryTypeId: any;
  @Input() existingCategories: GenericListViewModel[] = [];
  @Output() successErrorDisplay = new EventEmitter();

  @ViewChild("closeModal") closeModal: ElementRef;

  briefCategories: GenericListViewModel[] = [];
  categoryExistsError: boolean = false;

  constructor(
    private fb: FormBuilder,
    private matterConfigService: MatterConfigService,
    private matterService: MatterService
  ) { }

  ngOnInit() {
    this.showErrorMessage = false;
    this.btnSpinner = false;
    this.addCategoryTypeForm();
    this.getCategories();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.existingCategories) {
      this.existingCategories = changes.existingCategories.currentValue;
    }
  }

  getCategories() {
    this.matterConfigService.getBriefCategories().subscribe((data) => {
      this.briefCategories = data;
    });
  }

  addCategoryTypeForm() {
    this.newCategoryTypeForm = this.fb.group({
      title: ["", Validators.required],
      categoryID: ["", this.categoryTypeId ? null : Validators.required],
    });
  }

  get title() { return this.newCategoryTypeForm.get("title"); }
  get categoryID() { return this.newCategoryTypeForm.get("categoryID"); }

  onSaveNewCategoryType() {
    this.btnSpinner = true;

    if (this.existingCategories.length > 0) {
      for (let category of this.existingCategories) {
        if (category.name.toLowerCase() == this.title.value.toLowerCase()) {
          this.categoryExistsError = true;
          break;
        }
      }
    }

    if (this.categoryExistsError) {
      this.btnSpinner = false;
      return;
    }

    const addCat = {
      matterCategoryId:
        this.categoryTypeId !== null && this.categoryTypeId > 0
          ? this.categoryTypeId
          : Number(this.categoryID.value),
      name: this.title.value,
      description: "",
    };

    const showSuccessMessage = true;
    const successText = '"' + this.title.value + ' " Has been added';
    const showErrorMessage = true;
    const errorText = "an error occurred while trying to create practise";

    console.log(addCat);

    this.matterService.addSubCategory(addCat).subscribe(
      (data) => {
        console.log(data);
        this.successErrorDisplay.emit({
          showSuccessMessage,
          successText,
          catID: addCat.matterCategoryId,
        });
        this.newCategoryTypeForm.reset();
        this.btnSpinner = false;
        this.closeModal.nativeElement.click();
      },
      (error) => {
        console.log(error);
        this.successErrorDisplay.emit({ showErrorMessage, errorText });
        this.closeModal.nativeElement.click();
        this.btnSpinner = false;
        this.closeModal.nativeElement.click();
        //console.log(error);
      }
    );
  }

  clearError(): void {
    this.categoryExistsError = false;
  }
}
