import { UserTask } from "../../models/view-model/user-tasks";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Store, select } from "@ngrx/store";
import * as fromTask from "../../store/reducers/task.reducer";
import * as TaskActions from "../../store/actions/task.action";
import { IConfirm } from "../interfaces/confirm";
import { isNullOrUndefined } from "util";
import { TaskService } from "src/app/services/task.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-task-tag",
  templateUrl: "./task-tag.component.html",
  styleUrls: ["./task-tag.component.scss"],
})
export class TaskTagComponent implements OnInit, IConfirm {
  confirmType: string;
  confirmTitle: string;
  confirmMessage: string;
  confirmAction: string;
  confirmElementData: any;
  matterId: Number;

  todayDate: number;
  @Input() task: UserTask;
  @Output() currentTask = new EventEmitter();
  @Output() myShowTask = new EventEmitter();

  showCurrentTask: boolean;
  checkSpinner: boolean;

  constructor(
    private taskStore: Store<fromTask.State>,
    private taskService: TaskService,
    private route: ActivatedRoute
  ) {
    this.todayDate = new Date().getTime();
  }

  ngOnInit() {
    this.matterId = Number(this.route.parent.snapshot.paramMap.get("matterId"));
    if (!isNullOrUndefined(this.task)) {
      this.taskService.getTaskDetail(this.task.id).subscribe((res) => {
        this.task = res;
      });
    }

    this.taskStore
      .pipe(select(fromTask.completeTaskSuccess))
      .subscribe((data) => {
        if (!isNullOrUndefined(data)) {
          if (data) {
            // this.myMessgae.emit(true);
          }
        }
      });
  }

  setCurrentTask(file) {
    console.log('setting')
    this.taskStore.dispatch(new TaskActions.SetCurrentTask(file));
    this.taskStore.dispatch(new TaskActions.ViewTask(true));
    this.showCurrentTask = true;
    this.currentTask.emit(file);
    this.myShowTask.emit(file);
  }

  confirmDelete(id: any) {
    this.confirmType = "deleteTask";
    this.confirmTitle = "Delete Task";
    this.confirmMessage = "Are you sure you want to delete this task ?";
    this.confirmAction = "Yes, Delete Task";
    this.confirmElementData = id;
  }

  confirmResponse(data: any) {
    if (data.type === "deleteTask") {
      this.deleteTask(data.elementData);
    }
  }

  deleteTask(task: UserTask) {
    //console.log(task);
    // call delete task from store
    // this.taskStore.dispatch(new TaskActions.);
  }

  onChange(task: UserTask) {
    this.checkSpinner = true;
    this.taskStore.dispatch(new TaskActions.CheckTask(task));
  }
}
