import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, } from "@angular/core";
import { Reminder } from "src/app/models/view-model/matter-tasks";
import moment from "moment";
// import * as fromTask from "../../store/reducers/task.reducer";
import { FormGroup, Validators, FormBuilder, FormArray, FormControl, } from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { UserDetail } from "src/app/models/view-model/user-detail";

import * as fromStore from "../../auth/state/index";
import * as LoginActions from "../../auth/state/login.actions";
import { TaskParseModel } from "src/app/models/shared-model/newTask";
import { CommonUserModel } from "src/app/models/generic-model.ts/user-share";
import { FeatureTaskEnum, TagOptionGenericModel, } from "src/app/models/generic-model.ts/tag-option";
import { Notifier } from "../base-class/notifier";
import * as fromUserHeaderStore from "../../store/reducers/user-header.reducer";
import { TaskDetailViewModel } from "../../models/view-model/task-detail";
import { FileUploadModel } from "src/app/models/generic-model.ts/file-upload";
import { UserTask } from "src/app/models/view-model/user-tasks";
import { isNullOrUndefined } from "util";
import * as fromMatters from "../../store/reducers/matters.reducers";
import { GoogleCalendarService } from "../../services/google-calendar.service";
import { TaskService } from "src/app/services/task.service";
import { Router } from "@angular/router";
import { ReloadTaskService } from "src/app/matter-task/helpers/reload-task.service";

const RecurrenceInterval: Record<number, string> = {
  0: "DAILY",
  1: "WEEKLY",
  2: "BI-WEEKLY",
  3: "MONTHLY",
  4: "QUARTERLY",
  5: "YEARLY",
};

const ColorId: Record<number, number> = {
  1: 4, //red
  2: 6, //orange
  3: 1, //blue
};

export enum TaskIntervalEnum {
  DAILY = 1,
  WEEKLY = 2,
  MONTHLY = 3,
  QUARTERLY = 4,
  BI_WEEKLY = 5,
  YEARLY = 6,
}

@Component({
  selector: "app-new-task-modal",
  templateUrl: "./new-task-modal.component.html",
  styleUrls: ["./new-task-modal.component.scss"],
})
export class NewTaskModalComponent extends Notifier implements OnInit, OnChanges, OnDestroy {

  @Output() newTaskEmit = new EventEmitter<TaskDetailViewModel>();
  @Output() newGCalTaskEmit = new EventEmitter<any>();
  @Output() updateUser = new EventEmitter<CommonUserModel[]>();
  @Output() updateUpcomingUserList = new EventEmitter<any>();

  @Input() taskTeamMembers: any;
  @Input() gCalId = "";
  @Input() matterId: number = 0;
  @Input() taskTitle = "";
  // @Input() task;
  @Input() sendMatterId;
  @Input() editTask: UserTask;

  @ViewChild("closeTaskModal") closeTaskModal: ElementRef;
  @ViewChild("closeNewEventModal") closeNewEventModal: ElementRef;

  btnSpinner: boolean;
  componentActive: boolean;
  showChildModal: boolean;
  taskTeamMember: CommonUserModel[] = [];
  reminders: any = {};
  newTaskForm: FormGroup;
  user = new UserDetail();
  // firmId: any;
  resourceId: number = 0;
  // otherResource: string = "";
  // _taskTag = new TagOptionGenericModel();
  showResourceNotSelectedError: boolean;
  createNewTask = new TaskParseModel();
  errorText: string;
  showError: boolean;
  fileProgressArray: number[] = [];
  currentDocument: FileUploadModel[] = [];
  // myMatterId: number;
  value = new Date().getTime();
  public featureTaskEnum = FeatureTaskEnum;
  timeValue = new FormControl();
  categoryId: number = 0;
  public taskIntervalEnum = TaskIntervalEnum;
  selectedMatterId: number;

  constructor(
    private reloadTask: ReloadTaskService,
    private router: Router,
    private fb: FormBuilder,
    // private taskStore: Store<fromTask.State>,
    private store: Store<fromStore.State>,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>,
    private gCalendarService: GoogleCalendarService,
    private taskService: TaskService,
  ) {
    super(headerStore);
    this.componentActive = true;

    // ngrx to get the current matter
    this.store.pipe(select(fromMatters.getCurrentMatter))
      .subscribe((matter) => {
        if (!isNullOrUndefined(matter)) {
          // console.log(matter);
          this.categoryId = matter.categoryId;
        }
      });

    this.store.dispatch(new LoginActions.LoadLocalUserDetail());

    this.store.pipe(select(fromStore.getUserDetail)).subscribe((data) => {
      this.user = data;
    });

    // new task form builder
    this.newTaskForm = this.fb.group({
      taskName: ["", Validators.required],
      taskTag: [this.featureTaskEnum.Me, Validators.required],
      taskPriorityId: ["", Validators.required],
      dueDate: ["", Validators.required],
      description: ["", Validators.required],
      reminders: this.fb.array([]),
      isReoccurringTask: [false],
      reoccurringInterval: [0],
      // otherResource: [null]
    });
  }

  ngOnInit() {
    // this.myMatterId = this.sendMatterId;
    // console.log(`Send MatterId: ${this.sendMatterId}`);
    this.showResourceNotSelectedError = false;
    this.btnSpinner = false;
    this.showError = false;

    this.tranform_dates();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.editTask !== undefined) { this.editTaskMode(); }
    if (this.taskTitle !== "") { this.newTaskForm.patchValue({ taskName: this.taskTitle, }); }
    if (this.taskTeamMembers) { this.taskTeamMember = this.taskTeamMembers.updatedUsers; }

    // if (this.matterId) {
    //   this.resourceId = this.matterId;
    //   this.newTaskForm.patchValue({ taskTag: this.featureTaskEnum.Matter });
    // }
  }

  // for new task form control
  get taskName() { return this.newTaskForm.get("taskName"); }
  get taskTag() { return this.newTaskForm.get("taskTag"); }
  get description() { return this.newTaskForm.get("description"); }
  get taskPriorityId() { return this.newTaskForm.get("taskPriorityId"); }
  get dueDate() { return this.newTaskForm.get("dueDate"); }
  get isReoccurringTask() { return this.newTaskForm.get("isReoccurringTask"); }
  get reoccurringInterval() { return this.newTaskForm.get("reoccurringInterval"); }

  tranform_dates(): void {
    let today: any;
    today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();

    if (dd <= 9) { dd = "0" + dd; }
    if (mm <= 9) { mm = "0" + mm; }
    today = yyyy + "-" + mm + "-" + dd;
    this.dueDate.patchValue(today);
  }

  editTaskMode() {
    this.newTaskForm.reset();
    this.reminders = [];

    this.newTaskForm.patchValue({
      taskName: this.editTask.name,
    });
  }

  // from emitter
  getattachedObject(output) {
    this.errorText = output.errorText;
    this.showError = output.showError;
    this.fileProgressArray = output.fileProgressArray;
    this.currentDocument = output.currentDocument;
  }

  getAttachedDriveFiles(driveFiles: FileUploadModel[]) {
    this.currentDocument = driveFiles;
  }

  attachedToMeTaskTag: boolean = true;

  switchSelectOption(value) {
    if (this.attachedToMeTaskTag !== true) {
      this.taskTag.patchValue(this.featureTaskEnum.Me)
    } else {
      this.taskTag.patchValue(value)
    }
    this.selectedMatterId = 0;
    this.attachedToMeTaskTag = !this.attachedToMeTaskTag;
  }

  getSelectedOption(resourceId) {
    if (Number.parseInt(resourceId, 10) > 0) {
      this.resourceId = resourceId;
    }
  }

  getSelectedMatter(matterId) {
    if (Number.parseInt(matterId, 10) > 0) {
      this.selectedMatterId = matterId;
    }
  }

  saveTask() {
    this.btnSpinner = true;
    const reminderDates: Reminder[] = [];

    if (
      !isNullOrUndefined(this.reminders) &&
      !isNullOrUndefined(this.reminders.controls)
    ) {
      this.reminders.controls.forEach((controlData) => {
        const data = controlData.value;
        const interval = data.interval;
        let dueDateTimeForReminder = new Date(this.dueDate.value);
        const convertedTimeString = this.convertTime(this.timeValue.value);
        dueDateTimeForReminder.setHours(parseInt(convertedTimeString.hours));
        dueDateTimeForReminder.setMinutes(
          parseInt(convertedTimeString.minutes)
        );
        const value = data.value;

        if (interval.toLowerCase().includes("minute")) {
          dueDateTimeForReminder.setMinutes(
            dueDateTimeForReminder.getMinutes() - value
          );
        }
        if (interval.toLowerCase().includes("hour")) {
          dueDateTimeForReminder.setHours(
            dueDateTimeForReminder.getHours() - value
          );
        }
        if (interval.toLowerCase().includes("day")) {
          dueDateTimeForReminder.setDate(
            dueDateTimeForReminder.getDate() - value
          );
        }
        if (interval.toLowerCase().includes("week")) {
          dueDateTimeForReminder.setDate(
            dueDateTimeForReminder.getDate() - value * 7
          );
        }
        if (interval.toLowerCase().includes("month")) {
          dueDateTimeForReminder.setMonth(
            dueDateTimeForReminder.getMonth() - value
          );
        }
        if (interval.toLowerCase().includes("year")) {
          dueDateTimeForReminder.setFullYear(
            dueDateTimeForReminder.getFullYear() - value
          );
        }
        reminderDates.push({
          reminderTime: +new Date(dueDateTimeForReminder),
          message: interval + "#" + value,
        });
      });
    }

    let dueDateTime = new Date(this.dueDate.value);
    // console.log(this.timeValue.value)
    const convertedTimeString = this.convertTime(this.timeValue.value);
    dueDateTime.setHours(parseInt(convertedTimeString.hours));
    dueDateTime.setMinutes(parseInt(convertedTimeString.minutes));
    // console.log(convertedTimeString, this.timeValue.value, dueDateTime);
    this.createNewTask.name = this.taskName.value;
    this.createNewTask.description = this.description.value;
    this.createNewTask.startDate = `${new Date().getTime()}`;
    this.createNewTask.dueDate = +new Date(dueDateTime);
    this.createNewTask.isReoccurringTask =
      this.isReoccurringTask.value || false;
    this.createNewTask.reoccurringInterval = Number(
      this.reoccurringInterval.value
    );
    this.createNewTask.tagId = Number(this.taskPriorityId.value);
    this.createNewTask.reminders = reminderDates;

    /**
     * this numbers are independent of the Backend
     */
    // this.createNewTask.tagOption = new TagOptionGenericModel();
    this.createNewTask.featureId = Number(this.taskTag.value);
    if (
      this.taskTag.value == this.featureTaskEnum.Application ||
      this.taskTag.value == this.featureTaskEnum.Endorsement ||
      this.taskTag.value == this.featureTaskEnum.Matter
    ) {
      this.createNewTask.resourceId = Number(this.resourceId);
    }

    this.createNewTask.files = this.currentDocument;
    if (this.taskTeamMember && this.taskTeamMember.length > 0) {
      this.taskTeamMember.forEach((member) => {
        this.createNewTask.userIds.push(member.id);
        this.createNewTask.userEmails.push({ email: member.email });
      });
    }
    const gCalPayload = this.formatToGCalData(this.createNewTask);
    // console.log(this.gCalId);
    if (this.gCalId) {
      this.createNewTask.googleCalId = this.gCalId;
      this.gCalendarService.creategCallEvent(this.createNewTask).subscribe(
        (res) => {
          this.btnSpinner = false;
          this.newGCalTaskEmit.emit(res);
          this.closeTaskModal.nativeElement.click();
          this.pushMessage("Task created successfully");
        },
        (err) => {
          this.btnSpinner = false;
          this.sendErrorMessage("Error, Task cannot be created, try again Later");
        }
      );
    } else {
      // console.log(this.createNewTask)
      this.updateUpcomingUserList.emit(this.createNewTask);
      this.newGCalTaskEmit.emit();
      this.taskService.addNewTask(this.createNewTask).subscribe(
        (addedTaskId) => {
          this.btnSpinner = false;
          // console.log(addedTaskId);
          // console.log("Saving task from RxJs");

          // In all honesty, I still don't understand where and why this newTaskEmit is emiting but I will have it there till I figure something out
          let taskCaster: any;
          taskCaster = this.createNewTask;
          const taskDetailViewModel = new TaskDetailViewModel();
          taskDetailViewModel.id = addedTaskId;
          taskDetailViewModel.name = this.createNewTask.name;
          taskDetailViewModel.dueDate = this.createNewTask.dueDate;
          // console.log(taskDetailViewModel);
          this.newTaskEmit.emit(taskDetailViewModel);

          this.newTaskForm.reset();
          this.reminders.controls = [];
          this.closeTaskModal.nativeElement.click();
          this.pushMessage(`Adding task <strong>${this.createNewTask.name}</strong>,  was successfull`);

          // Check if it's not due before rerouting
          this.router.navigate(['/firm','tasks','upcoming']);
          this.reloadTask.changeDecision(true);
        },
        (err) => {
          this.btnSpinner = false;
          // console.log(err);
          this.sendErrorMessage("Error, Task cannot be created, try again Later");
        }
      )
    }

    this.newTaskForm.reset();
  }




  /**
   * format value to gCal expected data
   * @param {Object} item
   * @returns
   */
  formatToGCalData(item: TaskParseModel) {
    let interval;
    //helper function
    let reoccurringInterval = (val) => {
      if (val == "BI-WEEKLY") {
        interval = 2;
        return "WEEKLY";
      } else if (val == "QUARTERLY") {
        interval = 4;
        return "MONTHLY";
      }
      return val;
    };

    let reminders = item.reminders.map((reminder) => {
      let start = moment(new Date(reminder.reminderTime * 1000));
      let end = moment(new Date(item.dueDate));
      let duration = moment.duration(end.diff(start));
      let min = duration.asMinutes();
      // Valid reminder minutes values are between 0 and 40320 (4 weeks in minutes)
      const gReminder = {
        method: "popup",
        minutes: min > 40320 ? 40320 : min,
      };
      return gReminder;
    });

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    //helper function
    const prependZero = (val) => {
      let result = String(val).length > 1 ? val : `0${val}`;
      return result;
    };

    let endDate = new Date(item.dueDate);

    //date format to RFC2822
    let until_RFC2822 = "";
    until_RFC2822 += endDate.getFullYear();
    until_RFC2822 += prependZero(endDate.getMonth() + 1);
    until_RFC2822 += prependZero(endDate.getDate());
    until_RFC2822 += "T";
    until_RFC2822 += prependZero(endDate.getHours());
    until_RFC2822 += prependZero(endDate.getMinutes());
    until_RFC2822 += prependZero(endDate.getSeconds());
    until_RFC2822 += "Z";

    const payload = {
      attendees: item.userEmails,
      colorId: ColorId[item.tagId],
      end: {
        dateTime: endDate,
        timeZone,
      },
      recurrence: item.isReoccurringTask
        ? [
          `RRULE:FREQ=${reoccurringInterval(
            RecurrenceInterval[item.reoccurringInterval]
          )};${interval ? `INTERVAL=${interval};` : ""
          }UNTIL=${until_RFC2822};WKST=SU;BYDAY=MO,TU,WE,TH,FR`,
        ]
        : [],
      reminders: {
        overrides: reminders,
        useDefault: false,
      },
      start: {
        dateTime: new Date(),
        timeZone,
      },
      summary: item.name,
    };
    return payload;
  }

  createReminder(defaultValue): FormGroup {
    return this.fb.group({
      value: [defaultValue.value, Validators.required],
      interval: [defaultValue.interval, Validators.required],
    });
  }

  addReminder(defaultValue = { value: 1, interval: "Days before due" }): void {
    this.reminders = this.newTaskForm.get("reminders") as FormArray;
    this.reminders.push(this.createReminder(defaultValue));
  }

  removeReminder(index): void {
    this.reminders.removeAt(index);
  }

  removeMember(removedUser) {
    this.taskTeamMember.splice(
      this.taskTeamMember.indexOf(
        this.taskTeamMember.find((data) => data.id === removedUser.id)
      ),
      1
    );

    this.updateUser.emit(this.taskTeamMember);
  }

  fileAttachError(message) {
    this.sendErrorMessage(message);
  }

  public convertTime(timeStr: string): { hours: string; minutes: string } {
    const [time, modifier] = timeStr.split(" ");

    let [hours, minutes] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM" || modifier === "pm") {
      hours = (parseInt(hours, 10) + 12).toString();
    }
    return {
      hours: hours,
      minutes: minutes,
    };
  }

  ngOnDestroy(): void {
    this.clearErrorMessage();
  }
}
