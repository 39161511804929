import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { CurrentUserService } from "../services/factories/current-user.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  mytoken: string;
  authReq: any;

  constructor(
    private currentUserService: CurrentUserService,
    private router: Router,
    private injector: Injector
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.mytoken = this.currentUserService.getToken();

    // this.mytoken = this.currentUserService.getToken();

    if (req.url.indexOf("login") > 0) {
      const loginHeader = new HttpHeaders({
        "Content-Type": "application/json",
      });

      // 'Content-Type':
      // 'application/x-www-form-urlencoded',

      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': 'GET, POST, PUT, PATCH, OPTIONS'
      const requ = req.clone({ headers: loginHeader });
      return next.handle(requ);
    } else if (req.url.indexOf("doc-review") > 0) {
      return next.handle(req);
    }
    //  else if (req.url.indexOf("prime-api-staging") > 0) {
    //   const primsolHeader = new HttpHeaders({
    //     "X-API-KEY": "a70f4bd4-c1b7-4442-9a90-f35d02e14db2",
    //     "X-USER-TOKEN":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyNCwiZW1haWwiOiJiZW5qYW1pbi5heWFuZ2JvbGFAbGF3cGF2aWxpb24uY29tIiwidXNlcl90eXBlIjoicGx1Z2luIiwiaXNfYWRtaW4iOmZhbHNlLCJleHBpcmVzIjoiMjAyMS0wMS0xMCAxMzoyMTo0NyIsInN1Yl9leHAiOiIyMDIxLTEwLTE0IDAwOjAwOjAwIiwic3ViX3BsYW5faWQiOjI5LCJsb2dpbl9jb2RlIjo5MjMxNTMsImVhYyI6IiJ9.0afc3295b0fb3b07ae393c01de5e91391dc77b00009ef115fb2ee3f2dd098e88",
    //     "X-HTTP-REQUEST-SIGNATURE":"4KPNp7p22287nNG9rzoQhcbxgl017CiBDvtngemhyL2qu66O3W0qTf63SE85Z0mj",
    //     "X-HTTP-REQUEST-TIMESTAMP": new Date(),
    //   });
    //   const requss = req.clone({ headers: primsolHeader });
    //   return next.handle(requss);
    // }
    else {
      // //console.log(this.mytoken);
      const auth = "Bearer " + this.mytoken; // get the token from the cookie coming from the cookie service
      const authRequest = req.clone({
        headers: req.headers
          .set("Authorization", auth)
          .set("Cache-Control", "no-cache") // to solve IE issue with caching get Requests
          .set("Pragma", "no-cache"), // to solve IE issue with caching get Requests
      });
      return next.handle(authRequest).pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
            }
          },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.router.navigate(["logout"]);
              }
            }
          }
        )
      );
    }
  }
}
