import { Injectable } from '@angular/core';
import { Endpoints } from '../models/shared-model/endpoint';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../shared/helpers/app-config.service';
import { Observable } from 'rxjs';
import { MatterCategoryTypeParseModel } from '../models/parse-model/matter-category-type';
import { MatterCategorySubTypeParseModel } from '../models/parse-model/matter-category-sub-type';
import { GenericListViewModel } from '../models/generic-model.ts/generic-list';

@Injectable({
  providedIn: 'root'
})
export class MatterConfigService {

  Endpoints = new Endpoints(this.config);

  constructor(private httpClient: HttpClient,
    private config: AppConfigService) {
  }

  public postBriefCategoryType(data: MatterCategoryTypeParseModel ): Observable<GenericListViewModel []> {
    const url = this.config.getConfig().serverEndpoint + 'jj';
    return this.httpClient.post<GenericListViewModel []>(url, data);
  }

  public postBriefCategorySubType(data: MatterCategorySubTypeParseModel ): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + 'jj';
    return this.httpClient.post<any>(url, data);
  }

  public getBriefCategories(): Observable <GenericListViewModel []> {
    const url = this.config.getConfig().serverEndpoint + 'matter/category';
    return this.httpClient.get<GenericListViewModel []>(url);
  }

  public getBriefCategoryTypes(categoryId: number ): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `matter/${categoryId}/categorytype`;
    return this.httpClient.get<GenericListViewModel []>(url);
  }

  public getBriefCategorySubTypes(categoryTypeId: number ): Observable<GenericListViewModel []> {
    const url = this.config.getConfig().serverEndpoint + `matter/${categoryTypeId}/categorysubtype`;
    return this.httpClient.get<GenericListViewModel []>(url);
  }
}
