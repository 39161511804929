import { UserRoleModel } from "./../models/edit-model/user-role-model";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { Endpoints } from "../models/shared-model/endpoint";
import { FirmRole } from "../models/view-model/role-list";
import {
  PermissionGroup,
  PermissionGroupsData,
} from "../models/view-model/user-firm-role";

import { InvitedUsers } from "../models/view-model/user-list";
import { UserDetail } from "../models/view-model/user-detail";
import { InviteFirmUser } from "../models/parse-model/invite-firm-user";
import { EditFirmDetailsModel } from "../models/edit-model/edit-firm.model";
import { NewRoleParseModel } from "../models/parse-model/new-role";
import { EditRoleModel } from "../models/edit-model/edit-role";
import { FirmDetailViewModel } from "../models/view-model/firm-detail";
import { AppConfigService } from "../shared/helpers/app-config.service";
import { FileUploadModel } from "../models/generic-model.ts/file-upload";

import { Store, select } from "@ngrx/store";
import * as fromUserList from "../store/reducers/user-list.reducer";
import { CommonUserModel } from "../models/generic-model.ts/user-share";
import { AccountDTO } from "../models/view-model/user-account.model";
import { FirmStorageDetail } from "../models/view-model/user-firm-storage-model";

@Injectable({
  providedIn: "root",
})
export class UserManagementService {
  Endpoints = new Endpoints(this.config);
  user = new UserDetail();

  constructor(
    private httpClient: HttpClient,
    private store: Store<fromUserList.State>,
    private config: AppConfigService
  ) {
    this.store.pipe(select("auth")).subscribe((data) => {
      /// save token and user detail to local storage
      this.user = data.userProfile;
    });
  }

  public getUsersList(
    firmId: number,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex = 0,
    pageSize = 0,
    usePageSize = false
  ): Observable<HttpResponse<CommonUserModel[]>> {
    const endpoint =
      this.config.getConfig().serverEndpoint + `firm/${firmId}/user`;
    const url = `${endpoint}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${
      pageIndex + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;

    return this.httpClient.get<CommonUserModel[]>(url, { observe: "response" });
  }

  public getInvitedUsers(
    firmId: number,
    searchQuery: string,
    filter: string,
    sortDirection: string,
    pageIndex: number,
    pageSize: number
  ): Observable<any> {
    const usePageSize = true;
    const endpoint =
      this.config.getConfig().serverEndpoint + `firm/invite/${firmId}/user`;
    const url = `${endpoint}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${
      pageIndex + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: "response" });
  }

  public getRolesList(firmId: number): Observable<FirmRole[]> {
    const url = this.config.getConfig().serverEndpoint + `firm/role`;
    return this.httpClient.get<FirmRole[]>(url);
  }

  getPermissionGroups(): Observable<PermissionGroup[]> {
    // const url = this.config.getConfig().serverEndpoint + '';
    return of(PermissionGroupsData);
  }

  createRolePermission(data: NewRoleParseModel): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + this.Endpoints.newRole;
    return this.httpClient.post<any>(url, data);
  }

  updateRolePermission(data: EditRoleModel): Observable<EditRoleModel> {
    // reshape to data expected by the API

    const _firmRolePermissions: any[] = [];

    data.firmRolePermissions.forEach((permission) => {
      if (permission.permissionsActionId.length > 0) {
        permission.permissionsActionId.forEach((actionId) => {
          _firmRolePermissions.push({
            actionId: actionId,
            permissionEntityId: permission.permissionEntityId,
          });
        });
      }
    });

    data.firmRolePermissions = _firmRolePermissions;
    const url = this.config.getConfig().serverEndpoint + `firm/permission/role`;
    return this.httpClient.put<EditRoleModel>(url, data);
  }

  getRole(firmId, roleId): Observable<FirmRole> {
    const url = this.config.getConfig().serverEndpoint + `firm/${roleId}/role`;
    return this.httpClient.get<FirmRole>(url);
  }

  changePasssword(data): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.changePasswordEndpoint;
    return this.httpClient.post<any>(url, data);
  }

  editUserDetail(data: UserDetail) {
    const url = this.config.getConfig().serverEndpoint + "firm/firmuser";
    return this.httpClient.put<boolean>(url, data);
  }

  updateHourlyRate(data: InvitedUsers, hourlyRate: number) {
    const url =
      this.config.getConfig().serverEndpoint + "firm/updatehourlyrate";
    return this.httpClient.put<boolean>(url, {
      userId: data.id,
      rate: hourlyRate,
    });
  }

  resendUserInvite(data: InvitedUsers, firmId: number) {
    const url =
      this.config.getConfig().serverEndpoint + "firm/resend-invite/user";

    // tslint:disable-next-line: max-line-length
    return this.httpClient.put<boolean>(url, {
      hourlyRate: data.hourlyRate,
      firmRoleId: data.firmRoleId,
      firmName: data.firmName,
      inviteeEmail: data.inviteeEmail,
      firmId: firmId,
      id: data.id,
    });
  }

  getUserInvite(userInviteId: number): Observable<InvitedUsers> {
    const url =
      this.config.getConfig().serverEndpoint + "firm/invite/" + userInviteId;
    return this.httpClient.get<InvitedUsers>(url);
  }

  getUserProfile(firmId: number, userId: number) {
    const url =
      this.config.getConfig().serverEndpoint + `firm/${firmId}/user/${userId}`;
    return this.httpClient.get<any>(url);
  }

  getProfilePicture(userId) {
    const url =
      this.config.getConfig().serverEndpoint + `firm/userpicture/${userId}`;
    return this.httpClient.get<string>(url);
  }

  getFirmAccounts() {
    const url = this.config.getConfig().serverEndpoint + `firm/Bank-Account`;
    return this.httpClient.get<any>(url);
  }

  /**
   *
   * @param userAccount this model Contains Id, Name and Description
   */
  public createFirmAccount(firmAccount: AccountDTO): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + this.Endpoints.newFirmAccount;
    //console.log(url);
    return this.httpClient.post<any>(url, firmAccount);
  }

  public updateFirmAccount(firmAccount: AccountDTO): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + this.Endpoints.newFirmAccount;
    //console.log(url);
    return this.httpClient.put<any>(url, firmAccount);
  }

  public deleteAccount(id: number): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `firm/Bank-Account/${id}`;
    return this.httpClient.delete<any>(url);
  }

  updateProfilePicture(data: FileUploadModel) {
    const url = this.config.getConfig().serverEndpoint + `firm/userpicture`;
    const param: FileUploadModel = { ...data };
    param.fileBinary = (<string>param.fileBinary).split(",")[1];
    return this.httpClient.put<boolean>(url, param);
  }

  inviteFirmUser(user: InviteFirmUser): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + this.Endpoints.inviteEndpoint;
    return this.httpClient.post<any>(url, user);
  }

  updateInvite(user: InviteFirmUser, inviteId: number): Observable<any> {
    user.id = inviteId;
    const url =
      this.config.getConfig().serverEndpoint + `firm/update-invite/user`;
    return this.httpClient.put<any>(url, user);
  }

  disableUser(userId: number): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + this.Endpoints.disableUser;
    return this.httpClient.patch<any>(url, { id: userId });
  }

  deleteUser(userId: number): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `firm/${userId}/user`;
    return this.httpClient.delete<any>(url);
  }

  deleteRole(firmroleId: number): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `firm/${firmroleId}/role`;
    return this.httpClient.delete<any>(url);
  }

  deleteUserInvite(email: string): Observable<boolean> {
    const url =
      this.config.getConfig().serverEndpoint +
      `firm/cancel-invite/${this.user.firmId}/${email}/user`;
    return this.httpClient.delete<boolean>(url);
  }

  enableUser(userId: number): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + this.Endpoints.enableUser;
    return this.httpClient.patch<any>(url, { id: userId });
  }

  editFirmDetails(
    firm: EditFirmDetailsModel
  ): Observable<EditFirmDetailsModel> {
    const api =
      this.config.getConfig().serverEndpoint + this.Endpoints.updateFirmDetails;
    return this.httpClient.put<EditFirmDetailsModel>(api, firm);
  }
  getFirmDetails(id: number): Observable<FirmDetailViewModel> {
    const api =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.getfirmDetails +
      "/" +
      id;
    return this.httpClient.get<FirmDetailViewModel>(api);
  }

  updateUserRole(data: UserRoleModel) {
    const url = this.config.getConfig().serverEndpoint + "firm/Roleupdate";

    return this.httpClient.put<boolean>(url, data);
  }

  getFirmStorage(firmId: number): Observable<FirmStorageDetail> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("firmId", firmId.toString());
    const api = this.config.getConfig().serverEndpoint + "firm/storage-details"
    return this.httpClient.get<FirmStorageDetail>(api, { params: queryParams });
  }
}
