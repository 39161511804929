import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { UserDetail } from "../../../models/view-model/user-detail";
import { FormGroup, FormBuilder, Validators, FormControl, NgForm, FormGroupDirective } from "@angular/forms";
import { Token } from "../../../models/generic-model.ts/token";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import * as LoginActions from "../../state/login.actions";
import { ErrorStateMatcher } from "@angular/material/core";
import { ActivatedRoute } from "@angular/router";

import * as fromStore from "../../state/index";
import { isNullOrUndefined } from "util";
import { CurrentUserService } from "../../../services/factories/current-user.service";
import { UserLoginDetail } from "src/app/models/parse-model/login";



// What on earth does this guy do in fact?
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  user: UserDetail;
  responseData: Token;
  // token: any;
  userProfile: UserDetail;
  userToken$: Observable<any>;
  loginBtnSpinner: boolean;
  pageTitle = "Sign In | LP Case Manager";
  isLoginError: boolean;
  emailVal: string;
  errorMessage: any;
  alreadyLoogedIn: boolean;

  @ViewChild("errorBox") errorBox: ElementRef;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private currentUserService: CurrentUserService,
    private store: Store<fromStore.State>,
    private route: ActivatedRoute
  ) {
    this.emailVal = this.route.snapshot.paramMap.get("email");
    this.createForm();
    // this.store.select()
    this.store.select(("auth")).subscribe((data) => {
      // console.log(data);
      if (data.isLogging) {
        this.loginBtnSpinner = true;
      }
      // console.log(data);

      if (!isNullOrUndefined(data.errorMsg)) {
        this.isLoginError = true;

        this.loginBtnSpinner = false;
        this.errorMessage = data.errorMsg.error;

        if (data.errorMsg.status === 404) {
          this.errorMessage = `<strong>The email entered/password is incorrect, please contact your admin`;
        } else if (data.errorMsg.status === 400) {
          this.errorMessage = `<strong>The email entered/password is incorrect, please contact your admin`;
        } else if (data.errorMsg.status === 501) {
          this.errorMessage = `<strong>This is embarrassing!!! , an error occurred on our server, try to refresh and clear your browser data, or send us an email if it persist</strong>`;
        } else if (data.errorMsg.status === 409) {
          this.alreadyLoogedIn = true;
        } else {
          this.errorMessage = `<strong>An unknown error occured !!! Please check your internet connection</strong>`;
        }
      } else if (data.loggedIn) {
        // console.log(data);
        /// save token and user detail to local storage
        let userData = new UserDetail();
        userData = data.userProfile;
        this.currentUserService.addToken(
          data.authToken,
          data.accountPermissions
        );
        this.currentUserService.addUserProfile(userData);
        this.currentUserService.addEmail(data.email);
        this.currentUserService.addRole(data.role);
        this.currentUserService.addSubscriptionToOkm(data.isUserFirmSubscribedToOKM);
      } else {
        this.errorMessage = `<strong>An unknown error occured !!! Please check your internet connection</strong>`;
      }
    });
  }

  ngOnInit(): void {
    this.isLoginError = false;
    this.loginBtnSpinner = false;
    this.alreadyLoogedIn = false;
  }

  forceLogout() {
    this.router.navigate(["/auth/logout", this.email.value]);
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: [
        this.emailVal,
        [
          Validators.required,
          Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
          Validators.maxLength(255),
        ],
      ],
      password: ["", Validators.required],
    });
  }

  get email() {
    return this.loginForm.get("email");
  }

  get password() {
    return this.loginForm.get("password");
  }

  login() {
    this.loginBtnSpinner = true;
    let userLoginDetail: UserLoginDetail;
    userLoginDetail = this.loginForm.value;
    const email: string = this.loginForm.value.email.toLowerCase();
    const password: string = this.loginForm.value.password;
    const isMobile: boolean = false
    this.store.dispatch(new LoginActions.Login({ email, password, isMobile }));
    if (this.isLoginError) {
      this.errorBox.nativeElement.focus();
    }
  }

  LoginEnter() {
    if (this.email.value !== "" && this.password.value !== "") {
      this.login();
    }
  }
}
