import * as fromUserHeaderStore from '../../store/reducers/user-header.reducer';
import { Store, select } from '@ngrx/store';
import * as headertActions from '../../store/actions/user-header.actions';
import { StatusViewModel } from 'src/app/models/view-model/connection-view-model';
import { isNullOrUndefined } from 'util';
import { Subscription, TeardownLogic } from 'rxjs';

export class Notifier {
  constructor(protected headerStore: Store<fromUserHeaderStore.HeaderState>
  ) {


    this.showLoader = true;
    this.showNoResult = false;

    this.headerStore.pipe(select(fromUserHeaderStore.getNotificationMessages))
      .subscribe(data => {
        this.notificationMessages = data;

        // this.timerId = setTimeout(() => {
        //   if (!(this.notificationMessages.length < 1)) {
        //     this.clearAllMessages()
        //     console.log("I'm running now")
        //   }

        //   if (this.notificationMessages.length < 1) {
        //     if (this.timerId) {
        //       clearTimeout(this.timerId);
        //       console.log("This is the last time I will run");
        //       this.timerId = 0;
        //     }
        //   }
        //   console.log("Running really")
        // }, 5000)
      });

    this.headerStore.pipe(select(fromUserHeaderStore.getConnectionState))
      .subscribe(data => {
        if (!isNullOrUndefined(data)) {
          this.connectionState = data;
        }
      });

    this.headerStore.pipe(select(fromUserHeaderStore.getErrorState))
      .subscribe(data => {
        if (!isNullOrUndefined(data)) {
          this.errorState = data;
        }
      });
  }

  notificationMessages: string[] = [];
  notificationCount: number;
  connectionState = new StatusViewModel();
  errorState = new StatusViewModel();
  timerId: any = 0;

  showLoader: boolean;
  showNoResult: boolean;

  public subscription = new Subscription();
  public loading = true;
  public isEmptyData = false;

  pushMessage(successHtmlString: string) {
    this.headerStore.dispatch(new headertActions.PushNotification(successHtmlString));
    this.dismissNotifToast();
  }

  dismissNotifToast() {
    this.timerId = setTimeout(() => {
      if (!(this.notificationMessages.length < 1)) {
        this.removeMessage();
      }

      if (this.notificationMessages.length < 1) {
        if (this.timerId) {
          clearTimeout(this.timerId);
          this.timerId = 0;
        }
      }
    }, 5000)
  }

  removeMessage() {
    this.notificationMessages.pop();
  }

  sendErrorMessage(errorMsg: string) {
    this.headerStore.dispatch(new headertActions.SendError(errorMsg));
    this.dismissNotifToast();
  }

  clearErrorMessage() {
    this.errorState.status = false;
  }

  setInternetAvailable(message: string) {
    this.headerStore.dispatch(new headertActions.SetInternetAvailable(message));
  }

  setInternetNotAvailable(message: string) {
    this.headerStore.dispatch(new headertActions.SetInternetAvailable(message));
  }

  clearInternetAvailabilityState() {
    this.connectionState.status = false;
    this.connectionState.text = '';
  }

  clearAllMessages() {
    this.headerStore.dispatch(new headertActions.ClearNotifications());
  }

  clearInternetAvailableState(errorMsg: string) {
    this.headerStore.dispatch(new headertActions.SetInternetAvailable(errorMsg));
  }
  addSubscription(logic: TeardownLogic): void {
    this.subscription.add(logic);
  }
  clearSubscription(): void {
    this.subscription.unsubscribe();
  }

}
