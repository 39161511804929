import { DocumentList } from "../view-model/document-list";

export class FileUploadModel {
  fileName: string;
  fileType?: string;
  /// <summary>
  /// This is a Base64String value.
  /// </summary>
  fileBinary: string | ArrayBuffer;
  /// <summary>
  /// The file extension e.g .jpg, .jpeg, .mp4
  /// </summary>
  fileExtension?: string;
  fileSize?: number;
}

export class FileSaveUploadModel {
  resourceDirectoryId: number;
  resourceId: number;
  fileModel: FileUploadModel[];
}

export interface AttachedFileModel {
  showError: boolean;
  errorText: string;
  currentDocument: FileUploadModel[];
  fileProgressArray?: number[];
}

export class DriveFileUploadModel {
  fileName: string;
  fileType: string;
  fileExtension: string;
  fileBinary: string;
  driveFileId: string;

  constructor(driveFile: DocumentList) {
    this.fileName = driveFile.itemName;
    this.fileType = driveFile.format;
    this.fileExtension = driveFile.format;
    this.driveFileId = driveFile.id;
  }
}
