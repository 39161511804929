import {
  Component,
  NgZone,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy,
} from "@angular/core";

import * as fromEmailClientStore from "./store/reducers/email-client.reducer";
import { Store } from "@ngrx/store";
import * as emailClientActions from "./store/actions/email-client.actions";
import {
  Router,
  NavigationStart,
  RouterEvent,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from "@angular/router";
import { Observable, of, Subscription, fromEvent } from "rxjs";

import * as fromUserHeaderStore from "./store/reducers/user-header.reducer";
import { Notifier } from "src/app/shared/base-class/notifier";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent extends Notifier implements OnInit, OnDestroy {
  pageTitle = "LawPavilion Case Manager";
  public position: string = "";
  public visible: boolean = false;
  showpageLoader$: Observable<boolean> = of(true);

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;

  subscriptions: Subscription[] = [];

  connectionStatusMessage: string;
  connectionStatus: string;

  @ViewChild("spinnerElement")
  spinnerElement: ElementRef;
  isOnline$: Observable<boolean>;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private emailClientStore: Store<fromEmailClientStore.State>,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>
  ) {
    super(headerStore);
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
    this.matIconRegistry.addSvgIcon(
      `new_open_folder`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/img/icons/open_folder.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `new_rename_folder`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/img/icons/rename_folder.svg`)
    );
  }

  public setVisibility(position: string) {
    this.position = position;
    this.visible = true;

    localStorage.setItem("drawer", "1");
    localStorage.setItem("position", position);
  }
  public toggleVisibility(position: string) {
    this.position = position;
    this.visible = !this.visible;

    const drawer = this.visible ? "1" : "0";
    localStorage.setItem("drawer", drawer);
    localStorage.setItem("position", position);
  }
  public onDrawerHide(): void {
    localStorage.setItem("drawer", "0");
  }

  clearRightClicks() {
    this.emailClientStore.dispatch(
      new emailClientActions.HideEmailAccountMenu()
    );
  }

  // Shows and hides the loading spinner during RouterEvent changes
  private navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      // We wanna run this function outside of Angular's zone to
      // bypass change detection
      this.ngZone.runOutsideAngular(() => {
        // For simplicity we are going to turn opacity on / off
        // you could add/remove a class for more advanced styling
        // and enter/leave animation of the spinner
        // this.renderer.setStyle(
        //   this.spinnerElement.nativeElement,
        //   'opacity',
        //   '1'
        // );
        this.showpageLoader$ = of(true);
      });
    }
    if (event instanceof NavigationEnd) {
      this._hideSpinner();
      // setTimeout(() => this._hideSpinner(), 2000);
    }
    // Set loading state to false in both of the below requestEvents to
    // hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this._hideSpinner();
    }
    if (event instanceof NavigationError) {
      this._hideSpinner();
    }
  }

  private _hideSpinner(): void {
    // We wanna run this function outside of Angular's zone to
    // bypass change detection,
    this.ngZone.runOutsideAngular(() => {
      // For simplicity we are going to turn opacity on / off
      // you could add/remove a class for more advanced styling
      // and enter/leave animation of the spinner
      this.showpageLoader$ = of(false);
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem("drawer") === "1") {
      this.visible = true;
      this.position = localStorage.getItem("position")
        ? localStorage.getItem("position")
        : "";
    } else if (localStorage.getItem("drawer") === "0") {
      this.visible = false;
    }
    this.onlineEvent = fromEvent(window, "online");
    this.offlineEvent = fromEvent(window, "offline");

    this.subscriptions.push(
      this.onlineEvent.subscribe((e) => {
        this.connectionStatusMessage = "You are Back online";
        this.connectionStatus = "online";
        this.setInternetAvailable(this.connectionStatusMessage);
      })
    );

    this.subscriptions.push(
      this.offlineEvent.subscribe((e) => {
        this.connectionStatusMessage =
          "Connection lost! You are not connected to internet";
        this.connectionStatus = "offline";
        this.setInternetNotAvailable(this.connectionStatusMessage);
      })
    );
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all subscriptions to avoid memory leak
     */
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
