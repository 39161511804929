import { CommonUserModel } from "src/app/models/generic-model.ts/user-share";

// tslint:disable-next-line: class-name
export interface Endorsement {
  proceedingId: number;
  hearingDate: string;
  nextHearingDate: string[];
  nextHearingDates: string[];
  nextAction: string;
  nextStage: string;
  summary: string;
  matterId: number;
  commencementDate: string;
  lastHearingDate: string;
  dateCreated: string;
  tag: string;
  tagId: number;
  author?: string;
  isIndefinate: boolean;
  users?: CommonUserModel[];
  reviewersList?: CommonUserModel[];
  isReviewed?: boolean;
  reviewerId?: number;
}

export class ProceedingViewModel {
  proceedingId: number;
  hearingDate: string;
  nextHearingDate: string[];
  nextHearingDates: { id: number; date: string }[];
  nextAction: string;
  nextStage: string;
  summary: string;
  matterId: number;
  commencementDate: string;
  lastHearingDate: string;
  dateCreated: string;
  tagId: number;
  tag: string;
  author: string;
  isIndefinate: boolean;
  users?: CommonUserModel[];
  reviewersList?: CommonUserModel[];
  isReviewed?: boolean;
  reviewerId?: number;
}
