import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Endpoints } from "../models/shared-model/endpoint";
import { CalendarReminderViewModel } from "../models/view-model/calendar-event";
import { AppConfigService } from "../shared/helpers/app-config.service";

@Injectable({
  providedIn: "root",
})
export class GoogleCalendarService {
  Endpoints = new Endpoints(this.config);
  public gCalendarEvents: Subject<any> = new BehaviorSubject<any>([]);
  public gCalId: Subject<string> = new BehaviorSubject<string>("");

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) { }

  addGCalendarEvents(data: any) {
    this.gCalendarEvents.next(data);
  }

  addGCalId(data: any) {
    this.gCalId.next(data);
  }

  gCalendarAddAccount(payload: any): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `CalendarAuth/AddAccount`;
    return this.httpClient.post<any>(endpoint, payload);
  }

  gCalendarLogin(payload: any): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `CalendarAuth/Login`;
    return this.httpClient.get<any>(endpoint);
  }

  getAccounts(): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `CalendarAuth/Accounts`;
    return this.httpClient.get<any>(endpoint);
  }

  deleteCalendarAccount(id: any): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `CalendarAuth/Delete-Account/${id}`;
    return this.httpClient.delete<any>(endpoint, id);
  }



  calendarList(): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Calendar/ListCalendars`;
    return this.httpClient.get<any>(endpoint);
  }

  createEvent(payload: any, calId: string): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Calendar/InsertEvent?calId=${calId}`;
    return this.httpClient.post<any>(endpoint, payload);
  }

  creategCalReminder(payload: CalendarReminderViewModel): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `task/singlereminder/syncgooglecal`;
    return this.httpClient.post<any>(endpoint, payload);
  }

  creategCallEvent(payload: any): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `task/task/syncgooglecal`;
    return this.httpClient.post<any>(endpoint, payload);
  }

  deleteEvent(calId: string, evId: string): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Calendar/DeleteEvent?calId=${calId}&evId=${evId}`;
    return this.httpClient.post<any>(endpoint, {});
  }

  updateEvent(payload: any, calId: string, evId: string): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Calendar/UpdateEvent?calId=${calId}&evId=${evId}`;
    return this.httpClient.post<any>(endpoint, payload);
  }

  updategCalEvent(payload: any): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `task/updatetask/syncgooglecal`;
    return this.httpClient.put<any>(endpoint, payload);
  }

  userGCalendarEvents(calId: string): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `Calendar/ListEvents?calId=${calId}`;
    return this.httpClient.get<any>(endpoint);
  }
}
