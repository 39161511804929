import { AuthState } from './login.reducer';
import { ConnectionState } from '../../store/reducers/internet-connection.reducer';
import * as AppState from '../../store/app.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HeaderState } from '../../store/reducers/user-header.reducer';
import { CalendarState } from '../../store/reducers/calendar-lander.reducer';
import { isNullOrUndefined } from 'util';

// this can hold all reducer selectors even from lazy loaded modules

// if its not lazy loaded
// dont add reducer state here if its lazy loaded
export interface State extends AppState.State {
  auth: AuthState;
  // user: any;
  // returnUrl: string;
  connectionState: ConnectionState;
  // calendar: CalendarState;
  // header: HeaderState;
}

const getUserAuthDetailFeatureState = createFeatureSelector<AuthState>('auth');

export const getAccountPermissions = createSelector(
  getUserAuthDetailFeatureState,
  state => state.accountPermissions
);

export const getAuthToken = createSelector(
  getUserAuthDetailFeatureState,
  state => state.authToken
);

export const getFirmLogo = createSelector(
  getUserAuthDetailFeatureState,
  state => state.currentFirmLogo
);

export const getFirmSignature = createSelector(
  getUserAuthDetailFeatureState,
  state => state.currentFirmSignature
);

export const getUserId = createSelector(
  getUserAuthDetailFeatureState,
  state => state.currentUserId
);

export const getUserEmail = createSelector(
  getUserAuthDetailFeatureState,
  state => state.email
);

export const getErrorMsg = createSelector(
  getUserAuthDetailFeatureState,
  state => state.errorMsg
);

export const getProfileImageUploadError = createSelector(
  getUserAuthDetailFeatureState,
  state => state.errorInUpload
);

export const getFirmUpdateError = createSelector(
  getUserAuthDetailFeatureState,
  state => state.error
);

export const getFirmDetail = createSelector(
  getUserAuthDetailFeatureState,
  state => state.firmDetail
);






export const getUserDetail = createSelector(
  getUserAuthDetailFeatureState,
  state => state.userProfile
);

export const getUserSubscriptionToOkm = createSelector(
  getUserAuthDetailFeatureState,
  state => state.isUserFirmSubscribedToOKM
);

export const getReturnUrl = createSelector(
  getUserAuthDetailFeatureState,
  state => state.returnUrl
);


export const getUserRole = createSelector(
  getUserAuthDetailFeatureState,
  state => state.role
);

export const getCurrentProfileImage = createSelector(
  getUserAuthDetailFeatureState,
  state => state.currentProfilePicture
);

export const getFirmIsUpdated = createSelector(
  getUserAuthDetailFeatureState,
  state => state.isUpdated
);



export const getLoggedIn = createSelector(
  getUserAuthDetailFeatureState,
  state => state.loggedIn
);

// const getUserHeaderFeatureState = createFeatureSelector<HeaderState>('user-header');

// export const getTimerState = createSelector(
//   getUserHeaderFeatureState,
//   state => state.isShowTimer
// );


// const getInternetConnectionState = createFeatureSelector<ConnectionState>('conntecton-state');

// export const getConnectionState = createSelector(
//   getInternetConnectionState,
// );
