import { UserTask } from "../../models/view-model/user-tasks";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromTask from "../../store/reducers/task.reducer";
import * as TaskActions from "../../store/actions/task.action";
import { isNullOrUndefined } from "util";
import { TaskService } from "src/app/services/task.service";

@Component({
  selector: "app-task-tag-min",
  templateUrl: "./task-tag-min.component.html",
  styleUrls: ["./task-tag-min.component.scss"],
})
export class TaskTagMinComponent implements OnInit {
  confirmType: string;
  confirmTitle: string;
  confirmMessage: string;
  confirmAction: string;
  confirmElementData: any;

  todayDate: number;
  @Input() task: UserTask;
  @Output() currentTask = new EventEmitter();
  @Output() myShowTask = new EventEmitter();
  @Output() closeTask = new EventEmitter();
  @Output() closeDashboardTask = new EventEmitter();
  showCurrentTask: boolean;
  checkSpinner: boolean;

  constructor(
    private taskStore: Store<fromTask.State>,
    private taskService: TaskService
  ) {
    this.todayDate = new Date().getTime();
  }

  ngOnInit() {
    if (!isNullOrUndefined(this.task)) {
      this.taskService.getTaskDetail(this.task.id).subscribe((res) => {
        this.task = res;
        this.confirmElementData = res;
      });
    }
  }

  setCurrentTask(file) {
    // this.currentTask.emit(file)
    this.taskStore.dispatch(new TaskActions.SetCurrentTask(file));
    this.taskStore.dispatch(new TaskActions.ViewTask(true));
    this.showCurrentTask = true;
    this.currentTask.emit(file);
    this.myShowTask.emit(file);
  }

  confirmResponse(data: any) {
    if (data.type === "deleteTask") {
      // if(this.task.taskUsersCount > 0){
      //   let text = this.task.taskUsersCount == 1? `there is one other team member attached to this task, remove this individual before deleting`:
      //   `there are ${this.task.taskUsersCount} team members attached to this task , remove them before deleting`
      //   alert(text);
      // }
      // else{
      this.taskService
        .deleteTask(data.elementData.id)
        .toPromise()
        .then((res) => {
          if (res) {
            this.currentTask.emit(data.elementData.id);
            this.closeDialog();
          }
        })
        .catch((err) => {
          //console.log(err);
        });
      //}
    }
  }

  closeDialog() {
    this.closeTask.emit("true");
    this.closeDashboardTask.emit("true");
  }

  onChange(task: UserTask) {
    this.checkSpinner = true;
    this.taskStore.dispatch(new TaskActions.CheckTask(task));
    // the service to update the task to complete should be written here
  }
}
