import { SimpleChanges } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import * as html2pdf from "html2pdf.js";
import { ContractService } from 'src/app/contract/services/contract.service';
import { Contract } from '../../contract/models/contract';

@Component({
  selector: 'app-contract-view-modal',
  templateUrl: './contract-view-modal.component.html',
  styleUrls: ['./contract-view-modal.component.scss']
})
export class ContractViewModalComponent implements OnInit {
  downloading = false;
  @Input() details: Contract;
  @Input() showModal: string = 'FIRST';

  constructor(private contractService: ContractService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.details) {
      this.details = changes.details.currentValue;
    }
  }

  showSecondModal(): void {
    this.showModal = 'SECOND';
  }

  dismissModal(): void {
    this.showModal = 'FIRST';
  }

  getContractReport(): void {
    this.downloading = true;
    window.scrollTo(0, 0);

    const options = {
      filename: `Report for ${this.details.title}`,
      margin: [0, 0],
      image: { type: "png" },
      html2canvas: {
        quality: 1,
        scale: 1,
      },
      jsPDF: {
        orientation: "landscape",
      },
    };

    const content: Element = document.getElementById("full_view");

    html2pdf().from(content).set(options).save();
    setTimeout(() => this.downloading = false, 2000);
  }

  downloadFile(fileId: number) {
    this.contractService.getContractFile(fileId).subscribe(data => {
      if (data.serverFileName) {
        window.open(data.serverFileName, "_blank");
      } else alert("error downloading file");
    });
  }

}