import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatterApplicationService } from "../../services/matter-application.service";

@Component({
  selector: "app-application-status",
  templateUrl: "./application-status.component.html",
  styleUrls: ["./application-status.component.scss"],
})
export class ApplicationStatusComponent implements OnInit {
  applicationStatusForm: FormGroup;
  btnSpinner = false;
  @ViewChild("closeModal") closeModal: ElementRef;
  @Output() emitOutPut = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private matterApplicationService: MatterApplicationService
  ) {
    this.applicationStatusForm = this.fb.group({
      name: ["", Validators.required],
      description: [""],
    });
  }

  get getApplicationStatusName() {
    return this.applicationStatusForm.get("name");
  }

  get getApplicationStatusDescription() {
    return this.applicationStatusForm.get("description");
  }

  ngOnInit() {}

  saveApplicationStatus() {
    this.btnSpinner = true;
    const payload = this.applicationStatusForm.value;
    this.matterApplicationService.addNewApplicationStatus(payload).subscribe(
      (res) => {
        this.applicationStatusForm.reset();
        this.btnSpinner = false;
        this.emitOutPut.emit({
          isSuccessful: true,
          message: `${payload.name} was successfully added to application status`,
        });

        this.closeModal.nativeElement.click();
      },
      (err) => {
        this.btnSpinner = false;
        this.emitOutPut.emit({ isSuccessful: false, message: err.error });
        this.closeModal.nativeElement.click();
      }
    );
  }
}
