export interface AIDocResponse {
  data: AIDoc;
}

export class AIDoc {
  laws: Law[];
  cases: Case[];
  similar_docs: Doc[];
  similar_org_docs?: OrgDoc[];
  constructor(laws: Law) {}
}

export const initialAIDoc = {
  laws: [],
  cases: [],
  similar_docs: [],
  similar_org_docs: [],
};

export interface Law {
  cited_law: string;
  entity_type: string;
}

export interface Case {
  case_title: string;
  entity_type: string;
  caseId: string;
  sessionId: string;
}

export interface Doc {
  suit_number: string;
  subject_matter: string;
  case_title: string;
  full_judgement: string;
  mutual_cited_cases: string;
  court: string;
  percentage: number;
}

export interface OrgDoc {
  document_content: string;
  document_title: string;
  file_name: string;
  percentage: number;
}
