import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LicensePaymentComponent } from '../shared/license-payment/license-payment.component';
import { HomeGuard } from './guards/home-guard.service';
import { AuthGuard } from '../core/auth-guard.service';

const routes: Routes = [ ];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: [],
  providers: [AuthGuard]
})
export class SharedRoutingModule { }
