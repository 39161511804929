import { AppConfigService } from "./../shared/helpers/app-config.service";
import { Endpoints } from "./../models/shared-model/endpoint";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { UserTask } from "../models/view-model/user-tasks";
import { HttpClient } from "@angular/common/http";
import { TaskParseModel } from "../models/shared-model/newTask";
import { UserDetail } from "../models/view-model/user-detail";
import * as fromStore from "../auth/state/index";
import { Store, select } from "@ngrx/store";
import { AddReviewer } from "../models/task-model/task-model";
import { FeatureTaskEnum } from "../models/generic-model.ts/tag-option";

@Injectable({
  providedIn: "root",
})
export class TaskService {
  Endpoints = new Endpoints(this.config);
  userData = new UserDetail();

  constructor( private httpClient: HttpClient, private config: AppConfigService, private store: Store<fromStore.State>) {
    this.store.pipe(select("auth")).subscribe((data) => {
      this.userData = data.userProfile;
    });
  }

  public taskCheckSuccessful(taskId): Observable<boolean> {
    return of(true);
  }

  public loadMatterUsertasks(matterId: number, searchQuery = "", filter = "", sortDirection = "", pageIndex: number, pageSize: number): Observable<any> {
    const usePageSize = true;
    const api = this.config.getConfig().serverEndpoint + `task/matter/${matterId}`;
    const url = `${api}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${pageIndex + 1}&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: "response" });
  }

  public getFirmTask( searchQuery = "", filter = "", sortDirection = "", pageIndex: number, pageSize: number ): Observable<any> {
    const api = this.config.getConfig().serverEndpoint + `/task/firmtasks`;
    const usePageSize = true;
    const url = `${api}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${pageIndex + 1}&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: "response" });
  }
  public checkTask(obj: UserTask): Observable<boolean> {
    const url = this.config.getConfig().serverEndpoint + `/task/completetask`;
    return this.httpClient.put<boolean>(url, obj);
  }

  public addNewTask(model: TaskParseModel) {
    let url = "";

    if (model.featureId == FeatureTaskEnum.Me || model.featureId == FeatureTaskEnum.Others) {
      url = this.config.getConfig().serverEndpoint + `task`;
    } else if (model.featureId == FeatureTaskEnum.Matter) {
      url = this.config.getConfig().serverEndpoint + `task/matter`;
    } else if (model.featureId == FeatureTaskEnum.Endorsement) {
      url = this.config.getConfig().serverEndpoint + `task/proceeding`;
    } else if (model.featureId == FeatureTaskEnum.Application) {
      url = this.config.getConfig().serverEndpoint + `task/application`;
    }

    return this.httpClient.post<number>(url, model);
  }

  public updateTask(model: TaskParseModel) {
    let url = this.config.getConfig().serverEndpoint + `task`;
    return this.httpClient.put<boolean>(url, model);
  }

  public addReviewer(model: AddReviewer) {
    let url = this.config.getConfig().serverEndpoint + `task/AddReviewer`;
    return this.httpClient.put<boolean>(url, model);
  }

  public rateTask(model: AddReviewer) {
    let url = this.config.getConfig().serverEndpoint + `task/Reviewer/Rating`;
    return this.httpClient.put<boolean>(url, model);
  }

  public assignTaskToUser(model: any[]) {
    let url = "";
    url = this.config.getConfig().serverEndpoint + `task/user`;
    return this.httpClient.post<any>(url, model);
  }

  public addTaskFiles(model) {
    const url = this.config.getConfig().serverEndpoint + `task/taskfiles`;
    return this.httpClient.post<any>(url, model);
  }

  public getTaskDetail(taskId): Observable<UserTask> {
    const url = this.config.getConfig().serverEndpoint + `task/${taskId}`;
    return this.httpClient.get<UserTask>(url);
  }

  public getTaskFiles(taskId): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + `file/GetFilesByTask/${taskId}`;
    return this.httpClient.get<any[]>(url);
  }

  public getAllTask( page?: number, pageSize?: number, searchQuery = "", usePageSize: boolean = true): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `task/firmtasks`;
    const url = `${endpoint}?searchQuery=${searchQuery}&usePageSize=${usePageSize}&page=${page + 1}&pageSize=${pageSize}`;
    return this.httpClient.get<any>(url, { observe: "response" });
  }

  public getDueTask( page?: number, pageSize?: number, searchQuery = "", usePageSize: boolean = true): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `task/firmtasks/due`;
    const url = `${endpoint}?searchQuery=${searchQuery}&usePageSize=${usePageSize}&page=${page + 1}&pageSize=${pageSize}`;
    return this.httpClient.get<any>(url, { observe: "response" });
  }

  public getUpcomingTask( page?: number, pageSize?: number, searchQuery = "", usePageSize: boolean = true ): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `task/firmtasks/upcoming`;
    const url = `${endpoint}?searchQuery=${searchQuery}&usePageSize=${usePageSize}&page=${page + 1}&pageSize=${pageSize}`;
    return this.httpClient.get<any>(url, { observe: "response" });
  }

  public getCompletedTask( page?: number, pageSize?: number, searchQuery = "", usePageSize: boolean = true ): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `task/firmtasks/completed`;
    const url = `${endpoint}?searchQuery=${searchQuery}&usePageSize=${usePageSize}&page=${page + 1}&pageSize=${pageSize}`;
    return this.httpClient.get<any>(url, { observe: "response" });
  }

  public getTaskForReview( page?: number, pageSize?: number, searchQuery = "", usePageSize: boolean = true ): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint + `task/firmtasks/review`;
    const url = `${endpoint}?searchQuery=${searchQuery}&usePageSize=${usePageSize}&page=${page + 1}&pageSize=${pageSize}`;
    return this.httpClient.get<any>(url, { observe: "response" });
  }

  public deleteTask(id: number): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "task/" + id;
    return this.httpClient.delete(url);
  }

  removeUserTask(taskId: number, userId: number): Observable<boolean> {
    const url = this.config.getConfig().serverEndpoint+"task/"+taskId+"/user/"+userId;
    return this.httpClient.delete<boolean>(url);
  }
}
