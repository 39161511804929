import { Component, Input, OnInit } from '@angular/core';
import { AppConfigService } from '../../shared/helpers/app-config.service';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-generate-default-picture',
  templateUrl: './generate-default-picture.component.html',
  styleUrls: ['./generate-default-picture.component.scss']
})
export class GenerateDefaultPictureComponent implements OnInit {
  color: string;
  alphabet = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
  ];

  @Input() firstName = new Observable<string>();
  @Input() lastName = new Observable<string>();
  @Input() theLetter: string;

  constructor(private config: AppConfigService) { }

  ngOnInit() {

    this.lastName.subscribe(value => {
      if (!isNullOrUndefined(value)) {
        this.firstName.subscribe(_firstName => {
          if (!isNullOrUndefined(_firstName)) {
            this.theLetter =
              _firstName[0].toUpperCase() + '' + value[0].toUpperCase();

            const index = this.alphabet.indexOf(
              this.theLetter[this.theLetter.length - 1]
            ); // find the alphabet index
            const bgColors = this.config.getConfig().colors; // get all the colors from config
            const colorIndex = index % bgColors.length; // pick the color
            this.color = bgColors[colorIndex]; // set the color
          }
        });
      }
    });
  }
}
