import { CheckTaskSuccess } from "./../actions/task.action";
import { tagId } from "../../matter-endorsement/models/new-endorsement";
import { UserTask } from "./../../models/view-model/user-tasks";
import * as fromRoot from "../app.state";
import { TaskActions, TasksActionTypes } from "../actions/task.action";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { isNullOrUndefined } from "util";
// import { stat } from 'fs';

export interface State extends fromRoot.State {
  tasks: TasksState;
}

export interface TasksState {
  tasks: any;
  matterTask: any;
  matterTaskSorted: any;
  tasksSorted: UserTask[][];
  error: string;
  taskTobeCompleted: UserTask;
  viewTask: boolean;
  // viewMatterTask: boolean;
  currentTask: UserTask;
  // currentMatterTask: UserTask;
  taskToBeAdded: any;
  isTaskListFetchDone: boolean;
  CheckTaskSuccess: boolean;
  addTaskSuccess: boolean;
  updateTaskSuccess: boolean;
  newTaskId: number;
  taskToBeUpdated: any;
}
const initialState = {
  tasks: [],
  matterTask: [],
  matterTaskSorted: [],
  tasksSorted: [],
  error: "",
  taskTobeCompleted: new UserTask(),
  viewTask: false,
  // viewMatterTask: false,
  currentTask: new UserTask(),
  // currentMatterTask: new UserTask(),
  taskToBeAdded: {},
  CheckTaskSuccess: null,
  addTaskSuccess: false,
  newTaskId: 0,
  isTaskListFetchDone: false,
  taskToBeUpdated: {},
  updateTaskSuccess: false,
};

const getTaskFeatureState = createFeatureSelector<TasksState>("tasks");
export const getMatterTasks = createSelector(
  getTaskFeatureState,
  (state) => state.matterTask
);
export const getSortedMatterTasks = createSelector(
  getTaskFeatureState,
  (state) => state.matterTaskSorted
);
export const getTasks = createSelector(
  getTaskFeatureState,
  (state) => state.tasks
);
export const getSortedTasks = createSelector(
  getTaskFeatureState,
  (state) => state.tasksSorted
);
export const addTaskSuccess = createSelector(
  getTaskFeatureState,
  (state) => state.addTaskSuccess
);

export const addedTaskId = createSelector(
  getTaskFeatureState,
  (state) => state.newTaskId
);

export const addTaskerror = createSelector(
  getTaskFeatureState,
  (state) => state.error
);

export const updateTaskSuccess = createSelector(
  getTaskFeatureState,
  (state) => state.updateTaskSuccess
);

export const updateTaskError = createSelector(
  getTaskFeatureState,
  (state) => state.error
);
export const completeTaskSuccess = createSelector(
  getTaskFeatureState,
  (state) => state.CheckTaskSuccess
);

export const TaskLoadDone = createSelector(
  getTaskFeatureState,
  (state) => state.isTaskListFetchDone
);

export const viewTask = createSelector(
  getTaskFeatureState,
  (state) => state.viewTask
);
// export const viewMatterTask = createSelector(
//   getTaskFeatureState,
//   state => state.viewMatterTask
// );
export const currentTask = createSelector(
  getTaskFeatureState,
  (state) => state.currentTask
);
// export const currentMatterTask = createSelector(
//   getTaskFeatureState,
//   state => state.currentMatterTask
// );

export function taskReducer(
  state: TasksState = initialState,
  action: TaskActions
): TasksState {
  switch (action.type) {
    case TasksActionTypes.LoadMatterTasksSuccess:
      // var sorted = sortTasks(action.payload);
      return {
        ...state,
        matterTask: action.payload,
        matterTaskSorted: action.payload,
        isTaskListFetchDone: true,
      };
    case TasksActionTypes.LoadTasksSuccess:
      //console.log(action.payload);
      return {
        ...state,
        tasks: action.payload,
        tasksSorted: action.payload,
        isTaskListFetchDone: true,
      };
    case TasksActionTypes.CheckMatterTask:
      return {
        ...state,
        taskTobeCompleted: action.payload,
      };
    case TasksActionTypes.CheckTask:
      return {
        ...state,
        taskTobeCompleted: action.payload,
        updateTaskSuccess: false,
        addTaskSuccess: false,
      };
    // case TasksActionTypes.CheckMatterTaskSuccess:
    //   const matterId = state.taskTobeCompleted.id;
    //   //console.log(state.taskTobeCompleted);

    //   const tempMatterTask = state.matterTask.find(x => x.id == matterId);
    //   //console.log(tempMatterTask);
    //   //console.log(state.matterTask);
    //   state.matterTask.find(x => x.id ===matterId).isCompleted = true;
    //   var sorted = sortTasks(state.matterTask);
    //   return {
    //     ...state,
    //     matterTaskSorted: sorted,
    //     CheckTaskSuccess: true
    //   };
    case TasksActionTypes.CheckTaskSuccess:
      const id = state.taskTobeCompleted.id;
      //console.log(state.taskTobeCompleted);
      //console.log(id);

      // const tempTask = state.tasks.body.find(x => x.id === id);
      if (isNullOrUndefined(state.tasks.body)) {
        if (!isNullOrUndefined(state.matterTask.body)) {
          const tempMatterTask = state.matterTask.body.find((x) => x.id === id);
          if (!isNullOrUndefined(tempMatterTask)) {
            state.matterTask.body.find((x) => x.id === id).isCompleted = true;
          }
        }
      } else {
        if (!isNullOrUndefined(state.tasks.body)) {
          const tempTask = state.tasks.body.find((x) => x.id === id);

          if (!isNullOrUndefined(tempTask)) {
            state.tasks.body.find((x) => x.id === id).isCompleted = true;
          }
        }
      }

      //console.log(state.tasks.body);
      // //console.log(check);

      let sorted = [];
      let sortedMatter = [];

      if (!isNullOrUndefined(state.tasks.body)) {
        sorted = sortTasks(state.tasks.body);
      }

      if (!isNullOrUndefined(state.matterTask.body)) {
        sortedMatter = sortTasks(state.matterTask.body);
      }

      return {
        ...state,
        tasksSorted: sorted,
        matterTaskSorted: sortedMatter,
        CheckTaskSuccess: true,
        updateTaskSuccess: false,
        addTaskSuccess: false,
      };
    // case TasksActionTypes.CheckMatterTaskFail:
    //   return {
    //     ...state,
    //     CheckTaskSuccess: false
    //   };
    case TasksActionTypes.CheckTaskFail:
      return {
        ...state,
        error: action.payload,
        updateTaskSuccess: false,
        addTaskSuccess: false,
      };
    case TasksActionTypes.AddTask:
      return {
        ...state,
        taskToBeAdded: action.payload,
        updateTaskSuccess: false,
        addTaskSuccess: false,
      };
    case TasksActionTypes.AddTaskSuccess:
      const taskId = action.payload;
      // restructure parse model to fit viewModel

      let task = restructureNewTask(state.taskToBeAdded, taskId);
      if (isNullOrUndefined(state.tasks.body)) {
        state.tasks.body = [];
        state.tasks.body.push(task);
        sorted = sortTasks(state.tasks.body);
      } else {
        const task = restructureNewTask(state.taskToBeAdded, taskId);
        state.tasks.body.push(task);
        sorted = sortTasks(state.tasks.body);
      }

      return {
        ...state,
        tasksSorted: sorted,
        addTaskSuccess: true,
        error: "",
        newTaskId: action.payload,
      };
    case TasksActionTypes.AddTaskFail:
      return {
        ...state,
        error: "Error! Task Creation Failed",
        addTaskSuccess: false,
      };

    ///

    case TasksActionTypes.UpdateTask:
      return {
        ...state,
        taskToBeUpdated: action.payload,
        addTaskSuccess: false,
        error: "",
        updateTaskSuccess: false,
      };
    case TasksActionTypes.UpdateTaskSuccess:
      // const res = action.payload;
      // restructure parse model to fit viewModel
      // const editedtask = restructureNewTask(state.taskToBeAdded, state.currentTask.id);
      // const selectedTask = state.tasks.find(t => t.id === state.currentTask.id);
      // if (!isNullOrUndefined(selectedTask)) {
      //   state.tasks.splice(state.tasks.body.indexOf(selectedTask), 1, editedtask);
      // }
      // sorted = sortTasks(state.tasks.body);
      return {
        ...state,
        tasksSorted: sorted,
        addTaskSuccess: false,
        updateTaskSuccess: true,
      };
    case TasksActionTypes.UpdateTaskFail:
      return {
        ...state,
        error: "Error! Task Update Failed",
        updateTaskSuccess: false,
        addTaskSuccess: false,
      };

    ///
    // case TasksActionTypes.ViewMatterTask:
    //   return {
    //     ...state,
    //     viewMatterTask: action.payload
    //   };
    case TasksActionTypes.ViewTask:
      return {
        ...state,
        viewTask: action.payload,
      };
    // case TasksActionTypes.SetCurrentMatterTask:
    //   return {
    //     ...state,
    //     currentMatterTask: action.payload
    //   };
    case TasksActionTypes.SetCurrentTask:
      return {
        ...state,
        currentTask: action.payload,
        updateTaskSuccess: false,
        addTaskSuccess: false,
      };
    default:
      return {
        ...state,
      };
  }
}

function sortTasks(arr: any) {
  const tasks: [any, any, any] = [[], [], []];
  arr.forEach((task) => {
    task.dueDateInNumber = Number(task.dueDate);
    // if task is completed
    if (task.isCompleted) {
      tasks[2].push(task);
    } else if (
      new Date(Number(task.dueDate)).toDateString() ===
        new Date().toDateString() ||
      Number(task.dueDate) < new Date().getTime()
    ) {
      tasks[0].push(task);
    } else if (Number(task.dueDate) > new Date().getTime()) {
      tasks[1].push(task);
    }
  });
  return tasks;
}

function restructureNewTask(obj, no: number): UserTask {
  const priorityLevels: any[] = [
    { name: "Critical", id: 1 },
    { name: "High Priority", id: 2 },
    { name: "Normal", id: 3 },
  ];
  // this.config.getConfig().priorities;
  const payload = {
    id: no,
    name: obj.name,
    dueDate: obj.dueDate,
    dueDateInNumber: Number(obj.dueDate),
    resourceId: obj.resourceId,
    resourceName: obj.resourceName ? obj.resourceName : "",
    resourceType: obj.resourceType ? obj.resourceType : "",
    isCompleted: false,
    description: obj.description,
    tag: obj.tag,
    taskUsersCount: obj.teamMemberCount ? obj.teamMemberCount : 0,
    taskUsers: obj.teamMembers ? obj.teamMembers : [],
    decription: "",
  };
  return payload;
}
