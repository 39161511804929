import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { UserTask } from "../../models/view-model/user-tasks";
import * as fromTask from "../../store/reducers/task.reducer";
import * as TaskActions from "../../store/actions/task.action";
import { Store, select } from "@ngrx/store";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-upcoming-task",
  templateUrl: "./upcoming-task.component.html",
  styleUrls: ["./upcoming-task.component.scss"]
})
export class UpcomingTaskComponent implements OnInit, OnChanges {

  @Input() tasks: UserTask[];
  _tasks: UserTask[] = []
  currentTask: any;
  showCurrentTask: boolean;
  constructor(private taskStore: Store<fromTask.State>) {

  }

  ngOnChanges(changes) {
    if (!isNullOrUndefined(this.tasks)) {
      this._tasks = this.tasks
    }
  }

  ngOnInit() {



  }

  closeViewTask(e) {
    if (e === "true") {
      this.taskStore.dispatch(new TaskActions.ViewTask(false));
    }
  }

  // setCurrentTask(task){
  //   this.currentTask = task;
  // }
}
