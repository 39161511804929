import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material";
import { takeWhile } from "rxjs/operators";
import { ContactViewModel } from "../../contact/models/contact-list";
import { ContactLabel } from "../../contact/models/contact-label";
import * as contactActions from "../../store/actions/contacts.actions";
import * as fromContacts from "../../store/reducers/contacts.reducer";
import { State } from "../../store/reducers/contacts.reducer";
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormArray,
  FormControl,
} from "@angular/forms";
import { AppConfigService } from "../../shared/helpers/app-config.service";
import { ContactService } from "../../contact/services/contact.service";
import { isNullOrUndefined } from "util";
import { ActivatedRoute, Router } from "@angular/router";
import { Contact } from "../../contact/models/add-contact";
import { Notifier } from "src/app/shared/base-class/notifier";
import * as fromUserHeaderStore from "../../store/reducers/user-header.reducer";
import { Store, select } from "@ngrx/store";
import * as headertActions from "../../store/actions/user-header.actions";
import { UserManagementService } from "src/app/services/user-management.service";
import { GenericService } from "src/app/services/generic.service";
import { UserDetail } from "src/app/models/view-model/user-detail";

import * as LoginActions from "../../auth/state/login.actions";
import * as fromStore from "../../auth/state/index";
import { EditContactModel } from "src/app/contact/models/edit-contact.model";

@Component({
  selector: "app-edit-contact-modal",
  templateUrl: "./edit-contact-modal.component.html",
  styleUrls: ["./edit-contact-modal.component.scss"],
})
export class EditContactModalComponent extends Notifier implements OnInit {
  titles = [];
  relations = [];
  countries: any[] = [];
  states: any[] = [];

  editContactForm: FormGroup;
  selectedLabel: ContactLabel;
  successText: string;
  errorText: string;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  btnSpinner: boolean;
  currentContact: ContactViewModel;
  firmId: number;
  userId: number;
  @Input() firmIdNo: any;
  @Input() userIdNo: any;
  @Input() selectedClient: any;
  @Output() refreshContact = new EventEmitter();

  userData = new UserDetail();

  @ViewChild("messageBox") messageBox: ElementRef;

  constructor(
    public fb: FormBuilder,
    private store: Store<State>,
    private service: ContactService,
    private config: AppConfigService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private genericService: GenericService,
    // protected userManagementService: UserManagementService,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>
  ) {
    super(headerStore);
    this.store.dispatch(new LoginActions.LoadLocalUserDetail());
    this.store.pipe(select("auth")).subscribe((data) => {
      /// save token and user detail to local storage
      this.userData = data.userProfile;

      this.firmIdNo = this.userData.firmId;
      this.userIdNo = this.userData.userId;
    });

    this.editContactForm = fb.group({
      title: ["", Validators.required],
      firstName: ["", [Validators.required, Validators.pattern("^[a-zA-Z]*$")]],
      lastName: ["", [Validators.required, Validators.pattern("^[a-zA-Z]*$")]],
      companyName: [""],
      source: [""],
      phoneNumber: [""],
      // tslint:disable-next-line: max-line-length
      email: [
        "",
        [
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      relationship: ["", Validators.required],
      contactLabel: [""],
      website: [""],
      street: [""],
      country: [""],
      state: [""],
      city: [""],
    });
  }

  get title() {
    return this.editContactForm.get("title");
  }

  get firstName() {
    return this.editContactForm.get("firstName");
  }

  get lastName() {
    return this.editContactForm.get("lastName");
  }

  get companyName() {
    return this.editContactForm.get("companyName");
  }

  get source() {
    return this.editContactForm.get("source");
  }

  get phoneNumber() {
    return this.editContactForm.get("phoneNumber");
  }

  get email() {
    return this.editContactForm.get("email");
  }

  get status() {
    return this.editContactForm.get("status");
  }

  get relationship() {
    return this.editContactForm.get("relationship");
  }

  get contactLabel() {
    return this.editContactForm.get("contactLabel");
  }
  get website() {
    return this.editContactForm.get("website");
  }
  get street() {
    return this.editContactForm.get("street");
  }
  get country() {
    return this.editContactForm.get("country");
  }
  get city() {
    return this.editContactForm.get("city");
  }

  // tslint:disable-next-line: use-life-cycle-interface

  ngOnInit() {
    this.showErrorMessage = false;
    this.showSuccessMessage = false;
    this.btnSpinner = false;

    this.titles = this.config.getConfig().titles;
    this.relations = this.config.getConfig().relationship;
    this.genericService
      .getCountries()
      .toPromise()
      .then((res) => {
        this.countries = res;
      });

    this.editContactForm.patchValue({
      title: this.selectedClient.titleId,
      lastName: this.selectedClient.lastName,
      firstName: this.selectedClient.firstName,
      companyName: this.selectedClient.companyName,
      source: this.selectedClient.contactSource,
      phoneNumber: this.selectedClient.phoneNumber,
      email: this.selectedClient.email,
      relationship: this.selectedClient.contactStatusId,
      contactLabel: this.selectedClient.contactLabel,
      website: this.selectedClient.website,
      street: "",
      country: "",
      state: "",
      city: "",
    });

    // this.service.getContactDetail(this.id).subscribe(arg => {
    //   //console.log("this is contact details: ",arg);
    //   this.currentContact = arg;

    // });

    // this.firmId = Number.parseInt(this.activateRoute.parent.snapshot.params['firmId'], 10);
    // this.userId = Number.parseInt(this.activateRoute.parent.snapshot.params['userId'], 10);
  }

  ngOnChanges(changes) {
    //console.log(`this is selectedClient: ${this.selectedClient}`)
    this.editContactForm.patchValue({
      title: this.selectedClient.titleId,
      lastName: this.selectedClient.lastName,
      firstName: this.selectedClient.firstName,
      companyName: this.selectedClient.companyName,
      source: this.selectedClient.contactSource,
      phoneNumber: this.selectedClient.phoneNumber,
      email: this.selectedClient.email,
      relationship: this.selectedClient.contactStatusId,
      contactLabel: this.selectedClient.contactLabel,
      website: this.selectedClient.website,
      street: "",
      country: "",
      state: "",
      city: "",
    });
  }

  chooseLabel(theData) {
    //console.log(theData);
    this.selectedLabel = theData.label;
    this.contactLabel.patchValue(this.selectedLabel);
    //console.log(theData);
  }

  selectCountry(id: any) {
    this.genericService
      .getCountryState(id)
      .toPromise()
      .then((data) => {
        this.states = data;
      });
  }

  resetModal() {
    this.editContactForm.reset();
    this.btnSpinner = false;
  }

  submitContact() {
    this.btnSpinner = true;
    //console.log(this.editContactForm.value);
    // //console.log('form will send', formData);
    const formValue = this.editContactForm.value;
    const contact = new EditContactModel();
    contact.id = this.selectedClient.id;
    contact.firmId = this.firmIdNo;
    contact.firstName = formValue.firstName;
    contact.lastName = formValue.lastName;
    contact.titleId = Number.parseInt(formValue.title, 10);
    contact.email = formValue.email;
    contact.phoneNumber = formValue.phoneNumber;
    contact.contactStatusId = +formValue.relationship;
    contact.firmLabelId = !!formValue.contactLabel
      ? Number.parseInt(formValue.contactLabel.id, 10)
      : 0;
    contact.contactSource = formValue.source;
    contact.jobDescription = "";
    contact.instantMessenger = "";
    if (!isNullOrUndefined(formValue.companyName)) {
      contact.companyName = formValue.companyName;
    } else {
      contact.companyName = "N/A";
    }

    // contact.companyName = formValue.companyName;
    this.service
      .updateContact(contact)
      .toPromise()
      .then((data) => {
        // this.pushMessage('Contact, <strong>' + contact.firstName +
        //   ' ' + contact.lastName + '</strong> Was saved successfully');

        // this.showSuccessMessage = true;
        // this.router.navigate(['/firm/contact/' + this.userIdNo + '/firm/' + this.firmIdNo + '/']);
        this.btnSpinner = false;
        this.refreshContact.emit(data);
        this.messageBox.nativeElement.click();
      })
      .catch((err) => {
        this.showErrorMessage = true;
        this.pushMessage(
          "Adding Contact, " +
            contact.firstName +
            " " +
            contact.lastName +
            " failed"
        );
        this.btnSpinner = false;
      });
  }
}
