import { UserTask } from '../../models/view-model/user-tasks';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromTask from '../../store/reducers/task.reducer';
import * as TaskActions from '../../store/actions/task.action';
import { isNullOrUndefined } from 'util';
import { TaskService } from 'src/app/services/task.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-matter-task-tag',
  templateUrl: './matter-task-tag.component.html',
  styleUrls: ['./matter-task-tag.component.scss']
})
export class MatterTaskTagComponent implements OnInit {
  confirmType: string;
  confirmTitle: string;
  confirmMessage: string;
  confirmAction: string;
  confirmElementData: any;
  matterId: Number;

  todayDate: number;
  @Input() task: UserTask;
  @Output() currentTask = new EventEmitter();
  @Output() myShowTask = new EventEmitter();

  showCurrentTask: boolean;
  checkSpinner: boolean;
  // @Output() isClosed: Observable<boolean>;


  constructor(private taskStore: Store<fromTask.State>,
    private taskService: TaskService, private route: ActivatedRoute) {
    this.todayDate = new Date().getTime();
  }

  ngOnInit() {
    this.matterId = Number(this.route.parent.snapshot.parent.parent.params['id']);

    if (!isNullOrUndefined(this.task)) {
      this.taskService.getTaskDetail(this.task.id).subscribe(res => {
        this.task = res;
      });
    }

    this.taskStore.pipe(select(fromTask.completeTaskSuccess)).subscribe(data => {
      if (!isNullOrUndefined(data)) {
        if (data) {
          // this.myMessgae.emit(true);
        }
      }
    });

  }

  setCurrentTask(file) {
    // this.currentTask.emit(file)
    this.taskStore.dispatch(new TaskActions.SetCurrentTask(file));
    this.taskStore.dispatch(new TaskActions.ViewTask(true));
    this.showCurrentTask = true;
    this.currentTask.emit(file);
    this.myShowTask.emit(file);
  }


  confirmDelete(id: any) {
    this.confirmType = 'deleteTask';
    this.confirmTitle = 'Delete Task';
    this.confirmMessage = 'Are you sure you want to delete this task ?';
    this.confirmAction = 'Yes, Delete Task';
    this.confirmElementData = id;
  }

  confirmResponse(data: any) {
    if (data.type === 'deleteTask') {
      this.deleteTask(data.elementData);
    }
  }


  deleteTask(task: UserTask) {
    // call delete task from store
    // this.taskStore.dispatch(new TaskActions.);
  }


  onChange(task: UserTask) {
    // const test: UserTask = {
    //   dueDate: "1569180840000"
    //   dueDateInNumber: 1569180840000
    //   id: 1
    //   iscompleted: true
    //   name: "FAUA"
    //   resourceId: 3
    //   resourceName: "Assasin Creed Version 2"
    //   resourceType: "Application"
    //   tag: "Critical"
    //   teamMemberCount: 0
    //   teamMembers: null
    // }
    // const payload: UserTask = {
    //   // id: this.editTask !== undefined ? this.editTask.id : 0,
    //   name: this.taskName.value,
    //   // description: this.description.value,
    //   startDate: `${new Date().getTime()}`,
    //   dueDate: `${new Date(this.dueDate.value).getTime()}`,
    //   tagId: Number(this.taskPriorityId.value),
    //   reminders: reminderDates,
    //   isReoccurringTask: this.isReoccurringTask.value,
    //   reoccurringInterval: Number(this.reoccurringInterval.value),
    //   // taskUsers: this.taskTeamMembers,
    //   // files: this.selectedTaskFiles,
    //   // matterId: Number(this.matterId.value),
    //   resourceId: Number(this.matterId.value)
    // };

    this.checkSpinner = true;
    this.taskStore.dispatch(new TaskActions.CheckTask(task));





    // the service to update the task to complete should be written here
  }
}
