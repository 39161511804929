import { createFeatureSelector, createSelector } from "@ngrx/store";
import { isNullOrUndefined } from "util";
import { MatterExpensesModel } from "../../models/view-model/matter-expenses";
import {
  contactList,
  matterDetail,
  MatterList,
  GenericViewModel,
} from "../../models/view-model/matter-list";
import { MatterTaskModel } from "../../models/view-model/matter-tasks";
import { MatterTeamMember } from "../../models/view-model/team-list";
import { MatterTimeSheetModel } from "../../models/view-model/time-sheet";
import { MatterActions, MattersActionTypes } from "../actions/matters.action";
import * as fromRoot from "../app.state";

import { ProceedingViewModel } from "src/app/matter-endorsement/models/endorsement";
import { state } from "@angular/animations";

import { ApplicationParseModel } from "src/app/models/parse-model/add-application";

export interface State extends fromRoot.State {
  matters: MattersState;
}

export interface MattersState {
  mattersList: any;
  error: string;
  currentMatterId: number;
  filterId: string;
  endorsement: any;
  isMatterLoading: boolean;

  currentEndorsement: ProceedingViewModel;

  application: any;
  applicationToBeAdded: ApplicationParseModel;
  currentMatter: matterDetail;
  addPartyErr: string;
  timeSheet: any;
  currentMatterContacts: contactList[];
  currentMatterTeam: MatterTeamMember[];
  currentMatterTasks: MatterTaskModel[];
  partyToBeSaved: contactList;
  currentMatterExpenses: any;
  currentMatterTimes: any;
  teamMemberToBeSaved: MatterTeamMember[];
  teamMemberIdToDelete: number;
  teamDeleteMsg: string;
  addTeamMemberMsg: string;
  addApplicationMsg: string;
  applicationToBeUpdate: any;
  updatApplicationSuccess: boolean;
  documents: Document[];
  isAddingMatterTime: boolean;
  isAddedMatterTime: boolean;
  matterTimePayLoad: MatterTimeSheetModel;
  addMatterTimeSheetError: string;
  isLoadingMatter: boolean;
  currentMatterJudges: GenericViewModel[];
  closeNoteModal: boolean;
}

const initialState: MattersState = {
  mattersList: null,
  error: "",
  addPartyErr: null,
  endorsement: [],
  timeSheet: [],

  currentEndorsement: new ProceedingViewModel(),

  application: [],
  addApplicationMsg: null,
  applicationToBeAdded: null,

  currentMatterId: 0,
  filterId: "",
  currentMatter: null,
  currentMatterContacts: [],
  currentMatterTeam: [],
  currentMatterTasks: [],
  partyToBeSaved: new contactList(),
  currentMatterExpenses: null,
  currentMatterTimes: null,
  teamMemberToBeSaved: [],
  teamMemberIdToDelete: null,
  teamDeleteMsg: null,
  addTeamMemberMsg: null,
  documents: [],
  isAddingMatterTime: false,
  isAddedMatterTime: false,
  matterTimePayLoad: null,
  addMatterTimeSheetError: "",
  isLoadingMatter: false,
  isMatterLoading: false,
  updatApplicationSuccess: false,
  applicationToBeUpdate: {},
  currentMatterJudges: [],
  closeNoteModal: false,
};
const getMatterFeatureState = createFeatureSelector<MattersState>("matter");

export const getIsLoadingMatter = createSelector(
  getMatterFeatureState,
  (state) => state.isMatterLoading
);

export const getFilteredId = createSelector(getMatterFeatureState, (state) =>
  isNullOrUndefined(state) ? "" : state.filterId
);
export const getCurrentEndorsement = createSelector(
  getMatterFeatureState,
  (state) => state.currentEndorsement
);
export const getTimeSheet = createSelector(getMatterFeatureState, (state) =>
  isNullOrUndefined(state) ? "" : state.timeSheet
);
export const getAddPartyNotification = createSelector(
  getMatterFeatureState,
  (state) => (isNullOrUndefined(state) ? null : state.addPartyErr)
);
export const getAddTeamNotification = createSelector(
  // getMatterFeatureState,
  // state => state.error
  getMatterFeatureState,
  (state) => (isNullOrUndefined(state) ? null : state.addTeamMemberMsg)
);

export const getAllMatters = createSelector(
  // getMatterFeatureState,
  // state => state.error
  getMatterFeatureState,
  (state) => (isNullOrUndefined(state) ? null : state.mattersList)
);

export const getEndorsementByMatter = createSelector(
  getMatterFeatureState,
  (state) => state.endorsement
);
export const getApplicationByMatter = createSelector(
  getMatterFeatureState,
  (state) => state.application
);
export const getIsAddedMatterTime = createSelector(
  getMatterFeatureState,
  (state) => (isNullOrUndefined(state) ? null : state.isAddedMatterTime)
);

export const getIsAddingMatterTime = createSelector(
  getMatterFeatureState,
  (state) => (isNullOrUndefined(state) ? null : state.isAddingMatterTime)
);

export const getaddMatterTimeSheetError = createSelector(
  getMatterFeatureState,
  (state) => (isNullOrUndefined(state) ? "" : state.addMatterTimeSheetError)
);

export const getMatterList = createSelector(
  getMatterFeatureState,
  (state) => (isNullOrUndefined(state) ? null : state.mattersList)
  // //console.log(state.mattersList);
  // getMatterFeatureState,
  // getFilteredId,
  // (state, filter) => {
  //   const newfilter = Number(filter);
  //   if (!isNaN(newfilter)) {
  //     if (newfilter === 1) {
  //       return state.mattersList.filter(x => x.isCompleted === false);
  //     } else if (newfilter === 2) {
  //       return state.mattersList.filter(x => x.isCompleted === true);
  //     } else if (newfilter === 0) {
  //       return isNullOrUndefined(state) ? [] : state.mattersList;
  //     }
  //   } else if (isNaN(newfilter)) {
  //     return state.mattersList.filter(x => x.name.trim().toLowerCase().indexOf(filter) > -1);
  //   } else {
  //     return isNullOrUndefined(state) ? [] : state.mattersList;
  //   }
  // }
);
export const getCurrentMatterId = createSelector(
  getMatterFeatureState,
  (state) => (isNullOrUndefined(state) ? null : state.currentMatterId)
);
export const getDeleteTeamMsg = createSelector(
  getMatterFeatureState,
  // state =>{ return [state.teamDeleteMsg,state.error]}
  (state) => [state.teamDeleteMsg, state.error]
);
export const getCurrentMatter = createSelector(
  getMatterFeatureState,
  getCurrentMatterId,
  (state, id): matterDetail => {
    if (id === 0) {
      null;
      // return {
      //   id: 2,
      //   name: '',
      //   description: ``,
      //   createdBy: 0,
      //   firmId: 0,
      //   isArchieved: true,
      //   dateCreated: 0,
      //   isCompleted: false,
      //   currentJudge: [],
      //   currentCourt: {
      //     courtAddress: '',
      //     courtNames: '',
      //     suitNumber: '',
      //     courtTypeId: 0,
      //     matterCourtId: 0
      //   },
      //   proceeding: {
      //     id: 2,
      //     hearingDate: '',
      //     nextHearingDate: '',
      //     nextAction: '',
      //     nextStage: '',
      //     summary: '',
      //     matterId: 5,
      //     commencementDate: '',
      //     lastHearingDate: '',
      //     dateCreated: ''
      //   }
      // };
    } else {
      return state.currentMatter;
    }
  }
);

export const getCurrentMatterContacts = createSelector(
  getMatterFeatureState,
  (state) => state.currentMatterContacts
);
export const getCurrentMatterClients = createSelector(
  getMatterFeatureState,
  getCurrentMatterContacts,
  (state, contacts) => {
    return !isNullOrUndefined(contacts)
      ? Array.from(contacts).filter((x) => x.isParty === true)
      : [];
  }
);
export const getCurrentMatterParties = createSelector(
  getMatterFeatureState,
  getCurrentMatterContacts,
  (state, contacts) => {
    return !isNullOrUndefined(contacts)
      ? Array.from(contacts).filter((x) => x.isParty === true)
      : [];
  }
);
export const getCurrentMatterTeam = createSelector(
  getMatterFeatureState,
  (state) => state.currentMatterTeam
);

export const getCurrentMatterTasks = createSelector(
  getMatterFeatureState,
  (state) => state.currentMatterTasks
);
export const getCurrentMatterExpenses = createSelector(
  getMatterFeatureState,
  (state) => state.currentMatterExpenses
);

export const getCurrentMatterTimes = createSelector(
  getMatterFeatureState,
  (state) => state.currentMatterTimes
);

export const getCurrentMatterJudges = createSelector(
  getMatterFeatureState,
  (state) => state.currentMatterJudges
);

export const closeCurrentNoteModal = createSelector(
  getMatterFeatureState,
  (state) => state.closeNoteModal
);

export function matterReducer(
  state: MattersState = initialState,
  action: MatterActions
): MattersState {
  switch (action.type) {
    case MattersActionTypes.loadMatterListSuccess:
      return {
        ...state,
        mattersList: action.payload,
      };
    case MattersActionTypes.loadMatterListFail:
      return {
        ...state,
        mattersList: null,
        error: action.payload,
      };
    case MattersActionTypes.loadMatterTimeSheetSuccess:
      return {
        ...state,
        timeSheet: action.payload,
      };
    case MattersActionTypes.loadMatterTimeSheetFail:
      return {
        ...state,
        timeSheet: [],
        error: action.payload,
      };

    case MattersActionTypes.loadMatterListByCategory:
      return {
        ...state,
        error: "",
        isLoadingMatter: true,
      };

    case MattersActionTypes.loadMatterListByCategorySuccess:
      return {
        ...state,
        mattersList: action.payload,
        error: "",
        isLoadingMatter: false,
      };
    case MattersActionTypes.loadMatterListByCategoryFail:
      return {
        ...state,
        mattersList: null,
        error: action.payload,
        isLoadingMatter: false,
      };

    case MattersActionTypes.setCurrentMatter:
      return {
        ...state,
        currentMatterId: action.payload,
      };
    case MattersActionTypes.loadCurrentMatter:
      return {
        ...state,
        isMatterLoading: true,
        currentMatterId: action.payload,
      };
    case MattersActionTypes.loadCurrentMatterSuccess:
      return {
        ...state,
        isMatterLoading: false,
        currentMatter: action.payload,
      };
    case MattersActionTypes.loadCurrentMatterFail:
      return {
        ...state,
        isMatterLoading: false,
        error: action.payload,
      };
    case MattersActionTypes.UPDATE_ENDORSEMENT_OVERVIEW_DATA:
      if (!state.currentMatter.proceeding) {
        state.currentMatter.proceeding = new ProceedingViewModel();
      }

      state.currentMatter.proceeding = {
        ...state.currentMatter.proceeding,
        ...action.payload.proceeding,
      };
      return {
        ...state,
      };
    case MattersActionTypes.loadCurrentMatterContactsSuccess:
      return {
        ...state,
        currentMatterContacts: action.payload,
      };
    case MattersActionTypes.loadCurrentMatterContactsFail:
      return {
        ...state,
        error: action.payload,
      };
    case MattersActionTypes.loadCurrentMatterTeamSuccess:
      return {
        ...state,
        currentMatterTeam: action.payload,
      };
    case MattersActionTypes.loadCurrentMatterTeamFail:
      return {
        ...state,
        error: action.payload,
      };
    case MattersActionTypes.AddApplication:
      return {
        ...state,
        applicationToBeAdded: action.payload,
      };
    case MattersActionTypes.AddApplicationSuccess:
      state.applicationToBeAdded.id = action.payload;
      state.application.push(state.applicationToBeAdded);
      return {
        ...state,
        addApplicationMsg: "",
      };
    case MattersActionTypes.AddTeamMember:
      return {
        ...state,
        teamMemberToBeSaved: action.payload,
        addTeamMemberMsg: null,
      };

    case MattersActionTypes.AddTeamMemberSuccess:
      // state.teamMemberToBeSaved.id = action.payload
      state.teamMemberToBeSaved.forEach((element) => {
        state.currentMatterTeam.push(element);
      });

      return {
        ...state,
        addTeamMemberMsg: "",
      };

    case MattersActionTypes.AddTeamMemberFail:
      return {
        ...state,
        addTeamMemberMsg: action.payload,
      };

    case MattersActionTypes.DeleteTeamMember:
      state.teamMemberIdToDelete = action.payload;
      return {
        ...state,
        error: "",
      };
    case MattersActionTypes.LoadCurrentEndorsement:
      return {
        ...state,
        currentEndorsement: action.payload,
        error: "",
      };
    case MattersActionTypes.LoadCurrentEndorsementFail:
      return {
        ...state,
        currentEndorsement: null,
        error: action.payload,
      };
    case MattersActionTypes.DeleteTeamMemberSuccess:
      const index = state.currentMatterTeam.findIndex(
        (x) => x.id === state.teamMemberIdToDelete
      );
      state.currentMatterTeam.splice(index, 1);
      return {
        ...state,
        teamDeleteMsg: "this team member was successfully deleted",
        error: "",
      };

    case MattersActionTypes.DeleteTeamMemberFail:
      return {
        ...state,
        teamMemberIdToDelete: null,
        teamDeleteMsg: null,
        error: action.payload,
      };

    case MattersActionTypes.addNewParty:
      return {
        ...state,
        partyToBeSaved: action.payload,
      };
    case MattersActionTypes.addNewPartySuccess:
      state.partyToBeSaved.id = action.payload;
      state.currentMatterContacts.push(state.partyToBeSaved);
      return {
        ...state,
        addPartyErr: "",
        partyToBeSaved: state.partyToBeSaved,
      };
    case MattersActionTypes.loadCurrentMatterContactsFail:
      return {
        ...state,
        addPartyErr: action.payload,
      };
    case MattersActionTypes.filterMatterState:
      return {
        ...state,
        filterId: action.payload,
      };

    case MattersActionTypes.loadCurrentMatterTasksSuccess:
      return {
        ...state,
        currentMatterTasks: action.payload,
      };
    case MattersActionTypes.loadCurrentMatterTasksFail:
      return {
        ...state,
        error: action.payload,
      };
    case MattersActionTypes.LoadMatterEndorsementSuccess:
      return {
        ...state,
        endorsement: action.payload,
        error: "",
      };
    case MattersActionTypes.LoadMatterEndorsementFail:
      return {
        ...state,
        endorsement: [],
        error: action.payload,
      };
    case MattersActionTypes.LoadMatterApplicationSuccess:
      return {
        ...state,
        application: action.payload,
        error: "",
      };
    case MattersActionTypes.LoadMatterApplicationFail:
      return {
        ...state,
        application: [],
        error: action.payload,
      };
    case MattersActionTypes.UpdateApplication:
      return {
        ...state,
        applicationToBeUpdate: action.payload,
      };
    case MattersActionTypes.UpdateApplicationSuccess:
      const data = action.payload;
      return {
        ...state,
        applicationToBeUpdate: data,
        updatApplicationSuccess: true,
      };
    case MattersActionTypes.UpdateApplicationFail:
      return {
        ...state,
        error: action.payload,
      };
    case MattersActionTypes.loadCurrentMatterExpensesSuccess:
      return {
        ...state,
        currentMatterExpenses: action.payload,
      };
    case MattersActionTypes.loadCurrentMatterExpensesFail:
      return {
        ...state,
        error: action.payload,
      };
    case MattersActionTypes.loadCurrentMatterTimes:
      return {
        ...state,
      };
    case MattersActionTypes.loadCurrentMatterTimesSuccess:
      return {
        ...state,
        currentMatterTimes: action.payload,
      };
    case MattersActionTypes.loadCurrentMatterTimesFail:
      return {
        ...state,
        error: action.payload,
      };
    case MattersActionTypes.LoadMatterDocumentSuccess:
      return {
        ...state,
        documents: action.payload,
        error: "",
      };
    case MattersActionTypes.LoadMatterDocumentFail:
      return {
        ...state,
        documents: [],
        error: action.payload,
      };

    case MattersActionTypes.ADD_MATTER_TIME: {
      state.matterTimePayLoad = action.payload;
      return {
        ...state,
        addMatterTimeSheetError: "",
        isAddingMatterTime: true,
        isAddedMatterTime: false,
      };
    }
    case MattersActionTypes.ADD_MATTER_TIME_SUCCESS: {
      state.matterTimePayLoad.id = action.payload;
      let times: any;
      if (!isNullOrUndefined(state.currentMatterTimes)) {
        state.currentMatterTimes.body.push(state.matterTimePayLoad);
        times = state.currentMatterTimes;
      }

      //console.log(state.currentMatterTimes);
      return {
        ...state,
        addMatterTimeSheetError: "",
        isAddingMatterTime: false,
        isAddedMatterTime: true,
        currentMatterTimes: times,
      };
    }
    case MattersActionTypes.ADD_MATTER_TIME_FAIL:
      return {
        ...state,
        addMatterTimeSheetError: action.payload,
        isAddingMatterTime: false,
        isAddedMatterTime: false,
      };

    case MattersActionTypes.loadCurrentMatterJudge:
      return {
        ...state,
        currentMatterId: action.payload,
      };
    case MattersActionTypes.loadCurrentMatterJudgeSuccess:
      return {
        ...state,
        currentMatterJudges: action.payload,
      };
    case MattersActionTypes.loadCurrentMatterJudgeFail:
      return {
        ...state,
        error: action.payload,
      };
    case MattersActionTypes.closeCurrentMatterNoteModal:
      return {
        ...state,
        closeNoteModal: action.payload,
      };

    default:
      return state;
  }
}
