import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReloadTaskService {
  private subject = new BehaviorSubject<boolean>(false);

  changeDecision(reload: boolean) {
    this.subject.next(reload);
  }

  getDecision(): Observable<any> {
    return this.subject.asObservable();
  }

  unSubscribeReload(): void {
    this.subject.unsubscribe();
  }

}
