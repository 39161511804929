export class CommentModel {
  threadId: number;
  content: string;
  commentId: string;
  draftId: number;
  comment?: string;
  userIds?: any[];
  taskId?: number;
  dateAdded?: any;
  firstName?: string;
  lastName?: string;

}

export class CommentViewModel {
  comment: string;
  commentId: string;
  dateAdded: string;
  id: number;
  mensionCount: number;
  mensions: number[];
  taskId: number;
  dateAddedInNumber: number;
  firstName?: string;
  lastName?: string;
}
