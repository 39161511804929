import {
  Component,
  OnInit,
  OnChanges,
  Input,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FileViewModel } from "src/app/models/view-model/file";
import { FileUploadService } from "src/app/services/file-upload.service";

@Component({
  selector: "app-file-list",
  templateUrl: "./file-list.component.html",
  styleUrls: ["./file-list.component.scss"],
})
export class FileListComponent implements OnInit, OnChanges {
  @Input() files: FileViewModel[] = [];

  @ViewChild("downloadBtn") downloadBtn: ElementRef;
  downloadUrl: "";

  existingDocument: FileViewModel[] = [];
  constructor(private _fileUploadService: FileUploadService) {}

  ngOnInit() {}

  ngOnChanges(changes) {
    this.existingDocument = this.files;
  }

  download(document: FileViewModel) {
    this._fileUploadService
      .downloadNonAsync(document.id, document.itemTag)
      .toPromise()
      .then((result) => {
        this.downloadUrl = result.serverFileName;

        if (this.downloadUrl) {
          window.open(this.downloadUrl, "_blank");
        } else alert("error downloading file");
      });
  }

  closeFileItem(document: FileViewModel, index) {
    //console.log(document);
    const url = "";
    this._fileUploadService
      .deleteFile(document.id)
      .toPromise()
      .then((result) => {
        this.existingDocument.splice(index, 1);
      });
  }

  formatFileName(fileName: string): string {
    const splitFileName = fileName.split(".");
    if (splitFileName[0].length > 13) {
      const newFileName = splitFileName[0].slice(0, 20);
      return `${newFileName}...${splitFileName[1]}`;
    }
    return fileName;
  }
}
