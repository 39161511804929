import { Component, ElementRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { finalize } from 'rxjs/operators';
import { FeedbackParseModel, FeedbackRating } from 'src/app/client-management/models/feedback';
import { ClientManagementService } from 'src/app/client-management/services/client-management.service';
import { ContactViewModel } from 'src/app/contact/models/contact-list';
import { ContactService } from 'src/app/contact/services/contact.service';
import { MatterList } from 'src/app/models/view-model/matter-list';
import { Notifier } from '../base-class/notifier';

import { HeaderState } from '../../store/reducers/user-header.reducer';
import { AppConfigService } from '../helpers/app-config.service';

@Component({
  selector: 'app-client-feedback-modal',
  templateUrl: './client-feedback-modal.component.html',
  styleUrls: ['./client-feedback-modal.component.scss']
})
export class ClientFeedbackModalComponent extends Notifier implements OnInit, OnChanges {
  @Input() client: ContactViewModel;
  @Input() counselId: number;
  @Output() feedbackRecorded = new EventEmitter<boolean>();

  feedbackForm: FormGroup;
  matterList: MatterList[] = [];
  ratings: FeedbackRating[] = [];
  selectedRating: FeedbackRating;
  btnSpinner = false;

  @ViewChild("closeFeedbackModal") closeFeedbackModal: ElementRef;

  constructor(
    public fb: FormBuilder,
    protected headerStore: Store<HeaderState>,
    private contactService: ContactService,
    private clientManagement: ClientManagementService,
    private config: AppConfigService
  ) {
    super(headerStore);

    this.feedbackForm = fb.group({
      matterId: ['', Validators.required],
      feedback: ['', Validators.required],
      ratingName: [''],
      ratingId: ['', Validators.required]
    });

    this.ratings = this.config.getConfig().feedbackRatings;
  }

  get matterId(): AbstractControl {
    return this.feedbackForm.get('matterId');
  }

  get feedback(): AbstractControl {
    return this.feedbackForm.get('feedback');
  }

  get ratingName(): AbstractControl {
    return this.feedbackForm.get('ratingName');
  }

  get ratingId(): AbstractControl {
    return this.feedbackForm.get('ratingId');
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.client.currentValue.id) {
      this.contactService.getContactMatters(this.client.id, '', '', '', 0, 10).subscribe(
        res => this.matterList = res.body
      );
    }
  }

  selectRating(rating: FeedbackRating): void {
    this.selectedRating = rating;

    this.feedbackForm.patchValue({
      ratingId: rating.id,
      ratingName: rating.caption,
    });
  }

  submitFeedback(): void {
    const formData = this.feedbackForm.value;
    this.btnSpinner = true;
    let feedbackModel = new FeedbackParseModel();
    feedbackModel.matterId = Number(formData.matterId);
    feedbackModel.rating = formData.ratingId;
    feedbackModel.clientId = this.client.id;
    feedbackModel.feedback = formData.feedback;
    feedbackModel.counselId = this.counselId;
    // this.clientManagement.submitFeedback(feedbackModel).pipe(
    //   finalize(() => this.btnSpinner = false)
    // ).subscribe(res => {
    //   this.closeFeedbackModal.nativeElement.click();
    //   this.feedbackForm.reset();
    //   this.feedbackRecorded.emit(true);
    // },
    //   err => this.sendErrorMessage('Could not record feedback'));
  }

}
