import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { isNullOrUndefined } from 'util';
import { UserDetail } from '../../models/view-model/user-detail';
import { TokenParams } from '../../models/generic-model.ts/token-params';
import { PermissionViewModel } from '../../models/view-model/permission';
import { Observable, of } from 'rxjs';
import { AccountResponseModel } from 'src/app/models/generic-model.ts/account-response';
import { EmailAccountTokenModel } from 'src/app/models/generic-model.ts/email-client-token';

import { throwError } from 'rxjs';
import * as allPermissions from '../../../../permissions.json';
@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {
  currentUser = new UserDetail();

  constructor(
    private localStorage: LocalStorageService
  ) { }

  get data() {
    const userData = this.localStorage.retrieve('user_profile');
    if (!userData) {
      return;
    }
    return userData;
  }

  getuserProfile(): Observable<UserDetail> {
    const value = this.localStorage.retrieve('user_profile');

    if (value !== '' || ' ') {
      let userData = new UserDetail();
      userData = value;
      const email = this.localStorage.retrieve('user_email');
      const role = this.localStorage.retrieve('user_role');
      if ((!isNullOrUndefined(role)) && (!isNullOrUndefined(email))) {
        userData.email = email;

        userData.role = role;
      } else {
        return throwError('user email ans role is not avalilable');
      }
      return of(userData);
    } else {

      return throwError('an error occured');
    }

  }

  // isAdmin

  getToken() {
    const tk = this.localStorage.retrieve('token');
    if ((!isNullOrUndefined(tk))) {
      return this.localStorage.retrieve('token');
    }
    // return this.cookieService.get('token');
  }

  addToken(token: TokenParams, permissions: PermissionViewModel[]) {
    // we delete the existing first
    this.localStorage.clear('token');
    this.localStorage.clear('refresh_token');
    this.localStorage.clear('expires_in');
    this.localStorage.clear('perm_acc');

    // then add
    if (!isNullOrUndefined(token)) {
      this.localStorage.store('token', token.auth_token);
      this.localStorage.store('refresh_token', token.refresh_token);
      this.localStorage.store('expires_in', token.expires_in);
      this.localStorage.store('perm_acc', JSON.stringify(permissions));
    }
  }

  addRedirectUrl(url: string) {
    this.localStorage.store('redirect_url', url);

  }

  getRedirectUrl(): string {
    if (!isNullOrUndefined(this.localStorage.retrieve('redirect_url'))) {
      return this.localStorage.retrieve('redirect_url');
    } else {
      return '';
    }
  }

  removeRedirectUrl() {
    this.localStorage.clear('redirect_url');
  }

  isLoggedIn() {
    const token = this.localStorage.retrieve('token');
    this.currentUser = this.localStorage.retrieve('user_profile');
    if ((!isNullOrUndefined(token)) && (!isNullOrUndefined(this.currentUser))) {
      if (token.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

  }

  isLicensed() {
    const token = this.localStorage.retrieve('token');
    this.currentUser = this.localStorage.retrieve('user_profile');

    if ((!isNullOrUndefined(token)) && (!isNullOrUndefined(this.currentUser))) {
      if (this.currentUser.isLicensed) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

  }

  /**
   * Add the user's profile to LocalStorage.
   */
  addUserProfile(userProfile) {
    userProfile.permissions = this.createPermissionsBag(userProfile.accountPermissions);
    this.localStorage.store('user_profile', userProfile);
  }

  /**
   * Create array of user's permissions from the accountPermissions <array of object>.
   */
  private createPermissionsBag(accountPermissions: any[]) {
    const permissions = [];

    accountPermissions.forEach((accountPermission) => {
      const index = accountPermission.permissionEntityId.toString();
      const applicationPermissions = (<any>allPermissions).default;
      let permission = applicationPermissions[index];

      if (!permission) {
        return;
      }

      permission = permission.alias;

      ['View', 'Edit', 'Write', 'Delete'].forEach((action) => {
        const _action = `can${action}`;
        const fullPermission = `${permission}.${action.toLocaleLowerCase()}`;

        if (!accountPermission.permissionAction[_action]) {
          return;
        }

        permissions.push(fullPermission);
      });
    });

    return permissions;
  }

  /**
   * Check if a user has access to permission.
   */
  public hasAccess(permission: string) {
    if (this.data.role === 'Administrator') {
      return true;
    }
    return this.data ? this.data.permissions.indexOf(permission) !== -1 : false;
  }

  addEmail(email: string) {
    this.localStorage.store('user_email', email);
  }

  addSubscriptionToOkm(sub: boolean) {
    this.localStorage.store('okm_subscription', sub);
  }

  addRole(role: string) {
    this.localStorage.store('user_role', role);
  }

  logout() {
    this.localStorage.clear('user_profile');
    this.localStorage.clear('token');
    this.localStorage.clear('user_permits');
    this.localStorage.clear('user_email');
    this.localStorage.clear('user_role');
    this.localStorage.clear('refresh_token');
    this.localStorage.clear('expires_in');
    this.localStorage.clear('okm_subscription');
  }

  clearPermission() {
    this.localStorage.clear('user_permits');
  }

  getUserId(): number {
    try {
      this.currentUser = this.localStorage.retrieve('user_profile');
      return this.currentUser.userId;
    } catch (err) {
      return err;
    }
  }

  getFirmId(): number {
    try {
      this.currentUser = this.localStorage.retrieve('user_profile');
      return this.currentUser.firmId;
    } catch (err) {
      return err;
    }
  }

  getOkmSubscription(): boolean {
    this.currentUser.isUserFirmSubscribedToOKM = this.localStorage.retrieve('okm_subscription');
    return this.currentUser.isUserFirmSubscribedToOKM;
  }


  addEmailToken(res: AccountResponseModel) {
    let tkns: string[] = [];
    tkns = this.getAllEmailTokenStrings();
    tkns.push(JSON.stringify(res));
    this.localStorage.store('user_emailAccounts', JSON.stringify(tkns));
  }

  getAllEmailTokenStrings(): string[] {
    let emailTokens: string[] = [];
    const ls_tkn = this.localStorage.retrieve('user_emailAccounts');
    if ((!isNullOrUndefined(ls_tkn)) && ls_tkn !== '') {
      emailTokens = JSON.parse(ls_tkn);
    }
    return emailTokens;
  }

  getArrayEmailTokenObject(): EmailAccountTokenModel[] {
    const result: EmailAccountTokenModel[] = [];
    const tokens: AccountResponseModel[] = [];
    let tokenStrings: string[] = [];

    tokenStrings = this.getAllEmailTokenStrings();
    if (tokenStrings.length > 0) {
      tokenStrings.forEach(tk => {
        tokens.push(JSON.parse(tk));
      });

      tokens.forEach(t => {
        const tokenItem = new EmailAccountTokenModel();
        tokenItem.unreadCount = t.unreadCount;
        tokenItem.token = JSON.parse(t.token);
        result.push(tokenItem);
      });
    }

    return result;
  }

}
