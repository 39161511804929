export class UserFirmRole {
  Id?: number;
  RoleName: any;
  PermissionGroup: PermissionGroup[];
}

export class PermissionGroup {
  Id: any;
  PermissionGroupName: string;
  permissionsActionId?: number[];
  permissionId?: number;
}


export const PermissionGroupsData: PermissionGroup[] = [
  {
    Id: 1,
    PermissionGroupName: 'Document'
  },
  // {
  //   Id: '12',
  //   PermissionGroupName: 'Matters'
  // },
  // {
  //   Id: '111111',
  //   PermissionGroupName: 'User Management'
  // },
   {
    Id: 2,
    PermissionGroupName: 'Tasks'
  },
  // {
  //   Id: 4,
  //   PermissionGroupName: 'Notes'
  // },
  // {
  //   Id: '111',
  //   PermissionGroupName: 'Report'
  // },
   {
    Id: 3,
    PermissionGroupName: 'Billings'
  },
  //  {
  //   Id: '1',
  //   PermissionGroupName: 'Contact Management'
  // }
];
