import { DraftCommentViewModel } from "src/app/models/view-model/draft-comment";
import { IModelFactory } from "./iparser";



export class ModelFactory implements IModelFactory {

test: DraftCommentViewModel;
    
create (comment: DraftCommentViewModel): DraftCommentViewModel {
 let _coment: DraftCommentViewModel = {

    commentId: comment.commentId,
    authorId: comment.authorId,
    content: comment.content,
    threadId: comment.threadId,
    createdAt: new Date(+comment.createdAt) ,

}

return _coment;

}




}

