import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  AppliedCouponResponse,
  ILicensePayment,
  LicenseEndPointModel,
  PlanGroup,
} from "../models/view-model/license-endpoint.model";
import { Endpoints } from "../models/shared-model/endpoint";
import { FirmLicenseModel } from "../models/view-model/firm-license.model";
import { LicenseUserModel } from "../models/view-model/license-user.model";
import { AppConfigService } from "../shared/helpers/app-config.service";
import { LicenseSummary } from "../models/view-model/license-summary";
import { map } from "rxjs/operators";
import { stringify } from "@angular/core/src/util";

@Injectable({
  providedIn: "root",
})
export class LicensingService {
  Endpoints = new Endpoints(this.config);

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) {}
  getLicensingList(): Observable<PlanGroup[]> {
    const api = this.config.getConfig().serverEndpoint + "license/type";

    return this.httpClient.get<PlanGroup[]>(api).pipe(
      map((data) => {
        const model: PlanGroup[] = [];
        //console.log(model);
        // console.log(data);
        Object.assign(model, data);

        model.forEach((c) => {
          if (Math.round(c.days / 365) === 2) {
            c.groupName = "Bi-Annually";
            c.maximumuAmount = c.bucketList.sort((c) => c.maximumUserCap)[
              c.bucketList.length - 1
            ].amount;
          } else if (c.days === 365) {
            c.groupName = "Annually";
            c.maximumuAmount = c.bucketList.sort((c) => c.maximumUserCap)[
              c.bucketList.length - 1
            ].amount;
          } else if (c.days === 183 || c.days === 182) {
            c.groupName = "Semi-Annually";
          } else if (c.days === 90 || c.days === 93) {
            c.groupName = "Quaterly";
            c.maximumuAmount = c.bucketList.sort((c) => c.maximumUserCap)[
              c.bucketList.length - 1
            ].amount;
          } else if (c.days === 30 || c.days === 31) {
            c.groupName = "Monthly";
            c.maximumuAmount = c.bucketList.sort((c) => c.maximumUserCap)[
              c.bucketList.length - 1
            ].amount;
          }
        });

        //console.log(model);
        return model;
      })
    );

    // return this.httpClient.get<PlanGroup[]>(api)
    // .pipe(
    //   map((data =>  {
    //       const model = new PlanGroup();
    //       //console.log(model);
    //       //console.log(data)
    //       Object.assign(model, data);
    //       // model.comments.forEach(c => {
    //       //   c.createdAt = new Date(+ c.createdAt);
    //       // })
    //       //console.log(model);
    //       return model;
    //    }))
    // );
  }

  getDiscount(): Observable<any> {
    const api = this.config.getConfig().serverEndpoint + "license/type";
    return this.httpClient.get<any>(api);
  }

  getCoupon(code: string, planId: number = null, clientId: number = null): Observable<AppliedCouponResponse> {
    const api = this.config.getConfig().serverEndpoint + "ExternalService/coupon";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("code", code);
    queryParams = queryParams.append("CRM_planId", planId ? String(planId) : null);
    queryParams = queryParams.append("CRM_clientId", clientId ? String(clientId) : null);
    return this.httpClient.get<AppliedCouponResponse>(api, { params: queryParams });
  }

  getLicenseData(firmId: number): Observable<FirmLicenseModel> {
    const api =
      this.config.getConfig().serverEndpoint + "license/" + firmId + "/firm";
    return this.httpClient.get<FirmLicenseModel>(api);
  }

  getLicenseUser(firmLicenseId: number): Observable<LicenseUserModel> {
    const api =
      this.config.getConfig().serverEndpoint +
      "license/" +
      firmLicenseId +
      "user";
    return this.httpClient.get<LicenseUserModel>(api);
  }

  saveLicense(plan: ILicensePayment): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint + "license/licensepayment";
    return this.httpClient.post<ILicensePayment>(api, plan);
  }

  getLicenseDetail(firmId: number): Observable<LicenseSummary> {
    const api =
      this.config.getConfig().serverEndpoint + `license/${firmId}/firm`;
    return this.httpClient.get<LicenseSummary>(api);
  }

  getBanks(): Observable<any[]> {
    const api =
      this.config.getConfig().serverEndpoint + `ExternalService/banks`;
    return this.httpClient.get<any[]>(api);
  }

  completePayment(payment: any): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint + "license/completePayment";
    return this.httpClient.post<any>(api, payment);
  }

  revokeLicense(firmLicenseId: number): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint +
      `license/${firmLicenseId}/revoke`;
    return this.httpClient.put<any>(api, { observe: "response" });
  }

  applyLicense(userId: number): Observable<any> {
    const api = this.config.getConfig().serverEndpoint + `license/user`;
    return this.httpClient.post<any>(api, {
      firmUserId: userId,
    });
  }
}
