import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "highlightText",
})
export class HighlightTextPipe implements PipeTransform {
  transform(value: any, args: any): any {
    if (!args) {
      return value;
    }

    const regex = new RegExp(args, "gi");
    const match = value.match(regex);
    const valueWithoutAnalysisCA = value.replace(
      /analysis_ca | analysis_sc/gi,
      ""
    );

    if (!match) {
      return valueWithoutAnalysisCA;
    }
    const result = valueWithoutAnalysisCA.replace(
      regex,
      `<span class='highlight'>${match[0]}</span>`
    );

    return result;
  }
}
