import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnChanges,
  Input,
  SimpleChanges,
} from "@angular/core";
import {
  CaseListViewModel,
  CaseTitleViewModel,
} from "src/app/models/view-model/cases-list";
import { ActivatedRoute, Router } from "@angular/router";
import { isNullOrUndefined } from "util";
import { FormControl } from "@angular/forms";
import { Store, select } from "@ngrx/store";
import * as fromlpdoc from "../../store/reducers/lp-doc.reducer";

import * as LPDocActions from "../../store/actions/lp-doc.actions";
import { Notifier } from "src/app/shared/base-class/notifier";

import * as fromUserHeaderStore from "../../store/reducers/user-header.reducer";
import { Observable } from "rxjs";
import { CKEditor5 } from "@ckeditor/ckeditor5-angular";
import * as DecoupledEditorwithComment from "../helpers/build/ckeditor";
import {
  ISearchData,
  resourceType,
  SearchResourceType,
} from "./models/search.model";

@Component({
  selector: "app-law-report",
  templateUrl: "./law-report.component.html",
  styleUrls: ["./law-report.component.scss"],
})
// ,OnChanges
export class LawReportComponent extends Notifier implements OnInit {
  public resourceTypeEnum = resourceType;
  public screen: string;
  public searchResourceType = SearchResourceType;

  public Editor = DecoupledEditorwithComment;
  public editor?: CKEditor5.Editor;
  src: string;
  query: string;
  // searchResult = new CaseListViewModel();
  searchResult: any;

  @Input() searchValue: string;
  @Input() searchValue2: string;

  countObservable: number[] = [];

  searchBox = new FormControl("");
  showLoader: boolean;
  showNoResult: boolean;
  page: number;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>,
    private lpDocStore: Store<fromlpdoc.State>
  ) {
    super(headerStore);

    this.page = 1;

    this.lpDocStore
      .pipe(select(fromlpdoc.getSearchResult))
      .subscribe((data) => {
        if (!isNullOrUndefined(data)) {
          this.countObservable = [];

          this.searchResult = data;

          if (!isNullOrUndefined(this.searchResult.data)) {
            for (let i = 1; i <= this.searchResult.last_page; i++) {
              this.countObservable.push(i);
            }
          } else {
            this.searchResult.data = [];
          }
        }
      });

    this.lpDocStore
      .pipe(select(fromlpdoc.getSearchStartedState))
      .subscribe((data) => {
        if (!isNullOrUndefined(data)) {
          this.showLoader = data;
        }
      });

    this.lpDocStore
      .pipe(select(fromlpdoc.getSearchCompletedState))
      .subscribe((data) => {
        if (!isNullOrUndefined(data)) {
          this.showNoResult = data;
        }
      });

    this.lpDocStore.pipe(select(fromlpdoc.getSearchParam)).subscribe((data) => {
      if (!isNullOrUndefined(data)) {
        // get the searched value from the editor suggestion
        this.searchBox.patchValue(data);
      }
    });

    this.lpDocStore.pipe(select(fromlpdoc.getSearchError)).subscribe((data) => {
      //console.log(data);
      if (!isNullOrUndefined(data)) {
        // push error message
        this.sendErrorMessage("An unknown error occured, check your license");
        //console.log(data);
      }
    });
  }

  ngOnInit() {
    this.showLoader = false;
    this.showNoResult = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    console.log(`${this.searchValue} search value`)
    this.searchBox.patchValue(this.searchValue)
    this.searchCases()
  }

  searchCases() {
    console.log(this.searchValue2)
    const searchQuery = this.searchBox.value;

    this.lpDocStore.dispatch(
      new LPDocActions.LoadCasesSearch({ searchQuery, page: this.page })
    );
  }

  callPage(page: number) {
    const searchQuery = this.searchBox.value;

    this.lpDocStore.dispatch(
      new LPDocActions.LoadCasesSearch({ searchQuery, page: page })
    );
    this.page = page;
  }

  selectCase(selectedCase: CaseTitleViewModel) {
    if (
      selectedCase.practice_area == null ||
      selectedCase.practice_area == "" ||
      selectedCase.practice_area == undefined
    ) {
    } else {
      this.lpDocStore.dispatch(new LPDocActions.SetCurrentCase(selectedCase));
      this.router.navigate(
        [
          "draft/editor/report/" +
          encodeURIComponent(selectedCase.suit_number) +
          "/" +
          selectedCase.court +
          "/" +
          selectedCase.practice_area,
        ],
        // { relativeTo: this.activatedRoute }
      );
    }
  }
  public isResource(item: ISearchData, type: string): boolean {
    return item.resource_type === type;
  }

  public generateLink(item: ISearchData): string[] {
    if (
      item.resource_type === this.resourceTypeEnum.appeal ||
      item.resource_type === this.resourceTypeEnum.supreme
    ) {
      return ["/analytics"];
    } else if (item.resource_type === this.resourceTypeEnum.tj) {
      return ["/t-j", item.publication_id];
    } else {
      return ["/reader"];
    }
  }
}
