import { Injectable } from '@angular/core';
import { AppConfigService } from '../shared/helpers/app-config.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CalendarViewModel } from '../models/view-model/calendar-event';
import { CurrentUserService } from './factories/current-user.service';
import { UserDetail } from '../models/view-model/user-detail';



import * as LoginActions from '../auth/state/login.actions';
import * as fromStore from '../auth/state/index';
import { Store, select } from '@ngrx/store';
@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  user = new UserDetail();
  constructor(private httpClient: HttpClient,
    private config: AppConfigService,
    // private currentUserService: CurrentUserService,
    private store: Store<fromStore.State>,
  ) {

    this.store.pipe(select(fromStore.getUserDetail)).subscribe(myUser => {
      this.user = myUser;
    });
  }

  public addCalendarEvent(payload: CalendarViewModel) {
    const url = this.config.getConfig().serverEndpoint + 'task/singlereminder';
    return this.httpClient.post(url, payload);
  }

  public updateCalendarEvent(payload: CalendarViewModel) {
    const url = this.config.getConfig().serverEndpoint + 'task/singlereminder';
    return this.httpClient.put(url, payload);
  }

  public loadUserReminderCalendar(month: number = 0, year: number): Observable<CalendarViewModel[]> {
    const url = this.config.getConfig().serverEndpoint + `Dashboard/${month}/${year}/userremindercalender`;
    return this.httpClient.get<CalendarViewModel[]>(url);
  }

  public loadUserCalendar(month: number = 0, year: number): Observable<CalendarViewModel[]> {

    let url = '';

    if (this.user.isAdmin) {
      url = this.config.getConfig().serverEndpoint + `Dashboard/${month}/${year}/firmgeneralcalender`;
    } else {
      url = this.config.getConfig().serverEndpoint + `Dashboard/${month}/${year}/usergeneralcalender`;

    }


    return this.httpClient.get<CalendarViewModel[]>(url);
  }

  public loadFirmProceedingCalendar(month: number = 0, year: number): Observable<CalendarViewModel[]> {
    const url = this.config.getConfig().serverEndpoint + `Dashboard/${month}/${year}/firmproceedingcalender`;
    return this.httpClient.get<CalendarViewModel[]>(url);

  }
  public loadFirmApplicationCalendar(month: number = 0, year: number): Observable<CalendarViewModel[]> {
    const url = this.config.getConfig().serverEndpoint + `Dashboard/${month}/${year}/firmapplicationcalender`;
    return this.httpClient.get<CalendarViewModel[]>(url);
  }

  public loadUserTaskCalendar(month: number = 0, year: number): Observable<CalendarViewModel[]> {
    const url = this.config.getConfig().serverEndpoint + `Dashboard/${month}/${year}/userTaskCalender`;
    return this.httpClient.get<CalendarViewModel[]>(url);
  }


  public deleteCalendarReminder(reminderId) {

    const url = this.config.getConfig().serverEndpoint + `task/singlereminder/${reminderId}`;
    return this.httpClient.delete<any>(url);

  }
}
