import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnChanges, DoCheck, SimpleChanges } from '@angular/core';
import { UserDetail } from '../../models/view-model/user-detail';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { MatterConfigService } from '../../services/matter-config.service';
import { GenericListViewModel } from 'src/app/models/generic-model.ts/generic-list';
import { MatterService } from 'src/app/services/matter.service';
import { CategoryObj } from 'src/app/form-widgets/new-brief-two/new-brief-two.component';

@Component({
  selector: 'app-new-sub-type',
  templateUrl: './new-sub-type.component.html',
  styleUrls: ['./new-sub-type.component.scss']
})
export class NewSubTypeComponent implements OnInit, OnChanges {
  errorMessage: string;
  public successText: string;
  public errorText: string;
  public showSuccessMessage: boolean;
  public showErrorMessage: boolean;
  public btnSpinner: boolean;
  newCategorySubTypeForm: FormGroup;
  userData = new UserDetail();
  states: any[] = [];
  currentCategoryId: number;
  currentCategoryTypeId: number;

  briefCategories: GenericListViewModel[] = [];
  briefCategoryTypes: GenericListViewModel[] = [];
  @Input() categoryTypeId: number;
  @Input() catObj: CategoryObj;
  @Input() existingSubCategories: GenericListViewModel[];
  @Output() successErrorDisplay = new EventEmitter();

  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChild('messageBox') messageBox: ElementRef;
  subCatID: number;
  selectedsubCat: any;
  selectedCat: GenericListViewModel;
  setDisabled = false;
  subcategoryExistsError: boolean = false;
  constructor(
    private fb: FormBuilder,
    private matterConfigService: MatterConfigService,
    private matterService: MatterService
  ) {
  }


  ngOnInit() {
    this.showErrorMessage = false;
    this.btnSpinner = false;

    this.addCategorySubTypeForm();
    if (isNullOrUndefined(this.catObj.categoryId)) {
      this.getCategories();
    }

  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.catObj.subCategoryId !== 0) {
      this.subCatID = this.catObj.subCategoryId;
      this.newCategorySubTypeForm.patchValue({
        categoryID: this.catObj.categoryId,
        SubcategoryID: this.catObj.subCategoryId
      });
    }

    if (changes.existingSubCategories) {
      this.existingSubCategories = changes.existingSubCategories.currentValue;
    }

  }

  getCategories() {
    this.matterConfigService.getBriefCategories().subscribe(data => {
      this.briefCategories = data;
    });
  }

  getCategoryTypes(categoryId) {
    this.matterConfigService
      .getBriefCategoryTypes(categoryId)
      .subscribe(data => {
        this.briefCategoryTypes = data;

        if (this.catObj.subCategoryId !== 0 && this.catObj.categoryId !== 0) {
          this.newCategorySubTypeForm.patchValue({
            title: '',
            categoryID: this.catObj.categoryId,
            SubcategoryID: this.catObj.subCategoryId
          });
          this.categoryID.disable();
          this.SubcategoryID.disable();
        }
      });
  }


  addCategorySubTypeForm() {
    this.newCategorySubTypeForm = this.fb.group({
      title: ['', Validators.required],
      categoryID: ['', Validators.required],
      SubcategoryID: ['', Validators.required]
    });
  }

  get title() {
    return this.newCategorySubTypeForm.get('title');
  }

  get categoryID() {
    return this.newCategorySubTypeForm.get('categoryID');
  }

  get SubcategoryID() {
    return this.newCategorySubTypeForm.get('SubcategoryID');
  }

  savenewSubType() {
    this.btnSpinner = true;

    if (this.existingSubCategories.length > 0) {
      for (let subcategory of this.existingSubCategories) {
        if (subcategory.name.toLowerCase() == this.title.value.toLowerCase()) {
          this.subcategoryExistsError = true;
          break;
        }
      }
    }

    if (this.subcategoryExistsError) {
      this.btnSpinner = false;
      return;
    }

    const addCat = {
      matterCategoryTypeId: Number(this.SubcategoryID.value),
      name: this.title.value,
      description: ''
    };
    const showSuccessMessage = true;
    const successText = '"' + this.title.value + ' " Has been added';
    const showErrorMessage = true;
    const errorText = 'an error occurred while trying to create practise';

    this.matterService.addPractise(addCat).subscribe(
      data => {
        this.successErrorDisplay.emit({ showSuccessMessage, successText });
        this.newCategorySubTypeForm.reset();
        this.btnSpinner = false;
        this.closeModal.nativeElement.click();
      },
      error => {
        this.successErrorDisplay.emit({ showErrorMessage, errorText });
        this.btnSpinner = false;
        this.closeModal.nativeElement.click();

      }
    );
  }

  clearError(): void {
    this.subcategoryExistsError = false;
  }

}
