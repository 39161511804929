import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './components/login/login.component';
import { StoreModule } from '@ngrx/store';
import { loginReducer } from './state/login.reducer';
import { EffectsModule } from '@ngrx/effects';
import { LoginEffects } from './state/login.effects';
import { AuthService } from '../services/auth.service';
import { FirmRegistrationComponent } from './components/firm-registration/firm-registration.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { NewInvitedUserComponent } from './components/new-invited-user/new-invited-user.component';
import { EmailConfirmationComponent } from './components/email-confirmation/email-confirmation.component';
import { RegistrationSuccessComponent } from './components/registration-success/registration-success.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ConfirmAccountComponent } from './components/confirm-account/confirm-account.component';
import { SharedModule } from '../shared/shared.module';
import { ForceLogoutComponent } from './components/force-logout/force-logout.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';

@NgModule({
  declarations: [LoginComponent, FirmRegistrationComponent,
    ResetPasswordComponent, ForgotPasswordComponent,
    NewInvitedUserComponent, EmailConfirmationComponent, RegistrationSuccessComponent, LogoutComponent, ConfirmAccountComponent, ForceLogoutComponent, SetPasswordComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    StoreModule.forFeature('auth', loginReducer),
    EffectsModule.forFeature([LoginEffects])
  ]
  ,
  providers: [AuthService]
})
export class AuthModule { }
