import { Component, OnInit, inject } from '@angular/core';
import { MatSnackBarRef } from '@angular/material';

@Component({
  selector: 'app-snackbar-toast',
  templateUrl: './snackbar-toast.component.html',
  styleUrls: ['./snackbar-toast.component.scss']
})
export class SnackbarToastComponent implements OnInit {

  snackBarRef = inject(MatSnackBarRef);

  constructor() { }

  ngOnInit() {
  }

}
