import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router,
  CanLoad,
  Route,
  UrlSegment,
} from "@angular/router";
import { CurrentUserService } from "../../services/factories/current-user.service";

@Injectable({ providedIn: "root" })
export class MainGuard implements CanLoad {
  constructor(
    private currentUserService: CurrentUserService,
    private router: Router
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    let url = `/${route.path}`;
    return this.checkLogin(segments);
  }

  checkLogin(segments: UrlSegment[]): boolean {
    if (this.currentUserService.isLoggedIn()) {
      return true;
    }

    // //console.log(this.getFullPath(segments));

    this.router.navigate(["/login"], {
      queryParams: { route: this.getFullPath(segments) },
    });
    return false;
  }

  getFullPath(segments: UrlSegment[]) {
    return segments.reduce((path, currentSegment) => {
      return `${path}/${currentSegment.path}`;
    }, "");
  }
}
