import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppConfigService } from "../helpers/app-config.service";
import { Store, select } from "@ngrx/store";
import { State } from "../../auth/state";
import { ActivatedRoute, Router } from "@angular/router";
import { LicenseEndPointModel, ILicensePayment, AppliedCouponData, } from "../../models/view-model/license-endpoint.model";
import { LicensingService } from "../../services/licensing.service";
import { UserDetail } from "src/app/models/view-model/user-detail";
import { isNullOrUndefined } from "util";
import { Notifier } from "../base-class/notifier";
import * as fromUserHeaderStore from "../../store/reducers/user-header.reducer";
import { CurrentUserService } from "src/app/services/factories/current-user.service";
import { PlanDetails } from "src/app/home/helpers/paystack-options";

@Component({
  selector: "app-license-payment",
  templateUrl: "./license-payment.component.html",
  styleUrls: ["./license-payment.component.scss"],
})
export class LicensePaymentComponent extends Notifier implements OnInit {
  userData = new UserDetail();
  licensingForm: FormGroup;
  crmPlanId: number;
  getAllLicenses: LicenseEndPointModel[] = [];
  btnSpinner: boolean;
  applyCouponLoader: boolean;
  paymentLoader: boolean;
  public price: number = 0;
  couponForm: FormGroup;
  appliedCoupon = new AppliedCouponData();
  features: string[] = [
    "Matter Management",
    "Contact Management",
    "Billing and Invoicing",
    "Legal Calendaring",
    "Document Management",
    "Unlimited Data Storage",
    "Firm Management",
    "Free Training and Support",
  ]

  selectedLicense = new LicenseEndPointModel();

  @ViewChild("focusBox") focusBox: ElementRef;

  constructor(
    public fb: FormBuilder,
    public service: LicensingService,
    public store: Store<State>,
    public config: AppConfigService,
    public activatedRoute: ActivatedRoute,
    public currentUserService: CurrentUserService,
    // the day is the secret key for the encryption
    // private encryptionService: RsaService,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>,
    public router: Router
  ) {
    super(headerStore);

    this.store.pipe(select("auth")).subscribe((data) => {
      this.userData = data.userProfile;
    });

    this.licensingForm = fb.group({
      terms_agreement: ["", Validators.required],
      number_of_users: [1, Validators.required],
    });

    this.couponForm = fb.group({
      couponCode: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.getEndPointLicensing();
  }

  get terms_agreement() {
    return this.licensingForm.get("terms_agreement");
  }

  get number_of_users() {
    return this.licensingForm.get("number_of_users");
  }

  get couponCode() {
    return this.couponForm.get("couponCode");
  }

  getEndPointLicensing() {
    this.service.getLicensingList().subscribe((data) => {
      if (!isNullOrUndefined(data)) {
        // console.log(data);
        this.getAllLicenses = data[0].bucketList.sort((a, b) => this.sortByMaximumUser(a, b));
        // this.getAllLicenses = data[0].bucketList;
        this.selectedLicense = this.getAllLicenses[0];
        this.price = this.selectedLicense.amount;
        this.crmPlanId = this.selectedLicense.crmPlanId
        this.showLoader = false;
        this.showNoResult;
      }
    },
    (error) => {
      this.showLoader = false;
      this.showNoResult;
    })
  }

  sortByMaximumUser(a: LicenseEndPointModel, b: LicenseEndPointModel): number {
    const A = a.maximumUserCap;
    const B = b.maximumUserCap;
    if (A > B) { return 1 }
    if (A < B) { return -1 }
    return 0;
  }

  onInputUsers(event) {
    this.checkUsersRange(+event.target.value);
  }

  increaseUsers() {
    let increasedUsers: number = this.number_of_users.value + 1;
    this.checkUsersRange(increasedUsers);
  }

  decreaseUsers() {
    let decreasedUsers: number = this.number_of_users.value - 1;
    this.checkUsersRange(decreasedUsers);
  }

  checkUsersRange(num: number) {
    let initialUsers = this.number_of_users.value;
    let license: LicenseEndPointModel;
    this.licensingForm.patchValue({ number_of_users: num });
    let intNum = this.number_of_users.value;
    if (isNullOrUndefined(intNum)) { this.licensingForm.patchValue({ number_of_users: 1 }) };
    if (intNum < 1) { this.licensingForm.patchValue({ number_of_users: this.getAllLicenses[0].maximumUserCap }) };
    if (intNum > 30) { this.licensingForm.patchValue({ number_of_users: this.getAllLicenses[this.getAllLicenses.length - 1].maximumUserCap }) };
    license = this.getAllLicenses.find(license => license.maximumUserCap === this.number_of_users.value);
    if (isNullOrUndefined(license)) {
      if (initialUsers > num) { this.decreaseUsers() };
      if (initialUsers < num || initialUsers === num) { this.increaseUsers() };
    }
    if (!isNullOrUndefined(license)) {
      this.selectPlan(license);
    }
  }

  selectPlan(plan: LicenseEndPointModel) {
    this.selectedLicense = plan;
    this.price = this.selectedLicense.amount;
    this.crmPlanId = this.selectedLicense.crmPlanId;
    this.appliedCoupon = new AppliedCouponData();
    // this.couponForm.reset();
  }

  onApplyCoupon() {
    this.btnSpinner = true;
    this.applyCouponLoader = true;
    this.service.getCoupon(this.couponCode.value, this.selectedLicense.crmPlanId, this.currentUserService.getUserId())
      .subscribe(
        (res) => {
          // console.log(res);
          this.btnSpinner = false;
          this.applyCouponLoader = false;
          this.appliedCoupon = res.data;
          this.price = this.appliedCoupon.discounted_cost;
          this.licensingForm.patchValue({ terms_agreement: true });
        },
        err => {
          const regexClient = new RegExp('.*?\\bnot valid for CRM_clientId\\b.*?[.?!]', 'g');
          const regexTransLess = new RegExp('.*?\\btransaction amount is lesser than\\b.*?[.?!]', 'g');
          this.btnSpinner = false;
          this.applyCouponLoader = false;
          this.appliedCoupon = new AppliedCouponData();
          this.price = this.selectedLicense.amount;
          // console.log(err);
          if (err.error.CRM_clientId && err.error.CRM_clientId[0].match(regexClient)) {
            this.sendErrorMessage("No account found. Kindly login or create an account");
            this.focusBox.nativeElement.scrollIntoView({ behavior: 'smooth'});
          }
          if (err.error.message && err.error.message.match(regexTransLess)) {
            this.sendErrorMessage("Plan not sufficient for the amount required to get discount.");
            this.focusBox.nativeElement.scrollIntoView({ behavior: 'smooth'});
          }
          if (err.error.status && err.error.status === 404) {
            this.sendErrorMessage("Invalid coupon");
            this.focusBox.nativeElement.scrollIntoView({ behavior: 'smooth'});
          }
        }
      )
  }

  onProceedToPayment() {
    this.btnSpinner = true;
    this.paymentLoader = true;
    const plan: ILicensePayment = {
      licenseTypeId: this.selectedLicense.crmPlanId, // BucketId
      licenseName: "Bronzze", // BucketName
      numberOfusers: this.selectedLicense.maximumUserCap, // BucketmaximumUserCap
      licenseuserCharge: this.price,
      numberOfDays: this.selectedLicense.valueDays,
      currency: "NGN",
    };

    this.service.saveLicense(plan).toPromise()
      .then((res) => {
        this.btnSpinner = false;
        this.paymentLoader = false;
        const id = res;
        const planDetails: PlanDetails = {
          crmPlanId: this.crmPlanId,
          amount: this.price,
          licensePayementId: id,
          couponCode: this.couponCode.value
        };
        // stringify the planDetail object
        const planString = JSON.stringify(planDetails);
        this.router.navigate(["/pay"], {
          // encrypt plan object and send as queryParam
          queryParams: { plan: encodeURIComponent(planString) },
        });
      })
      .catch((error) => {
        this.btnSpinner = false;
        this.paymentLoader = false;
        this.sendErrorMessage("An error occurred while trying to create plan");
        this.focusBox.nativeElement.scrollIntoView({ behavior: 'smooth'});
      });

    // call service to save the licence info
  }
}
