import { CaseAanlyticsViewModel } from "./case-analytics";

// export class PrincipleViewModel {
//   status: number;
//   data: CaseRatioViewModel[];
// }

export class PrincipleViewModel {
  status: number;
  ratio: CaseRatioViewModel[];
  data: {
    case_title: string;
    suit_number: string;
    court: string;
    date: string;
    date_formatted: string;
    citation: string;
    division: string;
    judges: string[];
    defendant: string;
    respondent_counsel: string;
    appellant: string;
    ratios: CaseRatioViewModel[];
    pages: { page_number: number; content: string }[];
    summary: string;
  };
}

// export class PrincipleViewModel {
//   status: number;
//   message: string;
//   data: {
//     case_title: string;
//     suit_number: string;
//     court: string;
//     date: string;
//     date_formatted: string;
//     citation: string;
//     division: string;
//     judges: string[];
//     defendant: string;
//     respondent_counsel: string;
//     appellant: string;
//     ratios: CaseRatioViewModel[];
//     pages: { page_number: number; content: string }[];
//     summary: string;
//   };
// }

export class CaseRatioViewModel {
  pk: number;
  suitno: string;
  legal_head: string;
  subject_matter: string;
  issue: string;
  principle: string;
  analytics: CaseAanlyticsViewModel;
  as_at: string
case_title:string
category: string
citation: string
court:string
from_page:number
from_para: string
has_full_analytics: true
issue_id: number
judge: string
judge_id: number
oldest_court: string
oldest_pk: number
precedence_value: number
principle_citation: string
principle_status: null
recommended_court: string
recommended_pk: number
suit_number: string
to_page: number
to_para: string
}
