import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges, } from "@angular/core";
import { Store, select } from "@ngrx/store";
import * as fromStore from "../../auth/state/index";
import * as LoginActions from "../../auth/state/login.actions";
import { MatterService } from "src/app/services/matter.service";
import { UserDetail } from "src/app/models/view-model/user-detail";
import { MatterList } from "src/app/models/view-model/matter-list";
import { isNullOrUndefined } from "util";
import * as fromMatters from "../../store/reducers/matters.reducers";
import { FormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Notifier } from "../base-class/notifier";
import * as fromUserHeaderStore from "../../store/reducers/user-header.reducer";
import { MatterApplicationService } from "src/app/matter-application/services/matter-application.service";
import { ApplicationViewModel } from "src/app/matter-application/models/application-list";

@Component({
  selector: "app-matter-search-box",
  templateUrl: "./matter-search-box.component.html",
  styleUrls: ["./matter-search-box.component.scss"],
})
export class MatterSearchBoxComponent extends Notifier implements OnInit, OnChanges, OnDestroy {

  user = new UserDetail();
  matterList: MatterList[] | ApplicationViewModel[] = [];
  titleString: string;
  currentMatterId: number;
  spinner: boolean;

  @Input() searchBoxTagOption: number;
  @Input() selectedMatterId: number;
  @Input() CategoryId: number = 0;
  @Input() disableChangeOfItem: boolean;
  @Input() selectedMatterName: string;
  @Output() postSelectedMatter = new EventEmitter();
  @Output() postSelectedMatterName = new EventEmitter();

  search_value = new FormControl("");

  constructor(
    private matterService: MatterService,
    private applicationService: MatterApplicationService,
    private store: Store<fromStore.State>,
    private matterStore: Store<fromMatters.State>,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>
  ) {
    super(headerStore);

    this.store.dispatch(new LoginActions.LoadLocalUserDetail());
    this.store.pipe(select(fromStore.getUserDetail)).subscribe((data) => {
      this.user = data;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populate_matter_list();
  }

  ngOnInit() {
    this.search_value.valueChanges.pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        this.populate_matter_list();
      });

    if (this.selectedMatterId === 0) {
      if (this.titleString === "matter") {
        this.selectedMatterName = "Select an item from this dropdown to find a " + this.titleString;
      } else {
        this.selectedMatterName = "Select an item from this dropdown to find an " + this.titleString;
      }
    }

    if (isNullOrUndefined(this.selectedMatterId)) {
      this.selectedMatterName = "select " + this.titleString;
    }

    if (this.selectedMatterId) {
      this.postSelectedMatter.emit(this.selectedMatterId.toString());
      this.postSelectedMatterName.emit(this.selectedMatterName);
    }
  }

  populate_matter_list() {
    this.spinner = true;

    // change value to make it generic
    if (!this.selectedMatterName) {
      this.selectedMatterName = "Select matter";
    }

    if (this.searchBoxTagOption === 3) {
      this.titleString = "application";
      // this.addSubscription(
      //   this.matterService.getAllApplication(this.search_value.value)
      //     .subscribe((data) => {
      //       this.matterList = data.body as ApplicationViewModel[];
      //     })
      // );
      // if (this.selectedMatterId) {
      //   this.addSubscription(
      //     this.applicationService.getMatterApplication( this.selectedMatterId, false, this.search_value.value )
      //       .subscribe((data) => { this.matterList = data.body; })
      //   );
      // } else {
      //   this.addSubscription(
      //     this.matterService.getAllApplication(this.search_value.value)
      //       .subscribe((data) => { this.matterList = data.body as ApplicationViewModel[]; })
      //   );
      // }
    }

    if (this.searchBoxTagOption === 2) {
      this.titleString = "endorsement";
      // this.addSubscription(
      //   this.matterService.getAllProceeding(this.search_value.value)
      //     .subscribe((data) => {
      //       this.matterList = data.body;
      //       // console.log(data);
      //     })
      // )
      // if (this.selectedMatterId) {
      //   this.addSubscription(
      //     this.matterService.getMatterEndorsement(this.selectedMatterId, false, this.search_value.value)
      //       .subscribe((data) => { this.matterList = data.body; console.log("getMatterEndorsement"); console.log(data) })
      //   );
      // } else {
      //   this.addSubscription(
      //     this.matterService.getAllProceeding(this.search_value.value)
      //       .subscribe((data) => { this.matterList = data.body; console.log("getAllProceeding"); console.log(data) })
      //   );
      // }
    }

    if (this.searchBoxTagOption === 1) {
      this.titleString = "matter";
      // this.matterService.getMatterList( this.user.firmId, this.search_value.value, "")
      //   .subscribe((data) => {
      //     this.matterList = data.body as MatterList[];
          // console.log(data);
          // if (!isNullOrUndefined(this.selectedMatterId)) {
          //   if (this.selectedMatterId > 0) {
          //     const matter = this.matterList.find(
          //       (c) => c.id === this.selectedMatterId
          //     );
          //     if (!isNullOrUndefined(matter)) {
          //       this.selectedMatterName = this.matterList.find(
          //         (c) => c.id === this.selectedMatterId
          //       ).name;
          //     }
          //     this.postSelectedMatter.emit(this.selectedMatterId.toString());
          //     this.postSelectedMatterName.emit(this.selectedMatterName);
          //   }
          // }
        // });
    }

    this.matterService.getMatterList( this.user.firmId, this.search_value.value, "")
      .subscribe((data) => {
        this.matterList = data.body as MatterList[];
        this.spinner = false;
      })

    // if (this.searchBoxTagOption === 0) {
    //   this.titleString = "matter";
    //   this.matterService.getAllLitigation(this.search_value.value)
    //     .subscribe((data) => { this.matterList = data.body; console.log("getAllLitigation"); console.log(data) });
    // }
  }

  selectMatter(name: string, id: number) {
    this.selectedMatterName = name;
    this.selectedMatterId = id;
    this.postSelectedMatter.emit(this.selectedMatterId.toString());
    this.postSelectedMatterName.emit(this.selectedMatterName);
  }

  ngOnDestroy(): void {
    this.clearSubscription();
  }
}
