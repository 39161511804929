import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/shared/helpers/app-config.service';
import { Endorsement, ProceedingViewModel } from '../models/endorsement';
import { NewEndorsement } from '../models/new-endorsement';

@Injectable({
  providedIn: 'root'
})
export class EndorsementService {

  constructor(private httpClient: HttpClient, private config: AppConfigService) { }

  loadEndorsementList(matterId: number, searchQuery = "", sortDirection = ""): Observable<ProceedingViewModel[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("searchQuery", searchQuery);
    queryParams = queryParams.append("sort", sortDirection);
    const url = `${this.config.getConfig().serverEndpoint}matter/${matterId}/proceeding`;
    return this.httpClient.get<ProceedingViewModel[]>(url, { params: queryParams });
  }



  addNewEndorsement(object: NewEndorsement): Observable<boolean> {
    const url = `${this.config.getConfig().serverEndpoint}matter/proceeding`;
    return this.httpClient.post<boolean>(url, object);
  }

  deleteMatterEndorsement(matterId: number): Observable<boolean> {
    const url = `${this.config.getConfig().serverEndpoint}matter/proceeding/${matterId}`;
    return this.httpClient.delete<boolean>(url);
  }

  editEndorsement(object: NewEndorsement): Observable<Endorsement> {
    const url = `${this.config.getConfig().serverEndpoint}matter/proceeding`;
    return this.httpClient.put<any>(url, object);
  }
}
