import { IDraft } from "../interfaces/lp-draft";

export class DraftParseModel implements IDraft {
    title: string; description: string;
    contents: string;
    matterId: number;
    directoryTypeId: number;
    directoryId: number;
    templateId: string;


}