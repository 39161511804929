import { ContractViewModalComponent } from "./contract-view-modal/contract-view-modal.component";
import { NgModule } from "@angular/core";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { AngularMaterialModule } from "../angular-material/angular-material.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AngularEditorModule } from '@kolkov/angular-editor';

import { FileUploadService } from "../services/file-upload.service";
import { NotificationService } from "../services/notification.service";
import { AnonymousHeaderComponent } from "./anonymous-header/anonymous-header.component";
import { AnonymousFooterComponent } from "./anonymous-footer/anonymous-footer.component";
import { NotificationsListComponent } from "./notifications-list/notifications-list.component";
import { PrimarySidebarComponent } from "./primary-sidebar/primary-sidebar.component";
import { SharedRoutingModule } from "./shared-routing.module";
import { UserHeaderComponent } from "./user-header/user-header.component";
import { CurrentUserService } from "../services/factories/current-user.service";
import { GenerateDefaultPictureComponent } from "./generate-default-picture/generate-default-picture.component";
// tslint:disable-next-line:max-line-length
import { GenerateDefaultPictureNonObservableComponent } from "./generate-default-picture-non-observable/generate-default-picture-non-observable.component";
import { FormParentComponent } from "./form-parent/form-parent.component";
import { ChooseUserComponent } from "./choose-user/choose-user.component";
import { ConfirmActionComponent } from "./confirm-action/confirm-action.component";
import { StopwatchTimerComponent } from "./stopwatch-timer/stopwatch-timer.component";
import { LicensePaymentComponent } from "./license-payment/license-payment.component";
import { ViewFileComponent } from "./view-file/view-file.component";
import { AuthAdminGuard } from "./guards/auth-admin-guard.service";
import { UserHeaderEffects } from "../store/effects/user-header.effects";
import { UserHeaderReducer } from "../store/reducers/user-header.reducer";
import { StoreModule } from "@ngrx/store";
import { TaskTagComponent } from "./task-tag/task-tag.component";
import { ViewTaskComponent } from "./view-task/view-task.component";
import { CompletedTaskComponent } from "./completed-task/completed-task.component";
import { UpcomingTaskComponent } from "./upcoming-task/upcoming-task.component";
import { DueTaskComponent } from "./due-task/due-task.component";
import { taskReducer } from "../store/reducers/task.reducer";
import { TaskEffects } from "../store/effects/task.effects";
import { EffectsModule } from "@ngrx/effects";
import { UtilsService } from "./services/util.service";
import { NewContactModalComponent } from "./new-contact-modal/new-contact-modal.component";
import { PaginatorComponent } from "./paginator/paginator.component";
import { TimiWidgetComponent } from "./timi-widget/timi-widget.component";
import { GlobalAlertComponent } from "./global-alert/global-alert.component";
import { UserShareComponent } from "./user-share/user-share.component";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { NewFolderComponent } from "./new-folder/new-folder.component";
import { RenameItemComponent } from "./rename-item/rename-item.component";
import { NewExpenseComponent } from "./new-expense/new-expense.component";
import { NewPaymentComponent } from "./new-payment/new-payment.component";
import { PageLoaderComponent } from "./page-loader/page-loader.component";
import { AttachMatterComponent } from "./attach-matter/attach-matter.component";
import { ChangeRoleModalComponent } from "./change-role-modal/change-role-modal.component";
import { ReusablesModule } from "../reusables/reusables.module";
import { NewTaskModalComponent } from "./new-task-modal/new-task-modal.component";
import { MatterSearchBoxComponent } from "./matter-search-box/matter-search-box.component";
import { AttachFileComponent } from "./attach-file/attach-file.component";
import { NewEndorsementModalComponent } from "./new-endorsement-modal/new-endorsement-modal.component";
import { ViewDetailAttachFileComponent } from "./view-detail-attach-file/view-detail-attach-file.component";
import { UpdateTaskModalComponent } from "./update-task-modal/update-task-modal.component";
import { TaskTagMinComponent } from "./task-tag-min/task-tag-min.component";
import { EditEndorsementModalComponent } from "./edit-endorsement-modal/edit-endorsement-modal.component";
import { NewTypeComponent } from "./new-type/new-type.component";
import { NewSubTypeComponent } from "./new-sub-type/new-sub-type.component";
import { CourtAppearanceComponent } from "./court-appearance/court-appearance.component";
import { AttachEntityComponent } from "./attach-entity/attach-entity.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { FileListComponent } from "./file-list/file-list.component";
import { matterReducer } from "../store/reducers/matters.reducers";
import { MattersEffects } from "../store/effects/matters.effects";
import { ErrorNotifierComponent } from "./error-notifier/error-notifier.component";
import { ConnectionGlobalAlertComponent } from "./connection-global-alert/connection-global-alert.component";
import { SafePipe } from "./helpers/safe-pipe";
import { CommentBoxComponent } from "./comment-box/comment-box.component";
import { EditContactModalComponent } from "./edit-contact-modal/edit-contact-modal.component";
import { MatterDueTaskComponent } from "./matter-due-task/matter-due-task.component";
import { MatterTaskTagComponent } from "./matter-task-tag/matter-task-tag.component";
import { CalendarModalComponent } from "./calendar-modal/calendar-modal.component";
import { ContactSearchBoxComponent } from "./contact-search-box/contact-search-box.component";
import { AddFileToFolderComponent } from "./add-file-to-folder/add-file-to-folder.component";
import { NameTransformPipe } from "./CustomPipe/nameTrim.pipe";
import { PdfImageViewerComponent } from "./pdf-image-viewer/pdf-image-viewer.component";
import { MatterSelectionBoxComponent } from "./matter-selection-box/matter-selection-box.component";
import { FileListCustomComponent } from "./file-list-custom/file-list-custom.component";
import { ChooseUserCustomModalComponent } from "./choose-user-custom-modal/choose-user-custom-modal.component";
import { TotalHoursPipe } from "./services/total-hours.pipe";
import { ClientFeedbackModalComponent } from "./client-feedback-modal/client-feedback-modal.component";
import { AttachFileCustomComponent } from "./attach-file-custom/attach-file-custom.component";
import { AllTaskComponent } from "./all-task/all-task.component";
import { FileBulkUploadComponent } from "./file-bulk-upload/file-bulk-upload.component";
import { DrawerComponent } from "./drawer/drawer.component";
import { AiDocumentReviewComponent } from "../lp-doc/ai-document-review/ai-document-review.component";
import { HighlightTextPipe } from "./helpers/highlight-text/highlight-text.pipe";
import { ComingSoonComponent } from "./dialogs/coming-soon/coming-soon.component";
import { ApplicationStatusComponent } from "../matter-application/dialogs/application-status/application-status.component";
import { ApplicationTypeComponent } from "../matter-application/dialogs/application-type/application-type.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { LawReportComponent } from "src/app/lp-doc/law-report/law-report.component";
import { DriveUserShareComponent } from './drive-user-share/drive-user-share.component';
import { MatterResourceSelectionBoxComponent } from './matter-resource-selection-box/matter-resource-selection-box.component';
import { DriveFileUploadComponent } from './drive-file-upload/drive-file-upload.component';
import { NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";
import { SnackbarToastComponent } from './snackbar-toast/snackbar-toast.component';
import { NewNotificationListComponent } from './new-notification-list/new-notification-list.component';
import { OkmSubscriptionModalComponent } from './okm-subscription-modal/okm-subscription-modal.component';
import { FileViewerComponent } from './file-viewer/file-viewer.component';
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";

// import {LocalUserService} from './services/local-user.service';
// import { LocalStorageService } from 'ngx-webstorage';

@NgModule({
  declarations: [
    AnonymousHeaderComponent,
    AnonymousFooterComponent,
    PrimarySidebarComponent,
    UserHeaderComponent,
    GenerateDefaultPictureComponent,
    GenerateDefaultPictureNonObservableComponent,
    FormParentComponent,
    ChooseUserComponent,
    ConfirmActionComponent,
    StopwatchTimerComponent,
    LicensePaymentComponent,
    ViewFileComponent,
    TaskTagComponent,
    ViewTaskComponent,
    CompletedTaskComponent,
    UpcomingTaskComponent,
    DueTaskComponent,
    NewContactModalComponent,
    PaginatorComponent,
    AnonymousHeaderComponent,
    AnonymousFooterComponent,
    PrimarySidebarComponent,
    UserHeaderComponent,
    GenerateDefaultPictureComponent,
    TimiWidgetComponent,
    GlobalAlertComponent,
    UserShareComponent,
    FileUploadComponent,
    NewFolderComponent,
    RenameItemComponent,
    NewExpenseComponent,
    NewPaymentComponent,
    NotificationsListComponent,
    TimiWidgetComponent,
    PageLoaderComponent,
    MatterSearchBoxComponent,
    AttachFileComponent,
    AttachMatterComponent,
    ChangeRoleModalComponent,
    NewTaskModalComponent,
    NewEndorsementModalComponent,
    ViewDetailAttachFileComponent,
    UpdateTaskModalComponent,
    TaskTagMinComponent,
    EditEndorsementModalComponent,
    NewTypeComponent,
    NewSubTypeComponent,
    CourtAppearanceComponent,
    AttachEntityComponent,
    FileListComponent,
    ErrorNotifierComponent,
    ConnectionGlobalAlertComponent,
    SafePipe,
    HighlightTextPipe,
    CommentBoxComponent,
    EditContactModalComponent,
    MatterDueTaskComponent,
    MatterTaskTagComponent,
    CalendarModalComponent,
    ContactSearchBoxComponent,
    AddFileToFolderComponent,
    NameTransformPipe,
    PdfImageViewerComponent,
    MatterSelectionBoxComponent,
    FileListCustomComponent,
    ChooseUserCustomModalComponent,
    TotalHoursPipe,
    ClientFeedbackModalComponent,
    AttachFileCustomComponent,
    ContractViewModalComponent,
    AllTaskComponent,
    FileBulkUploadComponent,
    DrawerComponent,
    AiDocumentReviewComponent,
    ComingSoonComponent,
    ApplicationTypeComponent,
    ApplicationStatusComponent,
    LawReportComponent,
    DriveUserShareComponent,
    MatterResourceSelectionBoxComponent,
    DriveFileUploadComponent,
    SnackbarToastComponent,
    NewNotificationListComponent,
    OkmSubscriptionModalComponent,
    FileViewerComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    ReusablesModule,
    NgxMaterialTimepickerModule,
    NgxExtendedPdfViewerModule,
    NgbPopoverModule,
    SharedRoutingModule,
    AngularEditorModule,
    StoreModule.forFeature("user-header", UserHeaderReducer),
    EffectsModule.forFeature([UserHeaderEffects]),
    StoreModule.forFeature("tasks", taskReducer),
    EffectsModule.forFeature([TaskEffects]),
    StoreModule.forFeature("matter", matterReducer),
    EffectsModule.forFeature([MattersEffects]),
  ],
  exports: [
    NgxExtendedPdfViewerModule,
    AngularMaterialModule,
    NgSelectModule,
    NgbPopoverModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    AngularEditorModule,
    AnonymousHeaderComponent,
    AnonymousFooterComponent,
    SharedRoutingModule,
    PrimarySidebarComponent,
    UserHeaderComponent,
    GenerateDefaultPictureComponent,
    GenerateDefaultPictureNonObservableComponent,
    SnackbarToastComponent,
    ChooseUserComponent,
    ConfirmActionComponent,
    StopwatchTimerComponent,
    CommonModule,
    FormParentComponent,
    ViewFileComponent,
    TaskTagComponent,
    TaskTagMinComponent,
    CompletedTaskComponent,
    UpcomingTaskComponent,
    DueTaskComponent,
    MatterSelectionBoxComponent,
    MatterDueTaskComponent,
    MatterTaskTagComponent,
    ViewTaskComponent,
    NewContactModalComponent,
    PaginatorComponent,
    NewPaymentComponent,
    LicensePaymentComponent,
    FileUploadComponent,
    UserShareComponent,
    FileUploadComponent,
    NewFolderComponent,
    RenameItemComponent,
    NewExpenseComponent,
    NotificationsListComponent,
    AttachMatterComponent,
    PageLoaderComponent,
    ChangeRoleModalComponent,
    NewTaskModalComponent,
    MatterSearchBoxComponent,
    ContactSearchBoxComponent,
    AttachFileComponent,
    NewEndorsementModalComponent,
    ViewDetailAttachFileComponent,
    UpdateTaskModalComponent,
    EditEndorsementModalComponent,
    NewTypeComponent,
    NewSubTypeComponent,
    CourtAppearanceComponent,
    AttachEntityComponent,
    FileListComponent,
    ConnectionGlobalAlertComponent,
    GlobalAlertComponent,
    ErrorNotifierComponent,
    CommentBoxComponent,
    EditContactModalComponent,
    CalendarModalComponent,
    NameTransformPipe,
    AddFileToFolderComponent,
    PdfImageViewerComponent,
    FileListCustomComponent,
    TotalHoursPipe,
    ClientFeedbackModalComponent,
    AttachFileCustomComponent,
    ChooseUserCustomModalComponent,
    ContractViewModalComponent,
    FileBulkUploadComponent,
    AllTaskComponent,
    DrawerComponent,
    AiDocumentReviewComponent,
    SafePipe,
    HighlightTextPipe,
    ComingSoonComponent,
    ApplicationTypeComponent,
    ApplicationStatusComponent,
    DriveUserShareComponent,
    MatterResourceSelectionBoxComponent,
    DriveFileUploadComponent,
    NewNotificationListComponent,
    OkmSubscriptionModalComponent,
    FileViewerComponent
  ],
  entryComponents: [ComingSoonComponent],

  providers: [
    CurrentUserService,
    AuthAdminGuard,
    UtilsService,
    FileUploadService,
    NotificationService,
    CurrencyPipe,
  ],
})
export class SharedModule { }
