import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  RouterStateSnapshot,
  Router,
  Route,
  UrlSegment,
} from "@angular/router";
import { CurrentUserService } from "../../services/factories/current-user.service";

@Injectable({ providedIn: "root" })
export class InHouseGuard implements CanActivate {
  constructor(
    private currentUserService: CurrentUserService,
    private router: Router
  ) { }

  canLoad(route: Route): boolean {
    let url = `/${route.path}`;

    return this.checkLogin(url);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    if (this.currentUserService.currentUser.role === "In-House Administrator") {
      return true;
    }

    this.router.navigate(["/login"]);
    return false;
  }

  // getFullPath(segments: UrlSegment[]) {
  //   return segments.reduce((path, currentSegment) => {
  //     return `${path}/${currentSegment.path}`;
  //   }, "");
  // }
}
