import { ContactLabel } from "./contact-label";

export class ContactViewModel {
  id: number;
  firmId?: number;
  firstName: string;
  firmLabelId?: number;
  lastName: string;
  companyName: string;
  email: string;
  phoneNumber: any;
  contactLabel: ContactLabel;
  referral?: number;
  contactStatusId: number;
  contactStatus?: string;
  titleId?: number;
  contactSource?: string;
  website?: string;
  contact?: string;
  contactId?: number;
  companyAddress?: string;
  companyPhoneNumber?: string;
  companyEmail?: string;
  industry?: string;
  rcno?: string;
  primaryTitleId?: number;
}
