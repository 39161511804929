import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { MatterList } from "../../models/view-model/matter-list";
import {
  MatterTimeSheetModel,
  StopWatchModel,
} from "../../models/view-model/time-sheet";
import * as fromStore from "../../auth/state";
import * as fromMatterTimeSheetAction from "../../store/actions/matters.action";
import * as fromMatterTimeSheet from "../../store/reducers/matters.reducers";

// since it's lazy loaded
import { MatterService } from "../../services/matter.service";
import * as fromUserHeaderStore from "../../store/reducers/user-header.reducer";
import * as UserHeaderActions from "../../store/actions/user-header.actions";
import { isNullOrUndefined } from "util";
import { UserDetail } from "../../models/view-model/user-detail";
import { AppConfigService } from "../helpers/app-config.service";
import { UserProfileViewModelLite } from "../../models/view-model/UserProfileViewModelLite";

@Component({
  selector: "app-stopwatch-timer",
  templateUrl: "./stopwatch-timer.component.html",
  styleUrls: ["./stopwatch-timer.component.scss"],
})
export class StopwatchTimerComponent implements OnInit, AfterViewInit {
  @Input() timerActive: boolean;
  @Input() selectedMatterId: number;
  @Input() autoStartTime: boolean;
  stopClock: StopWatchModel = {
    hours: "00",
    minutes: "00",
    seconds: "00",
    milliseconds: "00",
  };
  time: any;
  times: any;
  running = false;
  paused = false;
  timeSnap: StopWatchModel;
  timeEntryForm: FormGroup;
  successText: string;
  errorText: string;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  btnSpinner: boolean;
  @ViewChild("messageBox") messageBox: ElementRef;
  matterList: MatterList[];
  componentActive: boolean;
  firmId: number;
  user = new UserDetail();
  matterId: number;

  @Output() timeSheetSavingStatus = new EventEmitter();

  currentMatterId: number;
  firmTaskTypes: any[];
  showResourceNotSelectedError: boolean;

  @ViewChild("close_time_entry_modal") close_time_entry_modal: ElementRef;

  constructor(
    private store: Store<fromStore.State>,
    private fb: FormBuilder,
    private matterService: MatterService,
    private activatedRoute: ActivatedRoute,
    private config: AppConfigService
  ) {
    this.timeEntryForm = this.fb.group({
      taskName: ["", Validators.required],
      duration: [""],
      hourRate: [""],
      description: [""],
      // matterId: ['', Validators.required]
    });

    // this.store.pipe(select(fromMatters.getAllMatters))
    // .subscribe(
    //   (data) => {
    //     //console.log(data);
    //    if (!isNullOrUndefined(data)) {
    //     this.matterList = data;

    this.store
      .pipe(select(fromUserHeaderStore.getCurrentMatter))
      .subscribe((cuurentMatterData) => {
        if (!isNullOrUndefined(cuurentMatterData)) {
          this.currentMatterId = cuurentMatterData;
          this.matterId = this.currentMatterId;
          // this.timeEntryForm.patchValue({
          //   matterId: this.currentMatterId
          // });
        }
      });
    this.store.pipe(select(fromStore.getUserDetail)).subscribe((userData) => {
      if (!isNullOrUndefined(userData)) {
        this.user = userData;
        this.timeEntryForm.patchValue({
          hourRate: this.user.hourlyRate,
        });
      }
    });

    // }
    //   }
    // );

    this.store
      .pipe(select(fromUserHeaderStore.getTimerState))
      .subscribe((data) => {
        if (!isNullOrUndefined(data)) {
          this.timerActive = data;
        }
      });

    this.store
      .pipe(select(fromMatterTimeSheet.getIsAddingMatterTime))
      .subscribe((data) => {
        if (!isNullOrUndefined(data)) {
          // success
          this.btnSpinner = data;
        }
      });

    this.store
      .pipe(select(fromMatterTimeSheet.getIsAddedMatterTime))
      .subscribe((data) => {
        if (!isNullOrUndefined(data) && data) {
          this.showSuccessMessage = true;
          this.successText = "Time Entry was added";
          setTimeout(() => {
            this.showSuccessMessage = false;
          }, 10000);

          this.close_time_entry_modal.nativeElement.click();
          this.timeSheetSavingStatus.emit(true);
        }
      });

    this.store
      .pipe(select(fromMatterTimeSheet.getaddMatterTimeSheetError))
      .subscribe((data) => {
        // tslint:disable-next-line:triple-equals
        if (data != "") {
          this.showErrorMessage = true;
          this.btnSpinner = false;
          this.errorText = data;
          this.timeSheetSavingStatus.emit(false);
        } else {
          this.store
            .pipe(select(fromMatterTimeSheet.getIsAddingMatterTime))
            .subscribe((isAddingState) => {
              if (!isNullOrUndefined(isAddingState)) {
                // success
                this.btnSpinner = isAddingState;
              }
            });
        }
      });

    this.getMatterTimeTasks();
  }

  get taskName() {
    return this.timeEntryForm.get("taskName");
  }

  get hourRate() {
    return this.timeEntryForm.get("hourRate");
  }

  get duration() {
    return this.timeEntryForm.get("duration");
  }

  // get matterId() {
  //   return this.timeEntryForm.get('matterId').value;
  // }

  get description() {
    return this.timeEntryForm.get("description");
  }

  ngAfterViewInit(): void {
    if (!isNullOrUndefined(this.autoStartTime)) {
      if (this.autoStartTime) {
        this.startTimer();
      }
    }
  }

  hideTimer() {
    this.store.dispatch(new UserHeaderActions.HideTimer());
    // this.reset();
    // this.showSuccessMessage = false;
    // this.stopClock = {
    //   hours: "00",
    //   minutes: "00",
    //   seconds: "00",
    //   milliseconds: "00",
    // };
  }

  durationValue(): string {
    return `${this.timeSnap.hours}h:${this.timeSnap.minutes}m:${this.timeSnap.seconds}s`;
  }

  reset() {
    this.times = [0, 0, 0, 0];
  }

  startTimer() {
    if (!this.time) {
      this.time = performance.now();
    }
    if (!this.running) {
      this.running = true;
      requestAnimationFrame(this.step.bind(this));
    }
  }

  stopTimer() {
    // this.timerActive = false;
    this.timeSnap = { ...this.stopClock };
    this.hideTimer();
    this.running = false;
    this.time = null;
  }

  pauseTimer() {
    this.running = false;
    this.paused = true;
    this.time = null;
  }

  restartTimer() {
    if (!this.time) {
      this.time = performance.now();
    }
    if (!this.running) {
      this.running = true;
      requestAnimationFrame(this.step.bind(this));
    }
    this.reset();
  }

  step(timestamp) {
    if (!this.running) {
      return;
    }
    this.calculate(timestamp);
    this.time = timestamp;
    this.display();
    requestAnimationFrame(this.step.bind(this));
  }

  calculate(timestamp) {
    const diff = timestamp - this.time;
    // Hundredths of a second are 100 ms
    this.times[3] += diff / 10;
    // Seconds are 100 hundredths of a second
    if (this.times[3] >= 100) {
      this.times[2] += 1;
      this.times[3] -= 100;
    }
    // Minutes are 60 seconds
    if (this.times[2] >= 60) {
      this.times[1] += 1;
      this.times[2] -= 60;
    }
    // Hours are 60 minutes
    if (this.times[1] >= 60) {
      this.times[0] += 1;
      this.times[1] -= 60;
    }
  }

  display() {
    this.stopClock = this.format(this.times);
  }

  format(times): StopWatchModel {
    return {
      hours: this.pad0(times[0], 2),
      minutes: this.pad0(times[1], 2),
      seconds: this.pad0(times[2], 2),
      milliseconds: this.pad0(Math.floor(times[3]), 2),
    };
  }

  pad0(value, count) {
    let result = value.toString();
    for (; result.length < count; --count) {
      result = "0" + result;
    }
    return result;
  }

  ngOnInit() {
    this.btnSpinner = false;
    this.reset();
    this.timeSnap = { ...this.stopClock };

    this.firmId = this.activatedRoute.snapshot.params["firmId"];
  }

  getSelectedOption(resourceId) {
    if (Number.parseInt(resourceId, 10) > 0) {
      this.matterId = resourceId;
    }
  }

  getMatterTimeTasks() {
    this.matterService.getMatterTimeTasks().subscribe((data) => {
      this.firmTaskTypes = data;
    });
  }

  saveTimeEntry() {
    this.btnSpinner = true;
    if (this.matterId > 0) {
    } else {
      this.showResourceNotSelectedError = true;
      return;
    }

    const payload: MatterTimeSheetModel = {
      description: this.description.value,
      hourRate: this.hourRate.value,
      timeSpent: +(
        Number.parseInt(this.timeSnap.hours.toString(), 10) * 3600 +
        Number.parseInt(this.timeSnap.minutes.toString(), 10) * 60 +
        Math.round(Number.parseInt(this.timeSnap.seconds.toString(), 10))
      ),
      entryDate: new Date().getTime(),
      matterId: +this.matterId,
      firmTimeTaskTypeId: +this.taskName.value,
    };

    payload.cost = (this.user.hourlyRate / 60) * payload.timeSpent;
    // tslint:disable-next-line:triple-equals
    payload.firmTimeTaskType = this.firmTaskTypes.find(
      (t) => t.id == payload.firmTimeTaskTypeId
    ).name;
    payload.user = new UserProfileViewModelLite();
    payload.user.firstName = this.user.firstName;
    payload.user.lastName = this.user.lastName;
    //  payload.user.profileImage = this.user.;
    payload.user.id = this.user.userId;

    this.store.dispatch(new fromMatterTimeSheetAction.AddMatterTimes(payload));
    //  this.service.saveTimeEntry(payload).toPromise().then(data => {
    //   // success
    //   this.btnSpinner = false;
    //   this.showSuccessMessage = true;
    //   this.successText = 'Time Entry was added';
    // payload.duration = this.timeSnap.hours + 'h:' + this.timeSnap.minutes + 'm:' + this.timeSnap.seconds + 's';
    // }).catch(err => {
    //   this.showErrorMessage = true;
    //   this.btnSpinner = false;
    //   this.errorText = 'An error occurred while trying to add time entry';
    // });
    //  this.messageBox.nativeElement.focus();
  }

  closeError() {
    this.showErrorMessage = false;
  }

  closeSuccess() {
    this.showSuccessMessage = false;
  }
}
