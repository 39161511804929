import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnChanges,
  OnDestroy,
} from "@angular/core";
import { Observable, of } from "rxjs";
import { CommonUserModel } from "src/app/models/generic-model.ts/user-share";
import { UserLite } from "src/app/models/view-model/user-lite";
import { FormControl } from "@angular/forms";
import { IUser } from "src/app/models/interfaces/user";
import { Notifier } from "../base-class/notifier";

import * as fromUserHeaderStore from "../../store/reducers/user-header.reducer";
import { select, Store } from "@ngrx/store";
import * as fromStore from "../../auth/state/index";
import { UserDetail } from "src/app/models/view-model/user-detail";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-user-share",
  templateUrl: "./user-share.component.html",
  styleUrls: ["./user-share.component.scss"],
})
export class UserShareComponent
  extends Notifier
  implements OnInit, OnChanges, OnDestroy {
  @Input() shareTitle: string;
  permissionId = new FormControl("1");

  @Input() userList$: Observable<UserLite[]> = of([]);
  @Input() clearUser$: Observable<boolean>;
  selectedUser = new UserLite();
  @Input() throwError: boolean;
  @Input() permittedUsers: Observable<CommonUserModel[]> = of([]);

  @Output() emitDeletedSharedUser = new EventEmitter();
  @Output() emitPermissionUpdate = new EventEmitter();

  @ViewChild("searchbox") searchbox: ElementRef;
  userList: UserLite[] = [];

  permissionList: any[] = [];
  borderShown: boolean;

  filteredUsers: CommonUserModel[];
  selectedUsers: CommonUserModel[];
  @Output() usersUpdate = new EventEmitter<any>();
  @Output() searchText = new EventEmitter<any>();
  hideResult: boolean;
  userData = new UserDetail();
  getCurrentUser: CommonUserModel;

  @Input() btnSpinner$: Observable<boolean> = of(false);
  btnSpinner: boolean;
  @ViewChild("closeUserModal") closeUserModal: ElementRef;

  constructor(
    protected headerStore: Store<fromUserHeaderStore.HeaderState>,
    private userStore: Store<fromStore.State>
  ) {
    super(headerStore);
    this.borderShown = false;

    this.addSubscription(
      this.userStore.pipe(select("auth")).subscribe((data) => {
        /// save token and user detail to local storage
        if (!isNullOrUndefined(data.userProfile)) {
          this.userData = data.userProfile;
        }
      })
    );
  }

  ngOnChanges(changes) {
    this.btnSpinner$ = this.btnSpinner$;
    this.btnSpinner$.subscribe((result) => {
      this.btnSpinner = result;
    });

    this.permittedUsers.subscribe((data) => {
      data.forEach((user) => {
        if (user.id === this.userData.userId) {
          this.getCurrentUser = user;
        }
      });
    });

    this.userList$.subscribe((data) => {
      this.userList = data;
    });

    this.clearUser$.subscribe((res) => {
      if (res) {
        this.searchbox.nativeElement.value = "";
      }
    });
  }

  ngOnInit() {
    this.hideResult = true;

    this.permissionList = [
      {
        id: 1,
        name: "Can View",
      },
      {
        id: 2,
        name: "Can Comment",
      },
      {
        id: 3,
        name: "Can Edit",
      },
    ];
  }

  selectUser(_user: UserLite) {
    this.selectedUser = _user;
    this.hideResult = true;
    this.searchbox.nativeElement.value = _user.firstName + " " + _user.lastName;
  }

  shareWithUser() {
    this.btnSpinner$ = of(true);
    const _share: IUser = this.selectedUser;
    let myUser = new CommonUserModel();
    myUser = <CommonUserModel>_share;
    myUser.isOwner = false;
    myUser.accessPermissionId = this.permissionId.value;

    this.usersUpdate.emit(myUser);
    this.btnSpinner$ = of(false);
  }

  closeShareModal() {
    this.selectedUser = new UserLite();
    this.searchbox.nativeElement.value = "";
  }

  searchUsers(term) {
    this.hideResult = false;
    const theTerm = term.target.value.toLowerCase();
    this.searchText.emit(theTerm);
  }

  changePermission(user: CommonUserModel, permissionId) {
    const updatedUserPermission = user;
    updatedUserPermission.accessPermissionId = permissionId.target.value;
    this.emitPermissionUpdate.emit(updatedUserPermission);
  }

  removeUser(permitteduser) {
    this.emitDeletedSharedUser.emit(permitteduser);
  }

  showBorder(): void {
    this.borderShown = !this.borderShown;
  }

  ngOnDestroy(): void {
    this.clearSubscription();
  }
}
