import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { isNullOrUndefined } from "util";
import * as LoginActions from "../../auth/state/login.actions";
import * as fromStore from "../../auth/state/index";
import * as fromMatters from "../../store/reducers/matters.reducers";

@Injectable({
  providedIn: "root",
})
export class PermissionService {
  returnValue: boolean = false;

  constructor(public store: Store<fromMatters.State>) { }

  public checkPermission(
    permissionEntityName: string,
    action: string
  ): boolean {
    this.store.dispatch(new LoginActions.LoadLocalUserDetail());

    this.store.pipe(select(fromStore.getUserDetail)).subscribe((data) => {
      // console.log(data);
      var permissionObjects;
      if (data.accountPermissions) {
        permissionObjects = data.accountPermissions.filter((i) =>
          permissionEntityName.includes(i.permissionEntityName)
        )[0];
      }

      // console.log(permissionObjects);
      if (!isNullOrUndefined(permissionObjects)) {
        if (action == "canEdit") {
          this.returnValue = permissionObjects.permissionAction.canEdit;
        } else if (action == "canView") {
          this.returnValue = permissionObjects.permissionAction.canView;
        } else if (action == "canWrite") {
          this.returnValue = permissionObjects.permissionAction.canWrite;
        } else if (action == "canDelete") {
          this.returnValue = permissionObjects.permissionAction.canDelete;
        } else {
          this.returnValue = false;
        }
      } else {
        this.returnValue = false;
      }
    });
    return this.returnValue;
  }
}
