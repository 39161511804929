import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import * as LoginActions from '../../state/login.actions';
import * as fromStore from '../../state/index';
import { isNullOrUndefined } from 'util';
import { Store, select } from "@ngrx/store";


@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"]
})
export class LogoutComponent implements OnInit {

  email: string;

  constructor(
    private router: Router,
    private store: Store<fromStore.State>,
  ) {

    this.store.dispatch(new LoginActions.LoadLocalUserDetail());

    this.store.pipe(select('auth')).subscribe(data => {

      if (!isNullOrUndefined(data)) {
        if (!isNullOrUndefined(data.userProfile.email)) {
          this.email = data.userProfile.email;
        }
      }
    });

  }

  ngOnInit() {
    // console.log(this.email);

    if (this.email !== '') {
      this.store.dispatch(new LoginActions.Logout(this.email));

    } else {
      this.router.navigate(['/auth/login']);
    }

  }
}
