import { CommentService } from "./../../services/comment.service";
import { CommonUserModel } from "./../../models/generic-model.ts/user-share";
import { UserManagementService } from "./../../services/user-management.service";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";

import * as fromStore from "../../auth/state/index";
import { isNullOrUndefined } from "util";
import { UserDetail } from "src/app/models/view-model/user-detail";
import { ActivatedRoute } from "@angular/router";
import {
  CommentModel,
  CommentViewModel,
} from "src/app/models/view-model/comment";
import { Notifier } from "../base-class/notifier";
import * as fromUserHeaderStore from "../../store/reducers/user-header.reducer";

@Component({
  selector: "app-comment-box",
  templateUrl: "./comment-box.component.html",
  styleUrls: ["./comment-box.component.scss"],
})
export class CommentBoxComponent extends Notifier implements OnInit, OnChanges {
  taskId: number;
  user = new UserDetail();
  CommentSection: FormGroup;
  confirmType: string;
  confirmTitle: string;
  confirmMessage: string;
  confirmAction: string;
  confirmElementData: any;
  userProfilePicture = new Observable<string>();
  firstName: Observable<string>;
  lastName: Observable<string>;
  @Input() publicBtnSpinner: boolean = false;
  btnSpinner: boolean;
  @Output() commentDetails$ = new EventEmitter<object>();
  selectUser: boolean;
  filteredUsers: CommonUserModel[] = [];
  refName: string;
  initialComment = "";
  mentions: any[] = [];
  commentModel = new CommentModel();
  viewComments: CommentViewModel[] = [];
  selectComment = new CommentViewModel();
  @Input() appcomments: Observable<object> = of({});
  @Input() collect_taskId: number;

  constructor(
    private store: Store<fromStore.State>,
    private fb: FormBuilder,
    private userService: UserManagementService,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>,
    private route: ActivatedRoute,
    private commentService: CommentService
  ) {
    super(headerStore);
    this.CommentSection = this.fb.group({
      comment: ["", Validators.required],
    });

    this.store.pipe(select(fromStore.getUserDetail)).subscribe((data) => {
      this.user = data;
      this.firstName = of(this.user.firstName);
      this.lastName = of(this.user.lastName);
    });

    this.store
      .pipe(select(fromStore.getCurrentProfileImage))
      .subscribe((pic) => {
        if (!isNullOrUndefined(pic)) {
          this.userProfilePicture = of(pic);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appcomments && changes.appcomments.currentValue) {
      this.readComment();
    }
    if (this.collect_taskId) {
      this.taskId = this.collect_taskId;
      this.readComment();
    }
  }

  get comment() {
    return this.CommentSection.get("comment");
  }

  ngOnInit() {
    this.taskId = Number(this.route.snapshot.params.id);
    this.selectUser = false;

    this.userService.getUsersList(this.user.firmId).subscribe((res) => {
      this.filteredUsers = res.body;
    });

    this.readComment();
  }

  readComment() {
    this.commentService.getComment(this.taskId).subscribe((res) => {
      this.viewComments = res;
      // this.viewComments.forEach((m) => {
      //   if (m.comment.includes("@" + "Ekekwe" + " " + "Stephanie")) {
      //   }
      // });
    });
  }

  searchUsers(term) {
    this.userService.getUsersList(this.user.firmId, term).subscribe((res) => {
      this.filteredUsers = res.body;
    });
  }

  setComment($event) {
    this.initialComment = this.comment.value;

    if ($event.key === "@") {
      this.selectUser = true;
    }
    if (
      $event.key === "Enter" ||
      $event.key === "space" ||
      $event.key === "Backspace"
    ) {
      this.selectUser = false;
    }
  }

  getUserNames(users) {
    this.refName = users.firstName + " " + users.lastName;

    if (users) {
      if (this.mentions.includes(users) === false) {
        this.mentions.push(users);
      }
    }

    this.setCommentRef();
  }

  crossCheckComments() {
    this.mentions.forEach((m) => {
      if (this.initialComment.includes(m.firstName + " " + m.lastName)) {
      } else {
        const index = this.mentions.indexOf(m);
        if (index > -1) {
          this.mentions.splice(index, 1);
        }
      }
    });
  }

  setCommentRef() {
    this.initialComment = this.initialComment.concat(this.refName);
    this.selectUser = false;
  }

  postComment() {
    this.crossCheckComments();

    this.btnSpinner = true;

    this.commentModel.comment = this.initialComment;
    this.commentModel.userIds = [];

    this.mentions.forEach((m) => {
      this.commentModel.userIds.push(m.id);
    });

    const commentDetails = {
      comment: this.commentModel.comment,
      mentions: this.commentModel.userIds,
    };

    this.commentDetails$.emit(commentDetails);
    this.comment.reset();

    this.mentions = [];
    this.btnSpinner = false;

    this.addComment(commentDetails);
  }

  addComment(appcomments) {
    this.readComment();
  }

  deletecomment(commentSelected) {
    this.commentService.deleteComment(commentSelected.id).subscribe(
      (data) => {
        data = commentSelected.id;
        if (!isNullOrUndefined(data)) {
          this.viewComments.splice(this.viewComments.indexOf(data));
          this.readComment();
        }
      },
      (error) => {
        this.sendErrorMessage("comment not deleted");
      }
    );
  }
}
