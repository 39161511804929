import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromStore from '../../auth/state/index';
import { UserDetail } from '../../models/view-model/user-detail';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-timi-widget',
  templateUrl: './timi-widget.component.html',
  styleUrls: ['./timi-widget.component.scss']
})
export class TimiWidgetComponent implements OnInit {

  user = new UserDetail();
  isShowIntro: boolean;
  isShowchatBaloon: boolean;
  timiLink: string;
  expandTimiWidget = false;
  constructor(
    private store: Store<fromStore.State>,
  ) {

    this.store.pipe(select(fromStore.getUserDetail)).subscribe(data => {
      this.user = data;
      this.timiLink = `${environment.timi_url}?name=${this.user.firstName}&client=${this.user.userId}`;
    });
  }

  ngOnInit() {
    this.isShowIntro = true;
    this.isShowchatBaloon = false;
  }


  openChatBot() {
    this.isShowchatBaloon = true;
  }

  minimizeTimi() {
    this.expandTimiWidget = false;
  }
  maximizeTimi() {
    this.expandTimiWidget = true;
  }

}
