import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AccountResponseModel } from "../models/generic-model.ts/account-response";
import { FileModel } from "../models/generic-model.ts/file-model";
import { MailGenericGetRequestModel } from "../models/generic-model.ts/mail-request";
import {
  MailListViewModel,
  MailFolderCount,
  InboxInputModel,
  MailInboxViewModel,
  MailNewListViewModel,
  ComposeModel,
} from "./../models/view-model/email-list";
import { isNullOrUndefined } from "util";
import { NewMailParseModel } from "../models/parse-model/new-email";
// import { FileModel } from '../models/generic-model.ts/file-model';
// import { AccountResponseModel } from '../models/generic-model.ts/account-response';
// import { ComposeMail } from '../models/parse-model/compose-email';
import {
  EmailAccountModel,
  UpdateEmailAccount,
  LoginAccountDetails,
} from "../models/parse-model/add-account";
import { EmailLoginParseModel } from "../models/parse-model/email-login";
// import { NewMailParseModel } from '../models/parse-model/new-email';
import { AppConfigService } from "../shared/helpers/app-config.service";
import { ComposeMail, DraftMail, ReplyAllMail, ReplyMail } from "../models/parse-model/compose-email";
import { AddEmailToExistingTask, EmailTaskParseModel, GetEmailExistingTasksPayload } from "../models/shared-model/newTask";
// import { InboxInputModel, MailFolderCount, MailInboxViewModel, MailListViewModel } from './../models/view-model/email-list';

@Injectable({
  providedIn: "root",
})
export class EmailClientService {
  rootEnpoint: string;

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) {
    this.rootEnpoint = this.config.getConfig().serverEndpoint;
  }

  addManualEmailAccount() { }

  getSentMail(
    data: MailGenericGetRequestModel
  ): Observable<MailListViewModel[]> {
    const endpoint = this.rootEnpoint + "/";
    //console.log(data);
    return this.httpClient.get<MailListViewModel[]>(endpoint);
  }

  getDrafts(data: MailGenericGetRequestModel): Observable<MailListViewModel[]> {
    const endpoint = this.rootEnpoint + "/";
    return this.httpClient.get<MailListViewModel[]>(endpoint);
  }

  getEmailInbox(
    data: MailGenericGetRequestModel
  ): Observable<MailListViewModel[]> {
    const endpoint = this.rootEnpoint + "/";
    return this.httpClient.get<MailListViewModel[]>(endpoint);
  }

  getAllMails(
    data: MailGenericGetRequestModel
  ): Observable<MailListViewModel[]> {
    const endpoint = this.rootEnpoint + "/GetInbox";
    return this.httpClient.post<MailListViewModel[]>(endpoint, data);
  }
  compose(data: ComposeMail): Observable<any> {
    console.log(data)
    const api = `${this.rootEnpoint}Email/Compose`;
    return this.httpClient.post<any>(api, data);
  }

  saveToDraft(configId: number, data: DraftMail): Observable<any> {
    console.log(data)
    const api = `${this.rootEnpoint}Email/${configId}/draft`;
    return this.httpClient.post<any>(api, data);
  }

  updateToDraft(configId: number, draftId: any, data: DraftMail): Observable<any> {
    const api = `${this.rootEnpoint}Email/${configId}/draft/${draftId}`;
    return this.httpClient.put<any>(api, data);
  }

  sendDraftMessage(configId: number, draftId: any, data: DraftMail): Observable<any> {
    const api = `${this.rootEnpoint}Email/${configId}/draft/${draftId}/send`;
    return this.httpClient.post<any>(api, data);
  }

  reply(data: ReplyMail): Observable<any> {
    console.log(data)
    const api = `${this.rootEnpoint}Email/Reply`;
    return this.httpClient.post<any>(api, data);
  }

  replyAll(data: ReplyAllMail): Observable<any> {
    console.log(data)
    const api = `${this.rootEnpoint}Email/ReplyAll`;
    return this.httpClient.post<any>(api, data);
  }

  getThirdPartyUrl(configId: number): Observable<any> {
    const endpoint = `${this.rootEnpoint}Email/url?emailConfig=${configId}`;
    return this.httpClient.get<any>(endpoint);
  }

  getSingleEmail(token: string, macAdd: string = "") {
    const endpoint = this.rootEnpoint + "/";
    // + getInboxDetailEndPoint +
    // '?Token=' + token + '&MacAdd=' + macAdd ;
    return this.httpClient.get<MailListViewModel>(endpoint);
  }

  getEmailFiles(data: MailGenericGetRequestModel) {
    const endpoint = this.rootEnpoint + "/";
    // + getInboxDetailEndPoint +
    // '?Token=' + token + '&MacAdd=' + macAdd ;
    return this.httpClient.get<FileModel[]>(endpoint);
  }

  updateAccountDetail(input: UpdateEmailAccount): Observable<any> {
    const api = `${this.rootEnpoint}/Email/updatedisplayname`;
    return this.httpClient.put<any>(api, input);
  }

  getEmailFolderCount(id: number): Observable<MailFolderCount> {
    const api = `${this.rootEnpoint}Email/GetAllInboxCount/${id} `;
    return this.httpClient.get<MailFolderCount>(api);
  }

  deleteSelectedMails(payload: any): Observable<MailFolderCount> {
    const api = `${this.rootEnpoint}Email/DeleteSelectedMails`;
    return this.httpClient.post<any>(api, payload);
  }

  deleteEmail(input: InboxInputModel): Observable<any> {
    const api = `${this.rootEnpoint}Email/Deleted`;
    return this.httpClient.post<any>(api, input);
  }
  deleteMAccount(email: string): Observable<boolean> {
    const api = `${this.rootEnpoint}Email/DeleteAccount/?email=${email}`;
    return this.httpClient.delete<any>(api);
  }
  loggedOutEmail(email: string): Observable<any> {
    const api = `${this.rootEnpoint}Email/Logout?email=${email}`;
    return this.httpClient.post<any>(api, email);
  }
  logInAccount(input: LoginAccountDetails): Observable<any> {
    const api = `${this.rootEnpoint}Email/Login`;
    return this.httpClient.post<any>(api, input);
  }
  logInGmailAccount(input: any): Observable<any> {
    const api = `${this.rootEnpoint}Email/Login`;
    return this.httpClient.post<any>(api, input);
  }
  getMInbox(input: InboxInputModel, searchValue = ""): Observable<any> {
    var api = `${this.rootEnpoint}Email/GetInbox`;
    if (searchValue) {
      api = api + `?search=${searchValue}`
    }
    return this.httpClient.post<any>(api, input);
  }

  getMDraft(input: InboxInputModel): Observable<any> {
    const api = `${this.rootEnpoint}Email/draft`;
    return this.httpClient.post<any>(api, input);
  }

  getMSent(input: InboxInputModel): Observable<any> {
    const api = `${this.rootEnpoint}Email/sent`;
    return this.httpClient.post<any>(api, input);
  }

  getMSavedEmails(input: InboxInputModel): Observable<any> {
    const api = `${this.rootEnpoint}Email/Outbox`;
    return this.httpClient.post<any>(api, input);
  }

  getMArchived(input: InboxInputModel): Observable<any> {
    const api = `${this.rootEnpoint}Email/Archive`;
    return this.httpClient.post<any>(api, input);
  }

  getMSpam(input: InboxInputModel): Observable<any> {
    const api = `${this.rootEnpoint}Email/Junk`;
    return this.httpClient.post<any>(api, input);
  }

  getMdeleted(input: InboxInputModel): Observable<any> {
    const api = `${this.rootEnpoint}Email/deleted`;
    return this.httpClient.post<any>(api, input);
  }
  markMRead(input: InboxInputModel): Observable<any> {
    const api = `${this.rootEnpoint}Email/MarkAsRead`;
    return this.httpClient.post<any>(api, input);
  }
  markUnread(input: InboxInputModel): Observable<any> {
    const api = `${this.rootEnpoint}Email/MarkAsUnRead`;
    return this.httpClient.post<any>(api, input);
  }

  moveMailToArchived(data: InboxInputModel): Observable<any> {
    const endpoint = `${this.rootEnpoint}Email/Movetoarchive`;
    return this.httpClient.post<any>(endpoint, data);
  }
  moveMailToSpam(data: InboxInputModel): Observable<any> {
    const endpoint = `${this.rootEnpoint}Email/Movetospam`;
    return this.httpClient.post<any>(endpoint, data);
  }
  moveMailToDelete(data: InboxInputModel): Observable<any> {
    const endpoint = `${this.rootEnpoint}Email/Movetodelete`;
    return this.httpClient.post<any>(endpoint, data);
  }

  getUserEmailAccounts(): Observable<any[]> {
    const endpoint = this.rootEnpoint + "Email/UserMails";
    // getUserEmailAccounts + '/' + userId;
    // if (isNullOrUndefined( this.emailAccounts)) {
    // }

    return this.httpClient.get<any[]>(endpoint);
  }

  sendMail(data: NewMailParseModel) {
    const endpoint = this.rootEnpoint + "/";
    return this.httpClient.post<MailListViewModel[]>(endpoint, data);
  }

  getInboxDetail(
    mailId: string,
    configId: string,
    mailBoxType: string,
  ): Observable<MailInboxViewModel> {
    const data = {
      configId: configId,
      skip: 0,
      messageId: mailId,
      attachmentId: "",
    };
    let endpoint = ""
    if (mailBoxType == "sent") {
      endpoint = this.rootEnpoint + "/Email/GetInboxById";
    } else if (mailBoxType == "saved") {
      endpoint = this.rootEnpoint + "/Email/GetSentById";
    } else if (mailBoxType == "archived") {
      endpoint = this.rootEnpoint + "/Email/GetArchiveById";
    } else if (mailBoxType == "draft") {
      endpoint = this.rootEnpoint + "Email/GetDraftById";
    } else if (mailBoxType == "trash") {
      endpoint = this.rootEnpoint + "/Email/GetInboxById";
    } else {
      endpoint = this.rootEnpoint + "/Email/GetInboxById";
    }

    return this.httpClient.post<MailInboxViewModel>(endpoint, data);
  }

  getEmailAttachments(
    mailId: string,
    configId: string
  ): Observable<MailInboxViewModel> {
    const data = {
      configId: configId,
      skip: 0,
      messageId: mailId,
      attachmentId: "",
    };
    const endpoint = this.rootEnpoint + "/Email/GetAttachments";
    return this.httpClient.post<MailInboxViewModel>(endpoint, data);
  }

  registerMicrosoftAccount(data: EmailAccountModel): Observable<any> {
    console.log(data)
    const endpoint = this.rootEnpoint + "Email/AddAcount";
    // refresh email account list after a register
    return this.httpClient.post<any>(endpoint, data);
  }

  registerGmailAccount(data: EmailAccountModel): Observable<any> {
    const endpoint = this.rootEnpoint + "Email/AddAcount";
    // refresh email account list after a register
    return this.httpClient.post<any>(endpoint, data);
  }

  RegisterOtherEmailAccount(
    data: EmailAccountModel
  ): Observable<AccountResponseModel> {
    const endpoint = this.rootEnpoint + "Email/AddAcount";
    // refresh email account list after a register
    return this.httpClient.post<AccountResponseModel>(endpoint, data);
  }

  loginToGmailAccount(data: EmailLoginParseModel) {
    const endpoint = this.rootEnpoint + "/";
    // +
    // loginGmailAccountEndpoint;
    return this.httpClient.post<any>(endpoint, data);
  }

  loginToMicrosoftAccount(data: EmailLoginParseModel) {
    const endpoint = this.rootEnpoint + "/";
    // + loginMicrosoftAccountEndpoint;
    return this.httpClient.post<any>(endpoint, data);
  }

  loginOtherAccount(data: EmailLoginParseModel): Observable<any> {
    const endpoint = this.rootEnpoint + "/" + "emailLoginEndPoint";
    const res = this.httpClient.post<any>(endpoint, data);
    //console.log(res);
    return res;
  }

  createEmailMatterTask(data: EmailTaskParseModel): Observable<any> {
    const endpoint = `${this.rootEnpoint}resource/Email/task/matter`;
    return this.httpClient.post<any>(endpoint, data);
  }

  createEmailApplicationTask(data: EmailTaskParseModel): Observable<any> {
    const endpoint = `${this.rootEnpoint}resource/Email/task/application`;
    return this.httpClient.post<any>(endpoint, data);
  }

  createEmailEndorsementTask(data: EmailTaskParseModel): Observable<any> {
    const endpoint = `${this.rootEnpoint}resource/Email/taskproceeding`;
    return this.httpClient.post<any>(endpoint, data);
  }

  createEmailMeOrOthersTask(data: EmailTaskParseModel): Observable<any> {
    const endpoint = `${this.rootEnpoint}resource/Email/task`;
    return this.httpClient.post<any>(endpoint, data);
  }

  addEmailMessageToExistingTask(data: AddEmailToExistingTask): Observable<any> {
    const endpoint = `${this.rootEnpoint}resource/Email`;
    return this.httpClient.post<any>(endpoint, data);
  }

  getAllTasksForMail(data: any): Observable<any> {
    const endpoint = `${this.rootEnpoint}resource/Email/firmtasks`;
    return this.httpClient.post<any>(endpoint, data, { observe: "response" });
  }

  getEmailTasks(data: any[]): Observable<any> {
    const endpoint = `${this.rootEnpoint}resource/Email/messages`;
    return this.httpClient.post<any>(endpoint, data);
  }
}
