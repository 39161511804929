import { CaseListViewModel } from "./cases-list";

export class CaseAanlyticsViewModel {
  precedence_value: number;
  highest_ratings: RelatedCasesListViewModel;
  oldest_ratings: RelatedCasesListViewModel;
  latest_ratings: RelatedCasesListViewModel;
  related_cases: RelatedCasesListViewModel[];
  conflicts: RelatedCasesListViewModel[];
  exceptions: RelatedCasesListViewModel[];
  alternative_citations: CitationViewModel[];
  cases_relied_upon: RelatedCasesListViewModel[];
  cases_that_relied_on_this_principle: null;
  ratio: {
    as_at: string;
    case_title: string;
    category: null;
    citation: string;
    court: string;
    from_page: number;
    from_para: string;
    has_full_analytics: false;
    issue: string;
    issue_id: null;
    judge: string;
    judge_id: number;
    legal_head: string;
    oldest_court: null;
    oldest_pk: null;
    pk: number;
    precedence_value: null;
    principle: string;
    principle_citation: string;
    principle_status: string;
    recommended_court: null;
    recommended_pk: null;
    subject_matter: string;
    suit_number: string;
    to_page: number;
    to_para: string;
  };
  statutes_relied_upon: null;
}

export class RelatedCasesListViewModel {
  case_title2: string;
  case_title: string;
  year: number;
  case_type: string;
  judge: string;
  principle_pk: number;
  principle_court: string;
}

export class CitationViewModel {
  citation: string;
  page: string;
  part: string;
  year: number;
}
