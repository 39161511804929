import { CommentService } from "./../../services/comment.service";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";
import { UserTask } from "../../models/view-model/user-tasks";
import * as fromTask from "../../store/reducers/task.reducer";
import * as TaskActions from "../../store/actions/task.action";
import { Store, select } from "@ngrx/store";
import { CommonUserModel } from "src/app/models/generic-model.ts/user-share";
import { Observable, of } from "rxjs";
import { isNullOrUndefined } from "util";
import { TaskService } from "src/app/services/task.service";
import { IAttachFile } from "../interfaces/attach-file";
import { FileUploadModel } from "src/app/models/generic-model.ts/file-upload";
import { FileViewModel } from "src/app/models/view-model/document-list";
import { ModelFactory } from "../helpers/model-factory";
import { FileUploadService } from "src/app/services/file-upload.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CommentModel } from "src/app/models/view-model/comment";
import { Notifier } from "../base-class/notifier";
import * as fromUserHeaderStore from "../../store/reducers/user-header.reducer";
import { AddReviewer } from "src/app/models/task-model/task-model";
import * as fromStore from "../../auth/state/index";
import { UserDetail } from "src/app/models/view-model/user-detail";
import { FeedbackRating } from "src/app/client-management/models/feedback";
import { AppConfigService } from "../helpers/app-config.service";

@Component({
  selector: "app-view-task",
  templateUrl: "./view-task.component.html",
  styleUrls: ["./view-task.component.scss"],
})
export class ViewTaskComponent
  extends Notifier
  implements OnInit, OnChanges, IAttachFile {
  errorText: string;
  showError: boolean;
  fileProgressArray: number[];
  currentDocument: FileUploadModel[];
  existingDocument: FileViewModel[] = [];
  _modelFactory = new ModelFactory();
  entityId = 4;
  task = new UserTask();
  @Output() closeTask = new EventEmitter();
  @Output() closeDashboardTask = new EventEmitter();
  @Output() myMessgae = new EventEmitter<boolean>();
  @Output() updateUser = new EventEmitter<CommonUserModel[]>();

  @Output() taskCheckSuccess = new EventEmitter();

  // tslint:disable-next-line: max-line-length
  @Input() taskTeamMembers$: Observable<{
    selectedUsers: CommonUserModel[];
    updatedUsers: CommonUserModel[];
  }> = of({ selectedUsers: [], updatedUsers: [] });

  @Input() commentRefUser: Observable<number[]> = of([]);
  @Input() comment$: Observable<object> = of({});
  newCommentBtnSpinner: boolean;
  isViewTask = true;
  grabComment: CommentModel;

  taskTeamMembers: CommonUserModel[] = [];
  taskId: number;
  confirmType: string;
  confirmTitle: string;
  confirmMessage: string;
  confirmAction: string;
  confirmElementData: any;

  user = new UserDetail();
  isReviewer: boolean = false;

  reviewerRating: number;

  isLoading: boolean = false;
  ratings: FeedbackRating[] = [];

  reviewerList$: Observable<CommonUserModel[]> = of([]);

  constructor(
    private taskStore: Store<fromTask.State>,
    private _fileUploadService: FileUploadService,
    private activatedRoute: ActivatedRoute,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>,
    private taskService: TaskService,
    private commentService: CommentService,
    private store: Store<fromStore.State>,
    private config: AppConfigService
  ) {
    super(headerStore);

    this.store.pipe(select("auth")).subscribe((data) => {
      this.user = data.userProfile;
    });

    this.taskStore.pipe(select(fromTask.currentTask)).subscribe((data) => {
      this.task = data;
      this.taskTeamMembers = [];

      this.taskId =
        parseInt(this.activatedRoute.snapshot.params["id"]) || 10591;
      this.taskService.getTaskDetail(this.taskId).subscribe((res) => {
        this.task = res;
        //console.log(res);

        // get task team members

        if (!isNullOrUndefined(this.task.taskUsers)) {
          this.task.taskUsers.forEach((taskUser) => {
            let caster: any;
            caster = taskUser;
            let commonUser = new CommonUserModel();
            commonUser = caster;

            this.taskTeamMembers.push(commonUser);
          });
        }

        const reviewerLists = [];

        this.taskTeamMembers.forEach((member) => {
          if (member.id === this.user.userId && member.isReviewer == true) {
            this.isReviewer = true;
          }
          if (member.isReviewer) {
            reviewerLists.push(member);
          }
        });

        this.reviewerList$ = of(reviewerLists);

        this.updateUser.emit(this.taskTeamMembers);
      });

      this.getTaskFiles(this.taskId);
    });

    taskStore.pipe(select(fromTask.completeTaskSuccess)).subscribe((res) => {
      if (!isNullOrUndefined(res)) {
        this.taskCheckSuccess.emit(this.task);
      }
    });
  }

  ngOnInit() {
    this.errorText = "";
    this.showError = false;
    this.fileProgressArray = [];
    this.currentDocument = [];

    this.ratings = this.config.getConfig().feedbackRatings;
  }

  getTaskFiles(taskId) {
    this.taskService.getTaskFiles(taskId).subscribe(
      (res) => {
        this.existingDocument = res;
      },
      (err) => {
        //console.log(err);
      }
    );
  }

  confirmDelete(id: any) {
    this.confirmType = "deleteTask";
    this.confirmTitle = "Delete Task";
    this.confirmMessage = "Are you sure you want to delete this task ?";
    this.confirmAction = "Yes, Delete Task";
    this.confirmElementData = id;
  }

  confirmResponse(data: any) {
    if (data.type === "deleteTask") {
      if (this.taskTeamMembers.length > 0) {
        const text =
          this.taskTeamMembers.length === 1
            ? `there is one other team member attached to this task,
        remove this individual before deleting`
            : `there are ${this.taskTeamMembers.length}
        team members attached to this task , remove them before deleting`;
        alert(text);
      } else {
        this.taskService
          .deleteTask(data.elementData)
          .toPromise()
          .then(() => {
            this.closeDialog();
          })
          .catch((err) => {
            //console.log(err);
          });
      }
    }
  }

  deleteFile(index: number): void {
    const selectedFile = this.existingDocument[index].id;
    this._fileUploadService.deleteFile(selectedFile).subscribe(res => {
      this.existingDocument.splice(index, 1);
    });
  }

  getviewattachedObject(uploadedObject: any) {
    if (uploadedObject.errorText) {
      this.sendErrorMessage(uploadedObject.errorMsg);
    } else {
      this.currentDocument = [];
      this.errorText = uploadedObject.errorText;
      this.showError = uploadedObject.showError;
      this.fileProgressArray = uploadedObject.fileProgressArray;
      this.currentDocument = uploadedObject.docs;

      const files: any[] = [];

      this.currentDocument.forEach((item) => {
        const formData: any = {};
        formData.fileModel = item;
        formData.taskId = this.task.id;

        files.push(formData);
      });

      if (!this.showError || isNullOrUndefined(this.showError)) {
        this._fileUploadService
          .addFile(files)
          .toPromise()
          .then((res) => {
            this.currentDocument.forEach((file) => {
              const doc = this._modelFactory.Create(file);
              this.existingDocument.push(doc);
              this.getTaskFiles(this.task.id);
            });
          })
          .catch((err) => {
            //console.log(err.error);
            // this.sendErrorMessage(err.error[0]);
          });
      }
    }
  }

  closeDialog(): void {
    this.closeTask.emit("true");
    this.closeDashboardTask.emit("true");
  }

  show() {
    //console.log(this.task);
  }

  ngOnChanges(changes) {
    this.updateUser.emit(this.taskTeamMembers);

    if (this.taskTeamMembers$) {
      this.taskTeamMembers$.subscribe((users) => {
        // list of users of new users to send to the API

        const updatedtaskmemebers: any[] = [];

        if (!isNullOrUndefined(users.updatedUsers)) {
          users.updatedUsers.forEach((data) => {
            const taskMember: any = {
              firmUserId: data.id,
              taskId: this.task.id,
            };
            updatedtaskmemebers.push(taskMember);
          });
        }

        if (updatedtaskmemebers.length > 0) {
          // send task memebers to endpoint
          this.taskService
            .assignTaskToUser(updatedtaskmemebers)
            .toPromise()
            .then((res) => {
              users.updatedUsers.forEach((u) => {
                if (
                  isNullOrUndefined(
                    this.taskTeamMembers.find((c) => c.id === u.id)
                  )
                ) {
                  this.taskTeamMembers.push(u);
                }
              });
            })
            .catch((err) => {
              //console.log(err);
              // this.pushMessage(`Error assigning users to <strong>${this.createNewTask.name}</strong> task,  Failed`);
            });
        }
      });
    }
  }
  completeTask(): void {
    this.taskStore.dispatch(new TaskActions.CheckTask(this.task));
    this.closeTask.emit("true");
    this.closeDashboardTask.emit("true");
    this.myMessgae.emit(true);
  }

  removeMember(removedUser): void {
    this.taskTeamMembers.splice(
      this.taskTeamMembers.indexOf(
        this.taskTeamMembers.find((data) => data.id === removedUser.id)
      ),
      1
    );

    this.taskService
      .removeUserTask(this.taskId, removedUser.id)
      .subscribe((data) => {
        if (!isNullOrUndefined(data) && data) {
          this.updateUser.emit(this.taskTeamMembers);
        }
      });
  }

  // debugger;
  postComment(comments$) {
    const taskComment = new CommentModel();

    taskComment.taskId = this.taskId;
    taskComment.comment = comments$.comment;

    this.commentService.Comment(taskComment).subscribe(
      (data) => {
        this.pushMessage("Comment sent");
        taskComment.dateAdded = new Date();
        this.grabComment = taskComment;
      },
      (error) => {
        this.sendErrorMessage("Commment not sent");
        this.newCommentBtnSpinner = false;
      }
    );

    // taskComment.threadId = this.taskId;
    // taskComment.commentId = null;
    // taskComment.content = comments$.comment;
    // taskComment.draftId = 0;

    // this.commentService.writeComment(taskComment).subscribe(
    //   (data) => {
    //     this.pushMessage("Comment sent");
    //     this.grabComment.emit(taskComment);
    //   },
    //   (error) => {
    //     this.sendErrorMessage("Commment not sent");
    //     this.newCommentBtnSpinner = false;
    //   }
    // );
  }

  selectRating(rating: FeedbackRating): void {
    this.reviewerRating = rating.id;
    // this.feedbackForm.patchValue({
    //   ratingId: rating.id,
    //   ratingName: rating.caption,
    // });
  }

  getUsersChosen(user: any): void {
    console.log(user);
    this.isLoading = true;

    const payload: AddReviewer = {
      userId: 0,
      rating: 0,
      id: this.taskId,
    };

    if (user.selectedUsers && user.selectedUsers.length > 0) {
      user.selectedUsers.forEach((user) => {
        payload.userId = user.id;
      });
    }

    this.taskService.addReviewer(payload).subscribe(
      () => {
        this.pushMessage("Reviewer added successfully");
        this.isLoading = false;
      },
      (err) => {
        this.sendErrorMessage("An error occurred, please try again!!!!!");
        this.isLoading = false;
      }
    );
  }

  sendReview(): void {
    this.isLoading = true;

    const payload: AddReviewer = {
      rating: this.reviewerRating,
      id: this.taskId,
    };

    this.taskService.rateTask(payload).subscribe(
      (res) => {
        this.isLoading = false;
        this.pushMessage(`${this.task.name} successfully rated!!!`);
        this.closeDialog();
      },
      (err) => {
        this.sendErrorMessage(err.message);
        this.isLoading = false;
      }
    );
  }
}
