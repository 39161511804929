import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material";
import { takeWhile } from "rxjs/operators";
import { ContactViewModel } from "../../contact/models/contact-list";
import { ContactLabel } from "../../contact/models/contact-label";
import * as contactActions from "../../store/actions/contacts.actions";
import * as fromContacts from "../../store/reducers/contacts.reducer";
import { State } from "../../store/reducers/contacts.reducer";
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormArray,
  FormControl,
} from "@angular/forms";
import { AppConfigService } from "../../shared/helpers/app-config.service";
import { ContactService } from "../../contact/services/contact.service";
import { isNullOrUndefined } from "util";
import { ActivatedRoute, Router } from "@angular/router";
import { Contact } from "../../contact/models/add-contact";
import { Notifier } from "src/app/shared/base-class/notifier";
import * as fromUserHeaderStore from "../../store/reducers/user-header.reducer";
import { Store, select } from "@ngrx/store";
import * as headertActions from "../../store/actions/user-header.actions";
import { UserManagementService } from "src/app/services/user-management.service";
import { GenericService } from "src/app/services/generic.service";
import { UserDetail } from "src/app/models/view-model/user-detail";

import * as LoginActions from "../../auth/state/login.actions";
import * as fromStore from "../../auth/state/index";

@Component({
  selector: "app-new-contact-modal",
  templateUrl: "./new-contact-modal.component.html",
  styleUrls: ["./new-contact-modal.component.scss"],
})
export class NewContactModalComponent extends Notifier implements OnInit {
  titles = [];
  relations = [];
  countries: any[] = [];
  states: any[] = [];

  newContactForm: FormGroup;
  selectedLabel: ContactLabel;
  successText: string;
  errorText: string;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  btnSpinner: boolean;
  firmId: number;
  userId: number;
  @Input() firmIdNo: any;
  @Input() userIdNo: any;
  @Output() refreshContact = new EventEmitter();

  userData = new UserDetail();

  @ViewChild("messageBox") messageBox: ElementRef;

  constructor(
    public fb: FormBuilder,
    private store: Store<State>,
    private service: ContactService,
    private config: AppConfigService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private genericService: GenericService,
    // protected userManagementService: UserManagementService,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>
  ) {
    super(headerStore);

    this.store.dispatch(new LoginActions.LoadLocalUserDetail());
    this.store.pipe(select("auth")).subscribe((data) => {
      /// save token and user detail to local storage
      this.userData = data.userProfile;

      this.firmIdNo = this.userData.firmId;
      this.userIdNo = this.userData.userId;
    });

    this.newContactForm = fb.group({
      title: ["", Validators.required],
      firstName: ["", [Validators.required, Validators.pattern("^[a-zA-Z]*$")]],
      lastName: ["", [Validators.required, Validators.pattern("^[a-zA-Z]*$")]],
      companyName: ["", Validators.required],
      source: [""],
      phoneNumber: [
        "",
        [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      ],
      // tslint:disable-next-line: max-line-length
      email: [
        "",
        [
          Validators.required,
          ,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      relationship: ["", Validators.required],
      contactLabel: [""],
      website: [""],
      street: [""],
      country: [""],
      state: [""],
      city: [""],
    });
  }

  get title() {
    return this.newContactForm.get("title");
  }

  get firstName() {
    return this.newContactForm.get("firstName");
  }

  get lastName() {
    return this.newContactForm.get("lastName");
  }

  get companyName() {
    return this.newContactForm.get("companyName");
  }

  get source() {
    return this.newContactForm.get("source");
  }

  get phoneNumber() {
    return this.newContactForm.get("phoneNumber");
  }

  get email() {
    return this.newContactForm.get("email");
  }

  get status() {
    return this.newContactForm.get("status");
  }

  get relationship() {
    return this.newContactForm.get("relationship");
  }

  get contactLabel() {
    return this.newContactForm.get("contactLabel");
  }
  get website() {
    return this.newContactForm.get("website");
  }
  get street() {
    return this.newContactForm.get("street");
  }
  get country() {
    return this.newContactForm.get("country");
  }
  get city() {
    return this.newContactForm.get("city");
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnChanges(changes: SimpleChanges) {}

  ngOnInit() {
    this.showErrorMessage = false;
    this.showSuccessMessage = false;
    this.btnSpinner = false;

    this.titles = this.config.getConfig().titles;
    this.relations = this.config.getConfig().relationship;
    this.genericService
      .getCountries()
      .toPromise()
      .then((res) => {
        this.countries = res;
      });

    // this.firmId = Number.parseInt(this.activateRoute.parent.snapshot.params['firmId'], 10);
    // this.userId = Number.parseInt(this.activateRoute.parent.snapshot.params['userId'], 10);
  }

  chooseLabel(theData) {
    //console.log(theData);
    this.selectedLabel = theData.label;
    this.contactLabel.patchValue(this.selectedLabel);
    //console.log(theData);
  }

  selectCountry(id: any) {
    this.genericService
      .getCountryState(id)
      .toPromise()
      .then((data) => {
        this.states = data;
      });
  }

  resetModal() {
    this.newContactForm.reset();
    this.btnSpinner = false;
  }

  submitContact() {
    this.btnSpinner = true;
    //console.log(this.newContactForm.value);
    // //console.log('form will send', formData);
    const formValue = this.newContactForm.value;
    const contact = new Contact();
    contact.firmId = this.firmIdNo;
    contact.firstName = formValue.firstName;
    contact.lastName = formValue.lastName;
    contact.titleId = Number.parseInt(formValue.title, 10);
    contact.email = formValue.email;
    contact.phoneNumber = formValue.phoneNumber;
    contact.contactStatusId = +formValue.relationship;
    contact.firmLabelId =
      formValue.contactLabel === ""
        ? 0
        : Number.parseInt(formValue.contactLabel.id, 10);
    contact.contactSource = formValue.source;

    if (!isNullOrUndefined(formValue.companyName)) {
      contact.companyName = formValue.companyName;
    } else {
      contact.companyName = "N/A";
    }

    // contact.companyName = formValue.companyName;
    //console.log(contact);
    this.service
      .addContact(contact)
      .toPromise()
      .then((data) => {
        // this.pushMessage('Contact, <strong>' + contact.firstName +
        //   ' ' + contact.lastName + '</strong> Was saved successfully');

        // this.showSuccessMessage = true;
        // this.router.navigate(['/firm/contact/' + this.userIdNo + '/firm/' + this.firmIdNo + '/']);
        this.refreshContact.emit("refresh");
        this.messageBox.nativeElement.click();
      })
      .catch((err) => {
        this.showErrorMessage = true;
        this.pushMessage(
          "Adding Contact, " +
            contact.firstName +
            " " +
            contact.lastName +
            " failed"
        );
        this.btnSpinner = false;
      });
  }
}
