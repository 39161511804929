import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Notifier } from '../base-class/notifier';
import { Store } from '@ngrx/store';
import * as fromUserHeaderStore from '../../store/reducers/user-header.reducer';

@Component({
  selector: 'app-error-notifier',
  templateUrl: './error-notifier.component.html',
  styleUrls: ['./error-notifier.component.scss']
})
export class ErrorNotifierComponent extends Notifier implements OnInit   {

  @ViewChild('messageBox') messageBox: ElementRef;
  constructor(protected headerStore: Store<fromUserHeaderStore.HeaderState>) {
    super(headerStore);

  }

  ngOnInit() {

  }

}
