import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { CommonUserModel } from 'src/app/models/generic-model.ts/user-share';
import { UserManagementService } from 'src/app/services/user-management.service';
import * as fromStore from '../../auth/state/index';
import { Store, select } from '@ngrx/store';
import { UserDetail } from '../../models/view-model/user-detail';
import { Observable, of } from 'rxjs';
import { LoadLocalUserDetail } from 'src/app/store/actions/dashboard.actions';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-choose-user-custom-modal',
  templateUrl: './choose-user-custom-modal.component.html',
  styleUrls: ['./choose-user-custom-modal.component.scss']
})
export class ChooseUserCustomModalComponent implements OnInit, OnChanges {

  filteredUsers: CommonUserModel[] = [];
  selectedUsers: CommonUserModel[] = [];
  user = new UserDetail();
  btnSpinner: boolean;
  updatedUsers: any;
  @Input() selectTitle: string;
  @Input() usersList$: Observable<CommonUserModel[]> = of([]);
  @Output() usersUpdate = new EventEmitter<any>();
  @Output() closeUserModal = new EventEmitter<boolean>();

  constructor(
    private userService: UserManagementService,
    private store: Store<fromStore.State>
  ) {
    this.store.dispatch(new LoadLocalUserDetail());

    this.store.pipe(select('auth')).subscribe(data => {
      this.user = data.userProfile;
    });
  }

  ngOnInit() {
    this.userService.getUsersList(this.user.firmId).subscribe(res => {
      this.filteredUsers = res.body;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!isNullOrUndefined(this.usersList$)) {

      this.usersList$.subscribe(res => {
        this.selectedUsers = [...res];
        this.updatedUsers = []
      })

    }

  }

  searchUsers(term) {
    this.userService.getUsersList(this.user.firmId, term).subscribe(res => {
      this.filteredUsers = res.body;
    });
  }

  selectUser(user: CommonUserModel) {
    const foundUser = this.selectedUsers.find(data => data.id === user.id)
    if (isNullOrUndefined(foundUser)) {
      this.updatedUsers.push(user)
      this.selectedUsers.push(user);
    } else {
      this.selectedUsers.splice(this.selectedUsers.indexOf(foundUser), 1);
      this.updatedUsers.splice(this.updatedUsers.indexOf(foundUser), 1);
    }
  }

  checkIfUserIsSelected(user: CommonUserModel): boolean {
    const foundUser = this.selectedUsers.find(data => data.id === user.id)
    if (isNullOrUndefined(foundUser)) {
      return false;
    } else {
      return true;
    }
  }

  returnSelectedUsers() {
    this.closeModal();
    this.usersUpdate.emit({ selectedUsers: this.selectedUsers, updatedUsers: this.updatedUsers });
  }

  closeModal(): void {
    this.closeUserModal.emit(true);
  }

}
