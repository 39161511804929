import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges, } from "@angular/core";
import { CommonUserModel } from "src/app/models/generic-model.ts/user-share";
import { UserManagementService } from "src/app/services/user-management.service";
import * as fromStore from "../../auth/state/index";
import { Store, select } from "@ngrx/store";
import { UserDetail } from "../../models/view-model/user-detail";
import { Observable, of } from "rxjs";
import { LoadLocalUserDetail } from "src/app/store/actions/dashboard.actions";
import { isNullOrUndefined } from "util";
@Component({
  selector: "app-choose-user",
  templateUrl: "./choose-user.component.html",
  styleUrls: ["./choose-user.component.scss"],
})
export class ChooseUserComponent implements OnInit, OnChanges {

  filteredUsers: CommonUserModel[] = [];
  selectedUsers: CommonUserModel[] = [];
  successText: string;
  user = new UserDetail();
  errorText: string;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  btnSpinner: boolean;
  updatedUsers = [];
  @Input() selectTitle: string;
  @Input() usersList$: Observable<CommonUserModel[]> = of([]);
  @Output() usersUpdate = new EventEmitter<any>();
  @ViewChild("closeUserModal") closeUserModal: ElementRef;

  constructor(
    private userService: UserManagementService,
    private store: Store<fromStore.State>
  ) {
    this.store.dispatch(new LoadLocalUserDetail());

    this.store.pipe(select("auth")).subscribe((data) => {
      this.user = data.userProfile;
    });
  }

  ngOnChanges(changes) {
    if (!isNullOrUndefined(this.usersList$)) {
      this.usersList$.subscribe((res) => {
        this.selectedUsers = [...res];
        this.updatedUsers = [];
      });
    }
  }

  ngOnInit() {
    this.userService.getUsersList(this.user.firmId).subscribe((res) => {
      this.filteredUsers = res.body;
    });
  }

  searchUsers(term) {
    this.userService.getUsersList(this.user.firmId, term).subscribe((res) => {
      this.filteredUsers = res.body;
    });
  }

  selectUser(user: CommonUserModel) {
    // invert the original selection
    const foundUser = this.selectedUsers.find((data) => data.id === user.id);
    if (isNullOrUndefined(foundUser)) {
      this.updatedUsers.push(user);
      this.selectedUsers.push(user);
    } else {
      this.selectedUsers.splice(this.selectedUsers.indexOf(foundUser), 1);
      this.updatedUsers.splice(this.updatedUsers.indexOf(foundUser), 1);
    }
  }

  // closeModal() {
  //   // handle re-populating logic when modal is closed without saving the modal
  // }

  checkIfUserIsSelected(user: CommonUserModel): boolean {
    const foundUser = this.selectedUsers.find((data) => data.id === user.id);
    if (isNullOrUndefined(foundUser)) {
      return false;
    } else {
      return true;
    }
  }

  returnSelectedUsers() {
    this.closeUserModal.nativeElement.click();
    this.usersUpdate.emit({
      selectedUsers: this.selectedUsers,
      updatedUsers: this.updatedUsers,
    });
  }
}
