import { UserTask } from '../../models/view-model/user-tasks';
import { Component, OnInit, Input } from '@angular/core';
import * as fromTask from '../../store/reducers/task.reducer';
import * as TaskActions from '../../store/actions/task.action';
import { Store, select } from '@ngrx/store';

@Component({
  selector: 'app-completed-task',
  templateUrl: './completed-task.component.html',
  styleUrls: ['./completed-task.component.scss']
})
export class CompletedTaskComponent implements OnInit {
  @Input() tasks: UserTask[];
  currentTask: any;
  showCurrentTask: boolean;
  constructor(private taskStore: Store<fromTask.State>) {

  }

  ngOnInit() {}

  closeViewTask(e) {
    if (e === 'true') {
      this.taskStore.dispatch(new TaskActions.ViewTask(false));
    }
  }
  setCurrentTask(task) {
    this.currentTask = task;
  }
}
