import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { PageEvent } from "@angular/material";
import { finalize } from "rxjs/operators";
import {
  DriveFileUploadModel,
  FileUploadModel,
} from "src/app/models/generic-model.ts/file-upload";
import { DocumentList } from "src/app/models/view-model/document-list";
import { DraftService } from "src/app/services/draft.service";
import { isNullOrUndefined } from "util";
import { IAttachFile } from "../interfaces/attach-file";

@Component({
  selector: "app-attach-file-custom",
  templateUrl: "./attach-file-custom.component.html",
  styleUrls: ["./attach-file-custom.component.scss"],
})
export class AttachFileCustomComponent implements OnInit, IAttachFile {
  errorText: string;
  showError: boolean;
  fileProgressArray: number[];
  currentDocument: FileUploadModel[];
  totalFileSize: number;
  showDriveModal = false;
  driveFiles: DocumentList[] = [];
  selectedFiles: DocumentList[] = [];
  loadingData: boolean;
  showDriveError = false;
  pageSize = 12;
  pageSizeIndex: number;
  totalCount: number;
  files: any[] = [];
  @Input() elementId: string;
  @Input() text: string = 'Attach file';
  @Input() multiple: boolean = true;
  @Output() uploaderObject = new EventEmitter<any>();
  @Output() attachedDriveFiles = new EventEmitter<FileUploadModel[]>();

  constructor(private externalStorageService: DraftService) {}

  ngOnInit() {
    this.fileProgressArray = [];
    this.currentDocument = [];
    this.totalFileSize = 0;
  }

  closeFileItem(index) {
    this.fileProgressArray.splice(index, 1);
    this.currentDocument.splice(index, 1);
    if (this.selectedFiles.length) {
      this.selectedFiles.splice(index, 1);
    }
  }

  /**
   * when file is changed
   * @param event;
   */
  onFileChange(event) {
    this.files = event.target.files;
    console.log(this.files.length)
    this.set_upReader(event.target.files, 0);
  }

  set_upReader(files, i) {
    // check if it has file
    if (files && files[i]) {
      const file = files[i];
      const fileName = file.name;
      const fileSize = file.size;
      const fileType = file.type;
      if (file.size + this.totalFileSize > 25597152) {
        (this.errorText =
          "File attached " +
          file.name +
          "  with file size greater than 10MB is not allowed"),
          // tslint:disable-next-line: no-unused-expression
          "Error! ";
      } else {
        const fileType = file.type;
        const previewReader = new FileReader();
        previewReader.onprogress = (e) => {
          this.fileProgressArray.push((e.loaded / e.total) * 100);
        };

        previewReader.readAsDataURL(file);
        previewReader.onload = (e: any) => {
          this.readerLoaded(e, files, i, fileName, fileSize, fileType);
          console.log(e, files, i, fileName, fileSize, fileType);
        };
      }
    }
  }

  readerLoaded(e, files, i, fileName, fileSize, fileType) {
    // called once readAsDataURL is completed
    const FILE = new FileReader();

    const taskUpload = new FileUploadModel();

    taskUpload.fileBinary = (<string>e.target.result).split(",")[1];

    taskUpload.fileExtension = fileType;
    taskUpload.fileType = fileType;
    taskUpload.fileName = fileName;
    taskUpload.fileSize = fileSize;
    if (
      taskUpload.fileExtension ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      taskUpload.fileExtension = "docx";
    }
    if (
      taskUpload.fileExtension ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      taskUpload.fileExtension = "xlsx";
    }

    this.totalFileSize += taskUpload.fileSize;
    this.currentDocument.push(taskUpload);

    // If there's a file left to load, Load the next file
    if (i < files.length - 1) {
      return this.set_upReader(files, i + 1);
    }

    this.uploaderObject.emit({
      showError: this.showError,
      errorText: this.errorText,
      currentDocument: this.currentDocument,
    });
  }

  openDriveModal(): void {
    this.showDriveModal = true;
    this.loadDriveFiles();
  }

  closeDriveModal(): void {
    this.showDriveModal = false;
  }

  pageLoad(event: PageEvent): void {
    this.loadDriveFiles(event.pageIndex);
  }

  selectFile(file: DocumentList): void {
    if (this.fileSelected(file)) {
      this.selectedFiles = this.selectedFiles.filter(
        (item) => item.id != file.id
      );
      return;
    }

    this.selectedFiles.push(file);
  }

  fileSelected(file: DocumentList): boolean {
    const fileChosen = this.selectedFiles.find((item) => item.id == file.id);
    return !!fileChosen;
  }

  get disableDriveSubmit(): boolean {
    return (
      this.loadingData || this.showDriveError || this.selectedFiles.length < 1
    );
  }

  private loadDriveFiles(page = 0): void {
    this.loadingData = true;

    this.externalStorageService
      .getDriveFiles(1, this.pageSize, page)
      .pipe(finalize(() => (this.loadingData = false)))
      .subscribe(
        (res) => {
          this.totalCount =
            this.totalCount ||
            JSON.parse(res.headers.get("X-Pagination")).totalCount;
          this.driveFiles = res.body.length ? this.formatType(res.body) : [];
        },
        (err) => {
          if (err.status == 404) {
            this.showDriveError = true;
          }
        }
      );
  }

  emitDriveFiles(): void {
    this.selectedFiles.forEach((item) => {
      this.currentDocument.push(new DriveFileUploadModel(item));
    });
    this.attachedDriveFiles.emit(this.currentDocument);
    this.closeDriveModal();
  }

  private formatType(fileList: DocumentList[]): DocumentList[] {
    fileList.forEach((item) => {
      if (!isNullOrUndefined(item.format)) {
        if (
          item.format.includes("jpg") ||
          item.format.includes("png") ||
          item.format.toLowerCase().includes("image")
        ) {
          item.customFormat = "img";
        } else if (
          item.format.includes("doc") ||
          item.format.includes("docx") ||
          item.format.includes("msword")
        ) {
          item.customFormat = "doc";
        } else if (
          item.format.includes("xls") ||
          item.format.includes("sheet")
        ) {
          item.customFormat = "xls";
          item.format = "xls";
        } else if (item.format.includes("pdf")) {
          item.customFormat = "pdf";
        } else if (item.format.includes("plain/text")) {
          item.customFormat = "txt";
        }
      }
    });

    return fileList;
  }
}
