import { ProceedingViewModel } from 'src/app/matter-endorsement/models/endorsement';
import { TagViewModel } from './tag-view';

export class MatterList {
  id: number;
  name: string;
  description: string;
  createdBy: number;
  firmId: number;
  dateCreated: number;
  isCompleted: boolean;
  // client?:
  unCompletedTaskCount?: number;
  tag?: TagViewModel;
  lastHearingDate?: string;
  nextHearingDate?: string;
  suitNumber?: string;
  IsArchieved?: boolean;
  teamId?: number;
  subTypeId?: number;
  matterCategory?: string;
  matterCategoryTypeId?: number;
  matterCategoryId?: number;
  matterCategorySubType?: string;
  matterCategoryType?: string;
  clients?: GenericViewModel[] = [];
  status?: string;

  // endorsement
  proceedingId?: number;
  nextStage?: string;
  summary?: string;

  // application
  applicationId?: number;
  applicationType?: string;

  /**
   *
   */
  constructor() {
    this.matterCategorySubType = 'N/A';
    this.matterCategoryType = 'N/A';
    this.matterCategory = 'N/A';
    this.clients = [];
    this.status = 'N/A';
  }

}

// tslint:disable-next-line:class-name
export class matterDetail {
  id: number;
  name: string;
  description: string;
  createdBy: number;
  firmId: number;
  dateCreated: number;
  isCompleted: boolean;
  currentJudge: any[];
  currentCourt: CourtViewModel;
  proceeding: ProceedingViewModel;
  teamId?: number;
  isArchieved: boolean;
  matterCategory: string;
  categoryId?: number;
  matterCategoryType?: any;
  matterCategorySubType?: any;

}



export class CourtViewModel {
  matterCourtId: number;
  courtTypeId: number;
  courtAddress: string;
  courtNames: string;
  suitNumber: string;
}
export class EditMatterCourt {
  matterId: number;
  courtTypeId: number;
  suitNumber: string;
  dateCommenced: string;
  courtName: string;
  courtAddress: string;
}

export class EditJudgeDetail {
    courtTypeId: number;
    firstName: string;
    judgeId: number;
    gender: string;
    lastName: string;
    otherName: string;
    stateId: number;
}
export class EditMatterJudgeDetail {
  courtMatterId: number;
  judges: EditJudgeDetail;
}



export interface GenericViewModel {
  id: number; // courtmatter judge Id not the real judge
  name: string;
}
export interface CourtListModel {
  id: number; // courtmatter judge Id not the real judge
  court: string;
}
export class NextHearingDateListViewModel {
  id: number; // courtmatter judge Id not the real judge
  date: string;
}


export class contactList {
  id?: number;
  matterId: number;
  contactId: number;
  dateAdded?: string;
  isClient: boolean;
  isParty: boolean;
  matterContactStatusId: number;
  matter: string;
  contact: string;
  matterContactStatus: string;
  email?: string;
  phoneNumber?: string;
}

export class contactListLite {
  matterId: number;
  contactId: number;
  isClient: boolean;
  matterContactStatusId: number;
}

export class briefEditDetail {
  id: Number;
  name: string;
  description: string;
  firmId: Number;
  isAcheived?: boolean;
  tagId?: Number;
  subTypeId: Number
}

export interface Judge {
  id: number;
  name: string;
  description?: string;
}
