import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { LpDocService } from "src/app/services/lp-doc.service";
import { isNullOrUndefined } from "util";
import * as fromlpdoc from "../../store/reducers/lp-doc.reducer";
import { AIDocResponse } from "src/app/models/view-model/ai-doc.model";

@Component({
  selector: "app-pdf-image-viewer",
  templateUrl: "./pdf-image-viewer.component.html",
  styleUrls: ["./pdf-image-viewer.component.scss"],
})
export class PdfImageViewerComponent implements OnInit, OnChanges {
  public visible: boolean = false;
  @Input() previewFileDataUri: any;
  @Input() previewFileDataType: any;
  @Input() showLoader: boolean;
  maximize = false;
  dataPrefix = "";

  constructor(
    private dom: DomSanitizer,
    private _lppcDoc: LpDocService,
    private lpDocStore: Store<fromlpdoc.State>
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (!isNullOrUndefined(changes.previewFileDataType)) {
      this.previewFileDataType = changes.previewFileDataType.currentValue;
      if (this.previewFileDataType === "pdf") {
        this.dataPrefix = "data:application/pdf;base64";
      } else {
        this.dataPrefix = "data:image/png;base64";
      }
    }
    if (!isNullOrUndefined(changes.previewFileDataUri)) {
      this.previewFileDataUri = this.dom.bypassSecurityTrustResourceUrl(
        `${this.dataPrefix}, ${changes.previewFileDataUri.currentValue}`
      );
    }
  }

  ngOnInit() {}

  minimizeView(): void {
    this.maximize = false;
  }

  maximizeView(): void {
    this.maximize = true;
  }

  public analyzewithAI(): void {
    this.visible = true;
    // this.showLoader = true;
    // const formData = new FormData();
    // formData.append("file", this.blobCreationFromURL(this.previewFileDataUri));
    // this._lppcDoc.aiUploadReview(formData).subscribe({
    //   next: (res: AIDocResponse) => {
    //     //console.log(res);
    //     this.showLoader = false;
    //   },
    //   error: (_) => {
    //     //console.log(_);
    //     this.showLoader = false;
    //   },
    // });
  }
}
