import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/shared/helpers/app-config.service';
import { ClientEndorsement } from '../models/client-endorsement';
import { ClientFeedback, FeedbackParseModel } from '../models/feedback';
import { OverdueInvoice, UnpaidInvoice } from '../models/invoice-report';
import { ConvertedReferral, Referral } from '../models/referral';

@Injectable({
  providedIn: 'root'
})
export class ClientManagementService {

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) { }

  getClientFeedback(firmId: number, searchQuery = "", page = 0, pageSize = 10, usePageSize = true): Observable<HttpResponse<ClientFeedback[]>> {
    const endpoint = `${this.config.getConfig().serverEndpoint}clientFeedback/${firmId}/FirmClientFeedback`;
    const api = `${endpoint}?searchQuery=${searchQuery}&Page=${page}&PageSize=${pageSize}&usePageSize=${usePageSize}`;

    return this.httpClient.get<ClientFeedback[]>(api, { observe: "response" });
  }

  getClientFeedbacks(clientId: number, page?: number, pageSize?: number, searchQuery?: string, usePageSize: boolean = true): Observable<HttpResponse<ClientFeedback[]>> {
    const endpoint = `${this.config.getConfig().serverEndpoint}clientFeedback/${clientId}/ClientFeedback`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append("Page", page.toString());
    queryParams = queryParams.append("PageSize", pageSize.toString());
    queryParams = queryParams.append("usePageSize", usePageSize.toString());
    if (searchQuery) {
      queryParams = queryParams.append("searchQuery", searchQuery);
    }
    return this.httpClient.get<ClientFeedback[]>(endpoint, { observe: 'response', params: queryParams });
  }

  getEachClientFeedback(feedbackId: number): Observable<HttpResponse<ClientFeedback>>  {
    const endpoint = `${this.config.getConfig().serverEndpoint}clientFeedback/${feedbackId}`;
    return this.httpClient.get<ClientFeedback>(endpoint, { observe: 'response' });
  }


  getUnpaidInvoices(firmId: number, searchQuery = "", page = 0, pageSize = 10, usePageSize = true): Observable<HttpResponse<OverdueInvoice[]>> {
    const endpoint = `${this.config.getConfig().serverEndpoint}ClientReport/${firmId}/OverDueInvoices/Report`;
    const api = `${endpoint}?searchQuery=${searchQuery}&Page=${page}&PageSize=${pageSize}&usePageSize=${usePageSize}`;

    return this.httpClient.get<OverdueInvoice[]>(api, { observe: 'response' });
  }

  getInvoicesTotal(firmId: number): Observable<UnpaidInvoice> {
    const api = `${this.config.getConfig().serverEndpoint}ClientReport/${firmId}/TotalOverdueInvoices/Report`;

    return this.httpClient.get<UnpaidInvoice>(api);
  }

  getReferrals(firmId: number, searchQuery = "", page = 0, pageSize = 10, usePageSize = true): Observable<HttpResponse<Referral[]>> {
    const endpoint = `${this.config.getConfig().serverEndpoint}ClientReport/${firmId}/Referral/Lead`;
    const api = `${endpoint}?searchQuery=${searchQuery}&Page=${page}&PageSize=${pageSize}&usePageSize=${usePageSize}`;

    return this.httpClient.get<Referral[]>(api, { observe: 'response' });
  }

  getClientConversions(firmId: number, page = 0, pageSize = 10, usePageSize = true, state: number): Observable<HttpResponse<ConvertedReferral[]>> {
    const endpoint = `${this.config.getConfig().serverEndpoint}ClientReport/${firmId}/Client/Conversions`;
    const api = `${endpoint}?Page=${page}&PageSize=${pageSize}&usePageSize=${usePageSize}&conversionState=${state}`;

    return this.httpClient.get<ConvertedReferral[]>(api, { observe: 'response' });
  }

  getClientEndorsements(firmId: number, page = 0, pageSize = 10, usePageSize = true): Observable<HttpResponse<ClientEndorsement[]>> {
    const endpoint = `${this.config.getConfig().serverEndpoint}ClientReport/${firmId}/Firm`;
    const api = `${endpoint}?Page=${page}&PageSize=${pageSize}&usePageSize=${usePageSize}`;

    return this.httpClient.get<ClientEndorsement[]>(api, { observe: 'response' });
  }

  submitFeedback(data: ClientFeedback): Observable<any> {
    console.log(data)
    const api = `${this.config.getConfig().serverEndpoint}clientFeedback`;

    return this.httpClient.post<any>(api, data);
  }
}
