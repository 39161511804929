import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Endpoints } from "../models/shared-model/endpoint";
import { AppConfigService } from "../shared/helpers/app-config.service";
import { CommentModel, CommentViewModel } from "../models/view-model/comment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CommentService {
  Endpoints = new Endpoints(this.config);

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) {}

  public Comment(comment: CommentModel): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `comment`;
    //console.log(url);
    return this.httpClient.post<any>(url, comment);
  }

  public writeComment(comment: CommentModel): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `Document/Comment`;
    //console.log(url);
    return this.httpClient.post<any>(url, comment);
  }

  public getComment(taskId: number): Observable<CommentViewModel[]> {
    const api =
      this.config.getConfig().serverEndpoint + `comment/${taskId}/Task`;
    //console.log(api);
    return this.httpClient.get<CommentViewModel[]>(api).pipe(
      map((comments) => {
        let newComments: CommentViewModel[] = [];
        comments.forEach((c) => {
          c.dateAddedInNumber = +c.dateAdded;
          newComments.push(c);
        });

        return newComments.sort((d) => d.dateAddedInNumber);
      })
    );

    // return res;
  }

  public deleteComment(commentId: string): Observable<any> {
    const api = this.config.getConfig().serverEndpoint + `comment/${commentId}`;
    return this.httpClient.delete<any>(api);
  }

  public revomeComment(commentId: string): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint + `comment/Task/${commentId}`;
    return this.httpClient.delete<any>(api);
  }
}
