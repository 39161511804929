import { Location } from "@angular/common";
import { Injectable } from "@angular/core";

@Injectable()
export class UtilsService {
  constructor(private _location: Location) {}

  goBack() {
    this._location.back();
  }

  // goto(location: string) {
  //     }
}
