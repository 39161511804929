import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomepageComponent } from "./home/homepage/homepage.component";
// import { LoginComponent } from "./auth/login/login.component";
// import { LogoutComponent } from "./auth/logout/logout.component";
// import { ConfirmAccountComponent } from "./auth/confirm-account/confirm-account.component";
// import { EmailConfirmationComponent } from "./auth/email-confirmation/email-confirmation.component";
// import { FirmRegistrationComponent } from "./auth/firm-registration/firm-registration.component";
// import { ForgotPasswordComponent } from "./auth/forgot-password/forgot-password.component";
import { NewInvitedUserComponent } from "./auth/components/new-invited-user/new-invited-user.component";
import { ResetPasswordComponent } from "./auth/components/reset-password/reset-password.component";
import { RegistrationSuccessComponent } from "./auth/components/registration-success/registration-success.component";
// import { HomeGuard } from "./shared/guards/home-guard.service";
import { MainGuard } from "./shared/guards/main-guard.service";
import { LicensePaymentComponent } from "./shared/license-payment/license-payment.component";
import { LicenseGuard } from "./shared/guards/license-guard.service";
// import { CalendarResolverService } from "./resolver/calendar-resolver.service";
// import { ForceLogoutComponent } from "./auth/force-logout/force-logout.component";
import { InHouseGuard } from "./shared/guards/in-house-guard";
import { SetPasswordComponent } from "./auth/components/set-password/set-password.component";

const routes: Routes = [
  {
    path: "",
    component: HomepageComponent,
    pathMatch: "full",
  },
  {
    path: "auth",
    loadChildren: "./auth/auth.module#AuthModule"
  },
  {
    path: "feedback",
    loadChildren: "./feedback/feedback.module#FeedbackModule",
  },
  {
    path: "analytics",
    loadChildren: "./analytics/analytics.module#AnalyticsModule",
    canActivate: [InHouseGuard],
  },
  {
    path: "firm",
    loadChildren: "./user-dashboard/user-dashboard.module#UserDashboardModule",
    canLoad: [MainGuard],
    canActivate: [LicenseGuard]
    // resolve: { activities: CalendarResolverService }
  },
  // { path: "login", component: LoginComponent, canActivate: [HomeGuard] },
  // { path: "set-password", component: SetPasswordComponent, canActivate: [HomeGuard] },
  // { path: "logout", component: LogoutComponent },
  // { path: "logout/:email", component: ForceLogoutComponent },
  // { path: "confirm-email/:email", component: ConfirmAccountComponent },
  // { path: "email-confirmed", component: EmailConfirmationComponent },
  // { path: "login/:email", component: LoginComponent, canActivate: [HomeGuard] },
  // { path: "register", component: FirmRegistrationComponent },
  // { path: "forgot-password", component: ForgotPasswordComponent },
  // { path: "join", component: NewInvitedUserComponent },
  // { path: "reset-password", component: ResetPasswordComponent },
  // { path: "join-successfull", component: RegistrationSuccessComponent },
  { path: "license-payment", component: LicensePaymentComponent },
  { path: "draft", loadChildren: "./lp-doc/lp-doc.module#LpDocModule" },
  {
    path: "google-doc",
    loadChildren: "./google-doc/google-doc.module#GoogleDocModule",
  },
  {
    path: "manage-user",
    loadChildren:
      "./user-management/user-management.module#UserManagementModule",
    canLoad: [MainGuard],
  },
  {
    path: "reports",
    loadChildren:
      "./reports/reports.module#ReportsModule",
    canLoad: [MainGuard],
  },

  {
    path: "mailbox",
    loadChildren: "./mailbox/mailbox.module#MailboxModule",
    canLoad: [MainGuard],
  },

  {
    path: "new-brief",
    loadChildren: "./form-widgets/form-widgets.module#FormWidgetsModule",
    canLoad: [MainGuard],
  },

  {
    path: "**",
    loadChildren: "./error-pages/error-pages.module#ErrorPagesModule",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
