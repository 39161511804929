import { CalendarView } from "angular-calendar";
import { Action } from "@ngrx/store";
import { CalendarViewModel } from "../../models/view-model/calendar-event";
import {
  CaseListViewModel,
  CaseTitleViewModel,
} from "src/app/models/view-model/cases-list";
import { PrincipleViewModel } from "src/app/models/view-model/case-ration";
import { CaseAanlyticsViewModel } from "src/app/models/view-model/case-analytics";

export enum LPDocActionTypes {
  LOAD_CASES_SEARCH = "[Draft] LOAD_CASES_SEARCH",
  LOAD_CASES_SEARCH_SUCCESS = "[Draft] LOAD_CASES_SEARCH_SUCCESS",
  LOAD_CASES_SEARCH_FAIL = "[Draft] LOAD_CASES_SEARCH_FAIL",
  SHOW_DRAWER = "[Draft] SHOW_DRAWER",
  SHOW_AI_DRAWER = "[Draft] SHOW_AI_DRAWER",

  HIDE_DRAWER = "[Draft] HIDE_DRAWER",
  HIDE_AI_DRAWER = "[Draft] HIDE_AI_DRAWER",

  SET_CURRENT_CASE = "[Draft] SET_CURRENT_CASE",

  CLIP_TEXT = "[Draft] CLIP_TEXT",
  CLEAR_TEXT = "[Draft] CLEAR_TEXT",

  SEARCH_TEMPLATE = "[Draft] SEARCH_TEMPLATE",

  LOAD_CASE_RATIO = "[Draft] LOAD_CASE_RATIO",
  LOAD_CASE_RATIO_SUCCESS = "[Draft] LOAD_CASE_RATIO_SUCCESS",
  LOAD_CASE_RATIO_FAIL = "[Draft] LOAD_CASE_RATIO_FAIL",

  LOAD_CASE_ANALYTICS = "[Draft] LOAD_CASE_ANALYTICS",
  LOAD_CASE_ANALYTICS_SUCCESS = "[Draft] LOAD_CASE_ANALYTICS_SUCCESS",
  LOAD_CASE_ANALYTICS_FAIL = "[Draft] LOAD_CASE_ANALYTICS_FAIL",
}

export class LoadCasesSearch implements Action {
  readonly type = LPDocActionTypes.LOAD_CASES_SEARCH;
  constructor(
    public payload: {
      searchQuery: string;
      pageSize?: number;
      page?: number;
      usePageSize?: boolean;
    }
  ) {}
}

export class LoadCasesSearchSuccess implements Action {
  readonly type = LPDocActionTypes.LOAD_CASES_SEARCH_SUCCESS;
  constructor(public payload: CaseListViewModel) {}
}

export class LoadCasesSearchFail implements Action {
  readonly type = LPDocActionTypes.LOAD_CASES_SEARCH_FAIL;
  constructor(public payload: any) {}
}

export class ShowDrawer implements Action {
  readonly type = LPDocActionTypes.SHOW_DRAWER;
}

export class ShowAIDrawer implements Action {
  readonly type = LPDocActionTypes.SHOW_AI_DRAWER;
}

export class HideDrawer implements Action {
  readonly type = LPDocActionTypes.HIDE_DRAWER;
}

export class HideAIDrawer implements Action {
  readonly type = LPDocActionTypes.HIDE_AI_DRAWER;
}

export class SetCurrentCase implements Action {
  readonly type = LPDocActionTypes.SET_CURRENT_CASE;

  constructor(public payload: CaseTitleViewModel) {}
}

export class clipText implements Action {
  readonly type = LPDocActionTypes.CLIP_TEXT;
  constructor(public payload: string) {}
}

export class clearText implements Action {
  readonly type = LPDocActionTypes.CLEAR_TEXT;
}

export class LoadCaseRatio implements Action {
  readonly type = LPDocActionTypes.LOAD_CASE_RATIO;
  constructor(public payload: { suitNo: string; court: string }) {}
}

export class LoadCaseRatioSuccess implements Action {
  readonly type = LPDocActionTypes.LOAD_CASE_RATIO_SUCCESS;
  constructor(public payload: PrincipleViewModel) {}
}

export class LoadCaseRatioFail implements Action {
  readonly type = LPDocActionTypes.LOAD_CASE_RATIO_FAIL;
  constructor(public payload: any) {}
}

export class LoadCaseAnalytics implements Action {
  readonly type = LPDocActionTypes.LOAD_CASE_ANALYTICS;
  constructor(public payload: { ratio: string; court: string }) {
    console.log(payload);
  }
}

export class LoadCaseAnalyticsSuccess implements Action {
  readonly type = LPDocActionTypes.LOAD_CASE_ANALYTICS_SUCCESS;
  constructor(public payload: CaseAanlyticsViewModel) {}
}

export class LoadCaseAnalyticsFail implements Action {
  readonly type = LPDocActionTypes.LOAD_CASE_ANALYTICS_FAIL;
  constructor(public payload: any) {}
}

export class LoadSearchTemplate implements Action {
  readonly type = LPDocActionTypes.SEARCH_TEMPLATE;
  constructor(public payload: string) {}
}

export type LPDocActionsUnion =
  | LoadCasesSearch
  | LoadCasesSearchSuccess
  | LoadCasesSearchFail
  | ShowDrawer
  | HideDrawer
  | ShowAIDrawer
  | HideAIDrawer
  | LoadCaseRatio
  | LoadCaseRatioSuccess
  | LoadCaseRatioFail
  | LoadCaseAnalytics
  | LoadCaseAnalyticsSuccess
  | LoadCaseAnalyticsFail
  | SetCurrentCase
  | clipText
  | clearText
  | LoadSearchTemplate;
