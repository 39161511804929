import { Location } from "@angular/common";
import { Injectable } from "@angular/core";

@Injectable()
export class UtilService {
  constructor(private _location: Location) {}

  goBack() {
    this._location.back();
  }

  // Create Blob file from URL
  public blobCreationFromURL(inputURI): any {
    const base64string = inputURI.changingThisBreaksApplicationSecurity;

    let binaryVal;

    // mime extension extraction
    let inputMIME = base64string.split(",")[0].split(":")[1].split(";")[0];
    // Extract remaining part of URL and convert it to binary value
    if (base64string.split(",")[0].indexOf("base64") >= 0)
      binaryVal = atob(base64string.split(",")[1]);
    // Decoding of base64 encoded string
    else binaryVal = decodeURI(base64string.split(",")[1]);

    // Computation of new string in which hexadecimal
    // escape sequences are replaced by the character
    // it represents

    // Store the bytes of the string to a typed array
    let blobArray: any = [];
    for (let index = 0; index < binaryVal.length; index++) {
      blobArray.push(binaryVal.charCodeAt(index));
    }

    return new Blob([blobArray], {
      type: inputMIME,
    });
  }
}
