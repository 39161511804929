export class TagOptionGenericModel {
  // enum is better to pass the option around this option if you can redesign
  // or use enum along side

  isMatter: boolean = false;
  isEndorsement: boolean = false;
  isApplication: boolean = false;
  isUserTask: boolean = false;
}

export enum FeatureTaskEnum {
  Matter = 1,
  Endorsement = 2,
  Application = 3,
  Me = 4,
  Others = 5,
}

export enum FeedbackTypeEnum {
  Endorsement = 1,
  ContractMilestone = 2,
}

