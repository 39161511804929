import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
} from "@angular/core";
import { Reminder } from "src/app/models/view-model/matter-tasks";
import moment from "moment";
import * as fromTask from "../../store/reducers/task.reducer";
import { FormGroup, Validators, FormBuilder, FormArray } from "@angular/forms";
import { Store, select } from "@ngrx/store";
import * as TaskActions from "../../store/actions/task.action";
import { UserDetail } from "src/app/models/view-model/user-detail";

import * as fromStore from "../../auth/state/index";
import * as LoginActions from "../../auth/state/login.actions";
import { TaskParseModel } from "src/app/models/shared-model/newTask";
import { CommonUserModel } from "src/app/models/generic-model.ts/user-share";
import { TagOptionGenericModel } from "src/app/models/generic-model.ts/tag-option";
import { Notifier } from "../base-class/notifier";
import * as fromUserHeaderStore from "../../store/reducers/user-header.reducer";
import { TaskDetailViewModel } from "../../models/view-model/task-detail";
import { FileUploadModel } from "src/app/models/generic-model.ts/file-upload";
import { UserTask } from "src/app/models/view-model/user-tasks";
import { isNullOrUndefined } from "util";
import { GoogleCalendarService } from "src/app/services/google-calendar.service";

const RecurrenceInterval: Record<number, string> = {
  0: "DAILY",
  1: "WEEKLY",
  2: "BI-WEEKLY",
  3: "MONTHLY",
  4: "QUARTERLY",
  5: "YEARLY",
};
const ColorId: Record<number, number> = {
  0: 4, //red
  1: 6, //orange
  2: 1, //blue
};

@Component({
  selector: "app-update-task-modal",
  templateUrl: "./update-task-modal.component.html",
  styleUrls: ["./update-task-modal.component.scss"],
})
export class UpdateTaskModalComponent
  extends Notifier
  implements OnInit, OnChanges, OnDestroy
{
  btnSpinner: boolean;
  componentActive: boolean;

  @Output() newTaskEmit = new EventEmitter<TaskDetailViewModel>();
  showChildModal: boolean;

  @Output() updateUser = new EventEmitter<CommonUserModel[]>();

  @Input() taskTeamMembers: CommonUserModel[] = [];

  @Input() editTask: UserTask;

  @ViewChild("closeTaskModal") closeTaskModal: ElementRef;
  @ViewChild("closeNewEventModal") closeNewEventModal: ElementRef;
  reminders: any = {};
  editTaskForm: FormGroup;
  user = new UserDetail();
  firmId: any;
  resourceId: number;
  _taskTag = new TagOptionGenericModel();
  showResourceNotSelectedError: boolean;
  // createNewTask = new TaskParseModel();
  createNewTask = new UserTask();
  errorText: string;
  showError: boolean;
  fileProgressArray: number[] = [];
  currentDocument: FileUploadModel[] = [];
  gCalendarEvents: any[] = [];
  gCalId: string;

  // tagArray: any[] = []

  constructor(
    private fb: FormBuilder,
    private taskStore: Store<fromTask.State>,
    private store: Store<fromStore.State>,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>,
    private gCalendarService: GoogleCalendarService
  ) {
    super(headerStore);
    this.componentActive = true;

    this.store.dispatch(new LoginActions.LoadLocalUserDetail());

    this.store.pipe(select(fromStore.getUserDetail)).subscribe((data) => {
      this.user = data;
    });

    this.addSubscription(
      gCalendarService.gCalId.subscribe((gCalId) => (this.gCalId = gCalId))
    );
    this.addSubscription(
      gCalendarService.gCalendarEvents.subscribe(
        (gCalendarEvents) => (this.gCalendarEvents = gCalendarEvents)
      )
    );

    // FOR UPDATE TASK SUCCESS
    this.addSubscription(
      this.taskStore
        .pipe(select(fromTask.updateTaskSuccess))
        .subscribe((success) => {
          if (success) {
            this.btnSpinner = false;
            this.taskStore.dispatch(
              new TaskActions.SetCurrentTask({ ...this.createNewTask })
            );

            this.pushMessage("Updating task, Successful");
            this.closeTaskModal.nativeElement.click();
          }
        })
    );

    // FOR UPDATE TASK ERROR
    this.taskStore.pipe(select(fromTask.updateTaskError)).subscribe((error) => {
      if (error.length > 2) {
        this.btnSpinner = false;
        this.sendErrorMessage("Adding task, Failed");
      }
    });

    // new task form builder
    this.editTaskForm = this.fb.group({
      taskName: ["", Validators.required],
      description: [""],
      taskPriorityId: ["", Validators.required],
      dueDate: ["", Validators.required],
      reminders: this.fb.array([]),
      isReoccurringTask: [false],
      reoccurringInterval: [""],
      taskTag: [0, Validators.required],
    });
  }

  ngOnInit() {
    this.showResourceNotSelectedError = false;
    this.resourceId = 0;
    this.btnSpinner = false;
    this.showError = false;
  }

  ngOnChanges(changes) {
    if (!isNullOrUndefined(this.editTask)) {
      this.editTaskMode();
    }
  }

  editTaskMode() {
    this.editTaskForm.reset();
    this.resourceId = this.editTask.resourceId;
    this.reminders = [];
    let p_id;
    if (this.editTask.tag) {
      if (this.editTask.tag.toLocaleLowerCase().includes("high")) {
        p_id = 1;
      } else if (this.editTask.tag.toLocaleLowerCase().includes("normal")) {
        p_id = 2;
      } else if (this.editTask.tag.toLocaleLowerCase().includes("critical")) {
        p_id = 0;
      }
    }

    this.editTaskForm.patchValue({
      taskName: this.editTask.name,
      taskTag: this.editTask.resourceId,
      taskPriorityId: this.editTask.tagId,
      dueDate: this.editTask.dueDate
        ? new Date(+this.editTask.dueDate).toISOString().slice(0, 16)
        : "",
      description: this.editTask.description,
    });
  }

  // from emitter
  getattachedObject(output) {
    this.errorText = output.errorText;
    this.showError = output.showError;
    this.fileProgressArray = output.fileProgressArray;
    this.currentDocument = output.currentDocument;
  }

  // for new task form control
  get taskName() {
    return this.editTaskForm.get("taskName");
  }

  get taskTag() {
    return this.editTaskForm.get("taskTag");
  }

  get description() {
    return this.editTaskForm.get("description");
  }

  get taskPriorityId() {
    return this.editTaskForm.get("taskPriorityId");
  }

  get dueDate() {
    return this.editTaskForm.get("dueDate");
  }

  get isReoccurringTask() {
    return this.editTaskForm.get("isReoccurringTask");
  }

  get reoccurringInterval() {
    return this.editTaskForm.get("reoccurringInterval");
  }

  getSelectedOption(resourceId) {
    if (Number.parseInt(resourceId, 10) > 0) {
      this.resourceId = resourceId;
    }
  }

  saveTask() {
    const reminderDates: Reminder[] = [];
    if (this.reminders.length !== 0) {
      this.reminders.controls.forEach((controlData) => {
        const data = controlData.value;
        const interval = data.interval;
        let i = "";
        if (interval.toLowerCase().includes("minute")) {
          i = "m";
        }
        if (interval.toLowerCase().includes("hour")) {
          i = "h";
        }
        if (interval.toLowerCase().includes("day")) {
          i = "d";
        }
        if (interval.toLowerCase().includes("week")) {
          i = "w";
        }
        if (interval.toLowerCase().includes("month")) {
          i = "M";
        }
        if (interval.toLowerCase().includes("year")) {
          i = "y";
        }

        const value = data.value;
        const newDate: number = moment(this.dueDate.value)
          .subtract(i, value)
          .unix();
        reminderDates.push({
          reminderTime: newDate,
          message: interval + "#" + value,
        });
      });

      this.createNewTask.reminders = reminderDates;
      this.createNewTask.isReoccurringTask =
        this.isReoccurringTask.value || false;
      this.createNewTask.reoccurringInterval = Number(
        this.reoccurringInterval.value
      );
    }

    if (+this.taskTag.value > 0 && +this.taskTag.value !== 4) {
      // if no matter or application or resource is selected

      if (this.resourceId > 0) {
      } else {
        this.showResourceNotSelectedError = true;
        return;
      }
    }

    //console.log(this.createNewTask);
    //console.log(this.editTask);

    this.createNewTask.id = this.editTask.id;
    this.createNewTask.name = this.taskName.value;
    this.createNewTask.startDate = `${new Date().getTime()}`;
    this.createNewTask.description = this.description.value;
    this.createNewTask.dueDate = `${new Date(this.dueDate.value).getTime()}`;

    this.createNewTask.tagId = Number(this.taskPriorityId.value);

    /**
     * this numbers are independent of the Backend
     */
    this.createNewTask.tagOption = new TagOptionGenericModel();
    if (+this.taskTag.value === 4) {
      this.createNewTask.tagOption.isUserTask = true;
    } else if (+this.taskTag.value === 3) {
      this.createNewTask.resourceId = Number(this.resourceId);
      this.createNewTask.tagOption.isApplication = true;
    } else if (+this.taskTag.value === 2) {
      this.createNewTask.resourceId = Number(this.resourceId);
      this.createNewTask.tagOption.isEndorsement = true;
    } else if (+this.taskTag.value === 1) {
      this.createNewTask.resourceId = Number(this.resourceId);
      this.createNewTask.tagOption.isMatter = true;
    } else {
      this.createNewTask.resourceId = Number(this.resourceId);
    }

    const gCalendarEvent = this.linkGCalEventToCaseCalEvent(this.editTask);
    const gCalPayload = this.formatToGCalData(this.createNewTask);
    if (this.gCalId)
      this.gCalendarService
        .updateEvent(gCalPayload, this.gCalId, gCalendarEvent.id)
        .subscribe(
          (res) => {},
          (error) => {
            //console.log(error.error);
            this.sendErrorMessage("Updating Task Failed!");
          }
        );
    this.taskStore.dispatch(new TaskActions.UpdateTask(this.createNewTask));
  }

  linkGCalEventToCaseCalEvent(item) {
    const gCalendarEvent = this.gCalendarEvents.find(
      (event) => event.summary === item.name
    );
    //console.log(gCalendarEvent)
    return gCalendarEvent;
  }
  /**
   * format value to gCal expected data
   * @param {Object} item
   * @returns
   */
  formatToGCalData(item: UserTask) {
    let interval;
    //helper function
    let reoccurringInterval = (val) => {
      if (val == "BI-WEEKLY") {
        interval = 2;
        return "WEEKLY";
      } else if (val == "QUARTERLY") {
        interval = 4;
        return "MONTHLY";
      }
      return val;
    };

    let reminders =
      item.reminders &&
      item.reminders.map((reminder) => {
        let start = moment(new Date(+reminder.reminderTime * 1000));
        let end = moment(new Date(+item.dueDate));
        let duration = moment.duration(end.diff(start));
        let min = duration.asMinutes();
        //console.log(min);
        // Valid reminder minutes values are between 0 and 40320 (4 weeks in minutes)
        const gReminder = {
          method: "popup",
          minutes: min > 40320 ? 40320 : min,
        };
        return gReminder;
      });

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let endDate = new Date(+item.dueDate);

    const payload = {
      colorId: ColorId[item.tagId],
      end: {
        dateTime: endDate,
        timeZone,
      },
      reminders: {
        overrides: reminders ? reminders : [],
        useDefault: false,
      },
      start: {
        dateTime: new Date(+item.startDate),
        timeZone,
      },
      summary: item.name,
    };
    return payload;
  }

  createReminder(defaultValue): FormGroup {
    return this.fb.group({
      value: [defaultValue.value, Validators.required],
      interval: [defaultValue.interval, Validators.required],
    });
  }

  addReminder(defaultValue = { value: 1, interval: "Days before due" }): void {
    this.reminders = this.editTaskForm.get("reminders") as FormArray;
    this.reminders.push(this.createReminder(defaultValue));
  }
  removeReminder(index): void {
    this.reminders.removeAt(index);
  }

  removeMember(removedUser) {
    // invert the original selection
    // this.matterTeamMembers.find(data => data.userId === userId).selected =
    // !this.matterTeamMembers.find(data => data.userId === userId).selected;
    this.taskTeamMembers.splice(
      this.taskTeamMembers.indexOf(
        this.taskTeamMembers.find((data) => data.id === removedUser.id)
      ),
      1
    );

    this.updateUser.emit(this.taskTeamMembers);
  }

  trackByFn(index, item) {
    this.currentDocument.indexOf(item);

    return index;
  }

  closeFileItem(index) {
    //console.log(index);
  }

  ngOnDestroy(): void {
    this.clearErrorMessage();
    this.clearSubscription();
  }
}
