import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../../services/auth.service";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-email-confirmation",
  templateUrl: "./email-confirmation.component.html",
  styleUrls: ["./email-confirmation.component.scss"]
})
export class EmailConfirmationComponent implements OnInit {
  token: string;
  confirmation_status: string;
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.confirmation_status = 'confirmation in progress...'
    this.token = this.route.snapshot.queryParams["token"];
    if (!isNullOrUndefined(this.token)) {
      this.authService
        .confirmEmail(this.token)
        .toPromise()
        .then(data => {
          this.confirmation_status = 'confirmation successful, redirecting....'
          this.router.navigate(["/login"]);
        })
        .catch(err => {
         this.confirmation_status = 'Confirmation FAILED !!!!! Its possible the email has expired.'
        });
    } else {
      return;
    }
  }
}
