import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  animate,
  animation,
  style,
  transition,
  trigger,
  useAnimation,
} from "@angular/animations";

const showAnimation = animation([
  style({ transform: "{{transform}}", opacity: 0 }),
  animate("{{transition}}"),
]);
const hideAnimation = animation([
  style({ transform: "{{transform}}", opacity: 0 }),
  animate("{{transition}}", style({ transform: "{{transform}}", opacity: 0 })),
]);
@Component({
  selector: "app-drawer",
  templateUrl: "./drawer.component.html",
  styleUrls: ["./drawer.component.scss"],
  animations: [
    trigger("panelState", [
      transition("void => visible", [useAnimation(showAnimation)]),
      transition("visible => void", [useAnimation(hideAnimation)]),
    ]),
  ],
})
export class DrawerComponent implements OnInit {
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() backEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onHide: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() header: string = "";
  @Input() showCloseIcon: boolean = true;

  @Input() get visible(): boolean {
    return this._visible;
  }
  set visible(val) {
    this._visible = val;
    this.visibleChange.emit(this._visible);
  }
  public appenTo: string = "body";
  public container: any;
  public _visible: boolean = false;

  public transformOptions: any = "translate3d(-100%, 0, 0)";
  public transitionOptions: string = "150ms cubic-bezier(0, 0, 0.2, 1)";

  public _position: string = "left";

  @Input() public get position(): string {
    return this._position;
  }

  public set position(value: string) {
    this._position = value;

    switch (value) {
      case "left":
        this.transformOptions = "translate3d(-100%, 0, 0)";
        break;
      case "right":
        this.transformOptions = "translate3d(100%, 0, 0)";
        break;
      case "top":
        this.transformOptions = "translate3d( 0,100%, 0)";
        break;
      case "bottom":
        this.transformOptions = "translate3d( 0,-100%, 0)";
        break;
    }
  }

  constructor() {}

  public hide(): void {
    this.onHide.emit(true);
  }

  public close(event: Event): void {
    this.visible = false;
    this.visibleChange.emit(false);
    event.preventDefault();
  }

  /**
   * Start Animation
   *
   * @private
   * @member of DrawerComponent
   */
  public onAnimationStart(event: any): void {
    switch (event.toState) {
      case "visible":
        this.container = event.element;
        this.appendContainer();
        break;
    }
  }

  public onAnimationEnd(event: any): void {
    switch (event.toState) {
      case "void":
        this.hide();
        break;
    }
  }

  public appendContainer(): void {
    document.body.appendChild(this.container);
  }

  public back(): void {
    this.backEvent.emit(true);
  }

  ngOnInit() {}
}
