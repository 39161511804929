import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "totalHours",
})
export class TotalHoursPipe implements PipeTransform {
  transform(value: number): string {
    let hours = Math.floor(value / 3600);
    let minutes = ((value % 3600) / 60).toFixed(0);
    return `${hours} hr(s) ${minutes} min(s)`;
  }
}
