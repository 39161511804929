import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UserDetail } from "../../models/view-model/user-detail";
import { MatterList } from "../../models/view-model/matter-list";
import { MatterService } from "../../services/matter.service";
import { Store, select } from "@ngrx/store";
import { isNullOrUndefined } from "util";
import * as fromStore from "../../auth/state/index";
import * as LoginActions from "../../auth/state/login.actions";
import * as fromContact from "../../store/reducers/contacts.reducer";
import { ContactService } from "src/app/contact/services/contact.service";
import { ContactViewModel } from "src/app/contact/models/contact-list";

@Component({
  selector: "app-contact-search-box",
  templateUrl: "./contact-search-box.component.html",
  styleUrls: ["./contact-search-box.component.scss"],
})
export class ContactSearchBoxComponent implements OnInit {
  pageSize = 10;
  user = new UserDetail();
  contactList: ContactViewModel[] = [];
  titleString: string;

  @Input() selectedContactId: number;

  currentMatterId: number;

  // @Input() searchBoxTagOption: number;

  @Input() disableChangeOfItem: boolean;

  @Input() selectedMatterName: string;

  @Output() postSelectedMatter = new EventEmitter();

  constructor(
    private matterService: MatterService,
    private contactService: ContactService,
    private store: Store<fromStore.State>,
    private contactStore: Store<fromContact.State>
  ) {
    this.store.dispatch(new LoginActions.LoadLocalUserDetail());

    this.store.pipe(select(fromStore.getUserDetail)).subscribe((data) => {
      //console.log(data);
      this.user = data;
    });
    this.contactService
      .getAllContacts(this.user.firmId, "", "", "", 0, this.pageSize)
      .subscribe((data) => {
        //console.log(data);
        this.contactList = data.body;
      });
  }

  ngOnInit() {
    this.contactService
      .getAllContacts(this.user.firmId, "", "", "", 0, this.pageSize)
      .subscribe((data) => {
        //console.log(data);
        this.contactList = data.body;
      });
  }

  selectMatter(name: string, firstName: string, id: number) {
    //console.log(name);
    this.selectedMatterName = name + " " + firstName;
    this.selectedContactId = id;

    this.postSelectedMatter.emit(this.selectedContactId.toString());
  }

  searchMatter(value) {
    this.contactService
      .getAllContacts(this.user.firmId, value, "", "", 0, this.pageSize)
      .subscribe((data) => {
        this.contactList = data.body;
      });
  }
}
