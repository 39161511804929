import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { Endpoints } from "../../models/shared-model/endpoint";
import { ContactViewModel } from "../models/contact-list";
import { ContactLabel } from "../models/contact-label";
import { Contact } from "../models/add-contact";
import { FirmLabelParseModel } from "../../models/parse-model/firm-label";
import { FirmLabelEditModel } from "../../models/edit-model/firm-label";
import { AppConfigService } from "../../shared/helpers/app-config.service";
import { ContactStatus } from "../../models/parse-model/add-matter";
import { EditContactModel } from "../models/edit-contact.model";
import { ClientReportListViewModel } from "src/app/matter-endorsement/models/client-report-list";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ContactService {
  Endpoints = new Endpoints(this.config);

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) { }

  getAllContacts(
    firmId: number,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex: number,
    pageSize: number
  ): Observable<any> {
    const usePageSize = true;
    const api = `${this.config.getConfig().serverEndpoint
      }contact/${firmId}/firm`;
    // tslint:disable-next-line:max-line-length
    const url = `${api}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${pageIndex + 1
      }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: "response" });
  }

  getContactLead(
    firmId: number,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex: number,
    pageSize: number
  ): Observable<any> {
    const usePageSize = true;
    const api =
      this.config.getConfig().serverEndpoint +
      "contact/" +
      firmId +
      "/firmlead";
    // tslint:disable-next-line:max-line-length
    const url = `${api}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${pageIndex + 1
      }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: "response" });
  }

  getContactClient(
    firmId: number,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex: number,
    pageSize: number
  ): Observable<HttpResponse<ContactViewModel[]>> {
    const usePageSize = true;
    const api = `${this.config.getConfig().serverEndpoint
      }contact/${firmId}/firmclient`;
    // tslint:disable-next-line:max-line-length
    const url = `${api}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${pageIndex + 1
      }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<ContactViewModel[]>(url, {
      observe: "response",
    });
  }

  getOtherContact(
    firmId: number,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex: number,
    pageSize: number
  ): Observable<any> {
    const usePageSize = true;
    const api =
      this.config.getConfig().serverEndpoint + "contact/" + firmId + "/Others";
    // tslint:disable-next-line:max-line-length
    const url = `${api}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${pageIndex + 1
      }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: "response" });
  }

  public getClientListForInvoice(
    firmId: number,
    searchValue: string
  ): Observable<ContactViewModel[]> {
    const url =
      this.config.getConfig().serverEndpoint +
      `contact/${searchValue}/firm/${firmId}/clients`;
    return this.httpClient.get<ContactViewModel[]>(url);
  }

  public getAllClientListForInvoice(
    firmId: number,
    searchValue: string
  ): Observable<ContactViewModel[]> {
    const url =
      this.config.getConfig().serverEndpoint +
      `contact/${searchValue}/firm/${firmId}`;
    return this.httpClient.get<ContactViewModel[]>(url);
  }

  getContactStatus(categoryId: number): Observable<ContactStatus[]> {
    const url =
      this.config.getConfig().serverEndpoint +
      `Matter-Contact-Status/${categoryId}/Category`;
    return this.httpClient.get<ContactStatus[]>(url);
  }

  getContactSPartytatus(): Observable<ContactStatus[]> {
    const url =
      this.config.getConfig().serverEndpoint + `Matter-Contact-Status`;
    return this.httpClient.get<ContactStatus[]>(url);
  }

  public getFirmLabels(firmId: number): Observable<ContactLabel[]> {
    const url = this.config.getConfig().serverEndpoint + `firm/${firmId}/label`;
    return this.httpClient.get<ContactLabel[]>(url);
  }

  public getContactDetail(contactId: number): Observable<ContactViewModel> {
    const url =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.contactEndpoint +
      `/${contactId}`;
    return this.httpClient.get<ContactViewModel>(url);
  }

  public updateLabel(data: FirmLabelEditModel): Observable<ContactLabel> {
    const url =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.addFirmLabelEndpoint;
    return this.httpClient.put<any>(url, data);
  }

  public updateContactLabel(
    contactId: number,
    firmLabelId: number
  ): Observable<number> {
    const url = `${this.config.getConfig().serverEndpoint
      }contact/attach/firm/label`;
    return this.httpClient.post<number>(url, {
      contactId: contactId,
      firmLabelId: firmLabelId,
    });
  }

  public getContactLabelId(data: FirmLabelParseModel): Observable<number> {
    const url = this.config.getConfig().serverEndpoint + `contact/firm/label`;
    return this.httpClient.post<number>(url, data);
  }

  public updateContactStatus(
    contactId: number,
    statusId: number
  ): Observable<ContactViewModel> {
    const url = `${this.config.getConfig().serverEndpoint}${this.Endpoints.updateContactStatus
      }?contactId=${contactId}&statusId=${statusId}`;
    return this.httpClient.put<ContactViewModel>(url, {
      contactId: contactId,
      statusId: statusId,
    });
  }

  public addFirmLabel(data: FirmLabelParseModel) {
    const url =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.addFirmLabelEndpoint;
    return this.httpClient.post<any>(url, data);
  }

  public addContact(data: Contact) {
    const url =
      this.config.getConfig().serverEndpoint + this.Endpoints.contactEndpoint;
    return this.httpClient.post<any>(url, data);
  }

  public updateContact(data: EditContactModel) {
    const url =
      this.config.getConfig().serverEndpoint + this.Endpoints.contactEndpoint;
    return this.httpClient.put<any>(url, data);
  }

  getContactMatters(
    contactId: number,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex: number,
    pageSize: number,
    usePageSize = true
  ): Observable<any> {
    const api = `${this.config.getConfig().serverEndpoint
      }contact/${contactId}/matter`;
    // tslint:disable-next-line:max-line-length
    const url = `${api}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${pageIndex + 1
      }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: "response" });
  }



  // THIS IS VERY EPHEMERAL. REMEMBER TO LOOK INTO THE STORE FOLDER FOR NGRX EXECUTIONS TO REPLACE FOR IMPLEMENTATION
  // THIS GOES FOR BOTH MATTER AND CONTRACT
  getContactMatter(matterId: number): Observable<any> {
    const api = `${this.config.getConfig().serverEndpoint}matter/${matterId}`;
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get<any>(api, { observe: "response" });
  }

  getContactContract(contractId: number): Observable<any> {
    const api = `${this.config.getConfig().serverEndpoint}contract/${contractId}`;
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get<any>(api, { observe: "response" });
  }

  getContactMilestone(milestoneId: number): Observable<any> {
    const api = `${this.config.getConfig().serverEndpoint}contract/${milestoneId}/Milestone`;
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get<any>(api, { observe: "response" });
  }

  getContactEndorsement(endorsementId: number): Observable<any> {
    const api = `${this.config.getConfig().serverEndpoint}matter/proceeding/${endorsementId}`;
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get<any>(api, { observe: "response" });
  }

  public addContactFeedback(data: FirmLabelParseModel) {
    const url = `${this.config.getConfig().serverEndpoint}clientFeedback`;
    return this.httpClient.post<any>(url, data);
  }

  getFeedbackResource(resourceId: number, feedbacktypeId: number): Observable<any> {
    const api = `${this.config.getConfig().serverEndpoint}clientFeedback/resource/${resourceId}/feedback-type/${feedbacktypeId}`;
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get<any>(api, { observe: "response" });
  }





  getClientReports(
    contactId: number,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex: number,
    pageSize: number
  ): Observable<any> {
    const usePageSize = true;
    const api =
      this.config.getConfig().serverEndpoint +
      `ClientReport/${contactId}/Client`;
    // tslint:disable-next-line:max-line-length
    const url = `${api}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${pageIndex + 1
      }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: "response" });
  }

  getClientReportLite(
    contactId: number
  ): Observable<ClientReportListViewModel[]> {
    const url =
      this.config.getConfig().serverEndpoint +
      `ClientReport/${contactId}/Client`;

    return this.httpClient.get<ClientReportListViewModel[]>(url);
  }

  getContactInvoice(
    contactId: number,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex: number,
    pageSize: number
  ): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint +
      "contact/" +
      contactId +
      "/invoice";
    const usePageSize = true;
    console.log(contactId);
    // tslint:disable-next-line:max-line-length
    const url = `${api}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${pageIndex + 1
      }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: "response" });
  }
  // public getFirmLabels(firmId: number): Observable<ContactLabel[]> {
  //   const url = this.config.getConfig().serverEndpoint + `firm/${firmId}/label`;
  //   return this.httpClient.get<ContactLabel[]>(url);
  // }
  getContactDocument(
    contactId: number,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex: number,
    pageSize: number
  ): Observable<any> {
    const usePageSize = true;
    const api =
      this.config.getConfig().serverEndpoint + "Document/Contact/" + contactId;
    // tslint:disable-next-line: max-line-length
    const url = `${api}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${pageIndex + 1
      }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, { observe: "response" });
  }

  deleteContact(contactId: number): Observable<boolean> {
    const api = `${this.config.getConfig().serverEndpoint}contact/${contactId}`;
    return this.httpClient.delete<boolean>(api);
  }

  deleteMatterContact(
    matterId: number,
    contactId: number
  ): Observable<boolean> {
    const api =
      this.config.getConfig().serverEndpoint +
      `contact/${matterId}/${contactId}`;
    return this.httpClient.delete<boolean>(api);
  }

  resendReport(reportId: number): Observable<boolean> {
    const api =
      this.config.getConfig().serverEndpoint +
      "/ClientReport/Resendreport/" +
      reportId;
    return this.httpClient.get<boolean>(api);
  }
}
