import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatterService } from 'src/app/services/matter.service';
import { Notifier } from 'src/app/shared/base-class/notifier';
import * as fromUserHeaderStore from '../../store/reducers/user-header.reducer';
import { Store } from '@ngrx/store';
import { AttachedFileModel, FileUploadModel } from 'src/app/models/generic-model.ts/file-upload';
import { ExpenseFileFormat } from 'src/app/models/view-model/matter-expenses';

@Component({
  selector: 'app-new-expense',
  templateUrl: './new-expense.component.html',
  styleUrls: ['./new-expense.component.scss']
})
export class NewExpenseComponent extends Notifier implements OnInit {
  expenseForm: FormGroup;
  btnSpinner: boolean;
  errorText: string;
  showError: boolean;
  showResourceNotSelectedError: boolean;
  currentDocument: FileUploadModel[] = [];
  matterId: number = 0;
  matter_gotten_from_route: boolean = true;
  @ViewChild('messageBox') messageBox: ElementRef;
  @ViewChild('closeModal') closeModal: ElementRef;
  @Output() isSuccess = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>,
    private Service: MatterService
  ) {
    super(headerStore);
    this.expenseForm = fb.group({
      name: ['', Validators.required],
      desc: ['', Validators.required],
      date: ['', Validators.required],
      price: ['', [Validators.required, Validators.pattern('^[-+]?[0-9]+(?:,[0-9]{3})*(?:\.[0-9]+)?$')]],
      qty: ['', [Validators.required, Validators.pattern('^[0-9]+(\.[0-9]{1,2})?$')]]
    });
  }


  get expenseName() {
    return this.expenseForm.get('name');
  }

  get expenseDesc() {
    return this.expenseForm.get('desc');
  }

  get expenseDate() {
    return this.expenseForm.get('date');
  }

  get expensePrice() {
    return this.expenseForm.get('price');
  }

  get expenseQty() {
    return this.expenseForm.get('qty');
  }

  ngOnInit() {
    this.matterId = Number(this.route.parent.snapshot.parent.parent.paramMap.get('id'));
    this.showResourceNotSelectedError = false;
    this.isSuccess.emit(false);
    this.matter_gotten_from_route = !!this.matterId;
  }

  getSelectedOption(resourceId: string): void {
    let resourceIdNumber = parseInt(resourceId, 10);
    if (resourceIdNumber > 0) {
      this.matterId = resourceIdNumber;
    }

  }

  createExpense(): void {

    this.btnSpinner = true;

    if (!(this.matterId > 0)) {
      this.showResourceNotSelectedError = true;
      return;
    }

    const calcAmount = Number(this.expensePrice.value) * Number(this.expenseQty.value);
    const sendExpense = {
      amount: calcAmount,
      matterId: this.matterId,
      narration: this.expenseDesc.value,
      title: this.expenseName.value,
      dateRecorded: +(new Date(this.expenseDate.value)),
      quantity: this.expenseQty.value,
      price: this.expensePrice.value,
      files: this.fileRequestFormat(this.currentDocument)
    };

    this.Service.createExpense(sendExpense).subscribe(
      data => {
        if (data > 0) {
          this.pushMessage('Expense, <strong>' + sendExpense.title + '</strong> Was saved successfully');
          this.isSuccess.emit(true);
          this.btnSpinner = false;
          this.expenseForm.reset();
          this.closeModal.nativeElement.click();
        }
      },
      error => {
        this.sendErrorMessage('Adding Expense, ' + sendExpense.title + ' failed');
        this.isSuccess.emit(false);
        this.messageBox.nativeElement.focus();
        this.btnSpinner = false;
      }
    );


  }

  getattachedObject(output: AttachedFileModel): void {
    this.errorText = output.errorText;
    this.showError = output.showError;
    this.currentDocument = output.currentDocument;
  }

  getAttachedDriveFiles(driveFiles: FileUploadModel[]): void {
    this.currentDocument = driveFiles;
  }

  fileAttachError(event: string): void {
    this.sendErrorMessage(event);
  }

  deleteFile(event: number): void {
    this.currentDocument.splice(event, 1);
  }

  private fileRequestFormat(filesArray: FileUploadModel[]): ExpenseFileFormat[] {
    if (filesArray.length) {
      const formattedFiles = [];
      filesArray.forEach(file => {
        delete file.fileSize;
        let newFile: ExpenseFileFormat = file;
        newFile.description = file.fileType;
        formattedFiles.push(newFile);
      });

      return formattedFiles;
    }

    return [];
  }

}
