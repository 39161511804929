export class CompletePayment {
  paymentgatewayCharges: number; // only required when using paystack
  licensePaymentId: number; // retured value form firmLicense creation
  reference: string; // Transaction Refrence from either paystack or bank
  timeTransactionSentToGateway: string; // only used in paystack. nullable
  timeTransactionValidatedByGateway:  string; // only used in paystack, nullable
  transactionStatus: boolean; // used in paystack to know if the payment was successful
  isInternetTransfer:  boolean = false;
  isCheque: boolean = false;
  isCash:  boolean = false;
  isBankDeposit:  boolean = false;
  isPaystack:  boolean = false;
  crmPlanId: number; // form bucketPlan
  bankId: number;
  couponCode: string = "";
}
