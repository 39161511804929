import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NewNotificationViewModel } from '../../models/shared-model/notification';
import { NotificationService } from '../../services/notification.service';
import * as headertActions from '../../store/actions/user-header.actions';
import * as fromUserHeaderStore from '../../store/reducers/user-header.reducer';
import { Store } from '@ngrx/store';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-new-notification-list',
  templateUrl: './new-notification-list.component.html',
  styleUrls: ['./new-notification-list.component.scss']
})
export class NewNotificationListComponent implements OnInit {

  selection = new SelectionModel<NewNotificationViewModel>(true, []);
  notificationsLoading: boolean;
  allSelected: boolean = false;
  notificationList: NewNotificationViewModel[] = [];
  @Input('limit') limit: number | boolean = false;

  @Output() notoficationCount = new EventEmitter();

  constructor(private _notyService: NotificationService,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>
  ) {
    this.notificationsLoading = true;
  }

  ngOnInit() {
    this.getAllNotifications();
  }

  getAllNotifications() {
    this._notyService.getNewNotifications().subscribe(

      data => {
        this.headerStore.dispatch(new headertActions.SetNotificationCount(data.body.length));
        this.notificationsLoading = false;
        if (!this.limit) {
          this.notificationList = data.body;
        } else {
          this.notificationList = data.body.slice(0, <number>this.limit);
        }
      },
      error => {
      },
      () => {
      }
    );
  }

  masterToggle(): void {

  }

}
