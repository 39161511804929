import { createFeatureSelector, createSelector } from "@ngrx/store";
import { LPDocActionTypes, LPDocActionsUnion } from "../actions/lp-doc.actions";
import * as fromRoot from "../app.state";
import { isNullOrUndefined } from "util";
import {
  CaseListViewModel,
  CaseTitleViewModel,
} from "src/app/models/view-model/cases-list";
import { PrincipleViewModel } from "src/app/models/view-model/case-ration";
import { CaseAanlyticsViewModel } from "src/app/models/view-model/case-analytics";

export interface State extends fromRoot.State {
  lp_doc: LPDocState;
}

export interface LPDocState {
  searchResult: CaseListViewModel;
  startedSearch: boolean;
  isSearchCompleted: boolean;
  searchError: any;
  isDrawerOpen: boolean;
  isAIDrawerOpen: boolean;
  currentSearchValue: string;
  currentCase: CaseTitleViewModel;

  clipBoard: string;

  pricipleResult: PrincipleViewModel;
  isPincipleRequestCompleted: boolean;
  isStartPrincipleRequest: boolean;
  pricipleRequestError: any;

  analyticsResult: CaseAanlyticsViewModel;
  isAnalyticsRequestCompleted: boolean;
  isStartAnalyticsRequest: boolean;
  analyticsRequestError: any;
}

export const initialLPDocState: LPDocState = {
  // change this once you get the model
  searchResult: new CaseListViewModel(),
  startedSearch: false,
  isSearchCompleted: false,
  searchError: null,
  isDrawerOpen: false,
  isAIDrawerOpen: false,
  currentSearchValue: "",
  currentCase: new CaseTitleViewModel(),
  clipBoard: "",
  pricipleResult: new PrincipleViewModel(),
  isPincipleRequestCompleted: false,
  isStartPrincipleRequest: false,
  pricipleRequestError: null,
  analyticsResult: new CaseAanlyticsViewModel(),
  isAnalyticsRequestCompleted: false,
  isStartAnalyticsRequest: false,
  analyticsRequestError: null,
};

const getLPDocFeatureState = createFeatureSelector<LPDocState>("lpDoc");

export const getSearchResult = createSelector(
  getLPDocFeatureState,
  (state) => state.searchResult
);

export const getSearchStartedState = createSelector(
  getLPDocFeatureState,
  (state) => state.startedSearch
);

export const getSearchCompletedState = createSelector(
  getLPDocFeatureState,
  (state) => state.isSearchCompleted
);

export const getSearchParam = createSelector(
  getLPDocFeatureState,
  (state) => state.currentSearchValue
);

export const getCurrentCase = createSelector(
  getLPDocFeatureState,
  (state) => state.currentCase
);

export const getSearchError = createSelector(
  getLPDocFeatureState,
  (state) => state.searchError
);

export const getSearchTemplate = createSelector(
  getLPDocFeatureState,
  (state) => state.currentSearchValue
);

export const getDrawerStatus = createSelector(
  getLPDocFeatureState,
  (state) => state.isDrawerOpen
);
export const getAIDrawerStatus = createSelector(
  getLPDocFeatureState,
  (state) => state.isAIDrawerOpen
);

export const getClipBoard = createSelector(
  getLPDocFeatureState,
  (state) => state.clipBoard
);

export const getPriciple = createSelector(
  getLPDocFeatureState,
  (state) => state.pricipleResult
);

export const getPrincipleError = createSelector(
  getLPDocFeatureState,
  (state) => state.pricipleRequestError
);

export const getPricipleCompletedStaus = createSelector(
  getLPDocFeatureState,
  (state) => state.isPincipleRequestCompleted
);

export const getPricipleRequestStartedStaus = createSelector(
  getLPDocFeatureState,
  (state) => state.isStartPrincipleRequest
);

export const getAnalytics = createSelector(
  getLPDocFeatureState,
  (state) => state.analyticsResult
);

export const getAnalyticsError = createSelector(
  getLPDocFeatureState,
  (state) => state.analyticsRequestError
);

export const getAnalyticsCompletedStaus = createSelector(
  getLPDocFeatureState,
  (state) => state.isAnalyticsRequestCompleted
);

export const getAnalyticsRequestStartedStaus = createSelector(
  getLPDocFeatureState,
  (state) => state.isStartAnalyticsRequest
);

export function lpDocReducer(
  state: LPDocState = initialLPDocState,
  action: LPDocActionsUnion
): LPDocState {
  switch (action.type) {
    case LPDocActionTypes.LOAD_CASES_SEARCH:
      return {
        ...state,
        searchResult: new CaseListViewModel(),
        startedSearch: true,
        isSearchCompleted: false,
        searchError: null,
        currentSearchValue: getSearchString(action.payload),
      };

    case LPDocActionTypes.SEARCH_TEMPLATE:
      return {
        ...state,
        currentSearchValue: action.payload,
      };

    case LPDocActionTypes.LOAD_CASES_SEARCH_SUCCESS:
      return {
        ...state,
        searchResult: action.payload,
        startedSearch: false,
        isSearchCompleted: true,
        searchError: null,
      };
    case LPDocActionTypes.LOAD_CASES_SEARCH_FAIL:
      return {
        ...state,
        searchResult: new CaseListViewModel(),
        startedSearch: false,
        isSearchCompleted: true,
        searchError: action.payload,
      };

    case LPDocActionTypes.SHOW_DRAWER:
      return {
        ...state,
        isDrawerOpen: true,
      };
    case LPDocActionTypes.HIDE_AI_DRAWER:
      return {
        ...state,
        isAIDrawerOpen: false,
      };
    case LPDocActionTypes.SHOW_AI_DRAWER:
      return {
        ...state,
        isAIDrawerOpen: true,
      };

    case LPDocActionTypes.SET_CURRENT_CASE:
      return {
        ...state,
        currentCase: action.payload,
      };

    case LPDocActionTypes.CLIP_TEXT:
      return {
        ...state,
        clipBoard: action.payload,
      };

    case LPDocActionTypes.CLEAR_TEXT:
      return {
        ...state,
        clipBoard: "",
      };

    case LPDocActionTypes.HIDE_DRAWER:
      return {
        ...state,
        isDrawerOpen: false,
      };

    case LPDocActionTypes.LOAD_CASE_RATIO:
      return {
        ...state,
        pricipleResult: new PrincipleViewModel(),
        isPincipleRequestCompleted: false,
        isStartPrincipleRequest: true,
      };

    case LPDocActionTypes.LOAD_CASE_RATIO_SUCCESS:
      return {
        ...state,
        pricipleResult: action.payload,
        isPincipleRequestCompleted: true,
        isStartPrincipleRequest: false,
      };
    case LPDocActionTypes.LOAD_CASE_RATIO_FAIL:
      return {
        ...state,
        pricipleResult: new PrincipleViewModel(),
        isPincipleRequestCompleted: true,
        isStartPrincipleRequest: false,
        pricipleRequestError: action.payload,
      };

    case LPDocActionTypes.LOAD_CASE_ANALYTICS:
      return {
        ...state,
        analyticsResult: new CaseAanlyticsViewModel(),
        isAnalyticsRequestCompleted: false,
        isStartAnalyticsRequest: true,
      };

    case LPDocActionTypes.LOAD_CASE_ANALYTICS_SUCCESS:
      return {
        ...state,
        analyticsResult: action.payload,
        isAnalyticsRequestCompleted: true,
        isStartAnalyticsRequest: false,
      };
    case LPDocActionTypes.LOAD_CASE_ANALYTICS_FAIL:
      return {
        ...state,
        analyticsResult: new CaseAanlyticsViewModel(),
        isAnalyticsRequestCompleted: true,
        isStartAnalyticsRequest: false,
        pricipleRequestError: action.payload,
      };

    default: {
      return state;
    }
  }
}

function getSearchString(value): string {
  let searchParam: string = value.searchQuery;

  if (searchParam.includes("*")) {
    searchParam = searchParam.split("*")[1];
  }

  return searchParam;
}
